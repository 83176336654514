import HttpClient from '@/services/http-client'

class DeliveryService extends HttpClient {
  async show(id) {
    return this.rest.get('/v1/deliveries/' + id)
  }

  async list(page = 1, perPage = 1000, text = null) {
    return this.rest.get('/v1/deliveries', {
      params: {
        page,
        perPage,
        q: text
      }
    })
  }

  async create(payload) {
    return this.rest.post('/v1/deliveries', payload)
  }

  async update(payload) {
    return this.rest.patch('/v1/deliveries/' + payload.id, payload)
  }

  async delete(id) {
    return this.rest.delete('/v1/deliveries/' + id)
  }

  async createOccurrence(id, { description = null, occurrenceType = null }) {
    return this.rest.post('/v1/deliveries/' + id + '/occurrences', {
      description,
      occurrenceType
    })
  }

  async listOccurrences(deliveryId) {
    return this.rest.get('/v1/deliveries/' + deliveryId + '/occurrences')
  }
}

export default () => new DeliveryService()
