<template>
  <div class="md-12">
    <b-input-group class="mb-3">
      <b-form-input
        id="example-input"
        v-model="datePicked"
        lazy-formatter
        :disabled="computedAttrs.disabled"
        :size="computedAttrs.size"
        :state="computedAttrs.disabled ? undefined : computedAttrs.state"
        :formatter="formatDate"
        type="text"
        placeholder="Digite ou Selecione a Data"
        locale="pt-BR"
        autocomplete="off"
      ></b-form-input>
      <b-input-group-append>
        <b-form-datepicker
          button-only
          right
          :reset-value="null"
          :date-format-options="{
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          }"
          @input="datePickerChange"
          class="row-md-7"
          reset-button
          today-button
          :disabled="computedAttrs.disabled"
          :size="computedAttrs.size"
          :state="computedAttrs.disabled ? undefined : computedAttrs.state"
          close-button
          :initial-date="null"
          label-no-date-selected="Nenhuma Data Selecionada"
          label-reset-button="Remover Data"
          label-today-button="Usar Hoje"
          label-today="Hoje"
          label-help="Use as setas para navegar nas datas do calendário"
          label-calendar="Calendário"
          label-close-button="Fechar"
          format="dd/mm/yyyy"
          aria-controls="example-input"
          @context="onContext"
          locale="pt-BR"
        ></b-form-datepicker>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'date-picker',
  created() {
    this.evaluateValue()
  },
  computed: {
    computedAttrs() {
      return this.getOwnAttrs()
    }
  },
  data() {
    return {
      datePicked: ''
    }
  },
  props: {
    // eslint-disable-next-line vue/no-unused-properties
    value: {
      type: [String, Object, Date],
      default: '',
      required: false
    }
  },
  methods: {
    evaluateValue() {
      const that = this
      setTimeout(() => {
        if (that.value) {
          if (that.value.length === 'DD/MM/YYYY'.length) {
            that.datePickerChange(
              moment(that.value, 'DD/MM/YYYY').format('yyyy-MM-DD')
            )
          } else if (that.value) {
            that.datePickerChange(moment(that.value).format('yyyy-MM-DD'))
          }
        }
      }, 1000)
    },
    datePickerChange(value) {
      const parsedDate = !value ? null : moment(value, 'yyyy-MM-DD')
      this.datePicked = parsedDate ? parsedDate.format('DD/MM/YYYY') : null
      if (!value || !parsedDate.isValid()) {
        this.emitDate(null)
        return 'Data Inválida'
      }
      this.emitDate(parsedDate)
    },
    formatDate(value) {
      const parsedDate = moment(value, 'DD/MM/YYYY')
      if (!value || !parsedDate.isValid()) {
        this.emitDate(null)
        return 'Data Inválida'
      }
      this.emitDate(parsedDate)
      return parsedDate.format('DD/MM/YYYY')
    },
    emitDate(momentDate) {
      this.$emit('input', momentDate ? momentDate.toDate() : null)
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      // if (ctx.selectedDate) {
      //   this.datePicked = ctx.selectedFormatted
      // }
      // The following will be an empty string until a valid date is entered
    }
  }
}
</script>

<style></style>
