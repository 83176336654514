<template>
  <multiselector
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
    track-by="id"
    label="name"
    :custom-label="val => `${val?.name}`"
    :options="vehicleTypeList"
    :multiple="false"
    :allow-empty="false"
    :taggable="false"
  >
  </multiselector>
</template>

<script>
import vehicleTypesMixin from '@/features/vehicle-types/store/mixin'

export default {
  name: 'vehicle-type-selector',
  async mounted() {
    this.listVehicleTypes()
  },

  props: {
    // eslint-disable-next-line vue/no-unused-properties
    rows: Array,
    // eslint-disable-next-line vue/no-unused-properties
    header: Array
  },
  mixins: [vehicleTypesMixin],
  methods: {}
}
</script>
