'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  setState
} from '@/helpers/store-helpers'

import integrationLogService from '@/features/integration-logs/integration-logs-api'

const busyMutateName = 'setIntegrationLogsBusy'

export default {
  namespaced: true,
  state: {
    integrationLogList: [],
    integrationLogFilter: '',
    integrationLogIsBusy: false,
    integrationLogTotalRows: 0,
    integrationLogCurrentPage: 1,
    integrationLogPerPage: 10,
    integrationLogLastPage: 0
  },
  actions: {
    async listIntegrationLogs({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await integrationLogService().list(
            page,
            perPage,
            text
          )
          setState(commit, 'integrationLogTotalRows', payload.total)
          setState(commit, 'integrationLogCurrentPage', payload.page)
          setState(commit, 'integrationLogPerPage', payload.perPage)
          setState(commit, 'integrationLogLastPage', payload.lastPage)

          commit('setIntegrationLogsList', payload.data)
        }
      )
    },
    async showIntegrationLog({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return integrationLogService().show(id)
        }
      )
    },
    async createIntegrationLog({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await integrationLogService().create(payload)
          commit('unshiftIntegrationLog', entry)
        }
      )
    },
    async updateIntegrationLog({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await integrationLogService().update(payload)
          commit('updateIntegrationLogInList', entry)
          return entry
        }
      )
    },
    async deleteIntegrationLog({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await integrationLogService().delete(id)
          if (result) await commit('removeIntegrationLogInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredIntegrationLogs(state) {
      return filterElements(state.integrationLogList, state.filter)
    }
  },
  mutations: {
    mutate,
    setIntegrationLogsList(state, payload) {
      state.integrationLogList = payload
    },
    setIntegrationLogsFilter(state, payload) {
      state.integrationLogList = payload
    },
    setIntegrationLogsBusy(state, value = false) {
      state.integrationLogIsBusy = value
    },
    unshiftIntegrationLog(state, payload) {
      state.integrationLogList.unshift(payload)
    },
    removeIntegrationLogInList(state, payload) {
      state.integrationLogList = state.integrationLogList.filter(
        integrationLog => integrationLog.id !== payload
      )
    },
    updateIntegrationLogInList(state, payload) {
      if (state.integrationLogList) {
        state.integrationLogList = state.integrationLogList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
