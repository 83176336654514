import Vue from 'vue'

import {
  // eslint-disable-next-line no-unused-vars
  AxiosInstance
} from 'axios'

export default class HttpClient {
  /** @type AxiosInstance */
  http

  constructor() {
    this.http = Vue.prototype.$http
  }

  get rest() {
    return {
      get: async (...args) => {
        return (await this.http.get(...args)).data
      },
      post: async (...args) => {
        return (await this.http.post(...args)).data
      },
      patch: async (...args) => {
        return (await this.http.patch(...args)).data
      },
      put: async (...args) => {
        return (await this.http.put(...args)).data
      },
      delete: async (...args) => {
        return (await this.http.delete(...args)).data
      }
    }
  }
}
