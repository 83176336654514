'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  setState
} from '@/helpers/store-helpers'

import vehiclePositionService from '@/features/vehicle-positions/vehicle-positions-api'

const busyMutateName = 'setVehiclePositionsBusy'

export default {
  namespaced: true,
  state: {
    vehiclePositionList: [],
    vehiclePositionFilter: '',
    vehiclePositionIsBusy: false,
    vehiclePositionTotalRows: 0,
    vehiclePositionCurrentPage: 1,
    vehiclePositionPerPage: 10,
    vehiclePositionLastPage: 0
  },
  actions: {
    async listVehiclePositions({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await vehiclePositionService().list(
            page,
            perPage,
            text
          )
          setState(commit, 'vehiclePositionTotalRows', payload.total)
          setState(commit, 'vehiclePositionCurrentPage', payload.page)
          setState(commit, 'vehiclePositionPerPage', payload.perPage)
          setState(commit, 'vehiclePositionLastPage', payload.lastPage)

          commit('setVehiclePositionsList', payload.data)
        }
      )
    },
    async showVehiclePosition({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return vehiclePositionService().show(id)
        }
      )
    },
    async createVehiclePosition({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await vehiclePositionService().create(payload)
          commit('unshiftVehiclePosition', entry)
        }
      )
    },
    async updateVehiclePosition({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await vehiclePositionService().update(payload)
          commit('updateVehiclePositionInList', entry)
          return entry
        }
      )
    },
    async deleteVehiclePosition({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await vehiclePositionService().delete(id)
          if (result) await commit('removeVehiclePositionInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredVehiclePositions(state) {
      return filterElements(state.vehiclePositionList, state.filter)
    }
  },
  mutations: {
    mutate,
    setVehiclePositionsList(state, payload) {
      state.vehiclePositionList = payload
    },
    setVehiclePositionsFilter(state, payload) {
      state.vehiclePositionList = payload
    },
    setVehiclePositionsBusy(state, value = false) {
      state.vehiclePositionIsBusy = value
    },
    unshiftVehiclePosition(state, payload) {
      state.vehiclePositionList.unshift(payload)
    },
    removeVehiclePositionInList(state, payload) {
      state.vehiclePositionList = state.vehiclePositionList.filter(
        vehiclePosition => vehiclePosition.id !== payload
      )
    },
    updateVehiclePositionInList(state, payload) {
      if (state.vehiclePositionList) {
        state.vehiclePositionList = state.vehiclePositionList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
