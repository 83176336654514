'use strict'

export default Vue => {
  const importedModules = require.context(__dirname, true, /\.(js|vue)$/i)
  const stringList = new Map()
  const plugins = importedModules
    .keys()
    .filter(key => {
      return !/(_loader)/im.test(key)
    })
    .map(key => {
      const name = key.match(/[\w\s-]+/)[0]
      if (process?.env?.NODE_ENV !== 'production') {
        stringList.set(name, key)
      }
      return importedModules(key).default
    })
  for (const registerFn of plugins) {
    registerFn(Vue)
  }
  if (process?.env?.NODE_ENV !== 'production') {
    console.log('LOADED PLUGINS', stringList)
  }
}
