var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Multiselector', _vm._g(_vm._b({
    attrs: {
      "track-by": "id",
      "label": "name",
      "custom-label": function (val) {
        var _val$originCity, _val$destinationCity;
        return ((val === null || val === void 0 ? void 0 : val.description) + " - " + (val === null || val === void 0 ? void 0 : (_val$originCity = val.originCity) === null || _val$originCity === void 0 ? void 0 : _val$originCity.name) + " a " + (val === null || val === void 0 ? void 0 : (_val$destinationCity = val.destinationCity) === null || _val$destinationCity === void 0 ? void 0 : _val$destinationCity.name) + " por ");
      },
      "show-labels": false,
      "options": _vm.pathList,
      "multiple": false,
      "allow-empty": false,
      "taggable": false
    },
    scopedSlots: _vm._u([{
      key: "singleLabel",
      fn: function (_ref) {
        var _props$id, _props$originUf$uf, _props$originUf, _props$destinationUf$, _props$destinationUf;
        var props = _ref.props;
        return [_c('span', {
          staticClass: "option__desc"
        }, [_c('span', {
          staticClass: "option__title"
        }, [_vm._v("\n        #" + _vm._s((_props$id = props === null || props === void 0 ? void 0 : props.id) !== null && _props$id !== void 0 ? _props$id : 'NI') + " Trajeto: de " + _vm._s((_props$originUf$uf = props === null || props === void 0 ? void 0 : (_props$originUf = props.originUf) === null || _props$originUf === void 0 ? void 0 : _props$originUf.uf) !== null && _props$originUf$uf !== void 0 ? _props$originUf$uf : 'NI') + " a\n        " + _vm._s((_props$destinationUf$ = props === null || props === void 0 ? void 0 : (_props$destinationUf = props.destinationUf) === null || _props$destinationUf === void 0 ? void 0 : _props$destinationUf.uf) !== null && _props$destinationUf$ !== void 0 ? _props$destinationUf$ : 'NI') + " passando por\n        "), _vm._l(props.journeys, function (entry) {
          var _entry$uf$id, _entry$uf, _entry$uf$name, _entry$uf2;
          return _c('span', {
            key: entry.id
          }, [_vm._v("\n          #" + _vm._s((_entry$uf$id = entry === null || entry === void 0 ? void 0 : (_entry$uf = entry.uf) === null || _entry$uf === void 0 ? void 0 : _entry$uf.id) !== null && _entry$uf$id !== void 0 ? _entry$uf$id : 'NI') + " - " + _vm._s((_entry$uf$name = entry === null || entry === void 0 ? void 0 : (_entry$uf2 = entry.uf) === null || _entry$uf2 === void 0 ? void 0 : _entry$uf2.name) !== null && _entry$uf$name !== void 0 ? _entry$uf$name : 'NI') + ",\n        ")]);
        })], 2)])];
      }
    }, {
      key: "option",
      fn: function (_ref2) {
        var _props$id2, _props$originUf$name, _props$originUf2, _props$destinationUf$2, _props$destinationUf2;
        var props = _ref2.props;
        return [_c('div', {
          staticClass: "option__desc"
        }, [_c('b-col', [_c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("\n            #" + _vm._s((_props$id2 = props === null || props === void 0 ? void 0 : props.id) !== null && _props$id2 !== void 0 ? _props$id2 : 'NI') + " Origem:\n            " + _vm._s((_props$originUf$name = props === null || props === void 0 ? void 0 : (_props$originUf2 = props.originUf) === null || _props$originUf2 === void 0 ? void 0 : _props$originUf2.name) !== null && _props$originUf$name !== void 0 ? _props$originUf$name : 'NI') + "\n          ")])]), _vm._v(" "), _c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("\n            Trajeto por:\n            "), _vm._l(props.journeys, function (entry) {
          var _entry$uf$id2, _entry$uf3, _entry$uf$uf, _entry$uf4, _entry$uf$name2, _entry$uf5;
          return _c('span', {
            key: entry.id
          }, [_vm._v("\n              #" + _vm._s((_entry$uf$id2 = entry === null || entry === void 0 ? void 0 : (_entry$uf3 = entry.uf) === null || _entry$uf3 === void 0 ? void 0 : _entry$uf3.id) !== null && _entry$uf$id2 !== void 0 ? _entry$uf$id2 : 'NI') + " - " + _vm._s((_entry$uf$uf = entry === null || entry === void 0 ? void 0 : (_entry$uf4 = entry.uf) === null || _entry$uf4 === void 0 ? void 0 : _entry$uf4.uf) !== null && _entry$uf$uf !== void 0 ? _entry$uf$uf : 'NI') + "\n              " + _vm._s((_entry$uf$name2 = entry === null || entry === void 0 ? void 0 : (_entry$uf5 = entry.uf) === null || _entry$uf5 === void 0 ? void 0 : _entry$uf5.name) !== null && _entry$uf$name2 !== void 0 ? _entry$uf$name2 : 'NI') + "\n            ")]);
        })], 2)]), _vm._v(" "), _c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v(" Destino: " + _vm._s((_props$destinationUf$2 = props === null || props === void 0 ? void 0 : (_props$destinationUf2 = props.destinationUf) === null || _props$destinationUf2 === void 0 ? void 0 : _props$destinationUf2.name) !== null && _props$destinationUf$2 !== void 0 ? _props$destinationUf$2 : 'NI') + " ")])])], 1)], 1)];
      }
    }])
  }, 'Multiselector', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners));
}
var staticRenderFns = []

export { render, staticRenderFns }