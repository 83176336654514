'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import freightContractValueService from '@/features/freight-contract-values/freight-contract-values-api'

const busyMutateName = 'setFreightContractValuesBusy'

export default {
  namespaced: true,
  state: {
    freightContractValueList: [],
    freightContractValueFilter: '',
    freightContractValueIsBusy: false,
    freightContractValueTotalRows: 0,
    freightContractValueCurrentPage: 1,
    freightContractValuePerPage: 10,
    freightContractValueLastPage: 0
  },
  actions: {
    async listFreightContractValues({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await freightContractValueService().list(page, perPage, text)
          setState(commit, 'freightContractValueTotalRows', payload.total)
          setState(commit, 'freightContractValueCurrentPage', payload.page)
          setState(commit, 'freightContractValuePerPage', payload.perPage)
          setState(commit, 'freightContractValueLastPage', payload.lastPage)

          commit('setFreightContractValuesList', payload.data)
        }
      )
    },
    async showFreightContractValue({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return freightContractValueService().show(id)
        }
      )
    },
    async createFreightContractValue({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await freightContractValueService().create(payload)
          commit('unshiftFreightContractValue', entry)
        }
      )
    },
    async updateFreightContractValue({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await freightContractValueService().update(payload)
          commit('updateFreightContractValueInList', entry)
          return entry
        }
      )
    },
    async deleteFreightContractValue({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await freightContractValueService().delete(id)
          if (result) await commit('removeFreightContractValueInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredFreightContractValues(state) {
      return filterElements(state.freightContractValueList, state.filter)
    }
  },
  mutations: {
    mutate,
    setFreightContractValuesList(state, payload) {
      state.freightContractValueList = payload
    },
    setFreightContractValuesFilter(state, payload) {
      state.freightContractValueList = payload
    },
    setFreightContractValuesBusy(state, value = false) {
      state.freightContractValueIsBusy = value
    },
    unshiftFreightContractValue(state, payload) {
      state.freightContractValueList.unshift(payload)
    },
    removeFreightContractValueInList(state, payload) {
      state.freightContractValueList = state.freightContractValueList.filter(
        freightContractValue => freightContractValue.id !== payload
      )
    },
    updateFreightContractValueInList(state, payload) {
      if (state.freightContractValueList) {
        state.freightContractValueList = state.freightContractValueList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
