import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  methods: {
    ...mapActions('storehouses', [
      'listStorehouses',
      'showStorehouse',
      'deleteStorehouse',
      'createStorehouse',
      'updateStorehouse'
    ]),
    ...mapMutations('storehouses', [
      'cleanStorehousesErrors',
      'setStorehousesList',
      'setStorehousesFilter',
      'setStorehousesBusy'
    ])
  },
  computed: {
    ...mapState('storehouses', [
      'storehouseIsBusy',
      'storehouseList',
      'storehouseFilter',
      'storehousePerPage',
      'storehouseCurrentPage',
      'storehouseTotalRows',
      'storehouseLastPage'
    ]),
    ...mapGetters('storehouses', [
      'filteredStorehouses'
    ])
  }
}
