<template>
  <b-overlay
    id="overlay-background"
    :show="measurementTypeIsBusy"
    variant="white"
    opacity="0.85"
    blur="2px"
    spinner-variant="primary"
    spinner-type="grow"
    :spinner-small="true"
    rounded="sm"
  >
    <multiselector
      v-bind="{ ...$props, ...$attrs }"
      v-on="$listeners"
      track-by="id"
      label="name"
      :custom-label="val => `${val.name} - ${val.sefaz_code}`"
      :options="measurementTypeList ?? []"
      :multiple="false"
      @select="onSelect"
      :allow-empty="false"
      :taggable="false"
    >
      <template slot="option" slot-scope="{ props }">
        <div class="option__desc">
          <b-col>
            <b-row>
              <span class="option__small"> Código Sefaz: {{ props?.sefaz_code ?? 'NI' }} </span>
            </b-row>
            <b-row>
              <span class="option__small"> Nome: {{ props?.name ?? 'NI' }} </span>
            </b-row>
          </b-col>
        </div>
      </template>
    </multiselector>
  </b-overlay>
</template>

<script>
import measurementTypesMixin from '@/features/measurement-types/store/mixin'

export default {
  name: 'document-type-selector',
  mixins: [measurementTypesMixin],
  props: {
    rows: Array,
    header: Array,
    value: { type: Object, default: null },
    emitOnMount: {
      default: false
    }
  },
  async mounted() {
    await this.listMeasurementTypes()
    if (this.emitOnMount) {
      this.emitMount()
    }
  },
  methods: {
    async emitMount() {
      this.onSelect(this.value)
    },
    onSelect(emitValue) {
      // eslint-disable-next-line vue/no-mutating-props
      this.value = emitValue
      console.warn('Emitted measurement', this.value)
      this.$emit('input', this.value)
      this.$emit('change', this.value)
    }
  }
}
</script>
