'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import departmentService from '@/features/departments/departments-api'

const busyMutateName = 'setDepartmentsBusy'

export default {
  namespaced: true,
  state: {
    departmentList: [],
    departmentFilter: '',
    departmentIsBusy: false,
    departmentTotalRows: 0,
    departmentCurrentPage: 1,
    departmentPerPage: 10,
    departmentLastPage: 0
  },
  actions: {
    async listDepartments({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await departmentService().list(page, perPage, text)
          setState(commit, 'departmentTotalRows', payload.total)
          setState(commit, 'departmentCurrentPage', payload.page)
          setState(commit, 'departmentPerPage', payload.perPage)
          setState(commit, 'departmentLastPage', payload.lastPage)

          commit('setDepartmentsList', payload.data)
        }
      )
    },
    async showDepartment({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return departmentService().show(id)
        }
      )
    },
    async createDepartment({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await departmentService().create(payload)
          commit('unshiftDepartment', entry)
        }
      )
    },
    async updateDepartment({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await departmentService().update(payload)
          commit('updateDepartmentInList', entry)
          return entry
        }
      )
    },
    async deleteDepartment({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await departmentService().delete(id)
          if (result) await commit('removeDepartmentInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredDepartments(state) {
      return filterElements(state.departmentList, state.filter)
    }
  },
  mutations: {
    mutate,
    setDepartmentsList(state, payload) {
      state.departmentList = payload
    },
    setDepartmentsFilter(state, payload) {
      state.departmentList = payload
    },
    setDepartmentsBusy(state, value = false) {
      state.departmentIsBusy = value
    },
    unshiftDepartment(state, payload) {
      state.departmentList.unshift(payload)
    },
    removeDepartmentInList(state, payload) {
      state.departmentList = state.departmentList.filter(
        department => department.id !== payload
      )
    },
    updateDepartmentInList(state, payload) {
      if (state.departmentList) {
        state.departmentList = state.departmentList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
