import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  methods: {
    ...mapActions('drivers', [
      'listDrivers',
      'showDriver',
      'deleteDriver',
      'createDriver',
      'updateDriver'
    ]),
    ...mapMutations('drivers', [
      'cleanDriversErrors',
      'setDriversList',
      'setDriversFilter',
      'setDriversBusy'
    ])
  },
  computed: {
    ...mapState('drivers', [
      'driverIsBusy',
      'driverList',
      'driverFilter',
      'driverPerPage',
      'driverCurrentPage',
      'driverTotalRows',
      'driverLastPage'
    ]),
    ...mapGetters('drivers', [
      'filteredDrivers'
    ])
  }
}
