<template>
  <multiselector
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
    track-by="id"
    label="name"
    :custom-label="
      val =>
        `#${val?.id ?? 'NI'} ${val?.description} - de ${val?.originCity?.name} para -> ${val?.destinationCity?.name}`
    "
    :options="routeList"
    :multiple="false"
    :allow-empty="false"
    :taggable="false"
  >
    <template slot="option" slot-scope="{ props }">
      <div class="option__desc">
        <b-col>
          <b-row>
            <span class="option__small"
              >#{{ props?.id ?? 'NI' }} Descrição:
              {{ props?.description ?? 'NI' }}
            </span>
          </b-row>
          <b-row>
            <span class="option__small"
              >Origem:
              {{ props?.originCity?.name ?? 'NI' }}
            </span>
          </b-row>
          <b-row>
            <span class="option__small"
              >Destino:
              {{ props?.destinationCity?.name ?? 'NI' }}
            </span>
          </b-row>
        </b-col>
      </div>
    </template>
  </multiselector>
</template>

<script>
import routesMixin from '@/features/routes/store/mixin'

export default {
  name: 'route-selector',
  async mounted() {
    await this.listRoutes({ page: 1, perPage: 10000 })
  },

  props: {
    rows: {
      default: []
    },
    header: Array
  },
  mixins: [routesMixin],
  methods: {}
}
</script>
