<template>
  <b-overlay
    id="overlay-background"
    :show="employeeRegistryIsBusy"
    variant="white"
    opacity="0.85"
    blur="2px"
    spinner-variant="primary"
    spinner-type="grow"
    :spinner-small="true"
    rounded="sm"
  >
    <multiselector
      v-bind="{ ...$props, ...$attrs }"
      v-on="$listeners"
      track-by="id"
      label="name"
      :custom-label="val => `[${val.id ?? ''}] ${val.name ?? ''} - ${val.place ?? ''}`"
      :options="employeeRegistryList"
      :multiple="false"
      :allow-empty="false"
      :taggable="false"
    >
      <template slot="option" slot-scope="{ props }">
        <div class="option__desc">
          <b-col>
            <b-row>
              <span class="option__small"
                >Nome:
                {{ props?.name ?? 'NI' }}
              </span>
            </b-row>
            <b-row>
              <span class="option__small"
                >CNPJ:
                {{ props?.cnpj ?? 'N/I' }}
              </span>
            </b-row>
          </b-col>
        </div>
      </template>
    </multiselector>
  </b-overlay>
</template>

<script>
import employeeRegistriesMixin from '@/features/employee-registries/store/mixin'

export default {
  name: 'employee-registry-selector',

  props: {
    rows: Array,
    header: Array
  },
  mixins: [employeeRegistriesMixin],
  async mounted() {
    await this.listEmployeeRegistries({ page: 1, perPage: 10000 })
  },
  methods: {}
}
</script>
