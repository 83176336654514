import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  methods: {
    ...mapActions('transportServiceTypes', [
      'listTransportServiceTypes',
      'showTransportServiceType',
      'deleteTransportServiceType',
      'createTransportServiceType',
      'updateTransportServiceType'
    ]),
    ...mapMutations('transportServiceTypes', [
      'cleanTransportServiceTypesErrors',
      'setTransportServiceTypesList',
      'setTransportServiceTypesFilter',
      'setTransportServiceTypesBusy'
    ])
  },
  computed: {
    ...mapState('transportServiceTypes', [
      'transportServiceTypeIsBusy',
      'transportServiceTypeList',
      'transportServiceTypeFilter',
      'transportServiceTypePerPage',
      'transportServiceTypeCurrentPage',
      'transportServiceTypeTotalRows',
      'transportServiceTypeLastPage'
    ]),
    ...mapGetters('transportServiceTypes', [
      'filteredTransportServiceTypes'
    ])
  }
}
