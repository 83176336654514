var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-input', _vm._g(_vm._b({
    staticClass: "form-control",
    attrs: {
      "id": _vm.id,
      "autocomplete": "off",
      "type": "text"
    }
  }, 'b-input', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners), [_vm._t("default"), _vm._v("Slug\n")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }