module.exports = [
  {
    icon: 'ion-md-bus',
    name: 'Serviços',
    children: [
      {
        icon: 'ion-md-arrow-forward',
        name: 'Serviços de Transporte',
        to: '/transport-services',
        can: 'read_transport_services'
      },
      {
        icon: 'ion-md-document',
        name: 'CTEs',
        to: '/ctes',
        can: 'read_ctes'
      },
      {
        icon: 'ion-md-list',
        name: 'Tipos de Serviços de Transporte',
        to: '/transport-service-types',
        can: 'read_transport_service_types'
      },
      { icon: 'ion-md-list', name: 'Fechamentos', to: '/closures', can: 'read_transport_service_types' },
      { icon: 'ion-md-document', name: 'Faturas', to: '/invoices', can: 'read_invoices' },
      { icon: 'ion-md-document', name: 'Manifestos', to: '/manifests', can: 'read_manifests' },
      { icon: 'ion-md-cube', name: 'Ordens de Coleta', to: '/collect-orders', can: 'read_collect_orders' },
      {
        icon: 'ion-md-paper',
        name: 'Notas Fiscais de Serviços',
        to: '/service-fiscal-notes',
        can: 'read_service_fiscal_notes'
      },
      {
        icon: 'ion-md-paper',
        name: 'MDFEs',
        to: '/mdfes',
        can: 'read_mdfes'
      },
      {
        icon: 'ion-md-settings',
        name: 'Cartas de Correção',
        to: '/correction-letters',
        can: 'read_correction_letters'
      }
    ]
  },
  {
    icon: 'ion-md-book',
    name: 'Cadastros',
    children: [
      {
        icon: 'ion-md-business',
        name: 'Empresas',
        to: '/companies',
        can: 'read_companies'
      },
      { icon: 'ion-md-person', name: 'Clientes', to: '/clients', can: 'read_clients' },
    ]
  },
  {
    icon: 'ion-md-bus',
    name: 'Entregas / Pontos de Entregas',
    children: [
      {
        icon: 'ion-md-settings',
        name: 'Entregas',
        to: '/deliveries',
        can: 'read_deliveries'
      },
      {
        icon: 'ion-md-settings',
        name: 'Anexos de Entregas',
        to: '/delivery-attachments',
        can: 'read_delivery_attachments'
      },
      {
        icon: 'ion-md-bus',
        name: 'Pontos de Entregas',
        to: '/deliver-points',
        can: 'read_delivery_attachments'
      }
    ]
  },
  {
    icon: 'ion-md-bus',
    name: 'Fornecedores',
    children: [
      { icon: 'ion-md-person', name: 'Fornecedores', to: '/suppliers', can: 'read_suppliers' },
      {
        icon: 'ion-md-person',
        name: 'Informações Bancárias de Fornecedores',
        to: '/supplier-bank-accounts',
        can: 'read_supplier_bank_accounts'
      }
    ]
  },
  {
    icon: 'ion-md-document',
    name: 'Documentos a Transportar',
    to: '/transportable-documents',
    can: 'read_transportable_documents'
  },
  {
    icon: 'ion-md-paper',
    name: 'Averbações',
    to: '/annotations',
    can: 'read_annotations'
  },
  {
    icon: 'ion-md-car',
    name: 'CIOT',
    children: [
      { icon: 'ion-ios-bus', name: 'Contratos de Frete', to: '/freight-contracts', can: 'read_freight_contracts' },
      { divider: true },
      {
        icon: 'ion-md-list',
        name: 'Valores do Contrato de Frete',
        to: '/freight-contract-values',
        can: 'read_freight_contracts'
      },
      {
        icon: 'ion-md-calculator',
        name: 'Impostos do Contrato de Frete',
        to: '/freight-contract-taxes',
        can: 'read_freight_contracts'
      },
      { icon: 'ion-md-bus', name: "Gerenciamento de CIOT's", to: '/ciots', can: 'read_ciots' },
      { icon: 'ion-md-car', name: 'Operadoras de CIOT', to: '/ciot-operators', can: 'read_ciot_operators' },
      { divider: true },
      {
        icon: 'ion-md-card',
        name: 'Faixas de Impostos de Renda',
        to: '/income-tax-ranges',
        can: 'read_income_tax_ranges'
      },
      {
        icon: 'ion-md-card',
        name: 'Faixas de Impostos do INSS',
        to: '/tax-ranges-insses',
        can: 'read_tax_ranges_insses'
      }
    ]
  },
  {
    icon: 'ion-md-bus',
    name: 'Fretes / Transportes / Custos',
    children: [
      { icon: 'ion-md-cash', name: 'Custos de Transporte', to: '/transport-costs', can: 'read_transport_costs' },
      {
        icon: 'ion-md-list',
        name: 'Faixa de Custos de Frete',
        to: '/freight-cost-ranges',
        can: 'read_freight_cost_ranges'
      },
      { icon: 'ion-ios-archive', name: 'Receitas de Frete', to: '/transport-receipts', can: 'read_transport_receipts' },
      {
        icon: 'ion-md-archive',
        name: 'Faixa de Receita de Frete',
        to: '/freight-revenue-ranges',
        can: 'read_freight_revenue_ranges'
      },
      { icon: 'ion-md-list', name: 'Reajustes de Receitas de Frete', to: '/readjustments', can: 'read_readjustments' }
    ]
  },
  {
    icon: 'ion-ios-exit',
    name: 'Portarias',
    children: [
      { icon: 'ion-ios-archive', name: 'Gerenciar Armazéns', to: '/storehouses', can: 'read_storehouses' },
      { icon: 'ion-md-car', name: 'Gerenciar Docas', to: '/docks', can: 'read_docks' },
      {
        icon: 'ion-ios-people',
        name: 'Registro de Colaboradores',
        to: '/employee-registries',
        can: 'read_employee_registries'
      },
      { icon: 'ion-ios-apps', name: 'Departamentos', to: '/departments', can: 'read_departments' },
      {
        icon: 'ion-md-exit',
        name: 'Entrada e Saída de Portaria',
        to: '/concierge-records',
        can: 'read_concierge_records'
      }
    ]
  },
  {
    icon: 'ion-md-car',
    name: 'Veículos',
    children: [
      { icon: 'ion-md-car', name: 'Veículos Cadastrados', to: '/vehicles', can: 'read_vehicles' },
      { icon: 'ion-md-person', name: 'Motoristas', to: '/drivers', can: 'read_drivers' },
      { icon: 'ion-ios-bus', name: 'Modelos de Veículos', to: '/vehicle-models', can: 'read_vehicle_models' },
      { icon: 'ion-md-bus', name: 'Tipos de Veículos', to: '/vehicle-types', can: 'read_vehicle_types' },
      { icon: 'ion-md-bus', name: 'Posições de Veículos', to: '/vehicle-positions', can: 'read_vehicle_positions' }
    ]
  },
  {
    icon: 'ion-ios-return-right',
    name: 'Cidades / Rotas / Caminhos',
    children: [
      { icon: 'ion-md-return-right', name: 'Rotas', to: '/routes', can: 'read_routes' },
      { icon: 'ion-ios-send', name: 'Percursos / Trajeto', to: '/paths', can: 'read_paths' },
      { icon: 'ion-ios-contact', name: 'Cidades / Munícipios', to: '/cities', can: 'read_cities' }
    ]
  },
  {
    icon: 'ion-md-settings',
    name: 'Administrativo',
    children: [
      { icon: 'ion-ios-contact', name: 'Gerenciamento de Usuários', to: '/users', can: 'read_users' },
      { icon: 'ion-ios-lock', name: 'Gerenciamento de Cargos', to: '/cargos', can: 'read_roles' },
      { icon: 'ion-ios-hand', name: 'Visualização de Permissões', to: '/permissoes', can: 'read_permissions' },
      {
        icon: 'ion-ios-contact',
        name: 'Tipos de Transportador SEFAZ',
        to: '/sefaz-conveyor-types',
        can: 'read_sefaz_conveyor_types'
      },
      { icon: 'ion-md-train', name: 'Tipos de Faixa de Custo', to: '/type-cost-ranges', can: 'read_type_cost_ranges' },
      {
        icon: 'ion-md-list',
        name: 'Atividades das Integrações',
        to: '/integration-logs',
        can: 'read_integration_logs'
      },
      { icon: 'ion-md-timer', name: 'Atividades das Auditorias', to: '/audits', can: 'read_audits' },
      {
        icon: 'ion-md-settings',
        name: 'Tipos de Medições',
        to: '/measurement-types',
        can: 'read_measurement_types'
      },
      {
        icon: 'ion-md-company',
        name: 'Operações',
        to: '/operations',
        can: 'read_operations'
      },
      {
        icon: 'ion-md-settings',
        name: 'Configurações do Sistema',
        to: '/system-settings',
        can: 'read_system_settings'
      }
    ]
  }
]
