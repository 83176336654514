'use strict'

import Vue from 'vue'
import Vuex from 'vuex'
import { loadStoreModules } from './loadStoreFromFeatures'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const modules = loadStoreModules()

export default new Vuex.Store({
  strict: true,
  state: {
    packageVersion: process.env.PACKAGE_VERSION ?? '1.0.0'
  },
  modules: modules,
  mutations: {},
  actions: {},
  getters: {
    appVersion: state => {
      return state.packageVersion
    }
  },
  plugins: [
    createPersistedState({
      paths: ['authentication']
    })
  ]
})
