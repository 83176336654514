import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  methods: {
    ...mapActions('documentTypes', [
      'listDocumentTypes',
      'showDocumentType',
      'deleteDocumentType',
      'createDocumentType',
      'updateDocumentType'
    ]),
    ...mapMutations('documentTypes', [
      'setDocumentTypeList',
      'setDocumentTypeFilter',
      'setDocumentTypeBusy'
    ])
  },
  computed: {
    ...mapState('documentTypes', [
      'documentTypeIsBusy',
      'documentTypeList',
      'documentTypeFilter',
      'documentTypePerPage',
      'documentTypeCurrentPage',
      'documentTypeTotalRows',
      'documentTypeLastPage'
    ]),
    ...mapGetters('documentTypes', [
      'filteredDocumentTypes'
    ])
  }
}
