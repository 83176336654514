import HttpClient from './http-client'
import ValidationException from './exceptions/ValidationException'

class UserAPI extends HttpClient {
  async permissions() {
    const profileInfo = await this.rest.get('/v1/users/profile')
    const permissions = []
    profileInfo.roles.forEach(role => {
      if (role.permissions && role.permissions.length > 0) {
        permissions.push(...role.permissions)
      }
    })
    if (profileInfo.permissions && profileInfo.permissions.length > 0) {
      permissions.push(...profileInfo.permissions)
    }
    return permissions
  }

  async list(page = 1, limit = 1000, text = null) {
    return this.rest.get('/v1/users', {
      params: {
        page,
        limit,
        q: text
      }
    })
  }

  async getUserInfo() {
    return this.rest.get('/v1/me')
  }

  async getAllowedOperations(username) {
    return this.rest.get('/v1/allowed-operations', {
      params: { username }
    })
  }

  async get(id) {
    return this.rest.get('/v1/users/' + id)
  }

  async update(payload) {
    return this.rest.patch('/v1/users/' + payload.user.id, payload)
  }

  async store(payload) {
    try {
      return this.rest.post('/v1/users/', payload)
    } catch (error) {
      // adiciona o id do selo a mensagem de erro
      if (error.response.status === 400) {
        throw new ValidationException('Alguns campos estão inválidos', error.response.data)
      }
    }
  }

  async delete(id) {
    return this.rest.delete('/v1/users/' + id)
  }
}
export default () => new UserAPI()
