'use strict'

import { mutate, doAsyncBusyTask, filterElements, setState } from '@/helpers/store-helpers'

import cteService from '@/features/ctes/ctes-api'

const busyMutateName = 'setCTEsBusy'

export default {
  namespaced: true,
  state: {
    cteList: [],
    cteFilter: '',
    cteIsBusy: false,
    cteTotalRows: 0,
    cteCurrentPage: 1,
    ctePerPage: 10,
    cteLastPage: 0
  },
  actions: {
    async listCTEs({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const payload = await cteService().list(page, perPage, text)
        setState(commit, 'cteTotalRows', payload.total)
        setState(commit, 'cteCurrentPage', payload.page)
        setState(commit, 'ctePerPage', payload.perPage)
        setState(commit, 'cteLastPage', payload.lastPage)

        commit('setCtesList', payload.data)
      })
    },
    async showCTE({ state, commit }, id) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        return cteService().show(id)
      })
    },
    async cancelCTEs({ state, commit }, payload) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        return cteService().cancelCTEs(payload)
      })
    },
    async createCTE({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const entry = await cteService().create(payload)
        commit('unshiftCTE', entry)
      })
    },
    async updateCTE({ state, commit }, payload) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const entry = await cteService().update(payload)
        commit('updateCTEInList', entry)
        return entry
      })
    },
    async checkCTEs({ state, dispatch, commit }, documents) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        return await cteService().checkTransportServiceToCTEs(documents)
      })
    },
    async emitCTEs({ state, dispatch, commit }, documents) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        return await cteService().emitTransportServiceToCTEs(documents)
      })
    },
    async deleteCTE({ state, commit }, id) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const result = await cteService().delete(id)
        if (result) await commit('removeCTEInList', id)
        return result
      })
    }
  },
  getters: {
    filteredCtes(state) {
      return filterElements(state.cteList, state.filter)
    }
  },
  mutations: {
    mutate,
    setCtesList(state, payload) {
      state.cteList = payload
    },
    setCtesFilter(state, payload) {
      state.cteList = payload
    },
    setCTEsBusy(state, value = false) {
      state.cteIsBusy = value
    },
    unshiftCTE(state, payload) {
      state.cteList.unshift(payload)
    },
    removeCTEInList(state, payload) {
      state.cteList = state.cteList.filter(cte => cte.id !== payload)
    },
    updateCTEInList(state, payload) {
      if (state.cteList) {
        state.cteList = state.cteList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
