<template>
  <multiselect
    selectedLabel="Selecionado"
    selectLabel="Pressione para Selecionar"
    deselectLabel="Remover seleção"
    tag-placeholder="Selecione uma opção"
    placeholder="Escolha uma opção"
    class="multiselect-primary"
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
  >
    <span slot="noResult">Nada encontrado. Considere alterar o texto de busca.</span>
    <template slot="option" slot-scope="props">
      <slot name="option" v-bind:props="props.option"> </slot>
    </template>
    <template slot="singleLabel" slot-scope="props">
      <slot name="singleLabel" v-bind:props="props.option"> </slot>
    </template>
  </multiselect>
</template>

<!-- Multiselect-->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style src="@/vendor/styles/pages/tickets.scss" lang="scss"></style>
<style src="@/vendor/libs/vue-input-tag/vue-input-tag.scss" lang="scss"></style>
<style src="@/vendor/libs/vue-multiselect/vue-multiselect.scss" lang="scss"></style>

<script>
import { Multiselect } from 'vue-multiselect'

export default {
  name: 'mutiselector',
  async mounted() {},
  components: {
    Multiselect
  },
  props: {
    rows: {
      default: []
    },
    header: Array,
    'track-by': { default: 'id' },
    label: { default: 'name' },
    multiple: { default: false },
    'allow-empty': { default: false },
    taggable: { default: false }
  },
  mixins: [],
  methods: {}
}
</script>
