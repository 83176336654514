import HttpClient from '@/services/http-client'

class DriverService extends HttpClient {
  async show(crudUrl, id) {
    return this.rest.get(crudUrl + '/' + id)
  }

  async list(crudUrl, page = 1, perPage = 1000, text = null) {
    return this.rest.get(crudUrl + '', {
      params: {
        page,
        perPage,
        q: text
      }
    })
  }

  async create(crudUrl, payload) {
    return this.rest.post(crudUrl + '', payload)
  }

  async update(crudUrl, payload) {
    return this.rest.patch(crudUrl + '/' + payload.id, payload)
  }

  async delete(crudUrl, id) {
    return this.rest.delete(crudUrl + '/' + id)
  }
}

export default () => new DriverService()
