<template>
  <b-overlay
    id="overlay-background"
    :show="storehouseIsBusy"
    variant="white"
    opacity="0.85"
    blur="2px"
    spinner-variant="primary"
    spinner-type="grow"
    :spinner-small="true"
    rounded="sm"
  >
    <multiselector
      v-bind="{ ...$props, ...$attrs }"
      v-on="$listeners"
      track-by="id"
      label="name"
      :custom-label="val => `#${val.id ?? 'ND'} ${val.name ?? 'ND'} - ${val.place ?? 'ND'}`"
      :options="storehouseList"
      :multiple="false"
      :allow-empty="false"
      :taggable="false"
    >
      <template slot="option" slot-scope="{ props }">
        <div class="row">
          <b-col class="col-4">
            <b-row>
              <span class="option__small"
                >ID:
                {{ props?.id ?? 'ND' }}
              </span>
            </b-row>
            <b-row>
              <span class="option__small"
                >Nome:
                {{ props?.name ?? 'NI' }}
              </span>
            </b-row>
          </b-col>
          <b-col>
            <b-row>
              <span>-- </span>
            </b-row>
            <b-row>
              <span class="option__small"
                >Local:
                {{ props?.place ?? 'N/I' }}
              </span>
            </b-row>
          </b-col>
        </div>
      </template>
    </multiselector>
  </b-overlay>
</template>

<script>
import storehousesMixin from '@/features/storehouses/store/mixin'

export default {
  name: 'storehouse-selector',

  props: {
    rows: Array,
    header: Array
  },
  mixins: [storehousesMixin],

  async mounted() {
    await this.listStorehouses({ page: 1, perPage: 10000 })
  },
  methods: {}
}
</script>
