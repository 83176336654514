'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  setState
} from '@/helpers/store-helpers'

import cityService from '@/features/cities/cities-api'

const busyMutateName = 'setCitiesBusy'

export default {
  namespaced: true,
  state: {
    cityList: [],
    cityFilter: '',
    cityIsBusy: false,
    cityTotalRows: 0,
    cityCurrentPage: 1,
    cityPerPage: 10,
    cityLastPage: 0
  },
  actions: {
    async listCities({ state, commit }, payload = {}) {
      const { page = 1, perPage = 10000, text = '', uf = null } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await cityService().list(page, perPage, text, uf?.id)
          setState(commit, 'cityTotalRows', payload.meta.total)
          setState(commit, 'cityCurrentPage', payload.meta.current_page)
          setState(commit, 'cityPerPage', payload.meta.per_page)
          setState(commit, 'cityLastPage', payload.meta.last_page)

          commit('setCitiesList', payload.data)

          return payload.meta
        }
      )
    },
    async showCity({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return cityService().show(id)
        }
      )
    },
    async createCity({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = cityService().create(payload)
          commit('unshiftCity', entry)
        }
      )
    },
    async updateCity({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = cityService().update(payload)
          commit('updateCityInList', entry)
          return entry
        }
      )
    },
    async deleteCity({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await cityService().delete(id)
          if (result) await commit('removeCityInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredCities(state) {
      return filterElements(state.cityList, state.filter)
    }
  },
  mutations: {
    mutate,
    setCitiesList(state, payload) {
      state.cityList = payload
    },
    setCitiesFilter(state, payload) {
      state.cityList = payload
    },
    setCitiesBusy(state, value = false) {
      state.cityIsBusy = value
    },
    unshiftCity(state, payload) {
      state.cityList.unshift(payload)
    },
    removeCityInList(state, payload) {
      state.cityList = state.cityList.filter(city => city.id !== payload)
    },
    updateCityInList(state, payload) {
      if (state.cityList) {
        state.cityList = state.cityList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
