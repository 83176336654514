var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "id": "overlay-background",
      "show": _vm.storehouseIsBusy,
      "variant": "white",
      "opacity": "0.85",
      "blur": "2px",
      "spinner-variant": "primary",
      "spinner-type": "grow",
      "spinner-small": true,
      "rounded": "sm"
    }
  }, [_c('multiselector', _vm._g(_vm._b({
    attrs: {
      "track-by": "id",
      "label": "name",
      "custom-label": function (val) {
        var _val$id, _val$name, _val$place;
        return ("#" + ((_val$id = val.id) !== null && _val$id !== void 0 ? _val$id : 'ND') + " " + ((_val$name = val.name) !== null && _val$name !== void 0 ? _val$name : 'ND') + " - " + ((_val$place = val.place) !== null && _val$place !== void 0 ? _val$place : 'ND'));
      },
      "options": _vm.storehouseList,
      "multiple": false,
      "allow-empty": false,
      "taggable": false
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (_ref) {
        var _props$id, _props$name, _props$place;
        var props = _ref.props;
        return [_c('div', {
          staticClass: "row"
        }, [_c('b-col', {
          staticClass: "col-4"
        }, [_c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("ID:\n              " + _vm._s((_props$id = props === null || props === void 0 ? void 0 : props.id) !== null && _props$id !== void 0 ? _props$id : 'ND') + "\n            ")])]), _vm._v(" "), _c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("Nome:\n              " + _vm._s((_props$name = props === null || props === void 0 ? void 0 : props.name) !== null && _props$name !== void 0 ? _props$name : 'NI') + "\n            ")])])], 1), _vm._v(" "), _c('b-col', [_c('b-row', [_c('span', [_vm._v("-- ")])]), _vm._v(" "), _c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("Local:\n              " + _vm._s((_props$place = props === null || props === void 0 ? void 0 : props.place) !== null && _props$place !== void 0 ? _props$place : 'N/I') + "\n            ")])])], 1)], 1)];
      }
    }])
  }, 'multiselector', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }