<template>
  <Multiselector
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
    track-by="id"
    label="name"
    :custom-label="val => `${val.id} | ${val.name} |CNPJ: ${val.cnpj}`"
    :options="ciotOperatorList"
    :multiple="false"
    :allow-empty="false"
    :taggable="false"
  >
    <template slot="option" slot-scope="{ props }">
      <div class="option__desc">
        <b-col>
          <b-row>
            <span class="option__small"
              >Nome:
              {{ props?.name ?? 'NI' }}
            </span>
          </b-row>
          <b-row>
            <span class="option__small"
              >CNPJ:
              {{ props?.cnpj ?? 'NI' }}
            </span>
          </b-row>
        </b-col>
      </div>
    </template>
  </Multiselector>
</template>

<script>
import ciotOperatorsMixin from '@/features/ciot-operators/store/mixin'

export default {
  name: 'ciot-operator-selector',
  async mounted() {
    await this.listCiotOperators({ page: 1, perPage: 10000 })
  },
  props: {
    // eslint-disable-next-line vue/no-unused-properties
    rows: Array,
    // eslint-disable-next-line vue/no-unused-properties
    header: Array
  },
  mixins: [ciotOperatorsMixin],
  methods: {}
}
</script>
