'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import freightValueTypeService from '@/features/freight-value-types/freight-value-types-api'

const busyMutateName = 'setFreightValueTypesBusy'

export default {
  namespaced: true,
  state: {
    freightValueTypeList: [],
    freightValueTypeFilter: '',
    freightValueTypeIsBusy: false,
    freightValueTypeTotalRows: 0,
    freightValueTypeCurrentPage: 1,
    freightValueTypePerPage: 10,
    freightValueTypeLastPage: 0
  },
  actions: {
    async listFreightValueTypes({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await freightValueTypeService().list(page, perPage, text)
          setState(commit, 'freightValueTypeTotalRows', payload.total)
          setState(commit, 'freightValueTypeCurrentPage', payload.page)
          setState(commit, 'freightValueTypePerPage', payload.perPage)
          setState(commit, 'freightValueTypeLastPage', payload.lastPage)

          commit('setFreightValueTypesList', payload.data)
        }
      )
    },
    async showFreightValueType({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return freightValueTypeService().show(id)
        }
      )
    },
    async createFreightValueType({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await freightValueTypeService().create(payload)
          commit('unshiftFreightValueType', entry)
        }
      )
    },
    async updateFreightValueType({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await freightValueTypeService().update(payload)
          commit('updateFreightValueTypeInList', entry)
          return entry
        }
      )
    },
    async deleteFreightValueType({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await freightValueTypeService().delete(id)
          if (result) await commit('removeFreightValueTypeInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredFreightValueTypes(state) {
      return filterElements(state.freightValueTypeList, state.filter)
    }
  },
  mutations: {
    mutate,
    setFreightValueTypesList(state, payload) {
      state.freightValueTypeList = payload
    },
    setFreightValueTypesFilter(state, payload) {
      state.freightValueTypeList = payload
    },
    setFreightValueTypesBusy(state, value = false) {
      state.freightValueTypeIsBusy = value
    },
    unshiftFreightValueType(state, payload) {
      state.freightValueTypeList.unshift(payload)
    },
    removeFreightValueTypeInList(state, payload) {
      state.freightValueTypeList = state.freightValueTypeList.filter(
        freightValueType => freightValueType.id !== payload
      )
    },
    updateFreightValueTypeInList(state, payload) {
      if (state.freightValueTypeList) {
        state.freightValueTypeList = state.freightValueTypeList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
