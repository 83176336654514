var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "id": "overlay-background",
      "show": _vm.transportServiceIsBusy,
      "variant": "white",
      "opacity": "0.85",
      "blur": "2px",
      "spinner-variant": "primary",
      "spinner-type": "grow",
      "spinner-small": true,
      "rounded": "sm"
    }
  }, [_c('multiselector', _vm._g(_vm._b({
    attrs: {
      "track-by": "id",
      "label": "name",
      "custom-label": function (val) {
        var _val$id, _val$payer$name, _val$payer, _val$romanian_number, _val$payerType;
        return ("#" + ((_val$id = val === null || val === void 0 ? void 0 : val.id) !== null && _val$id !== void 0 ? _val$id : 'NI') + " pagador:" + ((_val$payer$name = val === null || val === void 0 ? void 0 : (_val$payer = val.payer) === null || _val$payer === void 0 ? void 0 : _val$payer.name) !== null && _val$payer$name !== void 0 ? _val$payer$name : 'NI') + " num. romaneio:" + ((_val$romanian_number = val === null || val === void 0 ? void 0 : val.romanian_number) !== null && _val$romanian_number !== void 0 ? _val$romanian_number : 'NI') + " tipo: " + ((_val$payerType = val === null || val === void 0 ? void 0 : val.payerType) !== null && _val$payerType !== void 0 ? _val$payerType : 'NI'));
      },
      "options": _vm.transportServiceList,
      "multiple": false,
      "allow-empty": false,
      "taggable": false
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (_ref) {
        var _props$id, _props$romanian_numbe, _props$payerType;
        var props = _ref.props;
        return [_c('div', {
          staticClass: "option__desc"
        }, [_c('b-col', [_c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("ID:\n              " + _vm._s((_props$id = props === null || props === void 0 ? void 0 : props.id) !== null && _props$id !== void 0 ? _props$id : 'NI') + "\n            ")])]), _vm._v(" "), _c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("Num.Romaneio:\n              " + _vm._s((_props$romanian_numbe = props === null || props === void 0 ? void 0 : props.romanian_number) !== null && _props$romanian_numbe !== void 0 ? _props$romanian_numbe : 'N/I') + "\n            ")])]), _vm._v(" "), _c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("Tipo de Pagador:\n              " + _vm._s((_props$payerType = props === null || props === void 0 ? void 0 : props.payerType) !== null && _props$payerType !== void 0 ? _props$payerType : 'N/I') + "\n            ")])])], 1)], 1)];
      }
    }])
  }, 'multiselector', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }