'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import auditService from '@/features/audits/audits-api'

const busyMutateName = 'setAuditsBusy'

export default {
  namespaced: true,
  state: {
    auditList: [],
    auditFilter: '',
    auditIsBusy: false,
    auditTotalRows: 0,
    auditCurrentPage: 1,
    auditPerPage: 10,
    auditLastPage: 0
  },
  actions: {
    async listAudits({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await auditService().list(page, perPage, text)
          setState(commit, 'auditTotalRows', payload.total)
          setState(commit, 'auditCurrentPage', payload.page)
          setState(commit, 'auditPerPage', payload.perPage)
          setState(commit, 'auditLastPage', payload.lastPage)

          commit('setAuditsList', payload.data)
        }
      )
    },
    async showAudit({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return auditService().show(id)
        }
      )
    },
    async createAudit({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await auditService().create(payload)
          commit('unshiftAudit', entry)
        }
      )
    },
    async updateAudit({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await auditService().update(payload)
          commit('updateAuditInList', entry)
          return entry
        }
      )
    },
    async deleteAudit({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await auditService().delete(id)
          if (result) await commit('removeAuditInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredAudits(state) {
      return filterElements(state.auditList, state.filter)
    }
  },
  mutations: {
    mutate,
    setAuditsList(state, payload) {
      state.auditList = payload
    },
    setAuditsFilter(state, payload) {
      state.auditList = payload
    },
    setAuditsBusy(state, value = false) {
      state.auditIsBusy = value
    },
    unshiftAudit(state, payload) {
      state.auditList.unshift(payload)
    },
    removeAuditInList(state, payload) {
      state.auditList = state.auditList.filter(
        audit => audit.id !== payload
      )
    },
    updateAuditInList(state, payload) {
      if (state.auditList) {
        state.auditList = state.auditList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
