'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  setState
} from '@/helpers/store-helpers'

import vehicleService from '@/features/vehicles/vehicles-api'

const busyMutateName = 'setVehiclesBusy'

export default {
  namespaced: true,
  state: {
    vehicleList: [],
    vehicleFilter: '',
    vehicleIsBusy: false,
    vehicleTotalRows: 0,
    vehicleCurrentPage: 1,
    vehiclePerPage: 10,
    vehicleLastPage: 0
  },
  actions: {
    async listVehicles({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await vehicleService().list(page, perPage, text)
          setState(commit, 'vehicleTotalRows', payload.total)
          setState(commit, 'vehicleCurrentPage', payload.page)
          setState(commit, 'vehiclePerPage', payload.perPage)
          setState(commit, 'vehicleLastPage', payload.lastPage)

          commit('setVehiclesList', payload.data)
        }
      )
    },
    async showVehicle({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return vehicleService().show(id)
        }
      )
    },
    async createVehicle({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = vehicleService().create(payload)
          commit('unshiftVehicle', entry)
        }
      )
    },
    async updateVehicle({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = vehicleService().update(payload)
          commit('updateVehicleInList', entry)
          return entry
        }
      )
    },
    async deleteVehicle({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await vehicleService().delete(id)
          if (result) await commit('removeVehicleInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredVehicles(state) {
      return filterElements(state.vehicleList, state.filter)
    }
  },
  mutations: {
    mutate,
    setVehiclesList(state, payload) {
      state.vehicleList = payload
    },
    setVehiclesFilter(state, payload) {
      state.vehicleList = payload
    },
    setVehiclesBusy(state, value = false) {
      state.vehicleIsBusy = value
    },
    unshiftVehicle(state, payload) {
      state.vehicleList.unshift(payload)
    },
    removeVehicleInList(state, payload) {
      state.vehicleList = state.vehicleList.filter(
        vehicle => vehicle.id !== payload
      )
    },
    updateVehicleInList(state, payload) {
      if (state.vehicleList) {
        state.vehicleList = state.vehicleList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
