<template>
  <div>
    <b-form-group label="Estado (UF):" class="col-md-12">
      <multiselect
        id="uf-input-type"
        track-by="id"
        label="name"
        :value="$v.address.uf.$model"
        @select="_setStateUf"
        :custom-label="val => `${val.uf} - ${val.name}`"
        :options="ufList"
        :multiple="false"
        :hide-selected="false"
        :taggable="false"
        tag-placeholder="Selecione um"
        placeholder="Informe o Estado"
        class="multiselect-primary"
      />
      <div class="cinvalid-tip" v-if="!isValid('address.uf', 'required')">
        O estado deve ser informado
      </div>
    </b-form-group>
    <b-form-group label="Cidade:" class="col-md-12">
      <multiselect
        id="city-input-type"
        track-by="id"
        label="name"
        :value="address.city"
        @select="_setCity"
        :options="cityList"
        :multiple="false"
        :hide-selected="false"
        :taggable="false"
        tag-placeholder="Selecione uma cidade"
        placeholder="Informe a Cidade"
        class="multiselect-primary"
      />
      <div class="cinvalid-tip" v-if="!isValid('address.city', 'required')">
        A Cidade deve ser informada
      </div>
      <div class="cinvalid-tip" v-if="!isValid('address.city', 'ufValid')">
        A Cidade deve pertencer ao Estado
      </div>
    </b-form-group>
    <b-row class="col-md-12">
      <b-form-group label="CEP:" class="col-md-6">
        <ZipInput id="zipcode-input" v-model="address.zipcode" :state="isValid('address.zipcode')" />
        <div class="invalid-tooltip" v-if="!isValid('address.zipcode', 'required')">
          CEP é obrigatório
        </div>
        <div class="invalid-tooltip" v-if="!isValid('address.zipcode', 'minLength')">
          CEP não é valido
        </div>
      </b-form-group>
      <b-form-group label="Bairro:" class="col-md-6">
        <c-input v-model="address.district" placeholder="Digite o Bairro" :state="isValid('address.district')" />
        <div class="invalid-tooltip" v-if="!isValid('address.district', 'required')">
          Bairro é obrigatório
        </div>
      </b-form-group>
    </b-row>
    <b-form-group label="Endereço:" class="col-md-12">
      <c-input v-model="address.street" placeholder="Digite o endereço" :state="isValid('address.street')" />
      <div class="invalid-tooltip" v-if="!isValid('address.street', 'required')">
        Endereço é obrigatório
      </div>
    </b-form-group>
    <b-row class="col-md-12">
      <b-form-group label="Número:" class="col-md-6">
        <c-input
          v-model="address.number"
          placeholder="Digite o Número"
          v-mask="['#######']"
          :state="isValid('address.number')"
        />
        <div class="invalid-tooltip" v-if="!isValid('address.number', 'required')">
          Número é obrigatório
        </div>
        <div class="invalid-tooltip" v-if="!isValid('address.number', 'number')">
          Número Inválido
        </div>
      </b-form-group>
      <b-form-group label="Complemento:" class="col-md-6">
        <c-input
          v-model="address.complement"
          placeholder="Digite o Complemento"
          :state="isValid('address.complement')"
        />
        <div class="invalid-tooltip" v-if="!isValid('address.complement', 'minLength')">
          Complemento está muito curto (4)
        </div>
      </b-form-group>
    </b-row>
  </div>
</template>

<script>
import { required, minLength, requiredIf } from 'vuelidate/lib/validators'
import vuelidateStateMixin from '@/mixins/vuelidate-state-mixin'
import citiesMixin from '@/features/cities/store/mixin'
import { isValidNumber } from '@/helpers/validations'

import { Multiselect } from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },
  data() {
    return {
      address: {
        street: null,
        number: null,
        complement: null,
        district: null,
        zipcode: null,
        uf: null,
        city: null
      }
    }
  },
  computed: {
    isValidCity() {
      if (this.city && this.city.ufId && this.uf) {
        return this.city.ufId === this.uf.id
      } else return true
    }
  },
  validations: {
    address: {
      street: { required },
      district: { required },
      complement: { minLength: minLength(4) },
      number: { required, number: isValidNumber },
      zipcode: { required, minLength: minLength(9) },
      uf: { required },
      city: {
        required,
        ufValid: requiredIf(function(nestedModel) {
          return this.isValidCity
        })
      }
    }
  },
  mixins: [vuelidateStateMixin, citiesMixin],
  props: {},
  name: 'AddressInputGroup',
  methods: {
    isValid(path, checkField = '$invalid') {
      return this.validateField(path, checkField, this.allreadyValidated)
    },
    async _setStateUf(nValue) {
      this.address.uf = nValue
      this.$v.address.uf.$touch()
      await this.listCities({ page: 1, perPage: 1000, text: null, uf: nValue })
    },
    _setCity(nValue) {
      this.address.city = nValue
      this.$v.address.city.$touch()
    }
  }
}
</script>

<style></style>
