'use strict'

// Thi store depends from some plugins like CASL in Vue instance
import settingsApi from '@/services/settings-api'
import { setState, filterElements, mutate } from '@/helpers/store-helpers'

// import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    isBusy: false,
    filter: '',
    settingsList: []
  },
  actions: {
    async listSettings({ state, commit }, payload) {
      const { page = 1, limit = 1000, q = null } = payload ?? {}
      try {
        commit('isBusy', true)
        const settingsList = await settingsApi().list({
          params: {
            page,
            limit,
            q
          }
        })
        setState(commit, 'settingsList', settingsList.data)
        setState(commit, 'settingsRows', settingsList?.meta?.total)
        setState(commit, 'settingsPage', settingsList?.meta?.current_page)
        setState(commit, 'settingsPerPage', settingsList?.meta?.per_page)

        return settingsList
      } catch (e) {
        commit('isBusy')
        throw e
      } finally {
        commit('isBusy')
      }
    },
    async updateSettingValue({ state, commit }, payload) {
      commit('isBusy', true)
      try {
        const updated = await settingsApi().update(payload.id, payload)
        commit('updateSettingOption', updated)
        commit('isBusy')
      } catch (e) {
        commit('isBusy')
        throw e
      } finally {
        commit('isBusy')
      }
    },
    async deleteSettings({ state, commit }, payload) {
      try {
        commit('isBusy', true)
        const createdLoad = await settingsApi().store(payload)
        commit('addToSettings', createdLoad)
      } catch (e) {
        commit('isBusy')
        throw e
      } finally {
        commit('isBusy')
      }
    },
    async resetSettings({ state, commit }, id) {
      try {
        commit('isBusy', true)
        const updated = await settingsApi().reset(id)
        commit('updateSettingOption', updated)
      } catch (e) {
        commit('isBusy')
        throw e
      } finally {
        commit('isBusy')
      }
    }
  },
  mutations: {
    isBusy(state, val = false) {
      state.isBusy = val
    },
    mutate,
    cleanErrors(state) {
      state.errMessage = undefined
    },
    showError(state, message) {
      state.errMessage = message
      setTimeout(() => {
        state.errMessage = undefined
      }, 2000)
    },
    addToSettings(state, load) {
      state.settingsList.push(load)
    },
    updateSettingOption(state, newSetting) {
      const newListing = state.settingsList.map(sett => {
        if (sett.id === newSetting.id) {
          return newSetting
        }
        return sett
      })
      state.settingsList = newListing
    },
    setSettingsFilter(state, filter = '') {
      state.filter = filter
    }
  },
  getters: {
    errorMessage(state) {
      return state.errMessage
    },
    settingsFilter(state) {
      return state.filter
    },
    totalSettings(state) {
      return state.settingsList.length || 0
    },
    connectionTypes(state) {
      return state.connection_types
    },
    filteredSettings(state) {
      return filterElements(state.settingsList, state.filter)
    }
  }
}
