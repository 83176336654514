'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  setState
} from '@/helpers/store-helpers'

import readjustmentService from '@/features/readjustments/readjustments-api'

const busyMutateName = 'setReadjustmentsBusy'

export default {
  namespaced: true,
  state: {
    readjustmentList: [],
    readjustmentFilter: '',
    readjustmentIsBusy: false,
    readjustmentTotalRows: 0,
    readjustmentCurrentPage: 1,
    readjustmentPerPage: 10,
    readjustmentLastPage: 0
  },
  actions: {
    async listReadjustments({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await readjustmentService().list(page, perPage, text)
          setState(commit, 'readjustmentTotalRows', payload.total)
          setState(commit, 'readjustmentCurrentPage', payload.page)
          setState(commit, 'readjustmentPerPage', payload.perPage)
          setState(commit, 'readjustmentLastPage', payload.lastPage)

          commit('setReadjustmentsList', payload.data)
        }
      )
    },
    async showReadjustment({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return readjustmentService().show(id)
        }
      )
    },
    async createReadjustment({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = readjustmentService().create(payload)
          commit('unshiftReadjustment', entry)
        }
      )
    },
    async updateReadjustment({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = readjustmentService().update(payload)
          commit('updateReadjustmentInList', entry)
          return entry
        }
      )
    },
    async deleteReadjustment({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await readjustmentService().delete(id)
          if (result) await commit('removeReadjustmentInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredReadjustments(state) {
      return filterElements(state.readjustmentList, state.filter)
    }
  },
  mutations: {
    mutate,
    setReadjustmentsList(state, payload) {
      state.readjustmentList = payload
    },
    setReadjustmentsFilter(state, payload) {
      state.readjustmentList = payload
    },
    setReadjustmentsBusy(state, value = false) {
      state.readjustmentIsBusy = value
    },
    unshiftReadjustment(state, payload) {
      state.readjustmentList.unshift(payload)
    },
    removeReadjustmentInList(state, payload) {
      state.readjustmentList = state.readjustmentList.filter(
        readjustment => readjustment.id !== payload
      )
    },
    updateReadjustmentInList(state, payload) {
      if (state.readjustmentList) {
        state.readjustmentList = state.readjustmentList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
