import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  methods: {
    ...mapActions('sefazConveyorTypes', [
      'listSefazConveyorTypes',
      'showSefazConveyorType',
      'deleteSefazConveyorType',
      'createSefazConveyorType',
      'updateSefazConveyorType'
    ]),
    ...mapMutations('sefazConveyorTypes', [
      'cleanSefazConveyorTypesErrors',
      'setSefazConveyorTypeList',
      'setSefazConveyorTypesFilter',
      'setSefazConveyorTypesBusy'
    ])
  },
  computed: {
    ...mapState('sefazConveyorTypes', [
      'sefazConveyorTypeIsBusy',
      'sefazConveyorTypeList',
      'sefazConveyorTypeFilter',
      'sefazConveyorTypePerPage',
      'sefazConveyorTypeCurrentPage',
      'sefazConveyorTypeTotalRows',
      'sefazConveyorTypeLastPage'
    ]),
    ...mapGetters('sefazConveyorTypes', ['filteredSefazConveyorTypes'])
  }
}
