'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  setState
} from '@/helpers/store-helpers'

import insurance_companyService from '@/features/insurance-companies/insurance-companies-api'

const busyMutateName = 'setInsuranceCompaniesBusy'

export default {
  namespaced: true,
  state: {
    insurance_companyList: [],
    insurance_companyFilter: '',
    insurance_companyIsBusy: false,
    insuranceCompanyTotalRows: 0,
    insuranceCompanyCurrentPage: 1,
    insuranceCompanyPerPage: 10,
    insurance_companyLastPage: 0
  },
  actions: {
    async listInsuranceCompanies({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await insurance_companyService().list(page, perPage, text)
          setState(commit, 'insuranceCompanyTotalRows', payload.total)
          setState(commit, 'insuranceCompanyCurrentPage', payload.page)
          setState(commit, 'insuranceCompanyPerPage', payload.perPage)
          setState(commit, 'insurance_companyLastPage', payload.lastPage)

          commit('setInsuranceCompaniesList', payload.data)
        }
      )
    },
    async showInsuranceCompany({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return insurance_companyService().show(id)
        }
      )
    },
    async createInsuranceCompany({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = insurance_companyService().create(payload)
          commit('unshiftInsuranceCompany', entry)
        }
      )
    },
    async updateInsuranceCompany({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = insurance_companyService().update(payload)
          commit('updateInsuranceCompanyInList', entry)
          return entry
        }
      )
    },
    async deleteInsuranceCompany({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await insurance_companyService().delete(id)
          if (result) await commit('removeInsuranceCompanyInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredInsuranceCompanies(state) {
      return filterElements(state.insurance_companyList, state.filter)
    }
  },
  mutations: {
    mutate,
    setInsuranceCompaniesList(state, payload) {
      state.insurance_companyList = payload
    },
    setInsuranceCompaniesFilter(state, payload) {
      state.insurance_companyList = payload
    },
    setInsuranceCompaniesBusy(state, value = false) {
      state.insurance_companyIsBusy = value
    },
    unshiftInsuranceCompany(state, payload) {
      state.insurance_companyList.unshift(payload)
    },
    removeInsuranceCompanyInList(state, payload) {
      state.insurance_companyList = state.insurance_companyList.filter(
        insurance_company => insurance_company.id !== payload
      )
    },
    updateInsuranceCompanyInList(state, payload) {
      if (state.insurance_companyList) {
        state.insurance_companyList = state.insurance_companyList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
