'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import freightContractTaxService from '@/features/freight-contract-taxes/freight-contract-taxes-api'

const busyMutateName = 'setFreightContractTaxesBusy'

export default {
  namespaced: true,
  state: {
    freightContractTaxList: [],
    freightContractTaxFilter: '',
    freightContractTaxIsBusy: false,
    freightContractTaxTotalRows: 0,
    freightContractTaxCurrentPage: 1,
    freightContractTaxPerPage: 10,
    freightContractTaxLastPage: 0
  },
  actions: {
    async listFreightContractTaxes({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await freightContractTaxService().list(page, perPage, text)
          setState(commit, 'freightContractTaxTotalRows', payload.total)
          setState(commit, 'freightContractTaxCurrentPage', payload.page)
          setState(commit, 'freightContractTaxPerPage', payload.perPage)
          setState(commit, 'freightContractTaxLastPage', payload.lastPage)

          commit('setFreightContractTaxesList', payload.data)
        }
      )
    },
    async showFreightContractTax({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return freightContractTaxService().show(id)
        }
      )
    },
    async createFreightContractTax({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await freightContractTaxService().create(payload)
          commit('unshiftFreightContractTax', entry)
        }
      )
    },
    async updateFreightContractTax({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await freightContractTaxService().update(payload)
          commit('updateFreightContractTaxInList', entry)
          return entry
        }
      )
    },
    async deleteFreightContractTax({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await freightContractTaxService().delete(id)
          if (result) await commit('removeFreightContractTaxInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredFreightContractTaxes(state) {
      return filterElements(state.freightContractTaxList, state.filter)
    }
  },
  mutations: {
    mutate,
    setFreightContractTaxesList(state, payload) {
      state.freightContractTaxList = payload
    },
    setFreightContractTaxesFilter(state, payload) {
      state.freightContractTaxList = payload
    },
    setFreightContractTaxesBusy(state, value = false) {
      state.freightContractTaxIsBusy = value
    },
    unshiftFreightContractTax(state, payload) {
      state.freightContractTaxList.unshift(payload)
    },
    removeFreightContractTaxInList(state, payload) {
      state.freightContractTaxList = state.freightContractTaxList.filter(
        freightContractTax => freightContractTax.id !== payload
      )
    },
    updateFreightContractTaxInList(state, payload) {
      if (state.freightContractTaxList) {
        state.freightContractTaxList = state.freightContractTaxList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
