<template>
  <b-overlay
    id="overlay-background"
    :show="transportServiceIsBusy"
    variant="white"
    opacity="0.85"
    blur="2px"
    spinner-variant="primary"
    spinner-type="grow"
    :spinner-small="true"
    rounded="sm"
  >
    <multiselector
      v-bind="{ ...$props, ...$attrs }"
      v-on="$listeners"
      track-by="id"
      label="name"
      :custom-label="
        val =>
          `#${val?.id ?? 'NI'} pagador:${val?.payer?.name ??
            'NI'} num. romaneio:${val?.romanian_number ?? 'NI'} tipo: ${val?.payerType ?? 'NI'}`
      "
      :options="transportServiceList"
      :multiple="false"
      :allow-empty="false"
      :taggable="false"
    >
      <template slot="option" slot-scope="{ props }">
        <div class="option__desc">
          <b-col>
            <b-row>
              <span class="option__small"
                >ID:
                {{ props?.id ?? 'NI' }}
              </span>
            </b-row>
            <b-row>
              <span class="option__small"
                >Num.Romaneio:
                {{ props?.romanian_number ?? 'N/I' }}
              </span>
            </b-row>
            <b-row>
              <span class="option__small"
                >Tipo de Pagador:
                {{ props?.payerType ?? 'N/I' }}
              </span>
            </b-row>
          </b-col>
        </div>
      </template>
    </multiselector>
  </b-overlay>
</template>

<script>
import transportServiceMixin from '@/features/transport-services/store/mixin'

export default {
  name: 'transport-service-selector',

  props: {
    rows: Array,
    header: Array
  },
  mixins: [transportServiceMixin],

  async mounted() {
    await this.listTransportServices({ page: 1, perPage: 1000 })
  },
  methods: {}
}
</script>
