import HttpClient from '@/services/http-client'

class CteService extends HttpClient {
  async show(id) {
    return this.rest.get('/v1/ctes/' + id)
  }

  async list(page = 1, perPage = 1000, text = null) {
    return this.rest.get('/v1/ctes', {
      params: {
        page,
        perPage,
        q: text
      }
    })
  }

  async emitAnnotations(payload) {
    return this.rest.post('/v1/annotations/emit', payload)
  }

  async cancelCTEs(payload) {
    return this.rest.post('/v1/ctes/cancel', payload)
  }

  async checkTransportServiceToCTEs(documents) {
    return this.rest.post('/v1/ctes/check-transport-service', documents)
  }

  async emitTransportServiceToCTEs(documents) {
    return this.rest.post('/v1/ctes/emit-transport-service', documents)
  }

  async create(payload) {
    return this.rest.post('/v1/ctes', payload)
  }

  async update(payload) {
    return this.rest.patch('/v1/ctes/' + payload.id, payload)
  }

  async delete(id) {
    return this.rest.delete('/v1/ctes/' + id)
  }
}

export default () => new CteService()
