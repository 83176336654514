import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  methods: {
    ...mapActions('deliverPoints', [
      'listDeliverPoints',
      'showDeliverPoint',
      'deleteDeliverPoint',
      'createDeliverPoint',
      'updateDeliverPoint'
    ]),
    ...mapMutations('deliverPoints', [
      'cleanDeliverPointsErrors',
      'setDeliverPointsList',
      'setDeliverPointsFilter',
      'setDeliverPointsBusy'
    ])
  },
  computed: {
    ...mapState('deliverPoints', [
      'deliverPointIsBusy',
      'deliverPointList',
      'deliverPointFilter',
      'deliverPointPerPage',
      'deliverPointCurrentPage',
      'deliverPointTotalRows',
      'deliverPointLastPage'
    ]),
    ...mapGetters('deliverPoints', [
      'filteredDeliverPoints'
    ])
  }
}
