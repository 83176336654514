<template>
  <c-input
    v-model="stringValue"
    autocomplete="off"
    type="text"
    style="text-align: right !important;"
    class="form-control"
    v-currency="{
      currency: {
        suffix: ''
      },
      precision: {
        min: 0,
        max: 2
      },
      valueAsInteger: false,
      distractionFree: false,
      autoDecimalMode: true,
      allowNegative: false
    }"
  >
    <slot></slot>
  </c-input>
</template>

<script>
const convertCommaToDotNotation = value => {
  if (typeof value === 'string') {
    value = value.replace(/\s/g, '').replace(',', '.')
  }
  return value
}

const normalizeNumber = value => {
  const parsed = parseFloat(convertCommaToDotNotation(value))
  if (Number.isNaN(parsed)) {
    return null
  }
  return parsed
}
export default {
  name: 'money-input',
  props: {
    id: {
      default: null
    },
    value: {
      default: null
    },
    // eslint-disable-next-line vue/no-unused-properties
    rows: Array,
    // eslint-disable-next-line vue/no-unused-properties
    header: Array,
    emitOnMount: {
      default: true
    }
  },
  data() {
    return {
      stringValue: null
    }
  },
  async mounted() {
    setTimeout(() => {
      this.stringValue = this.value
      if (this.emitOnMount) {
        this.emitChange(this.value)
      }
    }, 200)
  },
  watch: {
    stringValue(value) {
      this.emitInput(value)
    }
  },
  methods: {
    async emitMount(val) {
      this.emitInput(val)
      this.emitChange(val)
    },
    emitInput(value) {
      this.$emit('input', normalizeNumber(value))
    },
    emitChange(value) {
      this.$emit('change', normalizeNumber(value))
    }
  }
}
</script>
