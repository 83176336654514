'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  setState
} from '@/helpers/store-helpers'

import monitoringRecordService from '@/features/monitoring-records/monitoring-records-api'

const busyMutateName = 'setMonitoringRecordsBusy'

export default {
  namespaced: true,
  state: {
    monitoringRecordList: [],
    monitoringRecordFilter: '',
    monitoringRecordIsBusy: false,
    monitoringRecordTotalRows: 0,
    monitoringRecordCurrentPage: 1,
    monitoringRecordPerPage: 10,
    monitoringRecordLastPage: 0
  },
  actions: {
    async listMonitoringRecords({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await monitoringRecordService().list(page, perPage, text)
          setState(commit, 'monitoringRecordTotalRows', payload.total)
          setState(commit, 'monitoringRecordCurrentPage', payload.page)
          setState(commit, 'monitoringRecordPerPage', payload.perPage)
          setState(commit, 'monitoringRecordLastPage', payload.lastPage)

          commit('setMonitoringRecordsList', payload.data)
        }
      )
    },
    async showMonitoringRecord({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return monitoringRecordService().show(id)
        }
      )
    },
    async createMonitoringRecord({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await monitoringRecordService().create(payload)
          commit('unshiftMonitoringRecord', entry)
        }
      )
    },
    async updateMonitoringRecord({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await monitoringRecordService().update(payload)
          commit('updateMonitoringRecordInList', entry)
          return entry
        }
      )
    },
    async deleteMonitoringRecord({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await monitoringRecordService().delete(id)
          if (result) await commit('removeMonitoringRecordInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredMonitoringRecords(state) {
      return filterElements(state.monitoringRecordList, state.filter)
    }
  },
  mutations: {
    mutate,
    setMonitoringRecordsList(state, payload) {
      state.monitoringRecordList = payload
    },
    setMonitoringRecordsFilter(state, payload) {
      state.monitoringRecordList = payload
    },
    setMonitoringRecordsBusy(state, value = false) {
      state.monitoringRecordIsBusy = value
    },
    unshiftMonitoringRecord(state, payload) {
      state.monitoringRecordList.unshift(payload)
    },
    removeMonitoringRecordInList(state, payload) {
      state.monitoringRecordList = state.monitoringRecordList.filter(
        monitoringRecord => monitoringRecord.id !== payload
      )
    },
    updateMonitoringRecordInList(state, payload) {
      if (state.monitoringRecordList) {
        state.monitoringRecordList = state.monitoringRecordList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
