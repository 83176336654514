'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  setState
} from '@/helpers/store-helpers'

import ufService from '@/features/ufs/ufs-api'

const busyMutateName = 'setUfsBusy'

export default {
  namespaced: true,
  state: {
    ufList: [],
    ufFilter: '',
    ufIsBusy: false,
    ufTotalRows: 0,
    ufCurrentPage: 1,
    ufPerPage: 10,
    ufLastPage: 0
  },
  actions: {
    async listUfs({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await ufService().list(page, perPage, text)
          setState(commit, 'ufTotalRows', payload.meta.total)
          setState(commit, 'ufCurrentPage', payload.meta.current_page)
          setState(commit, 'ufPerPage', payload.meta.per_page)
          setState(commit, 'ufLastPage', payload.meta.last_page)

          commit('setUfsList', payload.data)
          return payload.data
        }
      )
    },
    async showUF({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return ufService().show(id)
        }
      )
    },
    async createUF({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = ufService().create(payload)
          commit('unshiftUF', entry)
        }
      )
    },
    async updateUF({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = ufService().update(payload)
          commit('updateUFInList', entry)
          return entry
        }
      )
    },
    async deleteUF({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await ufService().delete(id)
          if (result) await commit('removeUFInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredUfs(state) {
      return filterElements(state.ufList, state.filter)
    }
  },
  mutations: {
    mutate,
    setUfsList(state, payload) {
      state.ufList = payload
    },
    setUfsFilter(state, payload) {
      state.ufFilter = payload
    },
    setUfsBusy(state, value = false) {
      state.ufIsBusy = value
    },
    unshiftUF(state, payload) {
      state.ufList.unshift(payload)
    },
    removeUFInList(state, payload) {
      state.ufList = state.ufList.filter(uf => uf.id !== payload)
    },
    updateUFInList(state, payload) {
      if (state.ufList) {
        state.ufList = state.ufList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
