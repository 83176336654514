var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-input', {
    directives: [{
      name: "currency",
      rawName: "v-currency",
      value: {
        currency: {
          suffix: ''
        },
        precision: {
          min: 0,
          max: 2
        },
        valueAsInteger: false,
        distractionFree: false,
        autoDecimalMode: true,
        allowNegative: false
      },
      expression: "{\n    currency: {\n      suffix: ''\n    },\n    precision: {\n      min: 0,\n      max: 2\n    },\n    valueAsInteger: false,\n    distractionFree: false,\n    autoDecimalMode: true,\n    allowNegative: false\n  }"
    }],
    staticClass: "form-control",
    staticStyle: {
      "text-align": "right !important"
    },
    attrs: {
      "autocomplete": "off",
      "type": "text"
    },
    model: {
      value: _vm.stringValue,
      callback: function ($$v) {
        _vm.stringValue = $$v;
      },
      expression: "stringValue"
    }
  }, [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }