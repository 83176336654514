import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  methods: {
    ...mapActions('manifests', [
      'listManifests',
      'showManifest',
      'deleteManifest',
      'createManifest',
      'updateManifest',
      'requestManifestsSM',
      'checkFreightContract',
      'checkFreightContractValue',
      'generateFreightContract'
    ]),
    ...mapMutations('manifests', [
      'cleanManifestsErrors',
      'setManifestsList',
      'setManifestsFilter',
      'setManifestsBusy'
    ])
  },
  computed: {
    ...mapState('manifests', [
      'manifestIsBusy',
      'manifestList',
      'manifestFilter',
      'manifestPerPage',
      'manifestCurrentPage',
      'manifestTotalRows',
      'manifestLastPage'
    ]),
    ...mapGetters('manifests', [
      'filteredManifests'
    ])
  }
}
