import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  methods: {
    ...mapActions('routes', ['listRoutes', 'showRoute', 'deleteRoute', 'createRoute', 'updateRoute']),
    ...mapMutations('routes', ['cleanRoutesErrors', 'setRoutesList', 'setRoutesFilter', 'setRoutesBusy'])
  },
  computed: {
    ...mapState('routes', [
      'routeIsBusy',
      'routeList',
      'routeFilter',
      'routePerPage',
      'routeCurrentPage',
      'routeTotalRows',
      'routeLastPage'
    ]),
    ...mapGetters('routes', ['filteredRoutes'])
  }
}
