'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import operationService from '@/features/operations/operations-api'

const busyMutateName = 'setOperationsBusy'

export default {
  namespaced: true,
  state: {
    operationList: [],
    operationFilter: '',
    operationIsBusy: false,
    operationTotalRows: 0,
    operationCurrentPage: 1,
    operationPerPage: 10,
    operationLastPage: 0
  },
  actions: {
    async listOperations({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await operationService().list(page, perPage, text)
          setState(commit, 'operationTotalRows', payload.total)
          setState(commit, 'operationCurrentPage', payload.page)
          setState(commit, 'operationPerPage', payload.perPage)
          setState(commit, 'operationLastPage', payload.lastPage)

          commit('setOperationsList', payload.data)
        }
      )
    },
    async showOperation({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return operationService().show(id)
        }
      )
    },
    async createOperation({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await operationService().create(payload)
          commit('unshiftOperation', entry)
        }
      )
    },
    async updateOperation({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await operationService().update(payload)
          commit('updateOperationInList', entry)
          return entry
        }
      )
    },
    async deleteOperation({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await operationService().delete(id)
          if (result) await commit('removeOperationInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredOperations(state) {
      return filterElements(state.operationList, state.filter)
    }
  },
  mutations: {
    mutate,
    setOperationsList(state, payload) {
      state.operationList = payload
    },
    setOperationsFilter(state, payload) {
      state.operationList = payload
    },
    setOperationsBusy(state, value = false) {
      state.operationIsBusy = value
    },
    unshiftOperation(state, payload) {
      state.operationList.unshift(payload)
    },
    removeOperationInList(state, payload) {
      state.operationList = state.operationList.filter(
        operation => operation.id !== payload
      )
    },
    updateOperationInList(state, payload) {
      if (state.operationList) {
        state.operationList = state.operationList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
