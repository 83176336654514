var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "px-1"
  }, [_vm.can ? [_c('Can', {
    attrs: {
      "I": _vm.can
    }
  }, [_c('b-btn', _vm._g(_vm._b({
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "size": "xs",
      "variant": "warning",
      "disabled": _vm.disabled,
      "title": "Editar Entrada"
    },
    on: {
      "click": _vm.click
    }
  }, 'b-btn', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners), [_vm._v("Editar")])], 1), _vm._v(" "), _c('Can', {
    attrs: {
      "not": "",
      "I": _vm.can
    }
  }, [_c('b-btn', _vm._g(_vm._b({
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "size": "xs",
      "variant": "warning",
      "disabled": "",
      "title": "Sem permissão para editar"
    },
    on: {
      "click": function () {}
    }
  }, 'b-btn', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners), [_vm._v("Editar")])], 1)] : [_c('b-btn', _vm._g(_vm._b({
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "size": "xs",
      "variant": "warning",
      "disabled": _vm.disabled,
      "title": "Editar Entrada"
    },
    on: {
      "click": _vm.click
    }
  }, 'b-btn', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners), [_vm._v("Editar")])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }