import HttpClient from '@/services/http-client'

class VehicleService extends HttpClient {
  async show(id) {
    return this.rest.get('/v1/vehicles/' + id)
  }

  async list(page = 1, perPage = 1000, text = null) {
    return this.rest.get('/v1/vehicles', {
      params: {
        page,
        perPage,
        q: text
      }
    })
  }

  async create(payload) {
    return this.rest.post('/v1/vehicles', payload)
  }

  async update(payload) {
    return this.rest.patch('/v1/vehicles/' + payload.id, payload)
  }

  async delete(id) {
    return this.rest.delete('/v1/vehicles/' + id)
  }
}

export default () => new VehicleService()
