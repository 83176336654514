import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  methods: {
    ...mapActions('insuranceCompanies', [
      'listInsuranceCompanies',
      'showInsuranceCompany',
      'deleteInsuranceCompany',
      'createInsuranceCompany',
      'updateInsuranceCompany'
    ]),
    ...mapMutations('insuranceCompanies', [
      'cleanInsuranceCompaniesErrors',
      'setInsuranceCompaniesList',
      'setInsuranceCompaniesFilter',
      'setInsuranceCompaniesBusy'
    ])
  },
  computed: {
    ...mapState('insuranceCompanies', [
      'insurance_companyIsBusy',
      'insurance_companyList',
      'insurance_companyFilter',
      'insuranceCompanyPerPage',
      'insuranceCompanyCurrentPage',
      'insuranceCompanyTotalRows',
      'insurance_companyLastPage'
    ]),
    ...mapGetters('insuranceCompanies', [
      'filteredInsuranceCompanies'
    ])
  }
}
