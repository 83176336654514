import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  methods: {
    ...mapActions('freightContracts', [
      'listFreightContracts',
      'showFreightContract',
      'deleteFreightContract',
      'createFreightContract',
      'updateFreightContract'
    ]),
    ...mapMutations('freightContracts', [
      'cleanFreightContractsErrors',
      'setFreightContractsList',
      'setFreightContractsFilter',
      'setFreightContractsBusy'
    ])
  },
  computed: {
    ...mapState('freightContracts', [
      'freightContractIsBusy',
      'freightContractList',
      'freightContractFilter',
      'freightContractPerPage',
      'freightContractCurrentPage',
      'freightContractTotalRows',
      'freightContractLastPage'
    ]),
    ...mapGetters('freightContracts', [
      'filteredFreightContracts'
    ])
  }
}
