<template>
  <Multiselector
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
    track-by="id"
    label="name"
    :custom-label="val => `${val?.description} - ${val?.originCity?.name} a ${val?.destinationCity?.name} por `"
    :show-labels="false"
    :options="transportServiceTypeList"
    :multiple="false"
    :allow-empty="false"
    :taggable="false"
  >
    <template slot="singleLabel" slot-scope="{ props }">
      <span class="option__desc">
        <span class="option__title">
          #{{ props?.id ?? 'NI' }} Nome: {{ props?.name?.uf ?? 'NI' }}
          <span v-for="entry in props.journeys" :key="entry.id"> #{{ entry?.description ?? 'NI' }}, </span>
        </span>
      </span>
    </template>
    <template slot="option" slot-scope="{ props }">
      <div class="option__desc">
        <b-col>
          <b-row>
            <span class="option__small">
              #{{ props?.id ?? 'NI' }} Nome:
              {{ props?.name ?? 'NI' }}
            </span>
          </b-row>
          <b-row> </b-row>
          <b-row>
            <span class="option__small">
              Descrição:
              <span v-for="entry in props.journeys" :key="entry.id">
                {{ entry?.description ?? 'NI' }}
              </span>
            </span>
          </b-row>
        </b-col>
      </div>
    </template>
  </Multiselector>
</template>

<script>
import transportServiceTypeMixin from '@/features/transport-service-types/store/mixin'

export default {
  name: 'transport-service-type-selector',
  async created() {
    await this.listTransportServiceTypes({ page: 1, perPage: 10000 })
  },
  props: {
    rows: Array,
    header: Array
  },
  mixins: [transportServiceTypeMixin],
  methods: {}
}
</script>
