'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  setState
} from '@/helpers/store-helpers'

import companyService from '@/features/companies/companies-api'

const busyMutateName = 'setCompaniesBusy'

export default {
  namespaced: true,
  state: {
    companyList: [],
    companyFilter: '',
    companyIsBusy: false,
    companyTotalRows: 0,
    companyCurrentPage: 1,
    companyPerPage: 10,
    companyLastPage: 0
  },
  actions: {
    async listCompanies({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await companyService().list(page, perPage, text)
          setState(commit, 'companyTotalRows', payload.total)
          setState(commit, 'companyCurrentPage', payload.page)
          setState(commit, 'companyPerPage', payload.perPage)
          setState(commit, 'companyLastPage', payload.lastPage)

          commit('setCompaniesList', payload.data)
        }
      )
    },
    async showCompany({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return companyService().show(id)
        }
      )
    },
    async createCompany({ state, dispatch, commit }, payload) {
      const entry = companyService().create(payload)
      commit('unshiftCompany', entry)
      return entry
    },
    async updateCompany({ state, commit }, payload) {
      const entry = companyService().update(payload)
      commit('updateCompanyInList', entry)
      return entry
    },
    async deleteCompany({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await companyService().delete(id)
          if (result) await commit('removeCompanyInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredCompanies(state) {
      return filterElements(state.companyList, state.filter)
    }
  },
  mutations: {
    mutate,
    setCompaniesList(state, payload) {
      state.companyList = payload
    },
    setCompaniesFilter(state, payload) {
      state.companyList = payload
    },
    setCompaniesBusy(state, value = false) {
      state.companyIsBusy = value
    },
    unshiftCompany(state, payload) {
      state.companyList.unshift(payload)
    },
    removeCompanyInList(state, payload) {
      state.companyList = state.companyList.filter(
        company => company.id !== payload
      )
    },
    updateCompanyInList(state, payload) {
      if (state.companyList) {
        state.companyList = state.companyList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
