var render = function () {
  var _vm$annotationChecks, _vm$annotationResults;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    ref: "annotationCheckModal",
    attrs: {
      "size": "lg",
      "modal-class": "modal-top"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function (_ref) {
        var ok = _ref.ok,
          cancel = _ref.cancel;
        return [_c('b-button', {
          attrs: {
            "size": "md",
            "variant": "success"
          },
          on: {
            "click": function () {
              return _vm.doAnnotationEmission(ok);
            }
          }
        }, [_vm._v("\n        Emitir averbação\n      ")]), _vm._v(" "), _c('b-button', {
          attrs: {
            "size": "md",
            "variant": "secondary"
          },
          on: {
            "click": function () {
              return cancel(ok);
            }
          }
        }, [_vm._v("\n        Fechar\n      ")])];
      }
    }])
  }, [_c('div', {
    attrs: {
      "slot": "modal-title"
    },
    slot: "modal-title"
  }, [_vm._v("\n      Resumo de documentos a serem "), _c('span', {
    staticClass: "font-weight-light"
  }, [_vm._v("Averbados")]), _c('br'), _vm._v(" "), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Abaixo segue a lista se é permitido fazer a averbação dos documentos a transportar.\n      ")]), _vm._v(" "), _c('br')]), _vm._v(" "), _c('b-overlay', {
    attrs: {
      "id": "overlay-background",
      "variant": "white",
      "opacity": "0.85",
      "blur": "2px",
      "spinner-variant": "primary",
      "spinner-type": "grow",
      "spinner-small": true,
      "rounded": "sm"
    }
  }, [_c('CTable', {
    attrs: {
      "id": (_vm$annotationChecks = _vm.annotationChecks) === null || _vm$annotationChecks === void 0 ? void 0 : _vm$annotationChecks.length,
      "fields": _vm.checkedFields,
      "items": _vm.annotationChecks
    },
    scopedSlots: _vm._u([{
      key: "cell(allow_annotation)",
      fn: function (data) {
        return [data.item.allow_annotation ? _c('b-badge', {
          attrs: {
            "else": "",
            "href": "javascript:void(0)",
            "variant": "success text-white"
          }
        }, [_vm._v("\n            PERMITIDO\n          ")]) : _c('b-badge', {
          attrs: {
            "href": "javascript:void(0)",
            "variant": "danger text-white"
          }
        }, [_vm._v("\n            NÃO PERMITIDO\n          ")])];
      }
    }, {
      key: "cell(document_number)",
      fn: function (data) {
        var _ref2, _data$item$entity$nfs, _data$item, _data$item$entity, _data$item2, _data$item2$entity, _data$item3, _data$item3$entity;
        return [_c('b-badge', {
          attrs: {
            "else": "",
            "href": "javascript:void(0)",
            "variant": "success text-white"
          }
        }, [_vm._v("\n            " + _vm._s((_ref2 = (_data$item$entity$nfs = (_data$item = data.item) === null || _data$item === void 0 ? void 0 : (_data$item$entity = _data$item.entity) === null || _data$item$entity === void 0 ? void 0 : _data$item$entity.nfs_number) !== null && _data$item$entity$nfs !== void 0 ? _data$item$entity$nfs : (_data$item2 = data.item) === null || _data$item2 === void 0 ? void 0 : (_data$item2$entity = _data$item2.entity) === null || _data$item2$entity === void 0 ? void 0 : _data$item2$entity.collect_number) !== null && _ref2 !== void 0 ? _ref2 : (_data$item3 = data.item) === null || _data$item3 === void 0 ? void 0 : (_data$item3$entity = _data$item3.entity) === null || _data$item3$entity === void 0 ? void 0 : _data$item3$entity.cte_number) + "\n          ")])];
      }
    }])
  })], 1)], 1), _vm._v(" "), _c('b-modal', {
    ref: "annotationEmissionModal",
    attrs: {
      "size": "lg",
      "modal-class": "modal-top"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function (_ref3) {
        var ok = _ref3.ok,
          cancel = _ref3.cancel;
        return [_c('b-button', {
          attrs: {
            "size": "md",
            "variant": "secondary"
          },
          on: {
            "click": function () {
              return cancel(ok);
            }
          }
        }, [_vm._v("\n        Fechar\n      ")])];
      }
    }])
  }, [_c('div', {
    attrs: {
      "slot": "modal-title"
    },
    slot: "modal-title"
  }, [_vm._v("\n      Resumo de documentos "), _c('span', {
    staticClass: "font-weight-light"
  }, [_vm._v("averbados")]), _c('br'), _vm._v(" "), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Abaixo segue a lista de resultados da averbação dos documentos. ")]), _vm._v(" "), _c('br')]), _vm._v(" "), _c('b-overlay', {
    attrs: {
      "id": "overlay-background",
      "variant": "white",
      "opacity": "0.85",
      "blur": "2px",
      "spinner-variant": "primary",
      "spinner-type": "grow",
      "spinner-small": true,
      "rounded": "sm"
    }
  }, [_c('CTable', {
    attrs: {
      "id": (_vm$annotationResults = _vm.annotationResults) === null || _vm$annotationResults === void 0 ? void 0 : _vm$annotationResults.length,
      "fields": _vm.emittedFields,
      "items": _vm.annotationResults
    },
    scopedSlots: _vm._u([{
      key: "cell(error)",
      fn: function (data) {
        return [data.error ? _c('b-badge', {
          attrs: {
            "href": "javascript:void(0)",
            "variant": "danger text-white"
          }
        }, [_vm._v("\n            NÃO EMITIDO\n          ")]) : _c('b-badge', {
          attrs: {
            "else": "",
            "href": "javascript:void(0)",
            "variant": "success text-white"
          }
        }, [_vm._v("\n            EMITIDO\n          ")])];
      }
    }])
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }