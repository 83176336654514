import { mapGetters, mapActions } from 'vuex'

import router from '../router'

export default {
  beforeCreated() {
    this.preventLogin()
  },
  methods: {
    preventLogin: function() {
      if (router.currentRoute.path !== '/home' && !this.isAuthenticated) {
        router.push('/login')
      } else if (router.currentRoute.path === '/login' && this.isAuthenticated) {
        router.push('/')
      }
    },
    ...mapActions('authentication', ['logoutAccount'])
  },
  computed: {
    ...mapGetters('authentication', ['isAuthenticated'])
  }
}
