<template>
  <div>
    <b-modal size="lg" ref="manifestToEmit" modal-class="modal-top">
      <div slot="modal-title">
        Dados do Manifesto a gerar <span class="font-weight-light">Contrato de Frete</span><br />
        <small class="text-muted">Informações relevantes do contrato de frete. </small>
        <br />
      </div>
      <BusyOverlay :show="manifestIsBusy || taxIsBusy">
        <b-row>
          <b-col class="md-6">
            <b-form-group label="Contratante:" class="col-12">
              <span> <b> Operação: </b></span>{{ manifestToEmit?.contractor?.operation_name }}
              <br />
              <span> <b> Endereço: </b></span>{{ manifestToEmit?.contractor?.address?.street }}
              <br />
              <span> <b> Dependentes: </b></span>{{ manifestToEmit?.driver?.dependents_quantity }}
            </b-form-group>
            <b-form-group label="Dados do Transportador:" class="col-12">
              <span> <b> ID: </b></span> #{{ manifestToEmit?.transporter?.id }}
              <br />
              <span> <b> Nome: </b></span>{{ manifestToEmit?.transporter?.name }}
              <br />
              <span> <b> Endereço: </b></span>{{ manifestToEmit?.transporter?.address?.street }}
              <br />
              <span> <b> CEP: </b></span>{{ manifestToEmit?.transporter?.address?.zipcode }}
            </b-form-group>
          </b-col>

          <b-form-group label="Veículo / Motorista:" class="col-6">
            <span> <b> Placa: </b></span>{{ manifestToEmit.vehicle?.plate }}
            <br />
            <span> <b> Motorista: </b></span>{{ manifestToEmit?.driver?.fullname }}
            <br />
            <span> <b> CPF: </b></span>{{ manifestToEmit?.driver?.cpf }}
            <br />
            <span> <b> CNH: </b></span>{{ manifestToEmit?.driver?.cnh_number }}
            <br />
            <span> <b> RENAVAM: </b></span>{{ manifestToEmit?.vehicle?.renavam }}
            <br />
            <span> <b> Dependentes: </b></span>{{ manifestToEmit?.driver?.dependents_quantity }}
          </b-form-group>
        </b-row>
        <b-row>
          <b-form-group label="Valor Contratado:" class="col-4">
            <MoneyInput v-model="currentContractedValue" placeholder="Digite o Valor" :state="validContractValue" />
            <template v-if="!needReview">
              <template v-for="(item, index) in reviewReasons">
                <div class="invalid-tooltip" :key="index">
                  {{ item }}
                </div>
              </template>
            </template>
          </b-form-group>
          <b-form-group label="Valor de Pedágio (+):" class="col-4">
            <span>R$ {{ manifestToEmit.toll_value?.toFixed(2) ?? 0 }}</span>
          </b-form-group>
        </b-row>
        <b-form-group label="Alíquotas Aplicáveis:">
          <b-row class="col-12">
            <b-form-group :label="'IRRF (-' + (irrfTax?.totalRates ?? 0) + '%):'" class="col-4">
              <span>R$ {{ irrfTax?.tax?.toFixed(2) ?? 0 }}</span>
            </b-form-group>
            <b-form-group :label="'INSS (-' + (inssTax?.totalRates ?? 0) + '%):'" class="col-4">
              <span>R$ {{ inssTax?.tax?.toFixed(2) ?? 0 }}</span>
            </b-form-group>
            <b-form-group :label="'Sest/Senat (-' + (sestSenatTax?.totalRates ?? 0) + '%):'" class="col-4">
              <span>R$ {{ sestSenatTax?.tax?.toFixed(2) ?? 0 }}</span>
            </b-form-group>
            <b-form-group label="Total de Descontos (-):" class="col-4">
              <span>R$ {{ taxRatesTotal?.toFixed(2) ?? 0 }}</span>
            </b-form-group>
          </b-row>
        </b-form-group>
        <hr />
        <b-form-group label="Resumo:">
          <b-row class="col-12">
            <b-form-group label="Liquido a Receber Sem Adiantamento:" class="col-4">
              <span>R$ {{ toReceiveWithoutAdvance?.toFixed(2) ?? 0 }}</span>
            </b-form-group>
            <b-form-group label="Adiantamento Concedido (-):" class="col-4">
              <span>R$ {{ manifestToEmit.advance_value?.toFixed(2) ?? 0 }}</span>
            </b-form-group>
            <b-form-group label="Saldo a Receber:" class="row-4">
              <FloatInput disabled :value="toReceive.toFixed(2)"></FloatInput>
            </b-form-group>
          </b-row>
        </b-form-group>
        <b-form-group label="Observações:">
          <b-textarea v-model="observations"></b-textarea>
        </b-form-group>
        <b-button size="md" variant="primary" @click="calculateTax">
          Processar Valores
        </b-button>
      </BusyOverlay>

      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->

        <b-button size="md" :disabled="!validContractValue" variant="success" @click="() => genFreightContract(ok)">
          Gerar contrato de frete
        </b-button>
        <b-button size="md" variant="secondary" @click="() => cancel(ok)">
          Fechar
        </b-button>
      </template>
    </b-modal>

    <b-modal size="lg" ref="manifestEmissionModal" modal-class="modal-top">
      <div slot="modal-title">
        <span class="font-weight-light"> Manifestos que podem gerar </span>contrato de frete<br />
        <small class="text-muted">Abaixo segue a lista de manifestos validos para contrato de frete.</small>
        <br />
      </div>
      <BusyOverlay :show="manifestIsBusy">
        <CTable :fields="checkedFields" :items="manifestResults">
          <template #cell(actions)="data">
            <b-btn
              size="sm"
              @click="generateContract(data.item.manifest)"
              variant="primary text-white"
              :title="data.id"
            >
              Gerar Contrato de Frete
            </b-btn>
          </template>
        </CTable>
      </BusyOverlay>

      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="md" variant="secondary" @click="() => cancel(ok)">
          Fechar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import 'moment'
import manifestsMixin from '@/features/manifests/store/mixin'
import { isEmpty } from 'lodash'
import BusyOverlay from '../atoms/BusyOverlay.vue'

const checkedFields = [
  {
    key: 'manifest.id',
    sortable: false,
    label: 'ID'
  },
  {
    key: 'manifest.manifest_number',
    sortable: false,
    label: 'Número Manifesto'
  },
  {
    key: 'manifest.manifest_series',
    sortable: true,
    label: 'Série do Manifesto'
  },
  {
    key: 'actions',
    sortable: false,
    label: 'Ações',
    formatter: value => {
      return isEmpty(value) ? 'Sucesso' : value
    }
  }
]
const emittedFields = [
  {
    key: 'manifest.id',
    sortable: false,
    label: 'ID'
  },
  {
    key: 'manifest.manifest_number',
    sortable: false,
    label: 'Número Manifesto'
  },
  {
    key: 'manifest.manifest_series',
    sortable: true,
    label: 'Série do Manifesto'
  },
  {
    key: 'actions',
    sortable: false,
    label: 'Resultado',
    formatter: value => {
      return isEmpty(value) ? 'Sucesso' : value
    }
  }
]
const taxesFields = [
  {
    key: 'id',
    label: 'ID'
  },
  {
    key: 'floor',
    label: 'Piso de Isenção'
  },
  {
    key: 'ceiling',
    label: 'Teto de Isenção'
  },
  {
    key: 'tax_rate',
    label: 'Alíquota'
  },
  {
    key: 'exemption_per_dependent',
    label: 'Isenção por dependente'
  }
]
export default {
  name: 'GenFreightContractModal',
  components: { BusyOverlay },
  mixins: [manifestsMixin],
  data: () => {
    return {
      manifestChecks: [],
      manifestResults: [],
      manifestToEmit: {},
      checkedFields,
      emittedFields,
      /** user defined values */
      currentContractedValue: null,
      contractValueReason: 'Valor inválido de contrato de frete',
      taxIsBusy: false,
      /** Taxes */
      irrfTax: { taxRates: [], value: 0 },
      inssTax: { value: 0 },
      sestSenatTax: { value: 0 },
      taxRatesTotal: 0,
      advance_value: 0,
      toReceiveWithoutAdvance: 0,
      toReceive: 0,
      toll_value: 0,
      observations: null,
      needReview: false,
      reviewReasons: [],
      taxesFields
    }
  },
  props: {
    value: Object,
    entityName: String
  },
  computed: {
    validContractValue() {
      return this.toReceive
    }
  },
  methods: {
    async calculateTax() {
      this.taxIsBusy = true
      try {
        const {
          manifest,
          irrfTax,
          inssTax,
          sestSenatTax,
          taxRatesTotal,
          toReceiveWithoutAdvance,
          toReceive,
          advance_value,
          toll_value,
          needReview = false,
          reviewReasons = []
        } = await this.checkFreightContractValue({
          manifest: this.manifestToEmit,
          value: this.currentContractedValue
        })
        this.irrfTax = irrfTax
        this.sestSenatTax = sestSenatTax
        this.inssTax = inssTax
        this.taxRatesTotal = taxRatesTotal
        this.toReceive = toReceive
        this.toReceiveWithoutAdvance = toReceiveWithoutAdvance
        this.advance_value = advance_value
        this.toll_value = toll_value
        this.needReview = needReview
        this.reviewReasons = reviewReasons

        for (const reason of reviewReasons) {
          this.showInfoModal({
            title: 'Atenção !',
            html:
              'Este contrato será registrado más irá aguardar liberação por usuário autorizado: <br /><small>' +
              reason +
              '</small>',
            icon: 'info'
          })
        }
      } catch (error) {
        this.taxIsBusy = false
        throw error
      }
      this.taxIsBusy = false
    },
    async genFreightContract() {
      const result = await this.generateFreightContract({
        manifest: this.manifestToEmit,
        value: this.currentContractedValue,
        observations: this.observations
      })
      this.showInfoModal({
        title: 'Sucesso !',
        text: 'Contrato de frete gerado com sucesso.',
        icon: 'success'
      })
      this.$refs.manifestToEmit.hide()
      this.$refs.manifestEmissionModal.show()
    },
    async generateContract(documentCell) {
      this.manifestToEmit = documentCell
      this.$refs.manifestToEmit.show()
    },
    async doContractEmission(checks) {
      this.manifestResults = []
      this.manifestResults = checks
      this.$refs.manifestToEmit.hide()
      this.$refs.manifestEmissionModal.show()
    }
  }
}
</script>
