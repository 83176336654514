<template>
  <Multiselector
    :id="id"
    :disabled="disabled"
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
    :track-by="trackBy"
    :label="label"
    @search-change="asyncSearchEntity"
    :loading="isBusy"
    :custom-label="customLabel"
    :options="entityRows"
    :multiple="false"
    :allow-empty="false"
    :taggable="false"
  >
  </Multiselector>
</template>

<script>
import { debounce } from 'lodash'
import crudEntityApi from '../../features/crud/crud-entity-api'

const _ = require('lodash')
export default {
  name: 'entity-selector',
  async mounted() {
    if (this.emitOnMount) {
      this.$emit('input', this.isChecked)
    }
    await this.initialList()
  },
  data: () => ({
    entityRows: [],
    queryText: null,
    page: 1,
    perPage: 100,
    isBusy: false
  }),
  props: {
    // eslint-disable-next-line vue/no-unused-properties
    disabled: {
      default: false
    },
    id: {
      default: undefined
    },
    fields: Array,
    header: Array,
    'track-by': String,
    label: Array,
    // eslint-disable-next-line vue/no-unused-properties
    emitOnMount: {
      default: true
    },
    entityUrl: {
      type: String,
      default: '/v1/freight-check-types',
      required: true
    } // /entities
  },
  methods: {
    customLabel(val) {
      if (this?.fields?.length) {
        let finalText = `${val.id}`
        for (const field of this.fields) {
          finalText += ' | ' + JSON.stringify(_.get(val, field) ?? 'N/I')
        }
        return finalText
      }
      return `${val.id} - ${val.name}`
    },
    async initialList() {
      this.isBusy = true
      const payload = await crudEntityApi().list(this.entityUrl, this.page, this.perPage, null)
      this.entityRows = payload.data
      this.isBusy = false
    },
    asyncSearchEntity(text) {
      this.queryText = text
      this.debouncedSearch()
      text = ''
    },
    debouncedSearch: debounce(function() {
      this.isBusy = true
      crudEntityApi()
        .list(this.entityUrl, this.page, this.perPage, this.queryText)
        .then(payload => {
          this.isBusy = false
          this.entityRows = payload.data
        })
        .catch(err => {
          this.isBusy = false
          throw err
        })
    }, 500)
  }
}
</script>
