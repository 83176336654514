<template>
  <multiselector
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
    track-by="id"
    label="name"
    :custom-label="val => `#${val?.id ?? 'NI'} - ${val?.name ?? 'NI'}`"
    :options="userList"
    :multiple="false"
    :allow-empty="false"
    :taggable="false"
  >
    <template slot="option" slot-scope="{ props }">
      <div class="option__desc">
        <b-col>
          <b-row>
            <span class="option__small"
              >ID:
              {{ props?.id ?? 'NI' }}
            </span>
          </b-row>
          <b-row>
            <span class="option__small"
              >Nome:
              {{ props?.name ?? 'NI' }}
            </span>
          </b-row>
        </b-col>
      </div>
    </template>
  </multiselector>
</template>

<script>
import usersMixin from '@/features/users-editor/store/mixin'

export default {
  name: 'user-selector',
  props: {
    rows: Array,
    header: Array
  },
  mixins: [usersMixin],

  async mounted() {
    await this.listUsers({ page: 1, perPage: 10000 })
  },
  methods: {}
}
</script>
