'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import storehouseService from '@/features/storehouses/storehouses-api'

const busyMutateName = 'setStorehousesBusy'

export default {
  namespaced: true,
  state: {
    storehouseList: [],
    storehouseFilter: '',
    storehouseIsBusy: false,
    storehouseTotalRows: 0,
    storehouseCurrentPage: 1,
    storehousePerPage: 10,
    storehouseLastPage: 0
  },
  actions: {
    async listStorehouses({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const payload = await storehouseService().list(page, perPage, text)
        setState(commit, 'storehouseTotalRows', payload.total)
        setState(commit, 'storehouseCurrentPage', payload.page)
        setState(commit, 'storehousePerPage', payload.perPage)
        setState(commit, 'storehouseLastPage', payload.lastPage)

        commit('setStorehousesList', payload.data)
      })
    },
    async showStorehouse({ state, commit }, id) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        return storehouseService().show(id)
      })
    },
    async createStorehouse({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const entry = await storehouseService().create(payload)
        commit('unshiftStorehouse', entry)
      })
    },
    async updateStorehouse({ state, commit }, payload) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const entry = await storehouseService().update(payload)
        commit('updateStorehouseInList', entry)
        return entry
      })
    },
    async deleteStorehouse({ state, commit }, id) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const result = await storehouseService().delete(id)
        if (result) await commit('removeStorehouseInList', id)
        return result
      })
    }
  },
  getters: {
    filteredStorehouses(state) {
      return filterElements(state.storehouseList, state.filter)
    }
  },
  mutations: {
    mutate,
    setStorehousesList(state, payload) {
      state.storehouseList = payload
    },
    setStorehousesFilter(state, payload) {
      state.storehouseList = payload
    },
    setStorehousesBusy(state, value = false) {
      state.storehouseIsBusy = value
    },
    unshiftStorehouse(state, payload) {
      state.storehouseList.unshift(payload)
    },
    removeStorehouseInList(state, payload) {
      state.storehouseList = state.storehouseList.filter(storehouse => storehouse.id !== payload)
    },
    updateStorehouseInList(state, payload) {
      if (state.storehouseList) {
        state.storehouseList = state.storehouseList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
