<template>
  <b-form-group
    label="Grupo de Endereço:"
    class="md-12"
    style="border-radius: 10px 10px 10px 10px;
-webkit-border-radius: 10px 10px 10px 10px;
-moz-border-radius: 10px 10px 10px 10px;"
    :style="{ border: this.borderColor }"
  >
    <b-row class="md-12">
      <!-- <b-form-group label="Estado:" class="col-md-6">
        <Multiselector
          id="uf-input-type"
          track-by="id"
          label="name"
          :value="$v.value.city.uf.$model"
          :custom-label="val => `${val.uf} - ${val.name}`"
          :options="ufList"
          @select="_setStateUf"
          :multiple="false"
          :hide-selected="false"
          :taggable="false"
          tag-placeholder="Selecione uma opção"
          placeholder="Informe ao menos um"
          class="Multiselector-primary"
        />
        <div class="cinvalid-tip" v-if="isValid('value.city.uf', '$anyError')">
          O Estado (UF) deve ser informado
        </div>
      </b-form-group> -->
      <b-form-group label="Cidade:" class="col-md-12">
        <Multiselector
          id="city-input-type"
          track-by="id"
          :custom-label="customLabelCity"
          v-model="$v.value.city.$model"
          @search-change="triggerSearch"
          :options="cityList"
          :multiple="false"
          :hide-selected="false"
          :taggable="false"
          tag-placeholder="Selecione uma opção"
          placeholder="Informe ao menos um"
          class="Multiselector-primary"
        />
        <div class="cinvalid-tip" v-if="isValid('value.city', '$anyError')">
          A Cidade deve ser informada
        </div>
      </b-form-group>
    </b-row>
    <b-row class="md-12">
      <b-form-group label="CEP:" class="col-md-6 sm" size="sm">
        <template slot="description">
          <div>
            <i class="ion ion-md-information-circle-outline" style="color: deepskyblue;"></i> Traz Cidade, Bairro e
            Endereço automaticamente
          </div>
        </template>
        <ZipInput
          id="zipcode-input"
          @onFoundAddress="onFoundAddress"
          v-model="$v.value.zipcode.$model"
          :state="isValid('value.zipcode')"
        />
        <div class="invalid-tooltip" v-if="!isValid('value.zipcode', 'required')">
          CEP é obrigatório
        </div>
        <div class="invalid-tooltip" v-if="!isValid('value.zipcode', 'minLength')">
          CEP não é valido
        </div>
      </b-form-group>
      <b-form-group label="Bairro:" class="col-md-6">
        <c-input
          v-model="$v.value.neighborhood.$model"
          placeholder="Digite o Bairro"
          :state="isValid('value.neighborhood')"
        />
        <div class="invalid-tooltip" v-if="!isValid('value.neighborhood', 'minLength')">
          Endereço muito curto
        </div>
      </b-form-group>
    </b-row>
    <b-row class="md-12">
      <b-form-group label="Endereço:" class="col-md-6">
        <c-input v-model="$v.value.street.$model" placeholder="Digite o endereço" :state="isValid('value.street')" />
        <div class="invalid-tooltip" v-if="!isValid('value.street', 'minLength')">
          Endereço muito curto
        </div>
      </b-form-group>
      <b-form-group label="Número:" class="col-md-6">
        <c-input
          v-model="$v.value.number.$model"
          placeholder="Digite o Número"
          type="number"
          :state="isValid('value.number')"
        />
        <div class="invalid-tooltip" v-if="!isValid('value.number', 'number')">
          Número Inválido
        </div>
      </b-form-group>
    </b-row>
    <b-form-group label="Complemento:" class="md-6">
      <c-input
        v-model="$v.value.complement.$model"
        placeholder="Digite o Complemento"
        :state="isValid('value.complement')"
      />
      <div class="invalid-tooltip" v-if="!isValid('value.complement', 'minLength')">
        Complemento muito curto (menos de 4 letras)
      </div>
    </b-form-group>
  </b-form-group>
</template>

<script>
import { required, minLength, requiredIf, requiredUnless } from 'vuelidate/lib/validators'
import { validateBr } from 'js-brasil'
import { debounce, isEmpty } from 'lodash'
import { validate_number } from 'js-brasil/dist/src/validate'

import vuelidateStateMixin from '@/mixins/vuelidate-state-mixin'
import citiesMixin from '@/features/cities/store/mixin'
import ufsMixin from '@/features/ufs/store/mixin'

export default {
  name: 'AddressPicker',
  mixins: [vuelidateStateMixin, ufsMixin, citiesMixin],
  props: {
    value: {
      type: Object,
      default: () => ({
        street: null,
        number: null,
        complement: null,
        neighborhood: null,
        zipcode: null,
        city: {
          uf: {}
        }
      })
    },
    emitOnMount: {
      default: true
    }
  },
  data() {
    return {
      citySearch: null,
      borderColor: '2px solid #d2d2d2'
    }
  },
  async mounted() {
    if (this.emitOnMount) {
      this.$emit('input', this.isChecked)
    }
  },
  methods: {
    isValid(path, checkField = '$invalid') {
      return this.validateField(path, checkField, this.allreadyValidated)
    },
    validate() {
      this.$v.touch()
      return this.$v
    },
    // async _setStateUf(nValue) {
    //   this.value.city.uf = nValue
    //   await this.listCities({ page: 1, perPage: 1000, text: null, uf: nValue })
    //   this.$v.value.uf.$touch()
    // },
    async _setCity(nValue) {
      this.value.city = nValue
      await this.listCities({ page: 1, perPage: 1000, text: null, uf: nValue?.uf })
      this.$v.value.city.$touch()
    },
    async onFoundAddress(address) {
      if (!this?.value) {
        // eslint-disable-next-line vue/no-mutating-props
        this.value = {}
      }
      if (isEmpty(this?.value?.neighborhood?.trim())) {
        this.value.neighborhood = address.neighborhood
      }
      await this._setCity(address?.city)

      if (isEmpty(this?.value?.street?.trim())) {
        this.value.street = address.street
      }
      console.warn('CEP ENCONTRADO:', this.value)
    },
    customLabelCity(city) {
      if (city?.id) {
        return `${city?.name ?? '<Cidade NI>'} - ${city?.uf?.name ?? '<Estado NI>'}  (${city?.uf?.uf ?? '<UF NI>'})`
      }
      return 'Nenhum'
    },
    async triggerSearch(text) {
      this.citySearch = text
      if (this.citySearch) await this.debounceSearch()
    },
    debounceSearch: debounce(function() {
      const that = this
      that.listCities({ page: 1, perPage: 100, text: that.citySearch })
    }, 700)
  },
  computed: {
    isValidCity() {
      if (this.supplier.city && this.supplier.city.ufId && this.supplier.uf) {
        return this.supplier.city.ufId === this.supplier.uf.id
      } else return true
    },
    isValidAddress() {
      const isValid = this.isValid('value', '$anyError')
      return isValid
    },
    isValidColor() {
      return this.isValidAddress ? '2px solid #55ff22' : '2px solid #ff0988'
    }
  },
  watch: {
    value(nVal, oVal) {
      if (JSON.stringify(nVal) !== JSON.stringify(oVal)) {
        this.$emit('input', nVal)
      }
    }
    // 'value.city.id'() {
    //   // Aqui você pode executar a lógica necessária com o novo valor da v-model
    //   // Emita um evento personalizado para notificar o componente pai sobre a mudança
    //   this.$emit('input', this.value)
    // }
  },
  validations() {
    return {
      value: {
        street: { minLength: minLength(4) },
        neighborhood: { minLength: minLength(4) },
        complement: { minLength: minLength(4) },
        number: {
          required,
          number: requiredIf(function(nestedModel) {
            return validate_number(this.value.number ?? '')
          })
        },
        zipcode: { required, minLength: minLength(9) },
        uf: { required },

        city: {
          required,
          ufValid: requiredIf(function(nestedModel) {
            return this.isValidCity
          })
        }
      }
    }
  }
}
</script>
