'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  setState
} from '@/helpers/store-helpers'

import vehicleChecklistRecordsService from '@/features/vehicle-checklist-records/vehicle-checklist-records-api'

const busyMutateName = 'setVehicleChecklistRecordsBusy'

export default {
  namespaced: true,
  state: {
    vehicleChecklistRecordsList: [],
    vehicleChecklistRecordsFilter: '',
    vehicleChecklistRecordsIsBusy: false,
    vehicleChecklistRecordsTotalRows: 0,
    vehicleChecklistRecordsCurrentPage: 1,
    vehicleChecklistRecordsPerPage: 10,
    vehicleChecklistRecordsLastPage: 0
  },
  actions: {
    async listVehicleChecklistRecords({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await vehicleChecklistRecordsService().list(
            page,
            perPage,
            text
          )
          setState(commit, 'vehicleChecklistRecordsTotalRows', payload.total)
          setState(commit, 'vehicleChecklistRecordsCurrentPage', payload.page)
          setState(commit, 'vehicleChecklistRecordsPerPage', payload.perPage)
          setState(commit, 'vehicleChecklistRecordsLastPage', payload.lastPage)

          commit('setVehicleChecklistRecordsList', payload.data)
        }
      )
    },
    async showVehicleChecklistRecords({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return vehicleChecklistRecordsService().show(id)
        }
      )
    },
    async createVehicleChecklistRecords({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await vehicleChecklistRecordsService().create(payload)
          commit('unshiftVehicleChecklistRecords', entry)
        }
      )
    },
    async updateVehicleChecklistRecords({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await vehicleChecklistRecordsService().update(payload)
          commit('updateVehicleChecklistRecordsInList', entry)
          return entry
        }
      )
    },
    async deleteVehicleChecklistRecords({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await vehicleChecklistRecordsService().delete(id)
          if (result) await commit('removeVehicleChecklistRecordsInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredVehicleChecklistRecords(state) {
      return filterElements(state.vehicleChecklistRecordsList, state.filter)
    }
  },
  mutations: {
    mutate,
    setVehicleChecklistRecordsList(state, payload) {
      state.vehicleChecklistRecordsList = payload
    },
    setVehicleChecklistRecordsFilter(state, payload) {
      state.vehicleChecklistRecordsList = payload
    },
    setVehicleChecklistRecordsBusy(state, value = false) {
      state.vehicleChecklistRecordsIsBusy = value
    },
    unshiftVehicleChecklistRecords(state, payload) {
      state.vehicleChecklistRecordsList.unshift(payload)
    },
    removeVehicleChecklistRecordsInList(state, payload) {
      state.vehicleChecklistRecordsList = state.vehicleChecklistRecordsList.filter(
        vehicleChecklistRecords => vehicleChecklistRecords.id !== payload
      )
    },
    updateVehicleChecklistRecordsInList(state, payload) {
      if (state.vehicleChecklistRecordsList) {
        state.vehicleChecklistRecordsList = state.vehicleChecklistRecordsList.map(
          val => {
            if (val.id === payload.id) {
              return payload
            }
            return val
          }
        )
      }
    }
  }
}
