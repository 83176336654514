import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  methods: {
    ...mapActions('ciotOperators', [
      'listCiotOperators',
      'showCiotOperator',
      'deleteCiotOperator',
      'createCiotOperator',
      'updateCiotOperator'
    ]),
    ...mapMutations('ciotOperators', [
      'cleanCiotOperatorsErrors',
      'setCiotOperatorsList',
      'setCiotOperatorsFilter',
      'setCiotOperatorsBusy'
    ])
  },
  computed: {
    ...mapState('ciotOperators', [
      'ciotOperatorIsBusy',
      'ciotOperatorList',
      'ciotOperatorFilter',
      'ciotOperatorPerPage',
      'ciotOperatorCurrentPage',
      'ciotOperatorTotalRows',
      'ciotOperatorLastPage'
    ]),
    ...mapGetters('ciotOperators', [
      'filteredCiotOperators'
    ])
  }
}
