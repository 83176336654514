'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import dockService from '@/features/docks/docks-api'

const busyMutateName = 'setDocksBusy'

export default {
  namespaced: true,
  state: {
    dockList: [],
    dockFilter: '',
    dockIsBusy: false,
    dockTotalRows: 0,
    dockCurrentPage: 1,
    dockPerPage: 10,
    dockLastPage: 0
  },
  actions: {
    async listDocks({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await dockService().list(page, perPage, text)
          setState(commit, 'dockTotalRows', payload.total)
          setState(commit, 'dockCurrentPage', payload.page)
          setState(commit, 'dockPerPage', payload.perPage)
          setState(commit, 'dockLastPage', payload.lastPage)

          commit('setDocksList', payload.data)
        }
      )
    },
    async showDock({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return dockService().show(id)
        }
      )
    },
    async createDock({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await dockService().create(payload)
          commit('unshiftDock', entry)
        }
      )
    },
    async updateDock({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await dockService().update(payload)
          commit('updateDockInList', entry)
          return entry
        }
      )
    },
    async deleteDock({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await dockService().delete(id)
          if (result) await commit('removeDockInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredDocks(state) {
      return filterElements(state.dockList, state.filter)
    }
  },
  mutations: {
    mutate,
    setDocksList(state, payload) {
      state.dockList = payload
    },
    setDocksFilter(state, payload) {
      state.dockList = payload
    },
    setDocksBusy(state, value = false) {
      state.dockIsBusy = value
    },
    unshiftDock(state, payload) {
      state.dockList.unshift(payload)
    },
    removeDockInList(state, payload) {
      state.dockList = state.dockList.filter(
        dock => dock.id !== payload
      )
    },
    updateDockInList(state, payload) {
      if (state.dockList) {
        state.dockList = state.dockList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
