'use strict'

import { mutate, doAsyncBusyTask, filterElements, setState } from '@/helpers/store-helpers'

import manifestsApi from '@/features/manifests/manifests-api'

const busyMutateName = 'setManifestsBusy'

export default {
  namespaced: true,
  state: {
    manifestList: [],
    manifestFilter: '',
    manifestIsBusy: false,
    manifestTotalRows: 0,
    manifestCurrentPage: 1,
    manifestPerPage: 10,
    manifestLastPage: 0
  },
  actions: {
    async listManifests({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const payload = await manifestsApi().list(page, perPage, text)
        setState(commit, 'manifestTotalRows', payload.total)
        setState(commit, 'manifestCurrentPage', payload.page)
        setState(commit, 'manifestPerPage', payload.perPage)
        setState(commit, 'manifestLastPage', payload.lastPage)

        commit('setManifestsList', payload.data)
      })
    },
    async showManifest({ state, commit }, id) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        return manifestsApi().show(id)
      })
    },
    async createManifest({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const entry = await manifestsApi().create(payload)
        commit('unshiftManifest', entry)
      })
    },
    async requestManifestsSM({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const apiData = await manifestsApi().requestSM(payload)
        return apiData
      })
    },
    async checkFreightContract({ state, dispatch, commit }, manifests) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const apiData = await manifestsApi().checkContractGeneration(manifests)
        return apiData
      })
    },
    async checkFreightContractValue({ state, dispatch, commit }, { manifest, value }) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const apiData = await manifestsApi().checkContractGenerationValue({ manifest, value })
        return apiData
      })
    },
    async generateFreightContract({ state, dispatch, commit }, values) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const apiData = await manifestsApi().emitFreightContract(values)
        return apiData
      })
    },
    async updateManifest({ state, commit }, payload) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const entry = await manifestsApi().update(payload)
        commit('updateManifestInList', entry)
        return entry
      })
    },
    async deleteManifest({ state, commit }, id) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const result = await manifestsApi().delete(id)
        if (result) await commit('removeManifestInList', id)
        return result
      })
    }
  },
  getters: {
    filteredManifests(state) {
      return filterElements(state.manifestList, state.filter)
    }
  },
  mutations: {
    mutate,
    setManifestsList(state, payload) {
      state.manifestList = payload
    },
    setManifestsFilter(state, payload) {
      state.manifestList = payload
    },
    setManifestsBusy(state, value = false) {
      state.manifestIsBusy = value
    },
    unshiftManifest(state, payload) {
      state.manifestList.unshift(payload)
    },
    removeManifestInList(state, payload) {
      state.manifestList = state.manifestList.filter(manifest => manifest.id !== payload)
    },
    updateManifestInList(state, payload) {
      if (state.manifestList) {
        state.manifestList = state.manifestList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
