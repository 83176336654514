'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import freightCheckTypeService from '@/features/freight-check-types/freight-check-types-api'

const busyMutateName = 'setFreightCheckTypesBusy'

export default {
  namespaced: true,
  state: {
    freightCheckTypeList: [],
    freightCheckTypeFilter: '',
    freightCheckTypeIsBusy: false,
    freightCheckTypeTotalRows: 0,
    freightCheckTypeCurrentPage: 1,
    freightCheckTypePerPage: 10,
    freightCheckTypeLastPage: 0
  },
  actions: {
    async listFreightCheckTypes({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await freightCheckTypeService().list(page, perPage, text)
          setState(commit, 'freightCheckTypeTotalRows', payload.total)
          setState(commit, 'freightCheckTypeCurrentPage', payload.page)
          setState(commit, 'freightCheckTypePerPage', payload.perPage)
          setState(commit, 'freightCheckTypeLastPage', payload.lastPage)

          commit('setFreightCheckTypesList', payload.data)
        }
      )
    },
    async showFreightCheckType({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return freightCheckTypeService().show(id)
        }
      )
    },
    async createFreightCheckType({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await freightCheckTypeService().create(payload)
          commit('unshiftFreightCheckType', entry)
        }
      )
    },
    async updateFreightCheckType({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await freightCheckTypeService().update(payload)
          commit('updateFreightCheckTypeInList', entry)
          return entry
        }
      )
    },
    async deleteFreightCheckType({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await freightCheckTypeService().delete(id)
          if (result) await commit('removeFreightCheckTypeInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredFreightCheckTypes(state) {
      return filterElements(state.freightCheckTypeList, state.filter)
    }
  },
  mutations: {
    mutate,
    setFreightCheckTypesList(state, payload) {
      state.freightCheckTypeList = payload
    },
    setFreightCheckTypesFilter(state, payload) {
      state.freightCheckTypeList = payload
    },
    setFreightCheckTypesBusy(state, value = false) {
      state.freightCheckTypeIsBusy = value
    },
    unshiftFreightCheckType(state, payload) {
      state.freightCheckTypeList.unshift(payload)
    },
    removeFreightCheckTypeInList(state, payload) {
      state.freightCheckTypeList = state.freightCheckTypeList.filter(
        freightCheckType => freightCheckType.id !== payload
      )
    },
    updateFreightCheckTypeInList(state, payload) {
      if (state.freightCheckTypeList) {
        state.freightCheckTypeList = state.freightCheckTypeList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
