<template>
  <Multiselector
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
    track-by="id"
    label="name"
    :custom-label="val => `${val.id} | CPF: ${val.cpf} | Nome: ${val.fullname}`"
    :options="driverList"
    :multiple="false"
    :allow-empty="false"
    :taggable="false"
  >
    <template slot="option" slot-scope="{ props }">
      <div class="option__desc">
        <b-col>
          <b-row>
            <span class="option__small"
              >Nome:
              {{ props?.fullname ?? 'NI' }}
            </span>
          </b-row>
          <b-row>
            <span class="option__small"
              >CPF:
              {{ props?.cpf ?? 'NI' }}
            </span>
          </b-row>
          <b-row>
            <span class="option__small"
              >RG:
              {{ props?.rg ?? 'NI' }}
            </span>
          </b-row>
        </b-col>
      </div>
    </template>
  </Multiselector>
</template>

<script>
import driversMixin from '@/features/drivers/store/mixin'

export default {
  name: 'driver-selector',
  async mounted() {
    await this.listDrivers({ page: 1, perPage: 10000 })
  },
  props: {
    // eslint-disable-next-line vue/no-unused-properties
    rows: Array,
    // eslint-disable-next-line vue/no-unused-properties
    header: Array
  },
  mixins: [driversMixin],
  methods: {}
}
</script>
