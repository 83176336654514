var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-input', _vm._g(_vm._b({
    directives: [{
      name: "currency",
      rawName: "v-currency",
      value: {
        currency: {
          suffix: ''
        },
        precision: {
          min: 2,
          max: 2
        },
        valueAsInteger: false,
        distractionFree: false,
        autoDecimalMode: false,
        allowNegative: false
      },
      expression: "{\n    currency: {\n      suffix: ''\n    },\n    precision: {\n      min: 2,\n      max: 2\n    },\n    valueAsInteger: false,\n    distractionFree: false,\n    autoDecimalMode: false,\n    allowNegative: false\n  }"
    }],
    staticClass: "form-control",
    attrs: {
      "autocomplete": "off",
      "type": "text"
    }
  }, 'c-input', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners), [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }