import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  methods: {
    ...mapActions('transportServices', [
      'listTransportServices',
      'showTransportService',
      'deleteTransportService',
      'createTransportService',
      'updateTransportService'
    ]),
    ...mapMutations('transportServices', [
      'cleanTransportServicesErrors',
      'setTransportServicesList',
      'setTransportServicesFilter',
      'setTransportServicesBusy'
    ])
  },
  computed: {
    ...mapState('transportServices', [
      'transportServiceIsBusy',
      'transportServiceList',
      'transportServiceFilter',
      'transportServicePerPage',
      'transportServiceCurrentPage',
      'transportServiceTotalRows',
      'transportServiceLastPage'
    ]),
    ...mapGetters('transportServices', [
      'filteredTransportServices'
    ])
  }
}
