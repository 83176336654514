import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  methods: {
    ...mapActions('cities', [
      'listCities',
      'showCity',
      'deleteCity',
      'createCity',
      'updateCity'
    ]),
    ...mapMutations('cities', [
      'cleanCitiesErrors',
      'setCitiesList',
      'setCitiesFilter',
      'setCitiesBusy'
    ])
  },
  computed: {
    ...mapState('cities', [
      'cityIsBusy',
      'cityList',
      'cityFilter',
      'cityPerPage',
      'cityCurrentPage',
      'cityTotalRows',
      'cityLastPage'
    ]),
    ...mapGetters('cities', [
      'filteredCities'
    ])
  }
}
