var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Multiselector', _vm._g(_vm._b({
    attrs: {
      "track-by": "id",
      "label": "name",
      "custom-label": function (val) {
        return ((val.uf) + " - " + (val.name));
      },
      "options": _vm.ufList,
      "multiple": false,
      "allow-empty": false,
      "taggable": false
    },
    on: {
      "open": _vm.onList,
      "search-change": _vm.onSearchModel,
      "select": _vm.onSelectModel,
      "remove": _vm.onRemoveModel
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (_ref) {
        var _props$name, _props$uf;
        var props = _ref.props;
        return [_c('div', {
          staticClass: "option__desc"
        }, [_c('b-col', [_c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("Nome:\n            " + _vm._s((_props$name = props === null || props === void 0 ? void 0 : props.name) !== null && _props$name !== void 0 ? _props$name : 'NI') + "\n          ")])]), _vm._v(" "), _c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("UF:\n            " + _vm._s((_props$uf = props === null || props === void 0 ? void 0 : props.uf) !== null && _props$uf !== void 0 ? _props$uf : 'NI') + "\n          ")])])], 1)], 1)];
      }
    }])
  }, 'Multiselector', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners));
}
var staticRenderFns = []

export { render, staticRenderFns }