var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BusyOverlay', {
    attrs: {
      "show": _vm.freightContractIsBusy
    }
  }, [_c('Multiselector', _vm._g(_vm._b({
    attrs: {
      "track-by": "id",
      "label": "name",
      "custom-label": function (props) {
        var _props$id, _props$supplier$name, _props$supplier, _props$company$name, _props$company;
        return ("#" + ((_props$id = props === null || props === void 0 ? void 0 : props.id) !== null && _props$id !== void 0 ? _props$id : 'NI') + " |Fornec: " + ((_props$supplier$name = props === null || props === void 0 ? void 0 : (_props$supplier = props.supplier) === null || _props$supplier === void 0 ? void 0 : _props$supplier.name) !== null && _props$supplier$name !== void 0 ? _props$supplier$name : 'NI') + " |Empr:" + ((_props$company$name = props === null || props === void 0 ? void 0 : (_props$company = props.company) === null || _props$company === void 0 ? void 0 : _props$company.name) !== null && _props$company$name !== void 0 ? _props$company$name : 'NI'));
      },
      "options": _vm.freightContractList,
      "multiple": false,
      "disabled": _vm.disabled,
      "allow-empty": false,
      "taggable": false
    },
    scopedSlots: _vm._u([{
      key: "singleLabel",
      fn: function (_ref) {
        var _props$supplier$name2, _props$supplier2, _props$company$name2, _props$company2;
        var props = _ref.props;
        return [_c('span', {
          staticClass: "option__name"
        }, [_vm._v("\n        ID: " + _vm._s(props === null || props === void 0 ? void 0 : props.id) + "\n\n        Fornecedor: " + _vm._s((_props$supplier$name2 = props === null || props === void 0 ? void 0 : (_props$supplier2 = props.supplier) === null || _props$supplier2 === void 0 ? void 0 : _props$supplier2.name) !== null && _props$supplier$name2 !== void 0 ? _props$supplier$name2 : 'NI') + "\n\n        Empresa: " + _vm._s((_props$company$name2 = props === null || props === void 0 ? void 0 : (_props$company2 = props.company) === null || _props$company2 === void 0 ? void 0 : _props$company2.name) !== null && _props$company$name2 !== void 0 ? _props$company$name2 : 'NI') + "\n      ")]), _vm._v(" "), _c('span', {
          staticClass: "option__desc"
        })];
      }
    }, {
      key: "option",
      fn: function (_ref2) {
        var _props$id2, _props$ciotOperator$n, _props$ciotOperator, _props$supplier$name3, _props$supplier3, _props$supplier$cnpj, _props$supplier4, _props$driver$fullnam, _props$driver, _props$route$descript, _props$route;
        var props = _ref2.props;
        return [_c('div', {
          staticClass: "option__desc"
        }, [_c('b-col', [_c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("ID: " + _vm._s((_props$id2 = props === null || props === void 0 ? void 0 : props.id) !== null && _props$id2 !== void 0 ? _props$id2 : 'NI') + " ")])]), _vm._v(" "), _c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("Operadora de CIOT:\n              " + _vm._s((_props$ciotOperator$n = props === null || props === void 0 ? void 0 : (_props$ciotOperator = props.ciotOperator) === null || _props$ciotOperator === void 0 ? void 0 : _props$ciotOperator.name) !== null && _props$ciotOperator$n !== void 0 ? _props$ciotOperator$n : 'NI') + "\n            ")])]), _vm._v(" "), _c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("Fornecedor:\n              " + _vm._s((_props$supplier$name3 = props === null || props === void 0 ? void 0 : (_props$supplier3 = props.supplier) === null || _props$supplier3 === void 0 ? void 0 : _props$supplier3.name) !== null && _props$supplier$name3 !== void 0 ? _props$supplier$name3 : 'NI') + "\n              | CNPJ:\n              " + _vm._s((_props$supplier$cnpj = props === null || props === void 0 ? void 0 : (_props$supplier4 = props.supplier) === null || _props$supplier4 === void 0 ? void 0 : _props$supplier4.cnpj) !== null && _props$supplier$cnpj !== void 0 ? _props$supplier$cnpj : 'NI') + "\n            ")])]), _vm._v(" "), _c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("Motorista:\n              " + _vm._s((_props$driver$fullnam = props === null || props === void 0 ? void 0 : (_props$driver = props.driver) === null || _props$driver === void 0 ? void 0 : _props$driver.fullname) !== null && _props$driver$fullnam !== void 0 ? _props$driver$fullnam : 'NI') + "\n            ")])]), _vm._v(" "), _c('b-row', [_c('span', {
          staticClass: "option__title"
        }, [_vm._v("\n              Descrição de Rota:\n              " + _vm._s((_props$route$descript = props === null || props === void 0 ? void 0 : (_props$route = props.route) === null || _props$route === void 0 ? void 0 : _props$route.description) !== null && _props$route$descript !== void 0 ? _props$route$descript : 'NI') + "\n            ")])])], 1)], 1)];
      }
    }])
  }, 'Multiselector', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }