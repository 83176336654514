import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  methods: {
    ...mapActions('transportCosts', [
      'listTransportCosts',
      'showTransportCost',
      'deleteTransportCost',
      'createTransportCost',
      'updateTransportCost'
    ]),
    ...mapMutations('transportCosts', [
      'cleanTransportCostsErrors',
      'setTransportCostsList',
      'setTransportCostsFilter',
      'setTransportCostsBusy'
    ])
  },
  computed: {
    ...mapState('transportCosts', [
      'transportCostIsBusy',
      'transportCostList',
      'transportCostFilter',
      'transportCostPerPage',
      'transportCostCurrentPage',
      'transportCostTotalRows',
      'transportCostLastPage'
    ]),
    ...mapGetters('transportCosts', [
      'filteredTransportCosts'
    ])
  }
}
