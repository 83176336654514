import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  mounted() {},
  methods: {
    ...mapActions('usersEditor', [
      'listUsers',
      'filterUsers',
      'createUser',
      'updateUser',
      'deleteUser',
      'getUser',
      'uploadUserSignature'
    ]),
    ...mapMutations('usersEditor', [
      'cleanErrors',
      'markFoundError',
      'setMeterImage',
      'setMeterThumb',
      'updateUsers'
    ])
  },
  computed: {
    ...mapGetters('usersEditor', [
      'errorMessage',
      'hasNewUsers',
      'filteredUsers',
      'totalUsers'
    ]),
    ...mapState('usersEditor', [
      'userList',
      'users',
      'isBusy',
      'lastUsersPage',
      'perPageUsers',
      'currentUsersPage',
      'totalUsersRows'
    ])
  }
}
