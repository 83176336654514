'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import incomeTaxRangeService from '@/features/income-tax-ranges/income-tax-ranges-api'

const busyMutateName = 'setIncomeTaxRangesBusy'

export default {
  namespaced: true,
  state: {
    incomeTaxRangeList: [],
    incomeTaxRangeFilter: '',
    incomeTaxRangeIsBusy: false,
    incomeTaxRangeTotalRows: 0,
    incomeTaxRangeCurrentPage: 1,
    incomeTaxRangePerPage: 10,
    incomeTaxRangeLastPage: 0
  },
  actions: {
    async listIncomeTaxRanges({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await incomeTaxRangeService().list(page, perPage, text)
          setState(commit, 'incomeTaxRangeTotalRows', payload.total)
          setState(commit, 'incomeTaxRangeCurrentPage', payload.page)
          setState(commit, 'incomeTaxRangePerPage', payload.perPage)
          setState(commit, 'incomeTaxRangeLastPage', payload.lastPage)

          commit('setIncomeTaxRangesList', payload.data)
        }
      )
    },
    async showIncomeTaxRange({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return incomeTaxRangeService().show(id)
        }
      )
    },
    async createIncomeTaxRange({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await incomeTaxRangeService().create(payload)
          commit('unshiftIncomeTaxRange', entry)
        }
      )
    },
    async updateIncomeTaxRange({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await incomeTaxRangeService().update(payload)
          commit('updateIncomeTaxRangeInList', entry)
          return entry
        }
      )
    },
    async deleteIncomeTaxRange({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await incomeTaxRangeService().delete(id)
          if (result) await commit('removeIncomeTaxRangeInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredIncomeTaxRanges(state) {
      return filterElements(state.incomeTaxRangeList, state.filter)
    }
  },
  mutations: {
    mutate,
    setIncomeTaxRangesList(state, payload) {
      state.incomeTaxRangeList = payload
    },
    setIncomeTaxRangesFilter(state, payload) {
      state.incomeTaxRangeList = payload
    },
    setIncomeTaxRangesBusy(state, value = false) {
      state.incomeTaxRangeIsBusy = value
    },
    unshiftIncomeTaxRange(state, payload) {
      state.incomeTaxRangeList.unshift(payload)
    },
    removeIncomeTaxRangeInList(state, payload) {
      state.incomeTaxRangeList = state.incomeTaxRangeList.filter(
        incomeTaxRange => incomeTaxRange.id !== payload
      )
    },
    updateIncomeTaxRangeInList(state, payload) {
      if (state.incomeTaxRangeList) {
        state.incomeTaxRangeList = state.incomeTaxRangeList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
