/**
 * Este arquivo tem por finalidade fazer o tratamento dos erros
 * para  exibi-los adequadamente para o usuário.
 */

import nProgress from 'nprogress'
const router = require('@/router')

export default Vue => {
  function hasHttpError(err) {
    try {
      if (err?.request?.response) {
        // try if its and error message
        const parsedRequest = JSON.parse(err?.request?.response)
        console.warn('PARSED', parsedRequest)
        if (parsedRequest.message && parsedRequest.name) {
          return { error: parsedRequest, ...err }
        }
      }
    } catch (error) {
      console.warn('CANNOT DETERMINE FRIENDLY ERROR', error)
    }
    return null
  }
  function getIconFromExceptionName(data) {
    if (Array.isArray(data)) {
      return 'warning'
    } else if (data?.error?.name === 'DomainException') {
      return 'warning'
    }
    return 'error'
  }
  function getTitleFromExceptionName(data, status) {
    if (Array.isArray(data)) {
      return 'Validações'
    } else if (data?.error?.name && data?.error?.name?.includes('Exception')) {
      return 'Exceção'
    } else if (data?.error?.message && status === 400) {
      return 'Validação'
    } else if (status === 401) {
      return 'Não autorizado'
    }
    return 'Houve uma falha inesperada'
  }
  function getHtmlFromError(data) {
    if (Array.isArray(data)) {
      return (
        'Não foi possível completar ação, detalhes:</br> </br> <b>' +
        data.map(val => val.message).join('</br>') +
        '</b>'
      )
    }
    if (data?.message) {
      return `Ocorreu uma falha, detalhes do erro:
</br>
<small>
<p>${data.message}</p>
</small>`
    } else if (data?.error?.message && data?.error?.name && data?.error?.status) {
      return `Ocorreu uma falha, detalhes do erro:
</br>
<p>
${data.error.message}
</br>
${data.error.name} : ${data.error.status}
</p>`
    } else {
      return `Ocorreu uma falha inesperada, consulte o administrador do sistema, detalhes do erro:
</br>
<p>${JSON.stringify(data)}</p>`
    }
  }
  function showAlert(...args) {
    return Vue.prototype.$swal(...args)
  }
  const showResourceNotFoundDialog = function() {
    nProgress.done()
    showAlert({
      title: 'Ops',
      html: 'Não foi possível completar a ação:</br> O recurso requisitado não foi encontrado.',
      icon: 'error'
    })
  }
  const showValidationErrorDialog = function(errorData, status) {
    nProgress.done()
    const payload = {
      title: getTitleFromExceptionName(errorData, status),
      html: getHtmlFromError(errorData, status),
      icon: getIconFromExceptionName(errorData, status)
    }
    showAlert(payload)
  }

  window.onerror = function(msg, url, line, col, error) {
    nProgress.done()
    // certicate that progress are done
    const IS_400_AXIOS_HTTP_ERROR = error?.response && error?.response?.status >= 400 && error?.response?.status < 500
    const IS_400_HTTP_REQUEST =
      error?.xhr && error?.xhr?.status >= 400 && error?.xhr?.status < 500 && error?.xhr?.response
    if (IS_400_AXIOS_HTTP_ERROR) {
      showValidationErrorDialog(error?.response?.data, error?.response?.status)
    }
    if (IS_400_HTTP_REQUEST) {
      showValidationErrorDialog(JSON.parse(error?.xhr?.response))
    }
    console.warn('[window.onerror]', error)
  }

  window.addEventListener('unhandledrejection', function(event) {
    nProgress.done()
    // handle error here
    // event.promise contains the promise object
    // event.reason contains the reason for the rejection

    // certicate that progress are done
    if (event.reason && event.reason.response && event.reason.response.status === 400) {
      showValidationErrorDialog(event.reason.response.data)
    }
    if (event.reason && event.reason.response && event.reason.response.status === 404) {
      showResourceNotFoundDialog()
    }
    console.warn('[window.unhandledrejection]', event)
  })
  function showOnUIError(err) {
    let showOnUI = false
    const IS_400_HTTP_STATUS = err?.response && err?.response?.status >= 400 && err?.response?.status < 500
    const errString = err?.toString() ?? ''
    if (err?.message || errString.includes('layoutHelpers') || errString.includes('not a function')) {
      showOnUI = false
    }
    // erros de validação devem ser exibido como status é 400 e tem array de mensagens
    if (IS_400_HTTP_STATUS && (Array.isArray(err?.response?.data) || err?.response?.data?.message)) {
      showOnUI = true
    }
    return showOnUI
  }
  Vue.config.errorHandler = function(err, vm, info) {
    nProgress.done()
    const IS_400_HTTP_STATUS = err?.response && err?.response?.status >= 400 && err?.response?.status < 500
    const IS_404_HTTP_STATUS = err?.response && err?.response?.status === 404
    const IS_401_HTTP_STATUS = err?.request && err?.request?.status === 401
    // erros que são considerados toleráveis só são logados no console

    if (!showOnUIError(err)) {
      console.warn('-- UI ERROR (Vue.config.errorHandler) --')
      console.warn(err)
      return
    }

    if (IS_401_HTTP_STATUS) {
      if (!err.request.url.endsWith('/login') && !err.request.url.endsWith('/me')) {
        showAlert({
          title: 'Não autorizado',
          html: 'Não esta autenticado com um usuário',
          icon: 'info'
        })
      }
      if (err.request.url.endsWith('/permissions')) {
        showAlert({
          title: 'Não autorizado',
          html: 'Não esta autenticado com um usuário',
          icon: 'info'
        })
        setTimeout(() => {
          router.default.push('/login')
        }, 600)
      }
    }
    if (IS_400_HTTP_STATUS) showValidationErrorDialog(err.response.data)
    if (IS_404_HTTP_STATUS) showResourceNotFoundDialog()
    if (hasHttpError(err)) {
      showValidationErrorDialog(hasHttpError(err))
      console.log('LOGGING -  - in http')
    }
    // handle error
    // `info` is a Vue-specific error info, e.g. which lifecycle hook
    // the error was found in. Only available in 2.2.0+
    console.error('[Vue.config.errorHandler]', err)
  }
  return Vue
}
