'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  setState
} from '@/helpers/store-helpers'

import clientService from '@/features/clients/clients-api'

const busyMutateName = 'setClientsBusy'

export default {
  namespaced: true,
  state: {
    clientList: [],
    clientFilter: '',
    clientIsBusy: false,
    clientTotalRows: 0,
    clientCurrentPage: 1,
    clientPerPage: 10,
    clientLastPage: 0
  },
  actions: {
    async listClients({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await clientService().list(page, perPage, text)
          setState(commit, 'clientTotalRows', payload.total)
          setState(commit, 'clientCurrentPage', payload.page)
          setState(commit, 'clientPerPage', payload.perPage)
          setState(commit, 'clientLastPage', payload.lastPage)

          commit('setClientsList', payload.data)
        }
      )
    },
    async showClient({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return clientService().show(id)
        }
      )
    },
    async createClient({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = clientService().create(payload)
          commit('unshiftClient', entry)
        }
      )
    },
    async updateClient({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = clientService().update(payload)
          commit('updateClientInList', entry)
          return entry
        }
      )
    },
    async deleteClient({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await clientService().delete(id)
          if (result) await commit('removeClientInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredClients(state) {
      return filterElements(state.clientList, state.filter)
    }
  },
  mutations: {
    mutate,
    setClientsList(state, payload) {
      state.clientList = payload
    },
    setClientsFilter(state, payload) {
      state.clientList = payload
    },
    setClientsBusy(state, value = false) {
      state.clientIsBusy = value
    },
    unshiftClient(state, payload) {
      state.clientList.unshift(payload)
    },
    removeClientInList(state, payload) {
      state.clientList = state.clientList.filter(
        client => client.id !== payload
      )
    },
    updateClientInList(state, payload) {
      if (state.clientList) {
        state.clientList = state.clientList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
