<template>
  <c-input
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
    v-mask="'##.###.###/####-##'"
    placeholder="00.000.000/0001-00"
  >
    <slot></slot>
  </c-input>
</template>

<script>
export default {
  data() {
    return {}
  },
  name: 'cnpj-input',
  props: {
    // eslint-disable-next-line vue/no-unused-properties
    rows: Array,
    //  eslint-disable-next-line vue/no-unused-properties
    header: Array
  },
  methods: {}
}
</script>
