var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CanPillBtn', _vm._g(_vm._b({
    attrs: {
      "can": _vm.can,
      "variant": _vm.variant,
      "activeTitle": "Criar entrada !",
      "disableTitle": "Sem permissão de criação !"
    },
    on: {
      "click": _vm.click
    }
  }, 'CanPillBtn', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners), [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }