import HttpClient from '@/services/http-client'

class ManifestService extends HttpClient {
  async show(id) {
    return this.rest.get('/v1/manifests/' + id)
  }

  async list(page = 1, perPage = 1000, text = null) {
    return this.rest.get('/v1/manifests', {
      params: {
        page,
        perPage,
        q: text
      }
    })
  }

  async create(payload) {
    return this.rest.post('/v1/manifests', payload)
  }

  async update(payload) {
    return this.rest.patch('/v1/manifests/' + payload.id, payload)
  }

  async requestSM(payload) {
    return this.rest.post('/v1/manifests/request-sm', payload)
  }

  async checkContractGeneration(manifests) {
    return this.rest.post('/v1/manifests/check-contract-generation', manifests)
  }

  async checkContractGenerationValue({ manifest, value }) {
    return this.rest.post('/v1/manifests/check-contract-value', { manifest, value })
  }

  async emitFreightContract(values) {
    return this.rest.post('/v1/manifests/generate-freight-contract', values)
  }

  async delete(id) {
    return this.rest.delete('/v1/manifests/' + id)
  }
}

export default () => new ManifestService()
