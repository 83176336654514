<template>
  <b-overlay
    id="overlay-background"
    :show="transportCostIsBusy"
    variant="white"
    opacity="0.85"
    blur="2px"
    spinner-variant="primary"
    spinner-type="grow"
    :spinner-small="true"
    rounded="sm"
  >
    <multiselector
      v-bind="{ ...$props, ...$attrs }"
      v-on="$listeners"
      track-by="id"
      label="name"
      :custom-label="
        value => `#${value?.id ?? 'NI'} |Fornec.: ${value?.supplier?.name ?? 'NI'} |Empr.:${value?.company?.name ?? 'NI'}`
      "
      :options="transportCostList"
      :multiple="false"
      :disabled="disabled"
      :allow-empty="false"
      :taggable="false"
    >
      <template slot="singleLabel" slot-scope="{ props }">
        <span class="option__name">
          ID: {{ props?.id }}

          Fornecedor: {{ props?.supplier?.name ?? 'NI' }}

          Empresa: {{ props?.company?.name ?? 'NI' }}
        </span>
        <span class="option__desc"> </span>
      </template>
      <template slot="option" slot-scope="{ props }">
        <div class="option__desc">
          <b-col>
            <b-row>
              <span class="option__small">ID: {{ props?.id ?? 'NI' }} </span>
            </b-row>
            <b-row>
              <span class="option__small"
                >Empresa:
                {{ props?.company?.name ?? 'NI' }}
              </span>
            </b-row>
            <b-row>
              <span class="option__small"
                >Fornecedor:
                {{ props?.supplier?.name ?? 'NI' }}
              </span>
            </b-row>
            <b-row>
              <span class="option__small"
                >Tipo de veículo:
                {{ props?.vehicleType?.name ?? 'NI' }}
              </span>
            </b-row>
            <b-row>
              <span class="option__title">
                Descrição de Rota:
                {{ props?.route?.description ?? 'NI' }}
              </span>
            </b-row>
          </b-col>
        </div>
      </template>
    </multiselector>
  </b-overlay>
</template>

<script>
import transportCostMixin from '@/features/transport-costs/store/mixin'

export default {
  name: 'transport-cost-selector',

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    rows: Array,
    header: Array
  },
  mixins: [transportCostMixin],

  async mounted() {
    this.listTransportCosts({ page: 1, perPage: 1000 })
  },
  methods: {}
}
</script>
