var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Multiselector', _vm._g(_vm._b({
    attrs: {
      "track-by": "id",
      "label": "name",
      "custom-label": function (val) {
        var _val$originCity, _val$destinationCity;
        return ((val === null || val === void 0 ? void 0 : val.description) + " - " + (val === null || val === void 0 ? void 0 : (_val$originCity = val.originCity) === null || _val$originCity === void 0 ? void 0 : _val$originCity.name) + " a " + (val === null || val === void 0 ? void 0 : (_val$destinationCity = val.destinationCity) === null || _val$destinationCity === void 0 ? void 0 : _val$destinationCity.name) + " por ");
      },
      "show-labels": false,
      "options": _vm.transportServiceTypeList,
      "multiple": false,
      "allow-empty": false,
      "taggable": false
    },
    scopedSlots: _vm._u([{
      key: "singleLabel",
      fn: function (_ref) {
        var _props$id, _props$name$uf, _props$name;
        var props = _ref.props;
        return [_c('span', {
          staticClass: "option__desc"
        }, [_c('span', {
          staticClass: "option__title"
        }, [_vm._v("\n        #" + _vm._s((_props$id = props === null || props === void 0 ? void 0 : props.id) !== null && _props$id !== void 0 ? _props$id : 'NI') + " Nome: " + _vm._s((_props$name$uf = props === null || props === void 0 ? void 0 : (_props$name = props.name) === null || _props$name === void 0 ? void 0 : _props$name.uf) !== null && _props$name$uf !== void 0 ? _props$name$uf : 'NI') + "\n        "), _vm._l(props.journeys, function (entry) {
          var _entry$description;
          return _c('span', {
            key: entry.id
          }, [_vm._v(" #" + _vm._s((_entry$description = entry === null || entry === void 0 ? void 0 : entry.description) !== null && _entry$description !== void 0 ? _entry$description : 'NI') + ", ")]);
        })], 2)])];
      }
    }, {
      key: "option",
      fn: function (_ref2) {
        var _props$id2, _props$name2;
        var props = _ref2.props;
        return [_c('div', {
          staticClass: "option__desc"
        }, [_c('b-col', [_c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("\n            #" + _vm._s((_props$id2 = props === null || props === void 0 ? void 0 : props.id) !== null && _props$id2 !== void 0 ? _props$id2 : 'NI') + " Nome:\n            " + _vm._s((_props$name2 = props === null || props === void 0 ? void 0 : props.name) !== null && _props$name2 !== void 0 ? _props$name2 : 'NI') + "\n          ")])]), _vm._v(" "), _c('b-row'), _vm._v(" "), _c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("\n            Descrição:\n            "), _vm._l(props.journeys, function (entry) {
          var _entry$description2;
          return _c('span', {
            key: entry.id
          }, [_vm._v("\n              " + _vm._s((_entry$description2 = entry === null || entry === void 0 ? void 0 : entry.description) !== null && _entry$description2 !== void 0 ? _entry$description2 : 'NI') + "\n            ")]);
        })], 2)])], 1)], 1)];
      }
    }])
  }, 'Multiselector', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners));
}
var staticRenderFns = []

export { render, staticRenderFns }