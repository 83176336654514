'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  setState
} from '@/helpers/store-helpers'

import pathService from '@/features/paths/paths-api'

const busyMutateName = 'setPathsBusy'

export default {
  namespaced: true,
  state: {
    pathList: [],
    pathFilter: '',
    pathIsBusy: false,
    pathTotalRows: 0,
    pathCurrentPage: 1,
    pathPerPage: 10,
    pathLastPage: 0
  },
  actions: {
    async listPaths({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await pathService().list(page, perPage, text)
          setState(commit, 'pathTotalRows', payload.total)
          setState(commit, 'pathCurrentPage', payload.page)
          setState(commit, 'pathPerPage', payload.perPage)
          setState(commit, 'pathLastPage', payload.lastPage)

          commit('setPathsList', payload.data)
        }
      )
    },
    async showPath({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return pathService().show(id)
        }
      )
    },
    async createPath({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await pathService().create(payload)
          commit('unshiftPath', entry)
        }
      )
    },
    async updatePath({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await pathService().update(payload)
          commit('updatePathInList', entry)
          return entry
        }
      )
    },
    async deletePath({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await pathService().delete(id)
          if (result) await commit('removePathInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredPaths(state) {
      return filterElements(state.pathList, state.filter)
    }
  },
  mutations: {
    mutate,
    setPathsList(state, payload) {
      state.pathList = payload
    },
    setPathsFilter(state, payload) {
      state.pathList = payload
    },
    setPathsBusy(state, value = false) {
      state.pathIsBusy = value
    },
    unshiftPath(state, payload) {
      state.pathList.unshift(payload)
    },
    removePathInList(state, payload) {
      state.pathList = state.pathList.filter(
        path => path.id !== payload
      )
    },
    updatePathInList(state, payload) {
      if (state.pathList) {
        state.pathList = state.pathList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
