'use strict'

import { mutate, doAsyncBusyTask, filterElements, setState } from '@/helpers/store-helpers'

import routeService from '@/features/routes/routes-api'

const busyMutateName = 'setRoutesBusy'

export default {
  namespaced: true,
  state: {
    routeList: [],
    routeFilter: '',
    routeIsBusy: false,
    routeTotalRows: 0,
    routeCurrentPage: 1,
    routePerPage: 10,
    routeLastPage: 0
  },
  actions: {
    async listRoutes({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const payload = await routeService().list(page, perPage, text)
        setState(commit, 'routeTotalRows', payload.total)
        setState(commit, 'routeCurrentPage', payload.page)
        setState(commit, 'routePerPage', payload.perPage)
        setState(commit, 'routeLastPage', payload.lastPage)

        commit('setRoutesList', payload.data)
      })
    },
    async showRoute({ state, commit }, id) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        return routeService().show(id)
      })
    },
    async createRoute({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const entry = routeService().create(payload)
        commit('unshiftRoute', entry)
      })
    },
    async updateRoute({ state, commit }, payload) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const entry = routeService().update(payload)
        commit('updateRouteInList', entry)
        return entry
      })
    },
    async deleteRoute({ state, commit }, id) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const result = await routeService().delete(id)
        if (result) await commit('removeRouteInList', id)
        return result
      })
    }
  },
  getters: {
    filteredRoutes(state) {
      return filterElements(state.routeList, state.filter)
    }
  },
  mutations: {
    mutate,
    setRoutesList(state, payload) {
      state.routeList = payload ?? []
    },
    setRoutesFilter(state, payload) {
      state.routeList = payload ?? []
    },
    setRoutesBusy(state, value = false) {
      state.routeIsBusy = value
    },
    unshiftRoute(state, payload) {
      state.routeList.unshift(payload)
    },
    removeRouteInList(state, payload) {
      state.routeList = state.routeList.filter(route => route.id !== payload)
    },
    updateRouteInList(state, payload) {
      if (state.routeList) {
        state.routeList = state.routeList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
