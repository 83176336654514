<template>
  <b-input
    :id="id"
    autocomplete="off"
    type="text"
    class="form-control"
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
  >
    <slot></slot>Slug
  </b-input>
</template>

<script>
export default {
  data() {
    return {}
  },
  name: 'c-input', // abbreviation for "Custom Input"
  props: {
    // eslint-disable-next-line vue/no-unused-properties
    rows: Array,
    //  eslint-disable-next-line vue/no-unused-properties
    header: Array,
    id: {
      default: null
    }
  },
  methods: {}
}
</script>
