'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import deliveryService from '@/features/deliveries/deliveries-api'

const busyMutateName = 'setDeliveriesBusy'

export default {
  namespaced: true,
  state: {
    deliveryList: [],
    deliveryFilter: '',
    deliveryIsBusy: false,
    deliveryTotalRows: 0,
    deliveryCurrentPage: 1,
    deliveryPerPage: 10,
    deliveryLastPage: 0
  },
  actions: {
    async listDeliveries({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await deliveryService().list(page, perPage, text)
          setState(commit, 'deliveryTotalRows', payload.total)
          setState(commit, 'deliveryCurrentPage', payload.page)
          setState(commit, 'deliveryPerPage', payload.perPage)
          setState(commit, 'deliveryLastPage', payload.lastPage)

          commit('setDeliveriesList', payload.data)
        }
      )
    },
    async showDelivery({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return deliveryService().show(id)
        }
      )
    },
    async createDelivery({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await deliveryService().create(payload)
          commit('unshiftDelivery', entry)
        }
      )
    },
    async updateDelivery({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await deliveryService().update(payload)
          commit('updateDeliveryInList', entry)
          return entry
        }
      )
    },
    async deleteDelivery({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await deliveryService().delete(id)
          if (result) await commit('removeDeliveryInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredDeliveries(state) {
      return filterElements(state.deliveryList, state.filter)
    }
  },
  mutations: {
    mutate,
    setDeliveriesList(state, payload) {
      state.deliveryList = payload
    },
    setDeliveriesFilter(state, payload) {
      state.deliveryList = payload
    },
    setDeliveriesBusy(state, value = false) {
      state.deliveryIsBusy = value
    },
    unshiftDelivery(state, payload) {
      state.deliveryList.unshift(payload)
    },
    removeDeliveryInList(state, payload) {
      state.deliveryList = state.deliveryList.filter(
        delivery => delivery.id !== payload
      )
    },
    updateDeliveryInList(state, payload) {
      if (state.deliveryList) {
        state.deliveryList = state.deliveryList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
