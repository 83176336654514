var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Multiselector', _vm._g(_vm._b({
    attrs: {
      "track-by": "id",
      "label": "name",
      "custom-label": function (val) {
        return ((val.id) + " | " + (val.name) + " |CNPJ: " + (val.cnpj));
      },
      "options": _vm.ciotOperatorList,
      "multiple": false,
      "allow-empty": false,
      "taggable": false
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (_ref) {
        var _props$name, _props$cnpj;
        var props = _ref.props;
        return [_c('div', {
          staticClass: "option__desc"
        }, [_c('b-col', [_c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("Nome:\n            " + _vm._s((_props$name = props === null || props === void 0 ? void 0 : props.name) !== null && _props$name !== void 0 ? _props$name : 'NI') + "\n          ")])]), _vm._v(" "), _c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("CNPJ:\n            " + _vm._s((_props$cnpj = props === null || props === void 0 ? void 0 : props.cnpj) !== null && _props$cnpj !== void 0 ? _props$cnpj : 'NI') + "\n          ")])])], 1)], 1)];
      }
    }])
  }, 'Multiselector', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners));
}
var staticRenderFns = []

export { render, staticRenderFns }