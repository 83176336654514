'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import employeeRegistryService from '@/features/employee-registries/employee-registries-api'

const busyMutateName = 'setEmployeeRegistriesBusy'

export default {
  namespaced: true,
  state: {
    employeeRegistryList: [],
    employeeRegistryFilter: '',
    employeeRegistryIsBusy: false,
    employeeRegistryTotalRows: 0,
    employeeRegistryCurrentPage: 1,
    employeeRegistryPerPage: 10,
    employeeRegistryLastPage: 0
  },
  actions: {
    async listEmployeeRegistries({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const payload = await employeeRegistryService().list(page, perPage, text)
        setState(commit, 'employeeRegistryTotalRows', payload.total)
        setState(commit, 'employeeRegistryCurrentPage', payload.page)
        setState(commit, 'employeeRegistryPerPage', payload.perPage)
        setState(commit, 'employeeRegistryLastPage', payload.lastPage)

        commit('setEmployeeRegistriesList', payload.data)
      })
    },
    async showEmployeeRegistry({ state, commit }, id) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        return employeeRegistryService().show(id)
      })
    },
    async createEmployeeRegistry({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const entry = await employeeRegistryService().create(payload)
        commit('unshiftEmployeeRegistry', entry)
      })
    },
    async updateEmployeeRegistry({ state, commit }, payload) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const entry = await employeeRegistryService().update(payload)
        commit('updateEmployeeRegistryInList', entry)
        return entry
      })
    },
    async deleteEmployeeRegistry({ state, commit }, id) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const result = await employeeRegistryService().delete(id)
        if (result) {
          await commit('removeEmployeeRegistryInList', id)
        }
        return result
      })
    }
  },
  getters: {
    filteredEmployeeRegistries(state) {
      return filterElements(state.employeeRegistryList, state.filter)
    }
  },
  mutations: {
    mutate,
    setEmployeeRegistriesList(state, payload) {
      state.employeeRegistryList = payload
    },
    setEmployeeRegistriesFilter(state, payload) {
      state.employeeRegistryList = payload
    },
    setEmployeeRegistriesBusy(state, value = false) {
      state.employeeRegistryIsBusy = value
    },
    unshiftEmployeeRegistry(state, payload) {
      state.employeeRegistryList.unshift(payload)
    },
    removeEmployeeRegistryInList(state, payload) {
      state.employeeRegistryList = state.employeeRegistryList.filter(
        employeeRegistry => employeeRegistry.id !== payload
      )
    },
    updateEmployeeRegistryInList(state, payload) {
      if (state.employeeRegistryList) {
        state.employeeRegistryList = state.employeeRegistryList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
