var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "md-12"
  }, [_c('b-input-group', {
    staticClass: "mb-3"
  }, [_c('b-form-input', {
    attrs: {
      "id": "example-input",
      "lazy-formatter": "",
      "disabled": _vm.computedAttrs.disabled,
      "size": _vm.computedAttrs.size,
      "state": _vm.computedAttrs.disabled ? undefined : _vm.computedAttrs.state,
      "formatter": _vm.formatDate,
      "type": "text",
      "placeholder": "Digite ou Selecione a Data",
      "locale": "pt-BR",
      "autocomplete": "off"
    },
    model: {
      value: _vm.datePicked,
      callback: function ($$v) {
        _vm.datePicked = $$v;
      },
      expression: "datePicked"
    }
  }), _vm._v(" "), _c('b-input-group-append', [_c('b-form-datepicker', {
    staticClass: "row-md-7",
    attrs: {
      "button-only": "",
      "right": "",
      "reset-value": null,
      "date-format-options": {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      },
      "reset-button": "",
      "today-button": "",
      "disabled": _vm.computedAttrs.disabled,
      "size": _vm.computedAttrs.size,
      "state": _vm.computedAttrs.disabled ? undefined : _vm.computedAttrs.state,
      "close-button": "",
      "initial-date": null,
      "label-no-date-selected": "Nenhuma Data Selecionada",
      "label-reset-button": "Remover Data",
      "label-today-button": "Usar Hoje",
      "label-today": "Hoje",
      "label-help": "Use as setas para navegar nas datas do calendário",
      "label-calendar": "Calendário",
      "label-close-button": "Fechar",
      "format": "dd/mm/yyyy",
      "aria-controls": "example-input",
      "locale": "pt-BR"
    },
    on: {
      "input": _vm.datePickerChange,
      "context": _vm.onContext
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }