<template>
  <b-overlay
    id="overlay-background"
    :show="manifestIsBusy"
    variant="white"
    opacity="0.85"
    blur="2px"
    spinner-variant="primary"
    @click="openManifest"
    spinner-type="grow"
    :spinner-small="true"
    rounded="sm"
  >
    <multiselector
      v-bind="{ ...$props, ...$attrs }"
      v-on="$listeners"
      track-by="id"
      label="name"
      :custom-label="
        val => `#${val?.id ?? 'NI'} - CIOT: ${val?.ciot_number ?? 'N/I'} - Cliente: ${val?.company?.name ?? 'N/I'}`
      "
      :options="manifestList"
      :multiple="false"
      :allow-empty="false"
      :taggable="false"
    >
      <template slot="option" slot-scope="{ props }">
        <div class="option__desc">
          <b-col>
            <b-row>
              <span class="option__small"
                >Empresa:
                {{ props?.company?.name ?? 'NI' }}
              </span>
            </b-row>
            <b-row>
              <span class="option__small"
                >CNPJ:
                {{ props?.company?.cnpj ?? 'N/I' }}
              </span>
            </b-row>
          </b-col>
        </div>
      </template>
    </multiselector>
  </b-overlay>
</template>

<script>
import manifestsMixin from '@/features/manifests/store/mixin'

export default {
  data() {
    return {}
  },
  name: 'manifest-selector',
  props: {
    // eslint-disable-next-line vue/no-unused-properties
    rows: Array,
    // eslint-disable-next-line vue/no-unused-properties
    header: Array,
    disabled: { default: false }
  },
  mixins: [manifestsMixin],

  async mounted() {
    await this.listManifests({ page: 1, perPage: 10000 })
  },
  methods: {
    openManifest() {
      if (this.disabled) {
        this.$router.push({
          path: '/manifests/' + this.value.id
        })
      }
    }
  }
}
</script>
