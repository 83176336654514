'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  setState
} from '@/helpers/store-helpers'

import transportServiceService from '@/features/transport-services/transport-services-api'

const busyMutateName = 'setTransportServicesBusy'

export default {
  namespaced: true,
  state: {
    transportServiceList: [],
    transportServiceFilter: '',
    transportServiceIsBusy: false,
    transportServiceTotalRows: 0,
    transportServiceCurrentPage: 1,
    transportServicePerPage: 10,
    transportServiceLastPage: 0
  },
  actions: {
    async listTransportServices({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await transportServiceService().list(page, perPage, text)
          setState(commit, 'transportServiceTotalRows', payload.total)
          setState(commit, 'transportServiceCurrentPage', payload.page)
          setState(commit, 'transportServicePerPage', payload.perPage)
          setState(commit, 'transportServiceLastPage', payload.lastPage)

          commit('setTransportServicesList', payload.data)
        }
      )
    },
    async showTransportService({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return transportServiceService().show(id)
        }
      )
    },
    async createTransportService({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = transportServiceService().create(payload)
          commit('unshiftTransportService', entry)
        }
      )
    },
    async updateTransportService({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = transportServiceService().update(payload)
          commit('updateTransportServiceInList', entry)
          return entry
        }
      )
    },
    async deleteTransportService({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await transportServiceService().delete(id)
          if (result) await commit('removeTransportServiceInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredTransportServices(state) {
      return filterElements(state.transportServiceList, state.filter)
    }
  },
  mutations: {
    mutate,
    setTransportServicesList(state, payload) {
      state.transportServiceList = payload
    },
    setTransportServicesFilter(state, payload) {
      state.transportServiceList = payload
    },
    setTransportServicesBusy(state, value = false) {
      state.transportServiceIsBusy = value
    },
    unshiftTransportService(state, payload) {
      state.transportServiceList.unshift(payload)
    },
    removeTransportServiceInList(state, payload) {
      state.transportServiceList = state.transportServiceList.filter(
        transportService => transportService.id !== payload
      )
    },
    updateTransportServiceInList(state, payload) {
      if (state.transportServiceList) {
        state.transportServiceList = state.transportServiceList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
