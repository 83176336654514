'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  setState
} from '@/helpers/store-helpers'

import supplierService from '@/features/suppliers/suppliers-api'

const busyMutateName = 'setSuppliersBusy'

export default {
  namespaced: true,
  state: {
    supplierList: [],
    supplierFilter: '',
    supplierIsBusy: false,
    supplierTotalRows: 0,
    supplierCurrentPage: 1,
    supplierPerPage: 10,
    supplierLastPage: 0
  },
  actions: {
    async listSuppliers({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await supplierService().list(page, perPage, text)
          setState(commit, 'supplierTotalRows', payload.total)
          setState(commit, 'supplierCurrentPage', payload.page)
          setState(commit, 'supplierPerPage', payload.perPage)
          setState(commit, 'supplierLastPage', payload.lastPage)

          commit('setSuppliersList', payload.data)
        }
      )
    },
    async showSupplier({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return supplierService().show(id)
        }
      )
    },
    async createSupplier({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = supplierService().create(payload)
          commit('unshiftSupplier', entry)
        }
      )
    },
    async updateSupplier({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = supplierService().update(payload)
          commit('updateSupplierInList', entry)
          return entry
        }
      )
    },
    async deleteSupplier({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await supplierService().delete(id)
          if (result) await commit('removeSupplierInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredSuppliers(state) {
      return filterElements(state.supplierList, state.filter)
    }
  },
  mutations: {
    mutate,
    setSuppliersList(state, payload) {
      state.supplierList = payload
    },
    setSuppliersFilter(state, payload) {
      state.supplierList = payload
    },
    setSuppliersBusy(state, value = false) {
      state.supplierIsBusy = value
    },
    unshiftSupplier(state, payload) {
      state.supplierList.unshift(payload)
    },
    removeSupplierInList(state, payload) {
      state.supplierList = state.supplierList.filter(
        supplier => supplier.id !== payload
      )
    },
    updateSupplierInList(state, payload) {
      if (state.supplierList) {
        state.supplierList = state.supplierList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
