'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  setState
} from '@/helpers/store-helpers'

import vehicleTypeService from '@/features/vehicle-types/vehicle-types-api'

const busyMutateName = 'setVehicleTypesBusy'

export default {
  namespaced: true,
  state: {
    vehicleTypeList: [],
    vehicleTypeFilter: '',
    vehicleTypeIsBusy: false,
    vehicleTypeTotalRows: 0,
    vehicleTypeCurrentPage: 1,
    vehicleTypePerPage: 10,
    vehicleTypeLastPage: 0
  },
  actions: {
    async listVehicleTypes({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await vehicleTypeService().list(page, perPage, text)
          setState(commit, 'vehicleTypeTotalRows', payload.total)
          setState(commit, 'vehicleTypeCurrentPage', payload.page)
          setState(commit, 'vehicleTypePerPage', payload.perPage)
          setState(commit, 'vehicleTypeLastPage', payload.lastPage)

          commit('setVehicleTypesList', payload.data)
        }
      )
    },
    async showVehicleType({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return vehicleTypeService().show(id)
        }
      )
    },
    async createVehicleType({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = vehicleTypeService().create(payload)
          commit('unshiftVehicleType', entry)
        }
      )
    },
    async updateVehicleType({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = vehicleTypeService().update(payload)
          commit('updateVehicleTypeInList', entry)
          return entry
        }
      )
    },
    async deleteVehicleType({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await vehicleTypeService().delete(id)
          if (result) await commit('removeVehicleTypeInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredVehicleTypes(state) {
      return filterElements(state.vehicleTypeList, state.filter)
    }
  },
  mutations: {
    mutate,
    setVehicleTypesList(state, payload) {
      state.vehicleTypeList = payload
    },
    setVehicleTypesFilter(state, payload) {
      state.vehicleTypeList = payload
    },
    setVehicleTypesBusy(state, value = false) {
      state.vehicleTypeIsBusy = value
    },
    unshiftVehicleType(state, payload) {
      state.vehicleTypeList.unshift(payload)
    },
    removeVehicleTypeInList(state, payload) {
      state.vehicleTypeList = state.vehicleTypeList.filter(
        vehicleType => vehicleType.id !== payload
      )
    },
    updateVehicleTypeInList(state, payload) {
      if (state.vehicleTypeList) {
        state.vehicleTypeList = state.vehicleTypeList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
