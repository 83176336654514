import Vue from 'vue'

export default Vue.extend({
  methods: {
    async showErrorMessage(error) {
      return this.$swal(
        'Falha !',
        'Houve uma falha por favor contato o administrador do sistema, código do erro [' +
          (error || { code: 'Unknown' }).code +
          '].',
        'error'
      )
    },
    async showDoneModal({ title }) {
      return this.$swal(title || 'Feito !', '', 'success')
    },
    async showPromptModal({
      title = 'Tem certeza?',
      text = 'Essa ação não poderá ser revertida!',
      html,
      yesText = 'Fazer mesmo assim',
      cancelText = 'Cancelar',
      icon,
      onAccept = () => {
        this.$swal('Feito !', '', 'success')
      },
      onDecline = () => {},
      onError = () => {}
    }) {
      const alertResult = await this.$swal({
        title: title,
        text: text || null,
        html: html || null,
        icon: icon || 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: yesText,
        cancelButtonText: cancelText
      })
      if (alertResult.isConfirmed) {
        try {
          onAccept()
        } catch (error) {
          this.showErrorMessage(error)
          onError(error)
          throw error
        }
      } else {
        onDecline()
      }
      return alertResult
    },
    async showDeleteModal({
      title = 'Tem certeza?',
      text = 'Você irá apagar o item, e isso não poderá ser desfeito!',
      icon,
      onAccept,
      onDecline,
      onError
    }) {
      const sweetResult = await this.$swal({
        title: title,
        text: text,
        icon: icon || 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, apagar!',
        cancelButtonText: 'Cancelar'
      })
      if (sweetResult.isConfirmed) {
        try {
          onAccept()
          this.$swal('Apagado!', 'Foi apagado com sucesso.', 'success')
        } catch (error) {
          this.showErrorMessage(error)
          onError(error)
          throw error
        }
      } else {
        onDecline()
      }
      return sweetResult
    },
    showCancelEditModal({
      onAccept = () => {
        this.$swal('Feito !', '', 'success')
      },
      onDecline = () => {},
      onError = () => {}
    }) {
      const that = this

      this.showPromptModal({
        title: 'Deseja cancelar ?',
        text: 'Ao cancelar você irá perder as informações digitadas',
        yesText: 'Ficar',
        cancelText: 'Sair',
        onAccept,
        onDecline,
        onError
      })
    }
  }
})
