'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  setState
} from '@/helpers/store-helpers'

import transportReceiptService from '@/features/transport-receipts/transport-receipts-api'

const busyMutateName = 'setTransportReceiptsBusy'

export default {
  namespaced: true,
  state: {
    transportReceiptList: [],
    transportReceiptFilter: '',
    transportReceiptIsBusy: false,
    transportReceiptTotalRows: 0,
    transportReceiptCurrentPage: 1,
    transportReceiptPerPage: 10,
    transportReceiptLastPage: 0
  },
  actions: {
    async listTransportReceipts({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await transportReceiptService().list(page, perPage, text)
          setState(commit, 'transportReceiptTotalRows', payload.total)
          setState(commit, 'transportReceiptCurrentPage', payload.page)
          setState(commit, 'transportReceiptPerPage', payload.perPage)
          setState(commit, 'transportReceiptLastPage', payload.lastPage)

          commit('setTransportReceiptsList', payload.data)
        }
      )
    },
    async showTransportReceipt({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return transportReceiptService().show(id)
        }
      )
    },
    async createTransportReceipt({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = transportReceiptService().create(payload)
          commit('unshiftTransportReceipt', entry)
        }
      )
    },
    async updateTransportReceipt({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = transportReceiptService().update(payload)
          commit('updateTransportReceiptInList', entry)
          return entry
        }
      )
    },
    async deleteTransportReceipt({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await transportReceiptService().delete(id)
          if (result) await commit('removeTransportReceiptInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredTransportReceipts(state) {
      return filterElements(state.transportReceiptList, state.filter)
    }
  },
  mutations: {
    mutate,
    setTransportReceiptsList(state, payload) {
      state.transportReceiptList = payload
    },
    setTransportReceiptsFilter(state, payload) {
      state.transportReceiptList = payload
    },
    setTransportReceiptsBusy(state, value = false) {
      state.transportReceiptIsBusy = value
    },
    unshiftTransportReceipt(state, payload) {
      state.transportReceiptList.unshift(payload)
    },
    removeTransportReceiptInList(state, payload) {
      state.transportReceiptList = state.transportReceiptList.filter(
        transportReceipt => transportReceipt.id !== payload
      )
    },
    updateTransportReceiptInList(state, payload) {
      if (state.transportReceiptList) {
        state.transportReceiptList = state.transportReceiptList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
