export default {
  methods: {
    showErrorModal: function({ title = 'Falha !!!', text = null, html = null }) {
      this.$swal({
        title: title,
        // text: text,
        html: text ?? html,
        icon: 'error',
        cancelButtonText: 'Cancelar'
      })
    }
  }
}
