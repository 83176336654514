// Additional polyfills
import 'custom-event-polyfill'
import 'url-polyfill'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './stores'
import { sync } from 'vuex-router-sync'

import BootstrapVue from 'bootstrap-vue'
import Notifications from 'vue-notification'

import globals from '@/globals'
import Popper from 'popper.js'
import VueCurrencyInput from 'vue-currency-input'

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css'

import VueSweetalert2 from 'vue-sweetalert2'
import errorAlertModal from '@/mixins/error-modal-mixin'
import infoAlertModal from '@/mixins/info-modal-mixin'
import successAlertModal from '@/mixins/success-modal-mixin'
import promptModal from '@/mixins/prompt-modal-mixin'
import globalMixin from '@/mixins/global-mixin.js'
import pluginLoader from '@/plugins/_loader.js'

import { PiniaVuePlugin, createPinia } from 'pinia'

import './registerServiceWorker'

pluginLoader(Vue)

// Required to enable animations on dropdowns/tooltips/popovers
// @ts-ignore
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false
Vue.config.productionTip = false

// Multiselect Globally Available
Vue.component('multiselect', () => import('vue-multiselect'))

Vue.use(PiniaVuePlugin)
const pinia = createPinia()
// vue alerts
Vue.use(VueSweetalert2)
Vue.mixin(errorAlertModal)
Vue.mixin(infoAlertModal)
Vue.mixin(successAlertModal)
Vue.mixin(promptModal)
Vue.mixin(globalMixin)

// format inputs
Vue.use(VueCurrencyInput)

// validate fieldsplugins
Vue.use(BootstrapVue)
// show toasty notification common used to errors
Vue.use(Notifications)
// Global RTL flag
Vue.mixin({
  data: globals
})

require('./extensions/index')(Vue) // Carrea todas extensões de diretivas e etc

// will persist any state
sync(store, router)

export const { $store } = new Vue({
  router,
  pinia,
  store,
  render: h => h(App)
}).$mount('#app')
