<template>
  <Multiselector
    track-by="id"
    label="name"
    :custom-label="val => `${val.uf} - ${val.name}`"
    :options="ufList"
    :multiple="false"
    @open="onList"
    @search-change="onSearchModel"
    @select="onSelectModel"
    @remove="onRemoveModel"
    :allow-empty="false"
    :taggable="false"
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
  >
    <template slot="option" slot-scope="{ props }">
      <div class="option__desc">
        <b-col>
          <b-row>
            <span class="option__small"
              >Nome:
              {{ props?.name ?? 'NI' }}
            </span>
          </b-row>
          <b-row>
            <span class="option__small"
              >UF:
              {{ props?.uf ?? 'NI' }}
            </span>
          </b-row>
        </b-col>
      </div>
    </template>
  </Multiselector>
</template>

<script>
import ufsMixin from '@/features/ufs/store/mixin'
export default {
  name: 'uf-selector',
  props: {
    rows: { type: Array, default: () => [] },
    header: Array
  },
  mixins: [ufsMixin],
  data() {
    return {
      selectedEntry: null,
      filters: {
        page: 1,
        perPage: 100,
        text: undefined
      }
    }
  },
  methods: {
    async onList() {
      await this.listUfs(this.filters)
    },
    async onSearchModel(value) {
      this.filters.text = value
      await this.listUfs(this.filters)
    },
    onSelectModel(modelSelected, elementId) {
      console.warn('onSelectModel', modelSelected)
      this.$emit('select', modelSelected)
      // this.$emit('change', modelSelected)
      this.$emit('input', modelSelected)
    },
    onRemoveModel(arg1, arg2) {
      console.warn('removeModel', arg1)
      this.$emit('remove', arg1)
    }
  }
}
</script>
