<template>
  <Multiselector
    track-by="id"
    label="name"
    :custom-label="val => `#${val.id} ${val.name} - ${val.cnpj}`"
    :options="insurance_companyList"
    @open="onList"
    @search-change="onSearchModel"
    @select="onSelectModel"
    @remove="onRemoveModel"
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
  >
  </Multiselector>
</template>

<script>
import insurancesMixin from '@/features/insurance-companies/store/mixin'

export default {
  name: 'insurance-company-selector',
  mixins: [insurancesMixin],
  async mounted() {
    if (this.emitOnMount) this.$emit('input', this.value)
  },
  props: {
    rows: Array,
    header: Array,
    multiple: { default: false },
    'allow-empty': { default: true },
    taggable: { default: false },
    emitOnMount: {
      default: true
    }
  },
  data() {
    return {
      selectedEntry: null,
      filters: {
        page: 1,
        perPage: 100,
        text: undefined
      }
    }
  },
  methods: {
    async onList() {
      await this.listInsuranceCompanies({ page: 1, perPage: 10000, text: null })
    },
    async onSearchModel(value) {
      this.filters.text = value
      await this.listInsuranceCompanies(this.filters)
    },
    onSelectModel(modelSelected, elementId) {
      console.warn('onSelectModel', modelSelected)
      this.$emit('select', modelSelected)
      // this.$emit('change', modelSelected)
      this.$emit('input', modelSelected)
    },
    onRemoveModel(arg1, arg2) {
      console.warn('removeModel', arg1)
      this.$emit('remove', arg1)
    }
  }
}
</script>
