'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  setState
} from '@/helpers/store-helpers'

import documentTypeApi from '@/features/document-types/document-types-api'

const busyMutateName = 'setDocumentTypeBusy'

export default {
  namespaced: true,
  state: {
    documentTypeList: [],
    documentTypeFilter: '',
    documentTypeIsBusy: false,
    documentTypeTotalRows: 0,
    documentTypeCurrentPage: 1,
    documentTypePerPage: 10,
    documentTypeLastPage: 0
  },
  actions: {
    async listDocumentTypes({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      console.warn('listDocumentTypes', payload)
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await documentTypeApi().list(page, perPage, text)
          setState(commit, 'documentTypeTotalRows', payload.total)
          setState(commit, 'documentTypeCurrentPage', payload.page)
          setState(commit, 'documentTypePerPage', payload.perPage)
          setState(commit, 'documentTypeLastPage', payload.lastPage)

          commit('setDocumentTypeList', payload.data)
        }
      )
    },
    async showDocumentType({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return documentTypeApi().show(id)
        }
      )
    },
    async createDocumentType({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await documentTypeApi().create(payload)
          commit('unshiftDocumentType', entry)
        }
      )
    },
    async updateDocumentType({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await documentTypeApi().update(payload)
          commit('updateDocumentTypeInList', entry)
          return entry
        }
      )
    },
    async deleteDocumentType({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await documentTypeApi().delete(id)
          if (result) await commit('removeDocumentTypeInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredDocumentTypes(state) {
      return filterElements(state.documentTypeList, state.filter)
    }
  },
  mutations: {
    mutate,
    setDocumentTypeList(state, payload) {
      state.documentTypeList = payload
    },
    setDocumentTypeFilter(state, payload) {
      state.documentTypeList = payload
    },
    setDocumentTypeBusy(state, value = false) {
      state.documentTypeIsBusy = value
    },
    unshiftDocumentType(state, payload) {
      state.documentTypeList.unshift(payload)
    },
    removeDocumentTypeInList(state, payload) {
      state.documentTypeList = state.documentTypeList.filter(
        document_type => document_type.id !== payload
      )
    },
    updateDocumentTypeInList(state, payload) {
      if (state.documentTypeList) {
        state.documentTypeList = state.documentTypeList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
