'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import supplierBankAccountService from '@/features/supplier-bank-accounts/supplier-bank-accounts-api'

const busyMutateName = 'setSupplierBankAccountsBusy'

export default {
  namespaced: true,
  state: {
    supplierBankAccountList: [],
    supplierBankAccountFilter: '',
    supplierBankAccountIsBusy: false,
    supplierBankAccountTotalRows: 0,
    supplierBankAccountCurrentPage: 1,
    supplierBankAccountPerPage: 10,
    supplierBankAccountLastPage: 0
  },
  actions: {
    async listSupplierBankAccounts({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await supplierBankAccountService().list(page, perPage, text)
          setState(commit, 'supplierBankAccountTotalRows', payload.total)
          setState(commit, 'supplierBankAccountCurrentPage', payload.page)
          setState(commit, 'supplierBankAccountPerPage', payload.perPage)
          setState(commit, 'supplierBankAccountLastPage', payload.lastPage)

          commit('setSupplierBankAccountsList', payload.data)
        }
      )
    },
    async showSupplierBankAccount({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return supplierBankAccountService().show(id)
        }
      )
    },
    async createSupplierBankAccount({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await supplierBankAccountService().create(payload)
          commit('unshiftSupplierBankAccount', entry)
        }
      )
    },
    async updateSupplierBankAccount({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await supplierBankAccountService().update(payload)
          commit('updateSupplierBankAccountInList', entry)
          return entry
        }
      )
    },
    async deleteSupplierBankAccount({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await supplierBankAccountService().delete(id)
          if (result) await commit('removeSupplierBankAccountInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredSupplierBankAccounts(state) {
      return filterElements(state.supplierBankAccountList, state.filter)
    }
  },
  mutations: {
    mutate,
    setSupplierBankAccountsList(state, payload) {
      state.supplierBankAccountList = payload
    },
    setSupplierBankAccountsFilter(state, payload) {
      state.supplierBankAccountList = payload
    },
    setSupplierBankAccountsBusy(state, value = false) {
      state.supplierBankAccountIsBusy = value
    },
    unshiftSupplierBankAccount(state, payload) {
      state.supplierBankAccountList.unshift(payload)
    },
    removeSupplierBankAccountInList(state, payload) {
      state.supplierBankAccountList = state.supplierBankAccountList.filter(
        supplierBankAccount => supplierBankAccount.id !== payload
      )
    },
    updateSupplierBankAccountInList(state, payload) {
      if (state.supplierBankAccountList) {
        state.supplierBankAccountList = state.supplierBankAccountList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
