'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  setState
} from '@/helpers/store-helpers'

import eventTypeMdfeService from '@/features/event-type-mdfes/event-type-mdfes-api'

const busyMutateName = 'setEventTypeMdfesBusy'

export default {
  namespaced: true,
  state: {
    eventTypeMdfeList: [],
    eventTypeMdfeFilter: '',
    eventTypeMdfeIsBusy: false,
    eventTypeMdfeTotalRows: 0,
    eventTypeMdfeCurrentPage: 1,
    eventTypeMdfePerPage: 10,
    eventTypeMdfeLastPage: 0
  },
  actions: {
    async listEventTypeMdfes({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await eventTypeMdfeService().list(page, perPage, text)
          setState(commit, 'eventTypeMdfeTotalRows', payload.total)
          setState(commit, 'eventTypeMdfeCurrentPage', payload.page)
          setState(commit, 'eventTypeMdfePerPage', payload.perPage)
          setState(commit, 'eventTypeMdfeLastPage', payload.lastPage)

          commit('setEventTypeMdfesList', payload.data)
        }
      )
    },
    async showEventTypeMDFE({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return eventTypeMdfeService().show(id)
        }
      )
    },
    async createEventTypeMDFE({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await eventTypeMdfeService().create(payload)
          commit('unshiftEventTypeMDFE', entry)
        }
      )
    },
    async updateEventTypeMDFE({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await eventTypeMdfeService().update(payload)
          commit('updateEventTypeMDFEInList', entry)
          return entry
        }
      )
    },
    async deleteEventTypeMDFE({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await eventTypeMdfeService().delete(id)
          if (result) await commit('removeEventTypeMDFEInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredEventTypeMdfes(state) {
      return filterElements(state.eventTypeMdfeList, state.filter)
    }
  },
  mutations: {
    mutate,
    setEventTypeMdfesList(state, payload) {
      state.eventTypeMdfeList = payload
    },
    setEventTypeMdfesFilter(state, payload) {
      state.eventTypeMdfeList = payload
    },
    setEventTypeMdfesBusy(state, value = false) {
      state.eventTypeMdfeIsBusy = value
    },
    unshiftEventTypeMDFE(state, payload) {
      state.eventTypeMdfeList.unshift(payload)
    },
    removeEventTypeMDFEInList(state, payload) {
      state.eventTypeMdfeList = state.eventTypeMdfeList.filter(
        eventTypeMdfe => eventTypeMdfe.id !== payload
      )
    },
    updateEventTypeMDFEInList(state, payload) {
      if (state.eventTypeMdfeList) {
        state.eventTypeMdfeList = state.eventTypeMdfeList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
