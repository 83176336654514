var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Multiselector', _vm._g(_vm._b({
    attrs: {
      "track-by": "id",
      "label": "name",
      "custom-label": function (val) {
        return ((val === null || val === void 0 ? void 0 : val.crt_code) + " a " + (val === null || val === void 0 ? void 0 : val.description) + " por ");
      },
      "show-labels": false,
      "options": _vm.crtCodeList,
      "multiple": false,
      "allow-empty": false,
      "taggable": false
    },
    scopedSlots: _vm._u([{
      key: "singleLabel",
      fn: function (_ref) {
        var _props$crt_code, _props$description;
        var props = _ref.props;
        return [_c('span', {
          staticClass: "option__desc"
        }, [_c('span', {
          staticClass: "option__title"
        }, [_vm._v("Código: " + _vm._s((_props$crt_code = props === null || props === void 0 ? void 0 : props.crt_code) !== null && _props$crt_code !== void 0 ? _props$crt_code : 'NI') + " - " + _vm._s((_props$description = props === null || props === void 0 ? void 0 : props.description) !== null && _props$description !== void 0 ? _props$description : 'NI') + " ")])])];
      }
    }, {
      key: "option",
      fn: function (_ref2) {
        var _props$crt_code2, _props$description2;
        var props = _ref2.props;
        return [_c('div', {
          staticClass: "option__desc"
        }, [_c('b-col', [_c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("Código: " + _vm._s((_props$crt_code2 = props === null || props === void 0 ? void 0 : props.crt_code) !== null && _props$crt_code2 !== void 0 ? _props$crt_code2 : 'NI') + " ")])]), _vm._v(" "), _c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v(" Descrição: " + _vm._s((_props$description2 = props === null || props === void 0 ? void 0 : props.description) !== null && _props$description2 !== void 0 ? _props$description2 : 'NI') + " ")])])], 1)], 1)];
      }
    }])
  }, 'Multiselector', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners));
}
var staticRenderFns = []

export { render, staticRenderFns }