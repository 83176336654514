'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  setState
} from '@/helpers/store-helpers'

import transportableDocumentService from '@/features/transportable-documents/transportable-documents-api'

const busyMutateName = 'setTransportableDocumentsBusy'

export default {
  namespaced: true,
  state: {
    transportableDocumentList: [],
    transportableDocumentFilter: '',
    transportableDocumentIsBusy: false,
    transportableDocumentTotalRows: 0,
    transportableDocumentCurrentPage: 1,
    transportableDocumentPerPage: 10,
    transportableDocumentLastPage: 0
  },
  actions: {
    async listTransportableDocuments({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await transportableDocumentService().list(
            page,
            perPage,
            text
          )
          setState(commit, 'transportableDocumentTotalRows', payload.total)
          setState(commit, 'transportableDocumentCurrentPage', payload.page)
          setState(commit, 'transportableDocumentPerPage', payload.perPage)
          setState(commit, 'transportableDocumentLastPage', payload.lastPage)

          commit('setTransportableDocumentsList', payload.data)
        }
      )
    },
    async showTransportableDocument({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return transportableDocumentService().show(id)
        }
      )
    },
    async createTransportableDocument({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await transportableDocumentService().create(payload)
          commit('unshiftTransportableDocument', entry)
        }
      )
    },
    async updateTransportableDocument({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await transportableDocumentService().update(payload)
          commit('updateTransportableDocumentInList', entry)
          return entry
        }
      )
    },
    async deleteTransportableDocument({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await transportableDocumentService().delete(id)
          if (result) await commit('removeTransportableDocumentInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredTransportableDocuments(state) {
      return filterElements(state.transportableDocumentList, state.filter)
    }
  },
  mutations: {
    mutate,
    setTransportableDocumentsList(state, payload) {
      state.transportableDocumentList = payload
    },
    setTransportableDocumentsFilter(state, payload) {
      state.transportableDocumentList = payload
    },
    setTransportableDocumentsBusy(state, value = false) {
      state.transportableDocumentIsBusy = value
    },
    unshiftTransportableDocument(state, payload) {
      state.transportableDocumentList.unshift(payload)
    },
    removeTransportableDocumentInList(state, payload) {
      state.transportableDocumentList = state.transportableDocumentList.filter(
        transportableDocument => transportableDocument.id !== payload
      )
    },
    updateTransportableDocumentInList(state, payload) {
      if (state.transportableDocumentList) {
        state.transportableDocumentList = state.transportableDocumentList.map(
          val => {
            if (val.id === payload.id) {
              return payload
            }
            return val
          }
        )
      }
    }
  }
}
