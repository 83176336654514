'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import crtCodeService from '@/features/crt-codes/crt-codes-api'

const busyMutateName = 'setCrtCodesBusy'

export default {
  namespaced: true,
  state: {
    crtCodeList: [],
    crtCodeFilter: '',
    crtCodeIsBusy: false,
    crtCodeTotalRows: 0,
    crtCodeCurrentPage: 1,
    crtCodePerPage: 10,
    crtCodeLastPage: 0
  },
  actions: {
    async listCrtCodes({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await crtCodeService().list(page, perPage, text)
          setState(commit, 'crtCodeTotalRows', payload.total)
          setState(commit, 'crtCodeCurrentPage', payload.page)
          setState(commit, 'crtCodePerPage', payload.perPage)
          setState(commit, 'crtCodeLastPage', payload.lastPage)

          commit('setCrtCodesList', payload.data)
        }
      )
    },
    async showCrtCode({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return crtCodeService().show(id)
        }
      )
    },
    async createCrtCode({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await crtCodeService().create(payload)
          commit('unshiftCrtCode', entry)
        }
      )
    },
    async updateCrtCode({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await crtCodeService().update(payload)
          commit('updateCrtCodeInList', entry)
          return entry
        }
      )
    },
    async deleteCrtCode({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await crtCodeService().delete(id)
          if (result) await commit('removeCrtCodeInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredCrtCodes(state) {
      return filterElements(state.crtCodeList, state.filter)
    }
  },
  mutations: {
    mutate,
    setCrtCodesList(state, payload) {
      state.crtCodeList = payload
    },
    setCrtCodesFilter(state, payload) {
      state.crtCodeList = payload
    },
    setCrtCodesBusy(state, value = false) {
      state.crtCodeIsBusy = value
    },
    unshiftCrtCode(state, payload) {
      state.crtCodeList.unshift(payload)
    },
    removeCrtCodeInList(state, payload) {
      state.crtCodeList = state.crtCodeList.filter(
        crtCode => crtCode.id !== payload
      )
    },
    updateCrtCodeInList(state, payload) {
      if (state.crtCodeList) {
        state.crtCodeList = state.crtCodeList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
