import { SweetAlertResult } from 'sweetalert2'
import Vue from 'vue'

export default Vue.extend({
  methods: {
    async showInfoModal({ title, html, text, icon = 'info' }) {
      return this.$swal({
        title: title,
        text: text,
        html: html || null,
        icon: icon || 'error',
        cancelButtonText: 'Cancelar'
      })
    }
  }
})
