<template>
  <Multiselector
    track-by="sefaz_code"
    label="name"
    :custom-label="val => `#${val.sefaz_code} ${val.acronym} - ${val.name}`"
    :options="sefazConveyorTypeList"
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
  >
    <!-- <template slot="option" slot-scope="{ props }">
      <div class="option__desc">
        <b-col>
          <b-row>
            <span class="option__small"
              >Código Sefaz:
              {{ props?.sefaz_code ?? 'NI' }}
            </span>
          </b-row>
          <b-row>
            <span class="option__small"
              >Sigla:
              {{ props?.acronym ?? 'NI' }}
            </span>
          </b-row>
          <b-row>
            <span class="option__small"
              >Nome:
              {{ props?.name ?? 'NI' }}
            </span>
          </b-row>
        </b-col>
      </div>
    </template> -->
  </Multiselector>
</template>

<script>
import sefazMixin from '@/features/sefaz-conveyor-types/store/mixin'

export default {
  name: 'SefazConveyorSelector',
  mixins: [sefazMixin],
  props: {
    rows: Array,
    header: Array,
    multiple: { default: false },
    'allow-empty': { default: true },
    taggable: { default: false },
    emitOnMount: {
      default: true
    }
  },
  async mounted() {
    if (this.emitOnMount) this.$emit('input', this.value)

    this.listSefazConveyorTypes({ page: 1, perPage: 10000, text: null })
  },
  watch: {
    value(nVal) {
      this.$emit('input', nVal)
    }
  },
  methods: {}
}
</script>
