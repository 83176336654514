'use strict'

/** Normaliza valor booleano retornado pelo helper do vuelidate */
const _normalizeReturned = function(value, field, allreadyValidated) {
  let valueFound
  if (field === '$invalid') {
    valueFound = !value[field]
  } else if (field === 'between') {
    valueFound = !value[field]
  } else {
    valueFound = value[field]
  }
  if (value.$anyDirty) {
    return valueFound
  } else {
    if (allreadyValidated) {
      return valueFound
    } else {
      return undefined
    }
  }
}

export default {
  data: () => {
    return { allreadyValidated: false }
  },
  methods: {
    isValidInput(pathToField, field = '$invalid') {
      return this.validateField(pathToField, field, this.allreadyValidated)
    },
    validateField(fieldPath, fieldToCheck = '$invalid', haveSeened = false) {
      try {
        let validator = this.$v
        if (fieldPath.includes('.')) {
          for (const segment of fieldPath.split('.')) {
            validator = validator[segment]
          }
        } else {
          validator = validator[fieldPath]
        }
        return _normalizeReturned(validator /* this.$v.objeto.campo1 */, fieldToCheck, haveSeened)
      } catch (e) {
        return undefined
      }
    }
  },
  computed: {}
}
