<template>
  <multiselector
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
    track-by="name"
    label="name"
    :options="documentTypes"
    :multiple="false"
    :allow-empty="false"
    :taggable="false"
  >
  </multiselector>
</template>

<script>

export default {
  name: 'identification-type-selector',
  data() {
    return {
      documentTypes: [
        { id: 'CPF', name: 'CPF' },
        { id: 'RG', name: 'RG' }
      ]
    }
  },

  props: {
    rows: Array,
    header: Array
  },
  mixins: [],
  methods: {}
}
</script>
