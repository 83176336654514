import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  methods: {
    ...mapActions('ctes', [
      'listCTEs',
      'showCTE',
      'deleteCTE',
      'cancelCTEs',
      'createCTE',
      'updateCTE',
      'checkCTEs',
      'emitCTEs'
    ]),
    ...mapMutations('ctes', ['cleanCtesErrors', 'setCtesList', 'setCtesFilter', 'setCTEsBusy'])
  },
  computed: {
    ...mapState('ctes', [
      'cteIsBusy',
      'cteList',
      'cteFilter',
      'ctePerPage',
      'cteCurrentPage',
      'cteTotalRows',
      'cteLastPage'
    ]),
    ...mapGetters('ctes', ['filteredCtes'])
  }
}
