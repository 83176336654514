<template>
  <b-overlay
    id="overlay-background"
    :show="transportableDocumentIsBusy"
    variant="white"
    opacity="0.85"
    blur="2px"
    spinner-variant="primary"
    spinner-type="grow"
    :spinner-small="true"
    rounded="sm"
  >
    <multiselector
      v-bind="{ ...$props, ...$attrs }"
      v-on="$listeners"
      track-by="id"
      label="name"
      :custom-label="
        val => `emissor.: ${val?.issuer?.name ?? 'NI'} num.doc.: ${val?.document_number ?? 'NI'} - #${val.id}`
      "
      :options="transportableDocumentList"
      :multiple="false"
      :allow-empty="false"
      :taggable="false"
    >
      <template slot="option" slot-scope="{ props }">
        <div class="option__desc">
          <b-col>
            <b-row>
              <span class="option__small"
                >Emissor:
                {{ props?.issuer?.name ?? 'NI' }}
              </span>
            </b-row>
            <b-row>
              <span class="option__small"
                >Num do Doc:
                {{ props?.document_number ?? 'NI' }}
              </span>
            </b-row>
          </b-col>
        </div>
      </template>
    </multiselector>
  </b-overlay>
</template>

<script>
import docsMixin from '@/features/transportable-documents/store/mixin'

export default {
  name: 'TransportableDocumentSelector',

  props: {
    rows: Array,
    header: Array,
    emitOnMount: { default: false }
  },
  mixins: [docsMixin],

  async mounted() {
    await this.listTransportableDocuments({ page: 1, perPage: 10000 })
    if (this.emitOnMount) {
      this.$emit('input', this.value)
    }
  },
  methods: {}
}
</script>
