var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "m-0 p-0"
  }, [_c('a', _vm._g(_vm._b({
    staticClass: "card card-hover text-body m-0",
    staticStyle: {
      "height": "100% !important",
      "width": "100%"
    },
    attrs: {
      "href": "javascript:void(0)"
    }
  }, 'a', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners), [_c('b-card-body', {
    staticClass: "text-center p-4 card"
  }, [_c('div', {
    class: 'lnr ' + _vm.icon + ' display-3 text-primary'
  }), _vm._v(" "), _c('h5', {
    staticClass: "m-0 mt-3"
  }, [_vm._t("default")], 2)])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }