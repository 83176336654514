import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  methods: {
    ...mapActions('ufs', [
      'listUfs',
      'showUF',
      'deleteUF',
      'createUF',
      'updateUF'
    ]),
    ...mapMutations('ufs', [
      'cleanUfsErrors',
      'setUfsList',
      'setUfsFilter',
      'setUfsBusy'
    ])
  },
  computed: {
    ...mapState('ufs', [
      'ufIsBusy',
      'ufList',
      'ufFilter',
      'ufPerPage',
      'ufCurrentPage',
      'ufTotalRows',
      'ufLastPage'
    ]),
    ...mapGetters('ufs', [
      'filteredUfs'
    ])
  }
}
