import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  methods: {
    ...mapActions('suppliers', [
      'listSuppliers',
      'showSupplier',
      'deleteSupplier',
      'createSupplier',
      'updateSupplier'
    ]),
    ...mapMutations('suppliers', [
      'cleanSuppliersErrors',
      'setSuppliersList',
      'setSuppliersFilter',
      'setSuppliersBusy'
    ])
  },
  computed: {
    ...mapState('suppliers', [
      'supplierIsBusy',
      'supplierList',
      'supplierFilter',
      'supplierPerPage',
      'supplierCurrentPage',
      'supplierTotalRows',
      'supplierLastPage'
    ]),
    ...mapGetters('suppliers', [
      'filteredSuppliers'
    ])
  }
}
