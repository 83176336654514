'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  setState
} from '@/helpers/store-helpers'

import vehicleModelService from '@/features/vehicle-models/vehicle-models-api'

const busyMutateName = 'setVehicleModelsBusy'

export default {
  namespaced: true,
  state: {
    vehicleModelList: [],
    vehicleModelFilter: '',
    vehicleModelIsBusy: false,
    vehicleModelTotalRows: 0,
    vehicleModelCurrentPage: 1,
    vehicleModelPerPage: 10,
    vehicleModelLastPage: 0
  },
  actions: {
    async listVehicleModels({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await vehicleModelService().list(page, perPage, text)
          setState(commit, 'vehicleModelTotalRows', payload.total)
          setState(commit, 'vehicleModelCurrentPage', payload.page)
          setState(commit, 'vehicleModelPerPage', payload.perPage)
          setState(commit, 'vehicleModelLastPage', payload.lastPage)

          commit('setVehicleModelsList', payload.data)
        }
      )
    },
    async showVehicleModel({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return vehicleModelService().show(id)
        }
      )
    },
    async createVehicleModel({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = vehicleModelService().create(payload)
          commit('unshiftVehicleModel', entry)
        }
      )
    },
    async updateVehicleModel({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = vehicleModelService().update(payload)
          commit('updateVehicleModelInList', entry)
          return entry
        }
      )
    },
    async deleteVehicleModel({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await vehicleModelService().delete(id)
          if (result) await commit('removeVehicleModelInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredVehicleModels(state) {
      return filterElements(state.vehicleModelList, state.filter)
    }
  },
  mutations: {
    mutate,
    setVehicleModelsList(state, payload) {
      state.vehicleModelList = payload
    },
    setVehicleModelsFilter(state, payload) {
      state.vehicleModelList = payload
    },
    setVehicleModelsBusy(state, value = false) {
      state.vehicleModelIsBusy = value
    },
    unshiftVehicleModel(state, payload) {
      state.vehicleModelList.unshift(payload)
    },
    removeVehicleModelInList(state, payload) {
      state.vehicleModelList = state.vehicleModelList.filter(
        vehicleModel => vehicleModel.id !== payload
      )
    },
    updateVehicleModelInList(state, payload) {
      if (state.vehicleModelList) {
        state.vehicleModelList = state.vehicleModelList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
