<template>
  <div>
    <div v-if="value">
      <b-btn
        size="sm"
        variant="primary"
        @click="downloadB2File"
        target="_blank"
      >
        <slot>
          {{ value.client_file_name || 'Baixar' }}
        </slot>
      </b-btn>
    </div>
    <div v-else>Sem Arquivo</div>
  </div>
</template>

<script>
import 'moment'
import mixin from '@/features/file-download/store/mixin'

export default {
  name: 'b2-download-btn',
  mixins: [mixin],
  props: {
    value: Object
  },
  methods: {
    async downloadB2File() {
      this.downloadFile(this.value)
    }
  }
}
</script>
