var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form-group', {
    staticClass: "md-12",
    staticStyle: {
      "border-radius": "10px 10px 10px 10px",
      "-webkit-border-radius": "10px 10px 10px 10px",
      "-moz-border-radius": "10px 10px 10px 10px"
    },
    style: {
      border: this.borderColor
    },
    attrs: {
      "label": "Grupo de Endereço:"
    }
  }, [_c('b-row', {
    staticClass: "md-12"
  }, [_c('b-form-group', {
    staticClass: "col-md-12",
    attrs: {
      "label": "Cidade:"
    }
  }, [_c('Multiselector', {
    staticClass: "Multiselector-primary",
    attrs: {
      "id": "city-input-type",
      "track-by": "id",
      "custom-label": _vm.customLabelCity,
      "options": _vm.cityList,
      "multiple": false,
      "hide-selected": false,
      "taggable": false,
      "tag-placeholder": "Selecione uma opção",
      "placeholder": "Informe ao menos um"
    },
    on: {
      "search-change": _vm.triggerSearch
    },
    model: {
      value: _vm.$v.value.city.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.value.city, "$model", $$v);
      },
      expression: "$v.value.city.$model"
    }
  }), _vm._v(" "), _vm.isValid('value.city', '$anyError') ? _c('div', {
    staticClass: "cinvalid-tip"
  }, [_vm._v("\n          A Cidade deve ser informada\n        ")]) : _vm._e()], 1)], 1), _vm._v(" "), _c('b-row', {
    staticClass: "md-12"
  }, [_c('b-form-group', {
    staticClass: "col-md-6 sm",
    attrs: {
      "label": "CEP:",
      "size": "sm"
    }
  }, [_c('template', {
    slot: "description"
  }, [_c('div', [_c('i', {
    staticClass: "ion ion-md-information-circle-outline",
    staticStyle: {
      "color": "deepskyblue"
    }
  }), _vm._v(" Traz Cidade, Bairro e\n            Endereço automaticamente\n          ")])]), _vm._v(" "), _c('ZipInput', {
    attrs: {
      "id": "zipcode-input",
      "state": _vm.isValid('value.zipcode')
    },
    on: {
      "onFoundAddress": _vm.onFoundAddress
    },
    model: {
      value: _vm.$v.value.zipcode.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.value.zipcode, "$model", $$v);
      },
      expression: "$v.value.zipcode.$model"
    }
  }), _vm._v(" "), !_vm.isValid('value.zipcode', 'required') ? _c('div', {
    staticClass: "invalid-tooltip"
  }, [_vm._v("\n          CEP é obrigatório\n        ")]) : _vm._e(), _vm._v(" "), !_vm.isValid('value.zipcode', 'minLength') ? _c('div', {
    staticClass: "invalid-tooltip"
  }, [_vm._v("\n          CEP não é valido\n        ")]) : _vm._e()], 2), _vm._v(" "), _c('b-form-group', {
    staticClass: "col-md-6",
    attrs: {
      "label": "Bairro:"
    }
  }, [_c('c-input', {
    attrs: {
      "placeholder": "Digite o Bairro",
      "state": _vm.isValid('value.neighborhood')
    },
    model: {
      value: _vm.$v.value.neighborhood.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.value.neighborhood, "$model", $$v);
      },
      expression: "$v.value.neighborhood.$model"
    }
  }), _vm._v(" "), !_vm.isValid('value.neighborhood', 'minLength') ? _c('div', {
    staticClass: "invalid-tooltip"
  }, [_vm._v("\n          Endereço muito curto\n        ")]) : _vm._e()], 1)], 1), _vm._v(" "), _c('b-row', {
    staticClass: "md-12"
  }, [_c('b-form-group', {
    staticClass: "col-md-6",
    attrs: {
      "label": "Endereço:"
    }
  }, [_c('c-input', {
    attrs: {
      "placeholder": "Digite o endereço",
      "state": _vm.isValid('value.street')
    },
    model: {
      value: _vm.$v.value.street.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.value.street, "$model", $$v);
      },
      expression: "$v.value.street.$model"
    }
  }), _vm._v(" "), !_vm.isValid('value.street', 'minLength') ? _c('div', {
    staticClass: "invalid-tooltip"
  }, [_vm._v("\n          Endereço muito curto\n        ")]) : _vm._e()], 1), _vm._v(" "), _c('b-form-group', {
    staticClass: "col-md-6",
    attrs: {
      "label": "Número:"
    }
  }, [_c('c-input', {
    attrs: {
      "placeholder": "Digite o Número",
      "type": "number",
      "state": _vm.isValid('value.number')
    },
    model: {
      value: _vm.$v.value.number.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.value.number, "$model", $$v);
      },
      expression: "$v.value.number.$model"
    }
  }), _vm._v(" "), !_vm.isValid('value.number', 'number') ? _c('div', {
    staticClass: "invalid-tooltip"
  }, [_vm._v("\n          Número Inválido\n        ")]) : _vm._e()], 1)], 1), _vm._v(" "), _c('b-form-group', {
    staticClass: "md-6",
    attrs: {
      "label": "Complemento:"
    }
  }, [_c('c-input', {
    attrs: {
      "placeholder": "Digite o Complemento",
      "state": _vm.isValid('value.complement')
    },
    model: {
      value: _vm.$v.value.complement.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.value.complement, "$model", $$v);
      },
      expression: "$v.value.complement.$model"
    }
  }), _vm._v(" "), !_vm.isValid('value.complement', 'minLength') ? _c('div', {
    staticClass: "invalid-tooltip"
  }, [_vm._v("\n        Complemento muito curto (menos de 4 letras)\n      ")]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }