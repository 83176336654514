<template>
  <div class="m-0 p-0">
    <a
      href="javascript:void(0)"
      class="card card-hover text-body m-0 "
      style="height: 100% !important; width: 100%;"
      v-bind="{ ...$props, ...$attrs }"
      v-on="$listeners"
    >
      <b-card-body class="text-center p-4 card">
        <div :class="'lnr ' + icon + ' display-3 text-primary'"></div>
        <h5 class="m-0 mt-3"><slot></slot></h5>
      </b-card-body>
    </a>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'

export default {
  name: 'MobileButton',
  props: {
    title: {
      type: String,
      default: 'Abrir'
    },
    icon: {
      default: 'lnr-checkmark-circle'
    }
  }
}
</script>
