import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  methods: {
    ...mapActions('crtCodes', [
      'listCrtCodes',
      'showCrtCode',
      'deleteCrtCode',
      'createCrtCode',
      'updateCrtCode'
    ]),
    ...mapMutations('crtCodes', [
      'cleanCrtCodesErrors',
      'setCrtCodesList',
      'setCrtCodesFilter',
      'setCrtCodesBusy'
    ])
  },
  computed: {
    ...mapState('crtCodes', [
      'crtCodeIsBusy',
      'crtCodeList',
      'crtCodeFilter',
      'crtCodePerPage',
      'crtCodeCurrentPage',
      'crtCodeTotalRows',
      'crtCodeLastPage'
    ]),
    ...mapGetters('crtCodes', [
      'filteredCrtCodes'
    ])
  }
}
