<template>
  <Multiselector
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
    track-by="id"
    label="name"
    :custom-label="val => `${val?.crt_code} a ${val?.description} por `"
    :show-labels="false"
    :options="crtCodeList"
    :multiple="false"
    :allow-empty="false"
    :taggable="false"
  >
    <template slot="singleLabel" slot-scope="{ props }">
      <span class="option__desc">
        <span class="option__title">Código: {{ props?.crt_code ?? 'NI' }} - {{ props?.description ?? 'NI' }} </span>
      </span>
    </template>
    <template slot="option" slot-scope="{ props }">
      <div class="option__desc">
        <b-col>
          <b-row>
            <span class="option__small">Código: {{ props?.crt_code ?? 'NI' }} </span>
          </b-row>
          <b-row>
            <span class="option__small"> Descrição: {{ props?.description ?? 'NI' }} </span>
          </b-row>
        </b-col>
      </div>
    </template>
  </Multiselector>
</template>

<script>
import transportServiceTypeMixin from '@/features/crt-codes/store/mixin'

export default {
  name: 'crt-codes-selector',
  async created() {
    await this.listCrtCodes({ page: 1, perPage: 10000 })
  },
  props: {
    rows: Array,
    header: Array
  },
  mixins: [transportServiceTypeMixin],
  methods: {}
}
</script>
