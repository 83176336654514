'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import taxRangesInssService from '@/features/tax-ranges-insses/tax-ranges-insses-api'

const busyMutateName = 'setTaxRangesInssesBusy'

export default {
  namespaced: true,
  state: {
    taxRangesInssList: [],
    taxRangesInssFilter: '',
    taxRangesInssIsBusy: false,
    taxRangesInssTotalRows: 0,
    taxRangesInssCurrentPage: 1,
    taxRangesInssPerPage: 10,
    taxRangesInssLastPage: 0
  },
  actions: {
    async listTaxRangesInsses({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await taxRangesInssService().list(page, perPage, text)
          setState(commit, 'taxRangesInssTotalRows', payload.total)
          setState(commit, 'taxRangesInssCurrentPage', payload.page)
          setState(commit, 'taxRangesInssPerPage', payload.perPage)
          setState(commit, 'taxRangesInssLastPage', payload.lastPage)

          commit('setTaxRangesInssesList', payload.data)
        }
      )
    },
    async showTaxRangesInss({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return taxRangesInssService().show(id)
        }
      )
    },
    async createTaxRangesInss({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await taxRangesInssService().create(payload)
          commit('unshiftTaxRangesInss', entry)
        }
      )
    },
    async updateTaxRangesInss({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await taxRangesInssService().update(payload)
          commit('updateTaxRangesInssInList', entry)
          return entry
        }
      )
    },
    async deleteTaxRangesInss({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await taxRangesInssService().delete(id)
          if (result) await commit('removeTaxRangesInssInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredTaxRangesInsses(state) {
      return filterElements(state.taxRangesInssList, state.filter)
    }
  },
  mutations: {
    mutate,
    setTaxRangesInssesList(state, payload) {
      state.taxRangesInssList = payload
    },
    setTaxRangesInssesFilter(state, payload) {
      state.taxRangesInssList = payload
    },
    setTaxRangesInssesBusy(state, value = false) {
      state.taxRangesInssIsBusy = value
    },
    unshiftTaxRangesInss(state, payload) {
      state.taxRangesInssList.unshift(payload)
    },
    removeTaxRangesInssInList(state, payload) {
      state.taxRangesInssList = state.taxRangesInssList.filter(
        taxRangesInss => taxRangesInss.id !== payload
      )
    },
    updateTaxRangesInssInList(state, payload) {
      if (state.taxRangesInssList) {
        state.taxRangesInssList = state.taxRangesInssList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
