'use strict'

import { mutate, doAsyncBusyTask, filterElements, setState } from '@/helpers/store-helpers'

import mdfeService from '@/features/mdfes/mdfes-api'

const busyMutateName = 'setMdfesBusy'

export default {
  namespaced: true,
  state: {
    mdfeList: [],
    mdfeFilter: '',
    mdfeIsBusy: false,
    mdfeTotalRows: 0,
    mdfeCurrentPage: 1,
    mdfePerPage: 10,
    mdfeLastPage: 0
  },
  actions: {
    async listMdfes({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const payload = await mdfeService().list(page, perPage, text)
        setState(commit, 'mdfeTotalRows', payload.total)
        setState(commit, 'mdfeCurrentPage', payload.page)
        setState(commit, 'mdfePerPage', payload.perPage)
        setState(commit, 'mdfeLastPage', payload.lastPage)

        commit('setMdfesList', payload.data)
      })
    },
    async showMDFE({ state, commit }, id) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        return mdfeService().show(id)
      })
    },
    async createMDFE({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const entry = await mdfeService().create(payload)
        commit('unshiftMDFE', entry)
      })
    },
    async updateMDFE({ state, commit }, payload) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const entry = await mdfeService().update(payload)
        commit('updateMDFEInList', entry)
        return entry
      })
    },
    async deleteMDFE({ state, commit }, id) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const result = await mdfeService().delete(id)
        if (result) await commit('removeMDFEInList', id)
        return result
      })
    },
    async checkCTEToMDFE({ state, dispatch, commit }, mdfes) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        return await mdfeService().checkCTEToMDFE(mdfes)
      })
    },
    async emitMDFEFromCTE({ state, dispatch, commit }, mdfes) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        return await mdfeService().emitMDFEFromCTE(mdfes)
      })
    }
  },
  getters: {
    filteredMdfes(state) {
      return filterElements(state.mdfeList, state.filter)
    }
  },
  mutations: {
    mutate,
    setMdfesList(state, payload) {
      state.mdfeList = payload
    },
    setMdfesFilter(state, payload) {
      state.mdfeList = payload
    },
    setMdfesBusy(state, value = false) {
      state.mdfeIsBusy = value
    },
    unshiftMDFE(state, payload) {
      state.mdfeList.unshift(payload)
    },
    removeMDFEInList(state, payload) {
      state.mdfeList = state.mdfeList.filter(mdfe => mdfe.id !== payload)
    },
    updateMDFEInList(state, payload) {
      if (state.mdfeList) {
        state.mdfeList = state.mdfeList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
