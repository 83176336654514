var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Multiselector', _vm._g(_vm._b({
    attrs: {
      "track-by": "id",
      "label": "name",
      "custom-label": function (val) {
        return ((val.id) + " | CPF: " + (val.cpf) + " | Nome: " + (val.fullname));
      },
      "options": _vm.driverList,
      "multiple": false,
      "allow-empty": false,
      "taggable": false
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (_ref) {
        var _props$fullname, _props$cpf, _props$rg;
        var props = _ref.props;
        return [_c('div', {
          staticClass: "option__desc"
        }, [_c('b-col', [_c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("Nome:\n            " + _vm._s((_props$fullname = props === null || props === void 0 ? void 0 : props.fullname) !== null && _props$fullname !== void 0 ? _props$fullname : 'NI') + "\n          ")])]), _vm._v(" "), _c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("CPF:\n            " + _vm._s((_props$cpf = props === null || props === void 0 ? void 0 : props.cpf) !== null && _props$cpf !== void 0 ? _props$cpf : 'NI') + "\n          ")])]), _vm._v(" "), _c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("RG:\n            " + _vm._s((_props$rg = props === null || props === void 0 ? void 0 : props.rg) !== null && _props$rg !== void 0 ? _props$rg : 'NI') + "\n          ")])])], 1)], 1)];
      }
    }])
  }, 'Multiselector', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners));
}
var staticRenderFns = []

export { render, staticRenderFns }