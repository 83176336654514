import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  methods: {
    ...mapActions('clients', [
      'listClients',
      'showClient',
      'deleteClient',
      'createClient',
      'updateClient'
    ]),
    ...mapMutations('clients', [
      'cleanClientsErrors',
      'setClientsList',
      'setClientsFilter',
      'setClientsBusy'
    ])
  },
  computed: {
    ...mapState('clients', [
      'clientIsBusy',
      'clientList',
      'clientFilter',
      'clientPerPage',
      'clientCurrentPage',
      'clientTotalRows',
      'clientLastPage'
    ]),
    ...mapGetters('clients', [
      'filteredClients'
    ])
  }
}
