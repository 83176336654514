import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  methods: {
    ...mapActions('mdfes', [
      'listMdfes',
      'showMDFE',
      'deleteMDFE',
      'createMDFE',
      'updateMDFE',
      'checkCTEToMDFE',
      'emitMDFEFromCTE'
    ]),
    ...mapMutations('mdfes', [
      'cleanMdfesErrors',
      'setMdfesList',
      'setMdfesFilter',
      'setMdfesBusy'
    ])
  },
  computed: {
    ...mapState('mdfes', [
      'mdfeIsBusy',
      'mdfeList',
      'mdfeFilter',
      'mdfePerPage',
      'mdfeCurrentPage',
      'mdfeTotalRows',
      'mdfeLastPage'
    ]),
    ...mapGetters('mdfes', [
      'filteredMdfes'
    ])
  }
}
