<template>
  <CTable
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
    ref="selectableTable"
    bordered
    striped
    :items="items"
    :fields="customFields"
    @row-selected="onSelectEntry"
    :selectable="true"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name"></slot>
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
    <!-- SELECTION SLOTS -->
    <template #head(selected)="{ label }">
      <div class="text-center" :title="label">
        <b-checkbox v-model="isAllSelected" @change="selectAll" />
      </div>
    </template>
    <template #cell(selected)="{ rowSelected }">
      <div v-if="rowSelected" class="text-center">
        <b-checkbox disabled :checked="true" />
        <span class="sr-only">Selecionado</span>
      </div>
      <div v-else class="text-center">
        <b-checkbox disabled :checked="false" />
        <span class="sr-only">Não Selecionado</span>
      </div>
    </template>
  </CTable>
</template>

<script>
/* eslint-disable */
import { dateFn } from '@/helpers/table-formatter'
export default {
  name: 'CSTable',
  props: {
    fields: {
      type: Array,
      default: () => null
    },
    items: {
      type: Array,
      default: () => null
    },
    // default values
    bordered: {
      type: Boolean,
      default: true
    },
    selectable: {
      type: Boolean,
      default: true
    },
    striped: {
      type: Boolean,
      default: true
    },
    isStickyHeader: {
      type: Boolean,
      default: false
    },
    isSortable: {
      type: Boolean,
      default: false
    },
    isSortIconLeft: {
      type: Boolean,
      default: false
    },
    isStacked: {
      type: Boolean,
      default: false
    },
    isStriped: {
      type: Boolean,
      default: false
    },
    isHoverable: {
      type: Boolean,
      default: true
    },
    isBordered: {
      type: Boolean,
      default: false
    },
    isBorderless: {
      type: Boolean,
      default: false
    },
    isOutlined: {
      type: Boolean,
      default: false
    },
    isSmall: {
      type: Boolean,
      default: false
    },
    isFixed: {
      type: Boolean,
      default: false
    },
    isSelectable: {
      type: Boolean,
      default: true
    },
    isDark: {
      type: Boolean,
      default: false
    },
    isFootClone: {
      type: Boolean,
      default: false
    },
    headerStyle: {
      default: null
    },
    // Table options
    headerStyleOptions: [
      { text: 'Default', value: null },
      { text: 'Light', value: 'light' },
      { text: 'Dark', value: 'dark' }
    ]
  },
  computed: {
    customFields() {
      return [
        {
          key: 'selected',
          name: 'Selecionar'
        },
        ...this.fields.map(value => {
          const mapped = value
          if (['start_at', 'end_at', 'created_at', 'updated_at', 'deleted_at', 'changed_at'].includes(mapped.key)) {
            mapped.formatter = dateFn()
          }
          return mapped
        })
      ]
    }
  },
  data() {
    return {
      isAllSelected: false,
      selectedEntries: []
    }
  },
  watch: {
    selectedEntries(newVal) {
      this.$emit('update-selection', newVal ?? [])
    },
    isAllSelected(newVal) {
      this.$emit('all-selected', newVal ?? [])
    }
  },
  methods: {
    onSelectEntry(allSelection) {
      this.selectedEntries = allSelection
      this.isAllSelected = (this.selectedEntries ?? []).length === (this.$refs.selectableTable.items ?? []).length
    },
    selectAll(toggle) {
      if (toggle) {
        this.$refs.selectableTable.selectAllRows()
      } else {
        this.$refs.selectableTable.clearSelected()
      }
    }
  }
}
</script>
