var render = function () {
  var _vm$ctesCancelChecks;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    ref: "annotationCheckModal",
    attrs: {
      "size": "lg",
      "modal-class": "modal-top"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function (_ref) {
        var ok = _ref.ok,
          cancel = _ref.cancel;
        return [_c('b-button', {
          attrs: {
            "size": "md",
            "variant": "success"
          },
          on: {
            "click": function () {
              return _vm.doCancelCTE(ok);
            }
          }
        }, [_vm._v("\n        Cancelar CTE (incluí averbação)\n      ")]), _vm._v(" "), _c('b-button', {
          attrs: {
            "size": "md",
            "variant": "secondary"
          },
          on: {
            "click": function () {
              return cancel(ok);
            }
          }
        }, [_vm._v("\n        Fechar\n      ")])];
      }
    }])
  }, [_c('div', {
    attrs: {
      "slot": "modal-title"
    },
    slot: "modal-title"
  }, [_vm._v("\n      Resumo de CTEs a serem "), _c('span', {
    staticClass: "font-weight-light"
  }, [_vm._v("Cancelados")]), _c('br'), _vm._v(" "), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Abaixo segue a lista de CTEs que podem ser canceladas. ")]), _vm._v(" "), _c('br')]), _vm._v(" "), _c('b-overlay', {
    attrs: {
      "id": "overlay-background",
      "variant": "white",
      "opacity": "0.85",
      "blur": "2px",
      "spinner-variant": "primary",
      "spinner-type": "grow",
      "spinner-small": true,
      "rounded": "sm"
    }
  }, [_c('CTable', {
    attrs: {
      "id": (_vm$ctesCancelChecks = _vm.ctesCancelChecks) === null || _vm$ctesCancelChecks === void 0 ? void 0 : _vm$ctesCancelChecks.length,
      "fields": _vm.checkedFields,
      "items": _vm.ctesCancelChecks
    },
    scopedSlots: _vm._u([{
      key: "cell(can_cancel)",
      fn: function (data) {
        return [data.item.can_cancel ? _c('b-badge', {
          attrs: {
            "else": "",
            "href": "javascript:void(0)",
            "variant": "success text-white"
          }
        }, [_vm._v("\n            PERMITIDO\n          ")]) : _c('b-badge', {
          attrs: {
            "href": "javascript:void(0)",
            "variant": "danger text-white"
          }
        }, [_vm._v("\n            NÃO PERMITIDO\n          ")])];
      }
    }, {
      key: "cell(document_number)",
      fn: function (data) {
        var _ref2, _data$item$entity$nfs, _data$item, _data$item$entity, _data$item2, _data$item2$entity, _data$item3, _data$item3$entity;
        return [_c('b-badge', {
          attrs: {
            "else": "",
            "href": "javascript:void(0)",
            "variant": "success text-white"
          }
        }, [_vm._v("\n            " + _vm._s((_ref2 = (_data$item$entity$nfs = (_data$item = data.item) === null || _data$item === void 0 ? void 0 : (_data$item$entity = _data$item.entity) === null || _data$item$entity === void 0 ? void 0 : _data$item$entity.nfs_number) !== null && _data$item$entity$nfs !== void 0 ? _data$item$entity$nfs : (_data$item2 = data.item) === null || _data$item2 === void 0 ? void 0 : (_data$item2$entity = _data$item2.entity) === null || _data$item2$entity === void 0 ? void 0 : _data$item2$entity.collect_number) !== null && _ref2 !== void 0 ? _ref2 : (_data$item3 = data.item) === null || _data$item3 === void 0 ? void 0 : (_data$item3$entity = _data$item3.entity) === null || _data$item3$entity === void 0 ? void 0 : _data$item3$entity.cte_number) + "\n          ")])];
      }
    }, {
      key: "cell(reason)",
      fn: function (data) {
        return [_c('textarea', {
          staticClass: "mr-sm-2 col-12",
          attrs: {
            "type": "text"
          },
          domProps: {
            "value": data.reason
          },
          on: {
            "input": function (value) {
              _vm.updateReason(data.index, value.target.value);
            }
          }
        })];
      }
    }])
  })], 1)], 1), _vm._v(" "), _c('b-modal', {
    ref: "annotationEmissionModal",
    attrs: {
      "size": "lg",
      "modal-class": "modal-top"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function (_ref3) {
        var ok = _ref3.ok,
          cancel = _ref3.cancel;
        return [_vm.allSuccess ? _c('b-button', {
          attrs: {
            "size": "md",
            "variant": "secondary"
          },
          on: {
            "click": function () {
              return cancel(ok);
            }
          }
        }, [_vm._v("\n        Fechar\n      ")]) : _vm._e(), _vm._v(" "), _c('b-button', {
          attrs: {
            "size": "md",
            "variant": "secondary"
          },
          on: {
            "click": function () {
              return cancel(ok);
            }
          }
        }, [_vm._v("\n        Voltar\n      ")])];
      }
    }])
  }, [_c('div', {
    attrs: {
      "slot": "modal-title"
    },
    slot: "modal-title"
  }, [_vm._v("\n      Resumo de documentos "), _c('span', {
    staticClass: "font-weight-light"
  }, [_vm._v("averbados")]), _c('br'), _vm._v(" "), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Abaixo a lista dos CTEs cancelados e se houve sucesso.")]), _vm._v(" "), _c('br')]), _vm._v(" "), _c('b-overlay', {
    attrs: {
      "id": "overlay-background",
      "variant": "white",
      "opacity": "0.85",
      "blur": "2px",
      "spinner-variant": "primary",
      "spinner-type": "grow",
      "spinner-small": true,
      "rounded": "sm"
    }
  }, [_c('CTable', {
    attrs: {
      "fields": _vm.emittedFields,
      "items": _vm.ctesCancelResults
    },
    scopedSlots: _vm._u([{
      key: "cell(error)",
      fn: function (data) {
        return [data.item.error ? _c('b-badge', {
          attrs: {
            "href": "javascript:void(0)",
            "variant": "danger text-white"
          }
        }, [_vm._v("\n            CANCELAMENTO BLOQUEDO\n          ")]) : _c('b-badge', {
          attrs: {
            "else": "",
            "href": "javascript:void(0)",
            "variant": "success text-white"
          }
        }, [_vm._v("\n            CANCELADO COM SUCESSO\n          ")])];
      }
    }])
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }