const moment = require('moment')
const dateFn = function(format = 'DD/MM/YYYY HH:mm:ss') {
  /**
   * @param {string} val
   */
  return val => {
    let formatted = null
    if (val) {
      // incomming date need to be on ISO JSON format
      if (moment(val).isValid()) {
        formatted = moment(val).format(format)
      } else if (moment(val, 'DD/MM/YYYY HH:mm').isValid()) {
        formatted = moment(val, 'DD/MM/YYYY HH:mm').format(format)
      }
    }
    // create --/--/---- placeholder
    return formatted || '--'
  }
}
module.exports = {
  /**
   * @param {string} format - The format to use for the date on table (default 'DD/MM/YYYY HH:mm:ss')
   */
  dateFn,
  /**
   * @param {string} format - The format to use for the date on table (default 'DD/MM/YYYY HH:mm:ss')
   */
  dateFnDay: function(format = 'DD/MM/YYYY') {
    return dateFn(format)
  },
  /**
   * @param {string} default - The default value to be show
   */
  modelName: function(defaultval = 'N/I') {
    /**
     * @param {string} val
     */
    return function(val) {
      if (val && val.name) {
        return val.name
      }
      // create --/--/---- placeholder
      return defaultval
    }
  }
}
