'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  setState
} from '@/helpers/store-helpers'

import transportCostService from '@/features/transport-costs/transport-costs-api'

const busyMutateName = 'setTransportCostsBusy'

export default {
  namespaced: true,
  state: {
    transportCostList: [],
    transportCostFilter: '',
    transportCostIsBusy: false,
    transportCostTotalRows: 0,
    transportCostCurrentPage: 1,
    transportCostPerPage: 10,
    transportCostLastPage: 0
  },
  actions: {
    async listTransportCosts({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await transportCostService().list(page, perPage, text)
          setState(commit, 'transportCostTotalRows', payload.total)
          setState(commit, 'transportCostCurrentPage', payload.page)
          setState(commit, 'transportCostPerPage', payload.perPage)
          setState(commit, 'transportCostLastPage', payload.lastPage)

          commit('setTransportCostsList', payload.data)
        }
      )
    },
    async showTransportCost({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return transportCostService().show(id)
        }
      )
    },
    async createTransportCost({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = transportCostService().create(payload)
          commit('unshiftTransportCost', entry)
        }
      )
    },
    async updateTransportCost({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = transportCostService().update(payload)
          commit('updateTransportCostInList', entry)
          return entry
        }
      )
    },
    async deleteTransportCost({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await transportCostService().delete(id)
          if (result) await commit('removeTransportCostInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredTransportCosts(state) {
      return filterElements(state.transportCostList, state.filter)
    }
  },
  mutations: {
    mutate,
    setTransportCostsList(state, payload) {
      state.transportCostList = payload
    },
    setTransportCostsFilter(state, payload) {
      state.transportCostList = payload
    },
    setTransportCostsBusy(state, value = false) {
      state.transportCostIsBusy = value
    },
    unshiftTransportCost(state, payload) {
      state.transportCostList.unshift(payload)
    },
    removeTransportCostInList(state, payload) {
      state.transportCostList = state.transportCostList.filter(
        transportCost => transportCost.id !== payload
      )
    },
    updateTransportCostInList(state, payload) {
      if (state.transportCostList) {
        state.transportCostList = state.transportCostList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
