<template>
  <BusyOverlay :show="freightContractIsBusy">
    <Multiselector
      v-bind="{ ...$props, ...$attrs }"
      v-on="$listeners"
      track-by="id"
      label="name"
      :custom-label="
        props => `#${props?.id ?? 'NI'} |Fornec: ${props?.supplier?.name ?? 'NI'} |Empr:${props?.company?.name ?? 'NI'}`
      "
      :options="freightContractList"
      :multiple="false"
      :disabled="disabled"
      :allow-empty="false"
      :taggable="false"
    >
      <template slot="singleLabel" slot-scope="{ props }">
        <span class="option__name">
          ID: {{ props?.id }}

          Fornecedor: {{ props?.supplier?.name ?? 'NI' }}

          Empresa: {{ props?.company?.name ?? 'NI' }}
        </span>
        <span class="option__desc"> </span>
      </template>
      <template slot="option" slot-scope="{ props }">
        <div class="option__desc">
          <b-col>
            <b-row>
              <span class="option__small">ID: {{ props?.id ?? 'NI' }} </span>
            </b-row>
            <b-row>
              <span class="option__small"
                >Operadora de CIOT:
                {{ props?.ciotOperator?.name ?? 'NI' }}
              </span>
            </b-row>
            <b-row>
              <span class="option__small"
                >Fornecedor:
                {{ props?.supplier?.name ?? 'NI' }}
                | CNPJ:
                {{ props?.supplier?.cnpj ?? 'NI' }}
              </span>
            </b-row>
            <b-row>
              <span class="option__small"
                >Motorista:
                {{ props?.driver?.fullname ?? 'NI' }}
              </span>
            </b-row>
            <b-row>
              <span class="option__title">
                Descrição de Rota:
                {{ props?.route?.description ?? 'NI' }}
              </span>
            </b-row>
          </b-col>
        </div>
      </template>
    </Multiselector>
  </BusyOverlay>
</template>

<script>
import freightContractsMixin from '@/features/freight-contracts/store/mixin'

export default {
  name: 'freight-contract-selector',
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    rows: Array,
    header: Array
  },
  mixins: [freightContractsMixin],

  async mounted() {
    this.listFreightContracts({ page: 1, perPage: 1000 })
  },
  methods: {}
}
</script>
