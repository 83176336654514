'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  setState
} from '@/helpers/store-helpers'

import deliverPointService from '@/features/deliver-points/deliver-points-api'

const busyMutateName = 'setDeliverPointsBusy'

export default {
  namespaced: true,
  state: {
    deliverPointList: [],
    deliverPointFilter: '',
    deliverPointIsBusy: false,
    deliverPointTotalRows: 0,
    deliverPointCurrentPage: 1,
    deliverPointPerPage: 10,
    deliverPointLastPage: 0
  },
  actions: {
    async listDeliverPoints({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await deliverPointService().list(page, perPage, text)
          setState(commit, 'deliverPointTotalRows', payload.total)
          setState(commit, 'deliverPointCurrentPage', payload.page)
          setState(commit, 'deliverPointPerPage', payload.perPage)
          setState(commit, 'deliverPointLastPage', payload.lastPage)

          commit('setDeliverPointsList', payload.data)
        }
      )
    },
    async showDeliverPoint({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return deliverPointService().show(id)
        }
      )
    },
    async createDeliverPoint({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await deliverPointService().create(payload)
          commit('unshiftDeliverPoint', entry)
        }
      )
    },
    async updateDeliverPoint({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await deliverPointService().update(payload)
          commit('updateDeliverPointInList', entry)
          return entry
        }
      )
    },
    async deleteDeliverPoint({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await deliverPointService().delete(id)
          if (result) await commit('removeDeliverPointInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredDeliverPoints(state) {
      return filterElements(state.deliverPointList, state.filter)
    }
  },
  mutations: {
    mutate,
    setDeliverPointsList(state, payload) {
      state.deliverPointList = payload
    },
    setDeliverPointsFilter(state, payload) {
      state.deliverPointList = payload
    },
    setDeliverPointsBusy(state, value = false) {
      state.deliverPointIsBusy = value
    },
    unshiftDeliverPoint(state, payload) {
      state.deliverPointList.unshift(payload)
    },
    removeDeliverPointInList(state, payload) {
      state.deliverPointList = state.deliverPointList.filter(
        deliverPoint => deliverPoint.id !== payload
      )
    },
    updateDeliverPointInList(state, payload) {
      if (state.deliverPointList) {
        state.deliverPointList = state.deliverPointList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
