'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import annotationService from '@/features/annotations/annotations-api'

const busyMutateName = 'setAnnotationsBusy'

export default {
  namespaced: true,
  state: {
    annotationList: [],
    annotationFilter: '',
    annotationIsBusy: false,
    annotationTotalRows: 0,
    annotationCurrentPage: 1,
    annotationPerPage: 10,
    annotationLastPage: 0
  },
  actions: {
    async listAnnotations({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const payload = await annotationService().list(page, perPage, text)
        setState(commit, 'annotationTotalRows', payload.total)
        setState(commit, 'annotationCurrentPage', payload.page)
        setState(commit, 'annotationPerPage', payload.perPage)
        setState(commit, 'annotationLastPage', payload.lastPage)

        commit('setAnnotationsList', payload.data)
      })
    },
    async checkEmitAnnotation({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const checks = await annotationService().checkAnnotations(payload)
        return checks
      })
    },
    async emitAnnotations({ state, dispatch, commit }, { documents, entity }) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const results = await annotationService().emitAnnotations({ documents, entity })
        return results
      })
    },
    async showAnnotation({ state, commit }, id) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        return annotationService().show(id)
      })
    },
    async createAnnotation({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const entry = await annotationService().create(payload)
        commit('unshiftAnnotation', entry)
      })
    },
    async updateAnnotation({ state, commit }, payload) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const entry = await annotationService().update(payload)
        commit('updateAnnotationInList', entry)
        return entry
      })
    },
    async deleteAnnotation({ state, commit }, id) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const result = await annotationService().delete(id)
        if (result) await commit('removeAnnotationInList', id)
        return result
      })
    }
  },
  getters: {
    filteredAnnotations(state) {
      return filterElements(state.annotationList, state.filter)
    }
  },
  mutations: {
    mutate,
    setAnnotationsList(state, payload) {
      state.annotationList = payload
    },
    setAnnotationsFilter(state, payload) {
      state.annotationList = payload
    },
    setAnnotationsBusy(state, value = false) {
      state.annotationIsBusy = value
    },
    unshiftAnnotation(state, payload) {
      state.annotationList.unshift(payload)
    },
    removeAnnotationInList(state, payload) {
      state.annotationList = state.annotationList.filter(annotation => annotation.id !== payload)
    },
    updateAnnotationInList(state, payload) {
      if (state.annotationList) {
        state.annotationList = state.annotationList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
