'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  setState
} from '@/helpers/store-helpers'

import invoiceService from '@/features/invoices/invoices-api'

const busyMutateName = 'setInvoicesBusy'

export default {
  namespaced: true,
  state: {
    invoiceList: [],
    invoiceFilter: '',
    invoiceIsBusy: false,
    invoiceTotalRows: 0,
    invoiceCurrentPage: 1,
    invoicePerPage: 10,
    invoiceLastPage: 0
  },
  actions: {
    async listInvoices({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await invoiceService().list(page, perPage, text)
          setState(commit, 'invoiceTotalRows', payload.total)
          setState(commit, 'invoiceCurrentPage', payload.page)
          setState(commit, 'invoicePerPage', payload.perPage)
          setState(commit, 'invoiceLastPage', payload.lastPage)

          commit('setInvoicesList', payload.data)
        }
      )
    },
    async showInvoice({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return invoiceService().show(id)
        }
      )
    },
    async createInvoice({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = invoiceService().create(payload)
          commit('unshiftInvoice', entry)
        }
      )
    },
    async updateInvoice({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = invoiceService().update(payload)
          commit('updateInvoiceInList', entry)
          return entry
        }
      )
    },
    async deleteInvoice({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await invoiceService().delete(id)
          if (result) await commit('removeInvoiceInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredInvoices(state) {
      return filterElements(state.invoiceList, state.filter)
    }
  },
  mutations: {
    mutate,
    setInvoicesList(state, payload) {
      state.invoiceList = payload
    },
    setInvoicesFilter(state, payload) {
      state.invoiceList = payload
    },
    setInvoicesBusy(state, value = false) {
      state.invoiceIsBusy = value
    },
    unshiftInvoice(state, payload) {
      state.invoiceList.unshift(payload)
    },
    removeInvoiceInList(state, payload) {
      state.invoiceList = state.invoiceList.filter(
        invoice => invoice.id !== payload
      )
    },
    updateInvoiceInList(state, payload) {
      if (state.invoiceList) {
        state.invoiceList = state.invoiceList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
