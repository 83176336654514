import _ from 'lodash'
import Vue from 'vue'

export default Vue.extend({
  mixins: [
    /* other mixins needed */
  ],
  computed: {},
  methods: {
    viewUserProfile({ id }) {
      // @ts-ignore
      this.$router.push('/user/' + id)
    },
    getRouteId() {
      if (this.isCreationRoute()) {
        return null
      }
      return this.$router.currentRoute.params.id
    },
    // @ts-ignore
    isCreationRoute() {
      // @ts-ignore
      return (
        this.$router.currentRoute.fullPath.includes('/novo') ||
        this.$router.currentRoute.fullPath.includes('/nova') ||
        this.$router.currentRoute.fullPath.includes('/new')
      )
    },
    goBack() {
      this.$router.go(-1)
    },
    // @ts-ignore
    getOwnAttrs() {
      // @ts-ignore
      return _.omitBy(this.$attrs, v => v === undefined)
    },
    // @ts-ignore
    getOwnProps() {
      // @ts-ignore
      return _.omitBy(this.$props, v => v === undefined)
    },
    async exitEditing({ onExit = null, onCancelExit = null }) {
      // @ts-ignore
      await this.showPromptModal({
        title: 'Deseja sair ?',
        text: 'Ao sair você irá perder as informações digitadas',
        yesText: 'Cancelar',
        cancelText: 'Sair',
        onAccept: () => {
          if (onCancelExit) {
            return onCancelExit()
          }
        },
        onDecline: () => {
          if (onExit) {
            return onExit()
          } else {
            return this.goBack()
          }
        },
        onError: () => {}
      })
    }
  }
})
