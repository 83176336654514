import HttpClient from './http-client'

class CommitmentTermsApi extends HttpClient {
  async list() {
    return this.rest.get('/v1/settings')
  }

  async update(id, payload) {
    return this.rest.patch('/v1/settings/' + id, payload)
  }

  async show(id) {
    return this.rest.get('/v1/settings/' + id)
  }

  async delete(id) {
    return this.rest.get('/v1/settings/' + id)
  }

  async searchModels(id, { search, page, limit }) {
    return this.rest.get(`/v1/settings/${id}/model-entries`, {
      params: {
        search: search || '',
        page: page || 1,
        limit: limit || 1000
      }
    })
  }

  async reset(id) {
    return this.rest.get('/v1/settings/' + id + '/reset')
  }
}
export default () => new CommitmentTermsApi()
