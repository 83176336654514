<template>
  <b-row class="col-12">
    <b-col class="col-4">
      <b-form-group class="col-6" label="Origem:">
        <b-button variant="primary" size="xs" style="padding: 3px;">
          {{ value?.originUf?.uf }} - {{ value?.originUf?.name }}
        </b-button>
      </b-form-group>
      <b-form-group class="col-6" label="Destino:">
        <b-button variant="primary" size="xs" style="padding: 3px;">
          {{ value?.destinationUf?.uf }} - {{ value?.destinationUf?.name }}
        </b-button>
      </b-form-group>
    </b-col>
    <b-col class="col-6 col">
      <b-form-group class="col-12 col" label="Trajeto (ordem - estado):">
        <template>
          <ul style="padding-inline-start: 5px;">
            <li v-for="(item, index) in value?.journeys" :key="index" style="list-style-type: none;">
              <b-card type="primary" size="xs" style="padding: 6px !important;" no-body>
                {{ item?.order }} - {{ item?.uf?.name }} ({{ item?.uf?.uf }})
              </b-card>
            </li>
          </ul>
        </template>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import PathsMixin from '@/features/paths/store/mixin'

export default {
  name: 'path-visualizer',
  async mounted() {},
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  mixins: [PathsMixin],
  methods: {}
}
</script>
