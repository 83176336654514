import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  methods: {
    ...mapActions('measurementTypes', [
      'listMeasurementTypes',
      'showMeasurementType',
      'deleteMeasurementType',
      'createMeasurementType',
      'updateMeasurementType'
    ]),
    ...mapMutations('measurementTypes', [
      'cleanMeasurementTypesErrors',
      'setMeasurementTypesList',
      'setMeasurementTypesFilter',
      'setMeasurementTypesBusy'
    ])
  },
  computed: {
    ...mapState('measurementTypes', [
      'measurementTypeIsBusy',
      'measurementTypeList',
      'measurementTypeFilter',
      'measurementTypePerPage',
      'measurementTypeCurrentPage',
      'measurementTypeTotalRows',
      'measurementTypeLastPage'
    ]),
    ...mapGetters('measurementTypes', [
      'filteredMeasurementTypes'
    ])
  }
}
