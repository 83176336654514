<template>
  <b-overlay
    id="overlay-background"
    :show="clientIsBusy"
    variant="white"
    opacity="0.85"
    blur="2px"
    spinner-variant="primary"
    spinner-type="grow"
    :spinner-small="true"
    rounded="sm"
  >
    <Multiselector
      v-bind="{ ...$props, ...$attrs }"
      v-on="$listeners"
      track-by="id"
      @open="onOpenPicker"
      label="name"
      :custom-label="val => `${val.name} - ${val.cnpj}`"
      :options="clientList"
      :multiple="false"
      :allow-empty="false"
      :taggable="false"
    >
      <template slot="option" slot-scope="{ props }">
        <div class="option__desc">
          <b-col>
            <b-row>
              <span class="option__small"
                >Nome:
                {{ props?.name ?? 'NI' }}
              </span>
            </b-row>
            <b-row>
              <span class="option__small"
                >CNPJ:
                {{ props?.cnpj ?? 'N/I' }}
              </span>
            </b-row>
          </b-col>
        </div>
      </template>
    </Multiselector>
  </b-overlay>
</template>

<script>
import clientsMixin from '@/features/clients/store/mixin'

export default {
  name: 'ClientSelector',
  async mounted() {},
  props: {
    rows: {
      type: Array,
      default: () => []
    },
    header: Array
  },
  mixins: [clientsMixin],
  methods: {
    async onOpenPicker() {
      await this.listClients({ page: 1, perPage: 10000 })
    }
  }
}
</script>
