'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import freightContractService from '@/features/freight-contracts/freight-contracts-api'

const busyMutateName = 'setFreightContractsBusy'

export default {
  namespaced: true,
  state: {
    freightContractList: [],
    freightContractFilter: '',
    freightContractIsBusy: false,
    freightContractTotalRows: 0,
    freightContractCurrentPage: 1,
    freightContractPerPage: 10,
    freightContractLastPage: 0
  },
  actions: {
    async listFreightContracts({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await freightContractService().list(page, perPage, text)
          setState(commit, 'freightContractTotalRows', payload.total)
          setState(commit, 'freightContractCurrentPage', payload.page)
          setState(commit, 'freightContractPerPage', payload.perPage)
          setState(commit, 'freightContractLastPage', payload.lastPage)

          commit('setFreightContractsList', payload.data)
        }
      )
    },
    async showFreightContract({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return freightContractService().show(id)
        }
      )
    },
    async createFreightContract({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await freightContractService().create(payload)
          commit('unshiftFreightContract', entry)
        }
      )
    },
    async updateFreightContract({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await freightContractService().update(payload)
          commit('updateFreightContractInList', entry)
          return entry
        }
      )
    },
    async deleteFreightContract({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await freightContractService().delete(id)
          if (result) await commit('removeFreightContractInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredFreightContracts(state) {
      return filterElements(state.freightContractList, state.filter)
    }
  },
  mutations: {
    mutate,
    setFreightContractsList(state, payload) {
      state.freightContractList = payload
    },
    setFreightContractsFilter(state, payload) {
      state.freightContractList = payload
    },
    setFreightContractsBusy(state, value = false) {
      state.freightContractIsBusy = value
    },
    unshiftFreightContract(state, payload) {
      state.freightContractList.unshift(payload)
    },
    removeFreightContractInList(state, payload) {
      state.freightContractList = state.freightContractList.filter(
        freightContract => freightContract.id !== payload
      )
    },
    updateFreightContractInList(state, payload) {
      if (state.freightContractList) {
        state.freightContractList = state.freightContractList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
