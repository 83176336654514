import userApi from '@/services/user-api'

// @ts-ignore
import router from '@/router'
import { setState, mutate } from '@/helpers/store-helpers'
import operationsApi from '../../operations/operations-api'

export default {
  namespaced: true,
  state: {
    branches: [],
    username: null,
    password: null,
    roles: [],
    userInfo: {
      name: 'deslogado'
    },
    branchActive: { name: 'Não Associado', id: 1 },
    credentials: null,
    errMessage: undefined
  },
  actions: {
    async fetchUserInfo({ state, commit }) {
      const userInfo = await userApi().getUserInfo()
      setState(commit, 'userInfo', userInfo)
      return userInfo
    },
    async logoutAccount({ state, commit }, payload) {
      const { redirect = '/login' } = payload
      await commit('setCredentials', null)
      router.replace(redirect)
    },
    async fetchOperations({ state, commit }) {
      const branches = await operationsApi().list()
      commit('mutate', { key: 'branches', value: branches })
    }
  },
  getters: {
    isAuthenticated(state) {
      const token = localStorage.getItem('token')
      if (token !== null && token !== undefined && token !== 'null') {
        return true
      } else return false
    },
    userName(state) {
      return state?.userInfo?.name ?? 'Deslogado'
    },
    userId(state) {
      return state?.userInfo?.id ?? 'Deslogado'
    },
    errorMessage(state) {
      return state.errMessage && state.errMessage.message ? state.errMessage.mesage : state.errMessage
    },
    userRoles(state) {
      return state.roles ?? []
    },
    isAnTransporter(state) {
      return state?.roles?.find(val => val.slug === 'transporter')
    }
  },
  mutations: {
    cleanErrors(state) {
      state.errMessage = undefined
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    markFoundError(state, message) {
      state.errMessage = message
    },
    setLoginBranch(state, branch) {
      if (!branch) branch = state.branches[0]
      state.branchActive = branch
    },
    setCredentials(state, newCredential) {
      if (newCredential && newCredential.token) {
        const token = `Bearer ${newCredential.token}`
        state.roles = newCredential.user.roles
        state.credentials = newCredential
        state.operation = newCredential.user.operation
        localStorage.setItem('token', token)
        localStorage.setItem('operation', newCredential.user.operation)
        localStorage.setItem('roles', newCredential.user.roles)
        console.log('setCredentials', newCredential)
      } else {
        state.credentials = null
        state.operation = null
        state.roles = null
        localStorage.setItem('token', null)
        localStorage.setItem('operation', null)
        localStorage.setItem('roles', null)
      }
    },
    mutate,
    setLoginUser(state, user) {
      state.username = user
    },
    setLoginPass(state, pass) {
      state.password = pass
    }
  }
}
