<template>
  <c-input
    autocomplete="off"
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
    type="text"
    style="text-align: right !important;"
    class="form-control"
    v-currency="{
      currency: {
        suffix: ''
      },
      precision: {
        min: 2,
        max: 2
      },
      valueAsInteger: false,
      distractionFree: false,
      autoDecimalMode: false,
      allowNegative: false
    }"
    ><slot></slot>
  </c-input>
</template>

<script>
export default {
  data() {
    return {}
  },
  name: 'float-input',
  props: {
    // eslint-disable-next-line vue/no-unused-properties
    rows: Array,
    //  eslint-disable-next-line vue/no-unused-properties
    header: Array
  },
  methods: {}
}
</script>
