var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sidenav-inner",
    class: {
      'py-1': _vm.orientation !== 'horizontal'
    }
  }, [_vm._l(_vm.tree, function (menuItem) {
    var _menuItem$children, _menuItem$to, _menuItem$to2;
    return [menuItem !== null && menuItem !== void 0 && (_menuItem$children = menuItem.children) !== null && _menuItem$children !== void 0 && _menuItem$children.length ? [_c('sidenav-menu', {
      key: menuItem.id,
      attrs: {
        "icon": 'sidenav-icon ' + menuItem.icon,
        "open": _vm.isGroupOpen(menuItem)
      }
    }, [_c('template', {
      slot: "link-text"
    }, [_vm._v(_vm._s(menuItem.name))]), _vm._v(" "), _c('LeftMenuTree', {
      key: menuItem.id,
      attrs: {
        "tree": menuItem.children
      }
    })], 2)] : menuItem !== null && menuItem !== void 0 && menuItem.divider ? [_c('sidenav-divider', {
      key: menuItem.id,
      staticClass: "mt-0"
    })] : [menuItem.can ? [_c('Can', {
      key: menuItem.id,
      attrs: {
        "I": menuItem.can
      }
    }, [_c('sidenav-router-link', {
      attrs: {
        "id": menuItem.to,
        "icon": menuItem.icon,
        "to": (_menuItem$to = menuItem.to) !== null && _menuItem$to !== void 0 ? _menuItem$to : '#',
        "active": _vm.isMenuActive(menuItem.to),
        "exact": true
      }
    }, [_vm._v("\n            " + _vm._s(menuItem.name)), _c('br')])], 1), _vm._v(" "), _c('Can', {
      key: menuItem.id,
      attrs: {
        "not": "",
        "I": menuItem.can
      }
    }, [_c('sidenav-router-link', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.top",
        modifiers: {
          "top": true
        }
      }],
      attrs: {
        "title": "Você não possui permissão para visualizar essa seção",
        "id": menuItem.to,
        "icon": menuItem.icon,
        "to": "",
        "disabled": "",
        "exact": true
      }
    }, [_vm._v("\n            " + _vm._s(menuItem.name)), _c('br')])], 1)] : [_c('sidenav-router-link', {
      key: menuItem.id,
      attrs: {
        "id": menuItem.to,
        "icon": menuItem.icon,
        "to": (_menuItem$to2 = menuItem.to) !== null && _menuItem$to2 !== void 0 ? _menuItem$to2 : '#',
        "exact": true
      }
    }, [_vm._v("\n          " + _vm._s(menuItem.name) + " "), _c('br')])]]];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }