<template>
  <span>
    <PermissiveBtn
      :can="can"
      size="xs"
      variant="warning"
      title="Apagar Entrada"
      titleBlocked="Sem permissão para apagar"
      @click="click"
      :disabled="disabled"
      v-b-tooltip.right
      v-bind="{ ...$props, ...$attrs }"
      v-on="$listeners"
    >
      Remover
    </PermissiveBtn>
  </span>
</template>

<style></style>

<script>
export default {
  name: 'RemoveBtn',
  props: {
    can: {
      default: null
    },
    click: {
      default: null
    },
    disabled: {
      default: false
    }
  }
}
</script>
