'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import zipcodeService from '@/features/zipcodes/zipcodes-api'

const busyMutateName = 'setZipcodesBusy'

export default {
  namespaced: true,
  state: {
    zipcodeList: [],
    zipcodeFilter: '',
    zipcodeIsBusy: false,
    zipcodeTotalRows: 0,
    zipcodeCurrentPage: 1,
    zipcodePerPage: 10,
    zipcodeLastPage: 0
  },
  actions: {
    async listZipcodes({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const payload = await zipcodeService().list(page, perPage, text)
        setState(commit, 'zipcodeTotalRows', payload.total)
        setState(commit, 'zipcodeCurrentPage', payload.page)
        setState(commit, 'zipcodePerPage', payload.perPage)
        setState(commit, 'zipcodeLastPage', payload.lastPage)

        commit('setZipcodesList', payload.data)
      })
    },
    async checkEmitZipcode({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const checks = await zipcodeService().checkZipcodes(payload)
        return checks
      })
    },
    async emitZipcodes({ state, dispatch, commit }, { documents, entity }) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const results = await zipcodeService().emitZipcodes({ documents, entity })
        return results
      })
    },
    async showZipcode({ state, commit }, zipcode) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        return zipcodeService().show(zipcode)
      })
    },
    async createZipcode({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const entry = await zipcodeService().create(payload)
        commit('unshiftZipcode', entry)
      })
    },
    async updateZipcode({ state, commit }, payload) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const entry = await zipcodeService().update(payload)
        commit('updateZipcodeInList', entry)
        return entry
      })
    },
    async deleteZipcode({ state, commit }, id) {
      return doAsyncBusyTask({ commit, state, busyMutateName }, async ({ state, commit }) => {
        const result = await zipcodeService().delete(id)
        if (result) await commit('removeZipcodeInList', id)
        return result
      })
    }
  },
  getters: {
    filteredZipcodes(state) {
      return filterElements(state.zipcodeList, state.filter)
    }
  },
  mutations: {
    mutate,
    setZipcodesList(state, payload) {
      state.zipcodeList = payload
    },
    setZipcodesFilter(state, payload) {
      state.zipcodeList = payload
    },
    setZipcodesBusy(state, value = false) {
      state.zipcodeIsBusy = value
    },
    unshiftZipcode(state, payload) {
      state.zipcodeList.unshift(payload)
    },
    removeZipcodeInList(state, payload) {
      state.zipcodeList = state.zipcodeList.filter(zipcode => zipcode.id !== payload)
    },
    updateZipcodeInList(state, payload) {
      if (state.zipcodeList) {
        state.zipcodeList = state.zipcodeList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
