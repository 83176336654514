var render = function () {
  var _vm$checkedCTEs, _vm$emittedCTEs;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    ref: "annotationCheckModal",
    attrs: {
      "size": "lg",
      "modal-class": "modal-top"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function (_ref) {
        var ok = _ref.ok,
          cancel = _ref.cancel;
        return [_c('b-button', {
          attrs: {
            "size": "md",
            "variant": "success"
          },
          on: {
            "click": function () {
              return _vm.doTransportServicesEmission(ok);
            }
          }
        }, [_vm._v("\n        Emitir documentos CTEs\n      ")]), _vm._v(" "), _c('b-button', {
          attrs: {
            "size": "md",
            "variant": "secondary"
          },
          on: {
            "click": function () {
              return cancel(ok);
            }
          }
        }, [_vm._v("\n        Fechar\n      ")])];
      }
    }])
  }, [_c('div', {
    attrs: {
      "slot": "modal-title"
    },
    slot: "modal-title"
  }, [_vm._v("\n      Resumo de documentos a serem "), _c('span', {
    staticClass: "font-weight-light"
  }, [_vm._v("emitidos")]), _c('br'), _vm._v(" "), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Abaixo segue a lista se é permitido fazer a emissão no sefaz dos documentos a transportar.\n      ")]), _vm._v(" "), _c('br')]), _vm._v(" "), _c('b-overlay', {
    attrs: {
      "id": "overlay-background",
      "show": _vm.cteIsBusy,
      "variant": "white",
      "opacity": "0.85",
      "blur": "2px",
      "spinner-variant": "primary",
      "spinner-type": "grow",
      "spinner-small": true,
      "rounded": "sm"
    }
  }, [_c('CTable', {
    attrs: {
      "id": (_vm$checkedCTEs = _vm.checkedCTEs) === null || _vm$checkedCTEs === void 0 ? void 0 : _vm$checkedCTEs.length,
      "fields": _vm.checkedFields,
      "items": _vm.checkedCTEs
    },
    scopedSlots: _vm._u([{
      key: "cell(can_issue)",
      fn: function (data) {
        return [data.item.can_issue ? _c('b-badge', {
          attrs: {
            "else": "",
            "href": "javascript:void(0)",
            "variant": "success text-white"
          }
        }, [_vm._v("\n            PERMITIDO\n          ")]) : _c('b-badge', {
          attrs: {
            "href": "javascript:void(0)",
            "variant": "danger text-white"
          }
        }, [_vm._v("\n            NÃO PERMITIDO\n          ")])];
      }
    }, {
      key: "cell(transport_number)",
      fn: function (data) {
        var _data$item$entity$tra, _data$item, _data$item$entity;
        return [_c('b-badge', {
          attrs: {
            "else": "",
            "href": "javascript:void(0)",
            "variant": "success text-white"
          }
        }, [_vm._v("\n            " + _vm._s((_data$item$entity$tra = (_data$item = data.item) === null || _data$item === void 0 ? void 0 : (_data$item$entity = _data$item.entity) === null || _data$item$entity === void 0 ? void 0 : _data$item$entity.transport_number) !== null && _data$item$entity$tra !== void 0 ? _data$item$entity$tra : 'NI') + "\n          ")])];
      }
    }])
  })], 1)], 1), _vm._v(" "), _c('b-modal', {
    ref: "annotationEmissionModal",
    attrs: {
      "size": "lg",
      "modal-class": "modal-top"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function (_ref2) {
        var ok = _ref2.ok,
          cancel = _ref2.cancel;
        return [_c('b-button', {
          attrs: {
            "size": "md",
            "variant": "secondary"
          },
          on: {
            "click": function () {
              return cancel(ok);
            }
          }
        }, [_vm._v("\n        Fechar\n      ")])];
      }
    }])
  }, [_c('div', {
    attrs: {
      "slot": "modal-title"
    },
    slot: "modal-title"
  }, [_vm._v("\n      Resumo de documentos "), _c('span', {
    staticClass: "font-weight-light"
  }, [_vm._v("emitidos")]), _c('br'), _vm._v(" "), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Abaixo segue a lista de resultados da emissão no sefaz dos documentos. ")]), _vm._v(" "), _c('br')]), _vm._v(" "), _c('b-overlay', {
    attrs: {
      "id": "overlay-background",
      "show": _vm.cteIsBusy,
      "variant": "white",
      "opacity": "0.85",
      "blur": "2px",
      "spinner-variant": "primary",
      "spinner-type": "grow",
      "spinner-small": true,
      "rounded": "sm"
    }
  }, [_c('CTable', {
    attrs: {
      "id": (_vm$emittedCTEs = _vm.emittedCTEs) === null || _vm$emittedCTEs === void 0 ? void 0 : _vm$emittedCTEs.length,
      "fields": _vm.emittedFields,
      "items": _vm.emittedCTEs
    },
    scopedSlots: _vm._u([{
      key: "cell(emitted)",
      fn: function (data) {
        return [data.item.emitted ? _c('b-badge', {
          attrs: {
            "href": "javascript:void(0)",
            "variant": "success text-white"
          }
        }, [_vm._v("\n            EMITIDO\n          ")]) : _c('b-badge', {
          attrs: {
            "else": "",
            "href": "javascript:void(0)",
            "variant": "danger text-white"
          }
        }, [_vm._v("\n            NÃO EMITIDO\n          ")])];
      }
    }])
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }