import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  async mounted() {
    await this.fetchPermissions()
  },
  methods: {
    ...mapActions('permissions', ['fetchPermissions', 'listPermissions']),
    ...mapMutations('permissions', ['cleanErrors', 'markFoundError', 'filterPermissions'])
  },
  computed: {
    ...mapState('permissions', ['allPermissions']),
    ...mapGetters('permissions', ['permissions', 'errorMessage', 'filteredPermissions'])
  }
}
