import HttpClient from './http-client'

class RolesAPI extends HttpClient {
  async list() {
    return (await this.http.get('/v1/roles')).data
  }

  async get(id) {
    return (await this.http.get('/v1/roles/' + id)).data
  }

  async store(payload) {
    return (await this.http.post('/v1/roles', payload)).data
  }

  async delete(id) {
    return (await this.http.delete('/v1/roles/' + id)).data
  }

  async update(payload) {
    return this.rest.patch('/v1/roles/' + payload.id, payload)
  }
}

export default () => new RolesAPI()
