<template>
  <sidenav :orientation="orientation" :class="curClasses">
    <!-- Brand -->
    <div class="app-brand py-3" @click="toggleSidenav">
      <img :src="`${publicUrl}static/icone-3c.png`" alt="Logo da aplicação" class="app-brand-logo ui-w-40" />
      <a href="javascript:void(0)" class="app-brand-text sidenav-text text-big ml-2">
        3C TMS<br />
        <Can I="create_users">
          <span class="text-tiny font-weight-semibold opacity-50">Administrativo</span>
        </Can>
        <Can not I="create_users">
          <span class="text-tiny font-weight-semibold opacity-50">Operação</span>
        </Can>
      </a>
    </div>
    <div class="sidenav-divider my-0"></div>
    <!-- / Brand -->
    <!-- Inner -->
    <div v-if="isAnTransporter">
      <LeftMenuTree :tree="[]" :orientation="orientation"></LeftMenuTree>
    </div>
    <div v-else>
      <LeftMenuTree :tree="menuEntries" :orientation="orientation"></LeftMenuTree>
    </div>
  </sidenav>
</template>

<script>
import {
  Sidenav,
  SidenavLink,
  SidenavRouterLink,
  SidenavMenu,
  SidenavHeader,
  SidenavBlock,
  SidenavDivider
} from '@/vendor/libs/sidenav'

import LeftMenuTree from './LeftMenuTree.vue'
import authenticationMixin from '../features/authentication/store/mixin'

export default {
  name: 'app-layout-sidenav',
  mixins: [authenticationMixin],
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider,
    /* eslint-enable vue/no-unused-components */
    LeftMenuTree
  },
  mounted() {
    this.layoutHelpers.init()
    this.layoutHelpers.update()
    this.layoutHelpers.setAutoUpdate(true)
  },

  beforeDestroy() {
    this.layoutHelpers.destroy()
  },

  props: {
    orientation: {
      type: String,
      default: 'vertical'
    }
  },
  computed: {
    curClasses() {
      let bg = this.layoutSidenavBg

      if (
        this.orientation === 'horizontal' &&
        (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)
      ) {
        bg = bg
          .replace(' sidenav-dark', '')
          .replace(' sidenav-light', '')
          .replace('-darker', '')
          .replace('-dark', '')
      }

      return (
        `bg-${bg} ` +
        (this.orientation !== 'horizontal' ? 'layout-sidenav' : 'layout-sidenav-horizontal container-p-x flex-grow-0')
      )
    }
  },

  methods: {
    isMenuOpen(url) {
      return this.$route.path.indexOf(url) === 0 && this.orientation !== 'horizontal'
    },

    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed()
    }
  }
}
</script>
