var render = function () {
  var _vm$value, _vm$value$originUf, _vm$value2, _vm$value2$originUf, _vm$value3, _vm$value3$destinatio, _vm$value4, _vm$value4$destinatio, _vm$value5;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "col-12"
  }, [_c('b-col', {
    staticClass: "col-4"
  }, [_c('b-form-group', {
    staticClass: "col-6",
    attrs: {
      "label": "Origem:"
    }
  }, [_c('b-button', {
    staticStyle: {
      "padding": "3px"
    },
    attrs: {
      "variant": "primary",
      "size": "xs"
    }
  }, [_vm._v("\n        " + _vm._s((_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : (_vm$value$originUf = _vm$value.originUf) === null || _vm$value$originUf === void 0 ? void 0 : _vm$value$originUf.uf) + " - " + _vm._s((_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : (_vm$value2$originUf = _vm$value2.originUf) === null || _vm$value2$originUf === void 0 ? void 0 : _vm$value2$originUf.name) + "\n      ")])], 1), _vm._v(" "), _c('b-form-group', {
    staticClass: "col-6",
    attrs: {
      "label": "Destino:"
    }
  }, [_c('b-button', {
    staticStyle: {
      "padding": "3px"
    },
    attrs: {
      "variant": "primary",
      "size": "xs"
    }
  }, [_vm._v("\n        " + _vm._s((_vm$value3 = _vm.value) === null || _vm$value3 === void 0 ? void 0 : (_vm$value3$destinatio = _vm$value3.destinationUf) === null || _vm$value3$destinatio === void 0 ? void 0 : _vm$value3$destinatio.uf) + " - " + _vm._s((_vm$value4 = _vm.value) === null || _vm$value4 === void 0 ? void 0 : (_vm$value4$destinatio = _vm$value4.destinationUf) === null || _vm$value4$destinatio === void 0 ? void 0 : _vm$value4$destinatio.name) + "\n      ")])], 1)], 1), _vm._v(" "), _c('b-col', {
    staticClass: "col-6 col"
  }, [_c('b-form-group', {
    staticClass: "col-12 col",
    attrs: {
      "label": "Trajeto (ordem - estado):"
    }
  }, [[_c('ul', {
    staticStyle: {
      "padding-inline-start": "5px"
    }
  }, _vm._l((_vm$value5 = _vm.value) === null || _vm$value5 === void 0 ? void 0 : _vm$value5.journeys, function (item, index) {
    var _item$uf, _item$uf2;
    return _c('li', {
      key: index,
      staticStyle: {
        "list-style-type": "none"
      }
    }, [_c('b-card', {
      staticStyle: {
        "padding": "6px !important"
      },
      attrs: {
        "type": "primary",
        "size": "xs",
        "no-body": ""
      }
    }, [_vm._v("\n              " + _vm._s(item === null || item === void 0 ? void 0 : item.order) + " - " + _vm._s(item === null || item === void 0 ? void 0 : (_item$uf = item.uf) === null || _item$uf === void 0 ? void 0 : _item$uf.name) + " (" + _vm._s(item === null || item === void 0 ? void 0 : (_item$uf2 = item.uf) === null || _item$uf2 === void 0 ? void 0 : _item$uf2.uf) + ")\n            ")])], 1);
  }), 0)]], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }