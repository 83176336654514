<template>
  <b-overlay
    :show="deliverPointIsBusy"
    variant="white"
    opacity="0.85"
    blur="2px"
    spinner-variant="primary"
    spinner-type="grow"
    :spinner-small="true"
    rounded="sm"
  >
    <multiselector
      v-bind="{ ...$props, ...$attrs }"
      v-on="$listeners"
      track-by="id"
      label="address.address"
      :custom-label="val => `#${val?.id ?? 'NI'} - CNPJ:${val?.cnpj ?? 'NI'} - Observação:${val?.observations ?? 'NI'}`"
      :options="deliverPointList"
      :multiple="false"
      :allow-empty="false"
      :taggable="false"
    >
      <template slot="option" slot-scope="{ props }">
        <div class="option__desc">
          <b-col>
            <b-row>
              <span class="option__small">#:{{ props?.id ?? 'NI' }} </span>
            </b-row>
            <b-row>
              <span class="option__small">CNPJ: {{ props?.cnpj ?? 'NI' }} </span>
            </b-row>
            <b-row>
              <span class="option__small">Observação: {{ props?.observations ?? 'N/I' }} </span>
            </b-row>
            <b-row>
              <span class="option__small">Endereço: {{ props?.address?.street ?? 'NI' }} </span>
            </b-row>
            <b-row>
              <span class="option__small">Estado: {{ props?.address?.city?.uf?.uf ?? 'NI' }} </span> -

              <span class="option__small"> - Municipio: {{ props?.address?.name_micro_region ?? 'NI' }} </span>
            </b-row>
          </b-col>
        </div>
      </template>
    </multiselector>
  </b-overlay>
</template>

<script>
import deliverPointMixin from '@/features/deliver-points/store/mixin'

export default {
  name: 'DeliverPointSelector',

  props: {
    rows: Array,
    header: Array
  },
  mixins: [deliverPointMixin],

  async mounted() {
    await this.listDeliverPoints({ page: 1, perPage: 1000 })
  },
  methods: {}
}
</script>
