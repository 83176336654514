'use strict'
// Thi store depends from some plugins like CASL in Vue instance

import userApi from '@/services/user-api'
import { setState, mutate, filterElements } from '@/helpers/store-helpers'
import storedFilesApi from '@/services/file-download-api'

// import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    userList: [],
    currentUsersPage: 1,
    lastUsersPage: 1,
    totalUsersRows: 0,
    perPageUsers: 10,
    users: []
  },
  actions: {
    async createUser({ state, commit }, payload) {
      const created = await userApi().store(payload)
      commit('insertUser', created)
    },
    async updateUser({ state, commit }, payload) {
      commit('isBusy')
      try {
        const created = await userApi().update(payload)
        commit('updateListRef', created)
      } catch (e) {
        commit('isBusy', false)
        throw e
      } finally {
        commit('isBusy', false)
      }
    },
    async filterUsers({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      commit('isBusy')
      try {
        const usersUpdated = await userApi().list(page, perPage, text)
        setState(commit, 'userList', usersUpdated.data)
        setState(commit, 'lastUsersPage', usersUpdated.lastPage)
        setState(commit, 'currentUsersPage', usersUpdated.page)
        setState(commit, 'perPageUsers', usersUpdated.perPage)
        setState(commit, 'totalUsersRows', usersUpdated.total)
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        commit('isBusy', false)
      }
    },
    async listUsers({ state, commit }, { page, perPage }) {
      commit('isBusy')
      try {
        const usersUpdated = await userApi().list(page, perPage)
        setState(commit, 'userList', usersUpdated.data)
        setState(commit, 'lastUsersPage', usersUpdated.lastPage)
        setState(commit, 'currentUsersPage', usersUpdated.page)
        setState(commit, 'perPageUsers', usersUpdated.perPage)
        setState(commit, 'totalUsersRows', usersUpdated.total)
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        commit('isBusy', false)
      }
    },
    async uploadUserSignature({ state, commit }, file) {
      commit('isBusy', true)
      try {
        console.warn('upload', file)

        const imageData = await storedFilesApi().store({
          fileData: file,
          filename: file.name
        })
        setState(commit, 'signature_model', imageData)
        commit('isBusy', false)
        return imageData
      } catch (error) {
        commit('isBusy', false)
        throw error
      }
    },
    async getUser({ state, commit }, id) {
      return userApi().get(id)
    },
    async deleteUser({ state, commit }, id) {
      const result = await userApi().delete(id)
      if (result) await commit('removeUser', id)
    }
  },
  getters: {
    totalUsers(state) {
      return (state.users || []).length
    },
    errorMessage(state) {
      return state.errMessage
    },
    filteredUsers(state) {
      return filterElements(state.userList, state.filter)
    },

    hasNewUsers(state) {
      let check
      try {
        if (this.isFirstTime) {
          check = undefined
        } else {
          check = state.users.length > 3
        }
      } catch (e) {
        return false
      }
      return check
    }
  },
  mutations: {
    mutate,
    isBusy(state, val = true) {
      state.isBusy = val
    },
    updateTextFilter(state, filter) {
      state.filter = filter.toLowerCase()
    },
    removeUser(state, payload) {
      state.userList = state.userList.filter(value => value.id !== payload)
    },
    insertUser(state, data) {
      state.userList.push(data)
    },
    setInitial(state, user) {
      state.initialUser = user
    },
    setFinal(state, user) {
      state.finalUser = user
    },
    updateListRef(state, update) {
      if (state.userList) {
        state.userList = state.userList.map(val => {
          if (val.id === update.id) {
            return update
          }
          return val
        })
      }
    },
    updateUsers(state, users) {
      state.userList = users || []
    },
    resetUsersState(state, _) {
      state.initialUser = null
      state.finalUser = null
    }
  }
}
