var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-input', _vm._g(_vm._b({
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '#####-###',
      expression: "'#####-###'"
    }],
    attrs: {
      "placeholder": "99999-999"
    },
    on: {
      "input": _vm.onInput
    }
  }, 'c-input', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners), [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }