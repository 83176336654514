var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-form-group', {
    staticClass: "col-md-12",
    attrs: {
      "label": "Estado (UF):"
    }
  }, [_c('multiselect', {
    staticClass: "multiselect-primary",
    attrs: {
      "id": "uf-input-type",
      "track-by": "id",
      "label": "name",
      "value": _vm.$v.address.uf.$model,
      "custom-label": function (val) {
        return ((val.uf) + " - " + (val.name));
      },
      "options": _vm.ufList,
      "multiple": false,
      "hide-selected": false,
      "taggable": false,
      "tag-placeholder": "Selecione um",
      "placeholder": "Informe o Estado"
    },
    on: {
      "select": _vm._setStateUf
    }
  }), _vm._v(" "), !_vm.isValid('address.uf', 'required') ? _c('div', {
    staticClass: "cinvalid-tip"
  }, [_vm._v("\n      O estado deve ser informado\n    ")]) : _vm._e()], 1), _vm._v(" "), _c('b-form-group', {
    staticClass: "col-md-12",
    attrs: {
      "label": "Cidade:"
    }
  }, [_c('multiselect', {
    staticClass: "multiselect-primary",
    attrs: {
      "id": "city-input-type",
      "track-by": "id",
      "label": "name",
      "value": _vm.address.city,
      "options": _vm.cityList,
      "multiple": false,
      "hide-selected": false,
      "taggable": false,
      "tag-placeholder": "Selecione uma cidade",
      "placeholder": "Informe a Cidade"
    },
    on: {
      "select": _vm._setCity
    }
  }), _vm._v(" "), !_vm.isValid('address.city', 'required') ? _c('div', {
    staticClass: "cinvalid-tip"
  }, [_vm._v("\n      A Cidade deve ser informada\n    ")]) : _vm._e(), _vm._v(" "), !_vm.isValid('address.city', 'ufValid') ? _c('div', {
    staticClass: "cinvalid-tip"
  }, [_vm._v("\n      A Cidade deve pertencer ao Estado\n    ")]) : _vm._e()], 1), _vm._v(" "), _c('b-row', {
    staticClass: "col-md-12"
  }, [_c('b-form-group', {
    staticClass: "col-md-6",
    attrs: {
      "label": "CEP:"
    }
  }, [_c('ZipInput', {
    attrs: {
      "id": "zipcode-input",
      "state": _vm.isValid('address.zipcode')
    },
    model: {
      value: _vm.address.zipcode,
      callback: function ($$v) {
        _vm.$set(_vm.address, "zipcode", $$v);
      },
      expression: "address.zipcode"
    }
  }), _vm._v(" "), !_vm.isValid('address.zipcode', 'required') ? _c('div', {
    staticClass: "invalid-tooltip"
  }, [_vm._v("\n        CEP é obrigatório\n      ")]) : _vm._e(), _vm._v(" "), !_vm.isValid('address.zipcode', 'minLength') ? _c('div', {
    staticClass: "invalid-tooltip"
  }, [_vm._v("\n        CEP não é valido\n      ")]) : _vm._e()], 1), _vm._v(" "), _c('b-form-group', {
    staticClass: "col-md-6",
    attrs: {
      "label": "Bairro:"
    }
  }, [_c('c-input', {
    attrs: {
      "placeholder": "Digite o Bairro",
      "state": _vm.isValid('address.district')
    },
    model: {
      value: _vm.address.district,
      callback: function ($$v) {
        _vm.$set(_vm.address, "district", $$v);
      },
      expression: "address.district"
    }
  }), _vm._v(" "), !_vm.isValid('address.district', 'required') ? _c('div', {
    staticClass: "invalid-tooltip"
  }, [_vm._v("\n        Bairro é obrigatório\n      ")]) : _vm._e()], 1)], 1), _vm._v(" "), _c('b-form-group', {
    staticClass: "col-md-12",
    attrs: {
      "label": "Endereço:"
    }
  }, [_c('c-input', {
    attrs: {
      "placeholder": "Digite o endereço",
      "state": _vm.isValid('address.street')
    },
    model: {
      value: _vm.address.street,
      callback: function ($$v) {
        _vm.$set(_vm.address, "street", $$v);
      },
      expression: "address.street"
    }
  }), _vm._v(" "), !_vm.isValid('address.street', 'required') ? _c('div', {
    staticClass: "invalid-tooltip"
  }, [_vm._v("\n      Endereço é obrigatório\n    ")]) : _vm._e()], 1), _vm._v(" "), _c('b-row', {
    staticClass: "col-md-12"
  }, [_c('b-form-group', {
    staticClass: "col-md-6",
    attrs: {
      "label": "Número:"
    }
  }, [_c('c-input', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: ['#######'],
      expression: "['#######']"
    }],
    attrs: {
      "placeholder": "Digite o Número",
      "state": _vm.isValid('address.number')
    },
    model: {
      value: _vm.address.number,
      callback: function ($$v) {
        _vm.$set(_vm.address, "number", $$v);
      },
      expression: "address.number"
    }
  }), _vm._v(" "), !_vm.isValid('address.number', 'required') ? _c('div', {
    staticClass: "invalid-tooltip"
  }, [_vm._v("\n        Número é obrigatório\n      ")]) : _vm._e(), _vm._v(" "), !_vm.isValid('address.number', 'number') ? _c('div', {
    staticClass: "invalid-tooltip"
  }, [_vm._v("\n        Número Inválido\n      ")]) : _vm._e()], 1), _vm._v(" "), _c('b-form-group', {
    staticClass: "col-md-6",
    attrs: {
      "label": "Complemento:"
    }
  }, [_c('c-input', {
    attrs: {
      "placeholder": "Digite o Complemento",
      "state": _vm.isValid('address.complement')
    },
    model: {
      value: _vm.address.complement,
      callback: function ($$v) {
        _vm.$set(_vm.address, "complement", $$v);
      },
      expression: "address.complement"
    }
  }), _vm._v(" "), !_vm.isValid('address.complement', 'minLength') ? _c('div', {
    staticClass: "invalid-tooltip"
  }, [_vm._v("\n        Complemento está muito curto (4)\n      ")]) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }