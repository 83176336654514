var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "id": "overlay-background",
      "show": _vm.transportableDocumentIsBusy,
      "variant": "white",
      "opacity": "0.85",
      "blur": "2px",
      "spinner-variant": "primary",
      "spinner-type": "grow",
      "spinner-small": true,
      "rounded": "sm"
    }
  }, [_c('multiselector', _vm._g(_vm._b({
    attrs: {
      "track-by": "id",
      "label": "name",
      "custom-label": function (val) {
        var _val$issuer$name, _val$issuer, _val$document_number;
        return ("emissor.: " + ((_val$issuer$name = val === null || val === void 0 ? void 0 : (_val$issuer = val.issuer) === null || _val$issuer === void 0 ? void 0 : _val$issuer.name) !== null && _val$issuer$name !== void 0 ? _val$issuer$name : 'NI') + " num.doc.: " + ((_val$document_number = val === null || val === void 0 ? void 0 : val.document_number) !== null && _val$document_number !== void 0 ? _val$document_number : 'NI') + " - #" + (val.id));
      },
      "options": _vm.transportableDocumentList,
      "multiple": false,
      "allow-empty": false,
      "taggable": false
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (_ref) {
        var _props$issuer$name, _props$issuer, _props$document_numbe;
        var props = _ref.props;
        return [_c('div', {
          staticClass: "option__desc"
        }, [_c('b-col', [_c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("Emissor:\n              " + _vm._s((_props$issuer$name = props === null || props === void 0 ? void 0 : (_props$issuer = props.issuer) === null || _props$issuer === void 0 ? void 0 : _props$issuer.name) !== null && _props$issuer$name !== void 0 ? _props$issuer$name : 'NI') + "\n            ")])]), _vm._v(" "), _c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("Num do Doc:\n              " + _vm._s((_props$document_numbe = props === null || props === void 0 ? void 0 : props.document_number) !== null && _props$document_numbe !== void 0 ? _props$document_numbe : 'NI') + "\n            ")])])], 1)], 1)];
      }
    }])
  }, 'multiselector', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }