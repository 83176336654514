<template>
  <Multiselector
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
    track-by="id"
    label="name"
    :custom-label="val => `${val?.description} - ${val?.originCity?.name} a ${val?.destinationCity?.name} por `"
    :show-labels="false"
    :options="pathList"
    :multiple="false"
    :allow-empty="false"
    :taggable="false"
  >
    <template slot="singleLabel" slot-scope="{ props }">
      <span class="option__desc">
        <span class="option__title">
          #{{ props?.id ?? 'NI' }} Trajeto: de {{ props?.originUf?.uf ?? 'NI' }} a
          {{ props?.destinationUf?.uf ?? 'NI' }} passando por
          <span v-for="entry in props.journeys" :key="entry.id">
            #{{ entry?.uf?.id ?? 'NI' }} - {{ entry?.uf?.name ?? 'NI' }},
          </span>
        </span>
      </span>
    </template>
    <template slot="option" slot-scope="{ props }">
      <div class="option__desc">
        <b-col>
          <b-row>
            <span class="option__small">
              #{{ props?.id ?? 'NI' }} Origem:
              {{ props?.originUf?.name ?? 'NI' }}
            </span>
          </b-row>
          <b-row>
            <span class="option__small">
              Trajeto por:
              <span v-for="entry in props.journeys" :key="entry.id">
                #{{ entry?.uf?.id ?? 'NI' }} - {{ entry?.uf?.uf ?? 'NI' }}
                {{ entry?.uf?.name ?? 'NI' }}
              </span>
            </span>
          </b-row>
          <b-row>
            <span class="option__small"> Destino: {{ props?.destinationUf?.name ?? 'NI' }} </span>
          </b-row>
        </b-col>
      </div>
    </template>
  </Multiselector>
</template>

<script>
import PathsMixin from '@/features/paths/store/mixin'

export default {
  name: 'path-selector',
  async mounted() {
    await this.listPaths({ page: 1, perPage: 10000 })
  },
  props: {
    rows: Array,
    header: Array
  },
  mixins: [PathsMixin],
  methods: {}
}
</script>
