'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  setState
} from '@/helpers/store-helpers'

import eventRecordMdfeService from '@/features/event-record-mdfes/event-record-mdfes-api'

const busyMutateName = 'setEventRecordMdfesBusy'

export default {
  namespaced: true,
  state: {
    eventRecordMdfeList: [],
    eventRecordMdfeFilter: '',
    eventRecordMdfeIsBusy: false,
    eventRecordMdfeTotalRows: 0,
    eventRecordMdfeCurrentPage: 1,
    eventRecordMdfePerPage: 10,
    eventRecordMdfeLastPage: 0
  },
  actions: {
    async listEventRecordMdfes({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await eventRecordMdfeService().list(page, perPage, text)
          setState(commit, 'eventRecordMdfeTotalRows', payload.total)
          setState(commit, 'eventRecordMdfeCurrentPage', payload.page)
          setState(commit, 'eventRecordMdfePerPage', payload.perPage)
          setState(commit, 'eventRecordMdfeLastPage', payload.lastPage)

          commit('setEventRecordMdfesList', payload.data)
        }
      )
    },
    async showEventRecordMDFE({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return eventRecordMdfeService().show(id)
        }
      )
    },
    async createEventRecordMDFE({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await eventRecordMdfeService().create(payload)
          commit('unshiftEventRecordMDFE', entry)
        }
      )
    },
    async updateEventRecordMDFE({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await eventRecordMdfeService().update(payload)
          commit('updateEventRecordMDFEInList', entry)
          return entry
        }
      )
    },
    async deleteEventRecordMDFE({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await eventRecordMdfeService().delete(id)
          if (result) await commit('removeEventRecordMDFEInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredEventRecordMdfes(state) {
      return filterElements(state.eventRecordMdfeList, state.filter)
    }
  },
  mutations: {
    mutate,
    setEventRecordMdfesList(state, payload) {
      state.eventRecordMdfeList = payload
    },
    setEventRecordMdfesFilter(state, payload) {
      state.eventRecordMdfeList = payload
    },
    setEventRecordMdfesBusy(state, value = false) {
      state.eventRecordMdfeIsBusy = value
    },
    unshiftEventRecordMDFE(state, payload) {
      state.eventRecordMdfeList.unshift(payload)
    },
    removeEventRecordMDFEInList(state, payload) {
      state.eventRecordMdfeList = state.eventRecordMdfeList.filter(
        eventRecordMdfe => eventRecordMdfe.id !== payload
      )
    },
    updateEventRecordMDFEInList(state, payload) {
      if (state.eventRecordMdfeList) {
        state.eventRecordMdfeList = state.eventRecordMdfeList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
