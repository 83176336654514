import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  methods: {
    ...mapActions('transportableDocuments', [
      'listTransportableDocuments',
      'showTransportableDocument',
      'deleteTransportableDocument',
      'createTransportableDocument',
      'updateTransportableDocument'
    ]),
    ...mapMutations('transportableDocuments', [
      'cleanTransportableDocumentsErrors',
      'setTransportableDocumentsList',
      'setTransportableDocumentsFilter',
      'setTransportableDocumentsBusy'
    ])
  },
  computed: {
    ...mapState('transportableDocuments', [
      'transportableDocumentIsBusy',
      'transportableDocumentList',
      'transportableDocumentFilter',
      'transportableDocumentPerPage',
      'transportableDocumentCurrentPage',
      'transportableDocumentTotalRows',
      'transportableDocumentLastPage'
    ]),
    ...mapGetters('transportableDocuments', [
      'filteredTransportableDocuments'
    ])
  }
}
