import { Ability } from '@casl/ability'

/**
 * @param UserInfoData contains details about logged in user: its id, name, email, etc
 */
function defineAbilitiesFor(UserInfoData) {
  return new Ability()
}

export default defineAbilitiesFor
