var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Multiselector', _vm._g(_vm._b({
    attrs: {
      "track-by": "id",
      "label": "name",
      "custom-label": function (val) {
        var _val$id, _val$plate, _val$plateUf$name, _val$plateUf, _val$renavam, _val$crlv;
        return ("#" + ((_val$id = val.id) !== null && _val$id !== void 0 ? _val$id : 'NI') + " - Placa: " + ((_val$plate = val.plate) !== null && _val$plate !== void 0 ? _val$plate : 'NI') + " - " + ((_val$plateUf$name = val === null || val === void 0 ? void 0 : (_val$plateUf = val.plateUf) === null || _val$plateUf === void 0 ? void 0 : _val$plateUf.name) !== null && _val$plateUf$name !== void 0 ? _val$plateUf$name : '') + "; Renavam: " + ((_val$renavam = val === null || val === void 0 ? void 0 : val.renavam) !== null && _val$renavam !== void 0 ? _val$renavam : 'NI') + "; CRLV: " + ((_val$crlv = val === null || val === void 0 ? void 0 : val.crlv) !== null && _val$crlv !== void 0 ? _val$crlv : 'NI'));
      },
      "options": _vm.vehicleList,
      "multiple": false,
      "allow-empty": false,
      "taggable": false
    },
    on: {
      "open": _vm.onOpenPicker
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (_ref) {
        var _props$id, _props$plate, _props$renavam;
        var props = _ref.props;
        return [_c('div', {
          staticClass: "option__desc"
        }, [_c('b-col', [_c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("#" + _vm._s((_props$id = props === null || props === void 0 ? void 0 : props.id) !== null && _props$id !== void 0 ? _props$id : 'NI') + " ")]), _vm._v(" "), _c('span', {
          staticClass: "option__small"
        }, [_vm._v("\n            - Placa:\n            " + _vm._s((_props$plate = props === null || props === void 0 ? void 0 : props.plate) !== null && _props$plate !== void 0 ? _props$plate : 'NI') + "\n          ")])]), _vm._v(" "), _c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("Renavam:\n            " + _vm._s((_props$renavam = props === null || props === void 0 ? void 0 : props.renavam) !== null && _props$renavam !== void 0 ? _props$renavam : 'N/I') + "\n          ")])])], 1)], 1)];
      }
    }])
  }, 'Multiselector', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners));
}
var staticRenderFns = []

export { render, staticRenderFns }