'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import deliveryAttachmentService from '@/features/delivery-attachments/delivery-attachments-api'

const busyMutateName = 'setDeliveryAttachmentsBusy'

export default {
  namespaced: true,
  state: {
    deliveryAttachmentList: [],
    deliveryAttachmentFilter: '',
    deliveryAttachmentIsBusy: false,
    deliveryAttachmentTotalRows: 0,
    deliveryAttachmentCurrentPage: 1,
    deliveryAttachmentPerPage: 10,
    deliveryAttachmentLastPage: 0
  },
  actions: {
    async listDeliveryAttachments({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await deliveryAttachmentService().list(page, perPage, text)
          setState(commit, 'deliveryAttachmentTotalRows', payload.total)
          setState(commit, 'deliveryAttachmentCurrentPage', payload.page)
          setState(commit, 'deliveryAttachmentPerPage', payload.perPage)
          setState(commit, 'deliveryAttachmentLastPage', payload.lastPage)

          commit('setDeliveryAttachmentsList', payload.data)
        }
      )
    },
    async showDeliveryAttachment({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return deliveryAttachmentService().show(id)
        }
      )
    },
    async createDeliveryAttachment({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await deliveryAttachmentService().create(payload)
          commit('unshiftDeliveryAttachment', entry)
        }
      )
    },
    async updateDeliveryAttachment({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await deliveryAttachmentService().update(payload)
          commit('updateDeliveryAttachmentInList', entry)
          return entry
        }
      )
    },
    async deleteDeliveryAttachment({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await deliveryAttachmentService().delete(id)
          if (result) await commit('removeDeliveryAttachmentInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredDeliveryAttachments(state) {
      return filterElements(state.deliveryAttachmentList, state.filter)
    }
  },
  mutations: {
    mutate,
    setDeliveryAttachmentsList(state, payload) {
      state.deliveryAttachmentList = payload
    },
    setDeliveryAttachmentsFilter(state, payload) {
      state.deliveryAttachmentList = payload
    },
    setDeliveryAttachmentsBusy(state, value = false) {
      state.deliveryAttachmentIsBusy = value
    },
    unshiftDeliveryAttachment(state, payload) {
      state.deliveryAttachmentList.unshift(payload)
    },
    removeDeliveryAttachmentInList(state, payload) {
      state.deliveryAttachmentList = state.deliveryAttachmentList.filter(
        deliveryAttachment => deliveryAttachment.id !== payload
      )
    },
    updateDeliveryAttachmentInList(state, payload) {
      if (state.deliveryAttachmentList) {
        state.deliveryAttachmentList = state.deliveryAttachmentList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
