<template>
  <b-navbar toggleable="lg" :variant="getLayoutNavbarBg()" class="layout-navbar align-items-lg-center container-p-x">
    <!-- Sidenav toggle -->
    <b-navbar-nav class="align-items-lg-center mr-auto mr-lg-4" v-if="sidenavToggle">
      <a class="nav-item nav-link px-0 ml-2 ml-lg-0" href="javascript:void(0)" @click="toggleSidenav">
        <i class="ion ion-md-menu text-large align-middle" />
      </a>
    </b-navbar-nav>
    <a
      class="nav-item nav-link px-0 mx-2 ml-lg-0"
      href="javascript:void(0)"
      title="Ir para inicio"
      b-v-tooltip.hover
      @click="$router.push('/')"
    >
      <i class="lnr lnr-home text-large align-middle" />
    </a>

    <a
      class="nav-item nav-link px-0 ml-2 ml-lg-0"
      href="javascript:void(0)"
      title="Catálogo de Módulos (Ctrl + K)"
      b-v-tooltip.hover
      @click="toggleSearchModal"
    >
      <i class="ion ion-md-book text-large align-middle" />
    </a>
    <b-modal
      id="modals-fill-in"
      size="large"
      modal-class="modal-fill-in"
      ref="searchModal"
      @shown="onModalShown"
      hide-header
      hide-footer
    >
      <p class="text-white text-large font-weight-light mb-3">
        Catálogo de Módulos do Sistema (Ctrl + K)
      </p>
      <b-input-group size="lg" class="mb-3">
        <c-input
          id="module-search-input"
          ref="moduleSearchInput"
          placeholder="Digite o nome do módulo"
          class="bg-white border-0"
          v-model="filterText"
          @keyup.enter="openFirstEntry"
        />
        <b-input-group-btn slot="right">
          <b-btn variant="primary">Buscar</b-btn>
        </b-input-group-btn>
      </b-input-group>
      <div class="text-center text-right text-white opacity-60">
        Ao selecionar irá abrir na aba atual (salve suas alterações antes !)
      </div>
      <b-card>
        <b-list-group>
          <template v-for="entry in filteredItens">
            <template v-if="entry.divider"> </template>
            <template v-else-if="isSearchEntryActive(entry.to)">
              <b-list-group-item :key="entry.id" class="mb-1" active>
                <div class="d-flex justify-content-between w-100">
                  <h5 class="mb-1">
                    {{ entry.name }}
                  </h5>
                  <a tabindex="-1">Página Atual</a>
                </div>
                <small>{{ entry.to }}</small>
              </b-list-group-item>
            </template>
            <template v-else-if="entry?.can">
              <Can :I="entry.can" :key="entry.id">
                <b-list-group-item :href="entry.to" :key="entry.id" class="mb-1">
                  <div class="d-flex justify-content-between w-100">
                    <h5 class="mb-1">
                      {{ entry.name }}
                    </h5>
                    <a href="#" tabindex="-1">Abrir Módulo</a>
                  </div>
                  <small>{{ entry.to }}</small>
                </b-list-group-item>
              </Can>
              <Can not :I="entry.can" :key="entry.id">
                <b-list-group-item href="javascript:void(0)" :key="entry.id" disabled class="mb-1">
                  <div class="d-flex justify-content-between w-100">
                    <h5 class="mb-1">
                      {{ entry.name }}
                    </h5>
                    <a disabled>Bloqueado</a>
                  </div>
                  <small>(Sem permissão para acessar)</small>
                </b-list-group-item>
              </Can>
            </template>
            <template v-else>
              <b-list-group-item :href="entry.to" :key="entry.id" class="mb-1">
                <div class="d-flex justify-content-between w-100">
                  <h5 class="mb-1">
                    {{ entry.name }}
                  </h5>
                  <a href="#" tabindex="-1">Abrir Módulo</a>
                </div>
                <small>{{ entry.to }}</small>
              </b-list-group-item></template
            >
          </template>
        </b-list-group>
      </b-card>
    </b-modal>

    <!-- Navbar toggle -->
    <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle>

    <b-collapse is-nav id="app-layout-navbar">
      <b-navbar-nav class="align-items-lg-center"> </b-navbar-nav>
    </b-collapse>
    <!-- Divider -->

    <b-nav-item-dropdown :right="true" class="demo-navbar-user">
      <template slot="button-content">
        <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
          <span class="px-1 mr-lg-2 ml-2 ml-lg-0"
            >Bem Vindo, {{ (userInfo.name ?? '').trim().split(' ')[0] ?? userInfo.name ?? '' }}</span
          >
        </span>
      </template>
      <a @click="goToProfile">
        <b-dd-item><i class="ion ion-md-settings text-lightest"></i> &nbsp; Minha conta</b-dd-item>
      </a>
      <b-dd-divider />
      <b-dd-item @click="logoutAccount"><i class="ion ion-ios-log-out text-danger"></i> &nbsp; Sair</b-dd-item>
      <b-dd-item>Versão: {{ appVersion }}</b-dd-item>
    </b-nav-item-dropdown>
  </b-navbar>
</template>

<style scoped>
li:last-child {
  list-style-type: none;
}
</style>

<script>
import preventFakeLogin from '../mixins/prevent-fake-login'
import menuEntries from '../menu-entries'
import permissionsMixin from '@/features/permissions/store/mixin'
import authMixin from '@/features/authentication/store/mixin'
import { filterElements } from '@/helpers/store-helpers'
import HttpClient from '../services/http-client'

export default {
  mixins: [preventFakeLogin, permissionsMixin, authMixin],
  name: 'app-layout-navbar',

  props: {
    sidenavToggle: {
      type: Boolean,
      default: true
    }
  },
  data: () => {
    return {
      menuEntries: [],
      filterText: null
    }
  },
  created() {
    this.fetchUserInfo() // loads user info whe login
    this.menuEntries = this.flattenArray(menuEntries)
  },
  async mounted() {
    this.layoutHelpers.init()
    this.layoutHelpers.update()
    this.layoutHelpers.setAutoUpdate(true)

    window.addEventListener('keydown', this.handleKeydown)
    await this.checkVersion()
  },
  beforeDestroy() {
    this.layoutHelpers.destroy()
    window.removeEventListener('keydown', this.handleKeydown)
  },
  computed: {
    filteredItens(state) {
      return filterElements(this.menuEntries, this.filterText)
    }
  },
  methods: {
    async checkVersion() {
      try {
        const response = await new HttpClient().rest.get('/health')
        if (response?.version?.version !== this?.$store?.getters?.appVersion) {
          this.$bvToast.toast('Atualize recarregando o site utilizando Ctrl + F5', {
            title: 'Atualização v' + response?.version?.version,
            autoHideDelay: 4000,
            appendToast: false,
            toastClass: 'bs4-toast bg-success'
          })
        }
      } catch (error) {
        this.$bvToast.toast('Essa falha pode não impactar o uso (' + error.toString() + ')', {
          title: 'Falha ao checar versão',
          autoHideDelay: 2000,
          appendToast: false,
          toastClass: 'bs4-toast bg-warning'
        })
      }
    },
    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed()
    },
    openFirstEntry(event) {
      if (this?.filteredItens[0]?.to) {
        this.$router.push(this.filteredItens[0].to)
        setTimeout(this.toggleSearchModal, 200)
      }
    },
    async handleKeydown(event) {
      if (event.ctrlKey && event.key === 'k') {
        event.preventDefault()
        await this.toggleSearchModal()
      }
    },
    async toggleSearchModal() {
      await this.$refs.searchModal.toggle()
    },
    async onModalShown() {
      this.filterText = null
      this?.$refs?.moduleSearchInput?.$el?.focus()
    },
    flattenArray(array) {
      let result = []
      for (let i = 0; i < array.length; i++) {
        if (Array.isArray(array[i].children)) {
          result = result.concat(this.flattenArray(array[i].children))
        } else {
          result.push(array[i])
        }
      }
      return result
    },
    openSearch() {
      this.layoutHelpers.toggleCollapsed()
    },

    isSearchEntryActive(url) {
      return this.$route.path.endsWith(url) === true
    },
    goToProfile() {
      this.$router.push('/user/' + this.userInfo.id)
    },
    getLayoutNavbarBg() {
      return this.layoutNavbarBg
    }
  }
}
</script>
