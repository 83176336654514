import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  methods: {
    ...mapActions('vehicleTypes', [
      'listVehicleTypes',
      'showVehicleType',
      'deleteVehicleType',
      'createVehicleType',
      'updateVehicleType'
    ]),
    ...mapMutations('vehicleTypes', [
      'cleanVehicleTypesErrors',
      'setVehicleTypesList',
      'setVehicleTypesFilter',
      'setVehicleTypesBusy'
    ])
  },
  computed: {
    ...mapState('vehicleTypes', [
      'vehicleTypeIsBusy',
      'vehicleTypeList',
      'vehicleTypeFilter',
      'vehicleTypePerPage',
      'vehicleTypeCurrentPage',
      'vehicleTypeTotalRows',
      'vehicleTypeLastPage'
    ]),
    ...mapGetters('vehicleTypes', ['filteredVehicleTypes'])
  }
}
