'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import correctionLetterService from '@/features/correction-letters/correction-letters-api'

const busyMutateName = 'setCorrectionLettersBusy'

export default {
  namespaced: true,
  state: {
    correctionLetterList: [],
    correctionLetterFilter: '',
    correctionLetterIsBusy: false,
    correctionLetterTotalRows: 0,
    correctionLetterCurrentPage: 1,
    correctionLetterPerPage: 10,
    correctionLetterLastPage: 0
  },
  actions: {
    async listCorrectionLetters({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await correctionLetterService().list(page, perPage, text)
          setState(commit, 'correctionLetterTotalRows', payload.total)
          setState(commit, 'correctionLetterCurrentPage', payload.page)
          setState(commit, 'correctionLetterPerPage', payload.perPage)
          setState(commit, 'correctionLetterLastPage', payload.lastPage)

          commit('setCorrectionLettersList', payload.data)
        }
      )
    },
    async showCorrectionLetter({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return correctionLetterService().show(id)
        }
      )
    },
    async createCorrectionLetter({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await correctionLetterService().create(payload)
          commit('unshiftCorrectionLetter', entry)
        }
      )
    },
    async updateCorrectionLetter({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await correctionLetterService().update(payload)
          commit('updateCorrectionLetterInList', entry)
          return entry
        }
      )
    },
    async deleteCorrectionLetter({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await correctionLetterService().delete(id)
          if (result) await commit('removeCorrectionLetterInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredCorrectionLetters(state) {
      return filterElements(state.correctionLetterList, state.filter)
    }
  },
  mutations: {
    mutate,
    setCorrectionLettersList(state, payload) {
      state.correctionLetterList = payload
    },
    setCorrectionLettersFilter(state, payload) {
      state.correctionLetterList = payload
    },
    setCorrectionLettersBusy(state, value = false) {
      state.correctionLetterIsBusy = value
    },
    unshiftCorrectionLetter(state, payload) {
      state.correctionLetterList.unshift(payload)
    },
    removeCorrectionLetterInList(state, payload) {
      state.correctionLetterList = state.correctionLetterList.filter(
        correctionLetter => correctionLetter.id !== payload
      )
    },
    updateCorrectionLetterInList(state, payload) {
      if (state.correctionLetterList) {
        state.correctionLetterList = state.correctionLetterList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
