'use strict'

import fileDownloadApi from '@/services/file-download-api'

// eslint-disable-next-line no-unused-vars
import { setState, filterElements, mutate } from '@/helpers/store-helpers'

// import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    downloadBusy: false
    // table
  },
  actions: {
    async downloadFile({ state, commit }, fileData) {
      try {
        setState(commit, 'downloadBusy', true)
        await fileDownloadApi().downloadFile(fileData)
        setState(commit, 'downloadBusy', false)
      } catch (error) {
        setState(commit, 'downloadBusy', false)
        throw error
      }
    },
    async fetchFile({ state, commit }, fileData) {
      try {
        setState(commit, 'downloadBusy', true)
        const response = await fileDownloadApi().fetchFile(fileData)
        setState(commit, 'downloadBusy', false)
        return response
      } catch (error) {
        setState(commit, 'downloadBusy', false)
        throw error
      }
    }
  },
  mutations: {
    // set state need this
    mutate
  }
}
