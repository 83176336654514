'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import collectOrderService from '@/features/collect-orders/collect-orders-api'

const busyMutateName = 'setCollectOrdersBusy'

export default {
  namespaced: true,
  state: {
    collectOrderList: [],
    collectOrderFilter: '',
    collectOrderIsBusy: false,
    collectOrderTotalRows: 0,
    collectOrderCurrentPage: 1,
    collectOrderPerPage: 10,
    collectOrderLastPage: 0
  },
  actions: {
    async listCollectOrders({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await collectOrderService().list(page, perPage, text)
          setState(commit, 'collectOrderTotalRows', payload.total)
          setState(commit, 'collectOrderCurrentPage', payload.page)
          setState(commit, 'collectOrderPerPage', payload.perPage)
          setState(commit, 'collectOrderLastPage', payload.lastPage)

          commit('setCollectOrdersList', payload.data)
        }
      )
    },
    async showCollectOrder({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return collectOrderService().show(id)
        }
      )
    },
    async createCollectOrder({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await collectOrderService().create(payload)
          commit('unshiftCollectOrder', entry)
        }
      )
    },
    async updateCollectOrder({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await collectOrderService().update(payload)
          commit('updateCollectOrderInList', entry)
          return entry
        }
      )
    },
    async deleteCollectOrder({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await collectOrderService().delete(id)
          if (result) await commit('removeCollectOrderInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredCollectOrders(state) {
      return filterElements(state.collectOrderList, state.filter)
    }
  },
  mutations: {
    mutate,
    setCollectOrdersList(state, payload) {
      state.collectOrderList = payload
    },
    setCollectOrdersFilter(state, payload) {
      state.collectOrderList = payload
    },
    setCollectOrdersBusy(state, value = false) {
      state.collectOrderIsBusy = value
    },
    unshiftCollectOrder(state, payload) {
      state.collectOrderList.unshift(payload)
    },
    removeCollectOrderInList(state, payload) {
      state.collectOrderList = state.collectOrderList.filter(
        collectOrder => collectOrder.id !== payload
      )
    },
    updateCollectOrderInList(state, payload) {
      if (state.collectOrderList) {
        state.collectOrderList = state.collectOrderList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
