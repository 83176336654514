'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  setState
} from '@/helpers/store-helpers'

import transportServiceTypeService from '@/features/transport-service-types/transport-service-types-api'

const busyMutateName = 'setTransportServiceTypesBusy'

export default {
  namespaced: true,
  state: {
    transportServiceTypeList: [],
    transportServiceTypeFilter: '',
    transportServiceTypeIsBusy: false,
    transportServiceTypeTotalRows: 0,
    transportServiceTypeCurrentPage: 1,
    transportServiceTypePerPage: 10,
    transportServiceTypeLastPage: 0
  },
  actions: {
    async listTransportServiceTypes({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await transportServiceTypeService().list(page, perPage, text)
          setState(commit, 'transportServiceTypeTotalRows', payload.total)
          setState(commit, 'transportServiceTypeCurrentPage', payload.page)
          setState(commit, 'transportServiceTypePerPage', payload.perPage)
          setState(commit, 'transportServiceTypeLastPage', payload.lastPage)

          commit('setTransportServiceTypesList', payload.data)
        }
      )
    },
    async showTransportServiceType({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return transportServiceTypeService().show(id)
        }
      )
    },
    async createTransportServiceType({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = transportServiceTypeService().create(payload)
          commit('unshiftTransportServiceType', entry)
        }
      )
    },
    async updateTransportServiceType({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = transportServiceTypeService().update(payload)
          commit('updateTransportServiceTypeInList', entry)
          return entry
        }
      )
    },
    async deleteTransportServiceType({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await transportServiceTypeService().delete(id)
          if (result) await commit('removeTransportServiceTypeInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredTransportServiceTypes(state) {
      return filterElements(state.transportServiceTypeList, state.filter)
    }
  },
  mutations: {
    mutate,
    setTransportServiceTypesList(state, payload) {
      state.transportServiceTypeList = payload
    },
    setTransportServiceTypesFilter(state, payload) {
      state.transportServiceTypeList = payload
    },
    setTransportServiceTypesBusy(state, value = false) {
      state.transportServiceTypeIsBusy = value
    },
    unshiftTransportServiceType(state, payload) {
      state.transportServiceTypeList.unshift(payload)
    },
    removeTransportServiceTypeInList(state, payload) {
      state.transportServiceTypeList = state.transportServiceTypeList.filter(
        transportServiceType => transportServiceType.id !== payload
      )
    },
    updateTransportServiceTypeInList(state, payload) {
      if (state.transportServiceTypeList) {
        state.transportServiceTypeList = state.transportServiceTypeList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
