<template>
  <div>
    <b-modal size="lg" ref="annotationCheckModal" modal-class="modal-top">
      <div slot="modal-title">
        Resumo de CTEs a serem <span class="font-weight-light">emitidos como MDFE</span><br />
        <small class="text-muted"
          >Abaixo segue a lista se é permitido fazer a emissão no sefaz dos documentos a transportar.
        </small>
        <br />
      </div>
      <b-overlay
        id="overlay-background"
        :show="mdfeIsBusy"
        variant="white"
        opacity="0.85"
        blur="2px"
        spinner-variant="primary"
        spinner-type="grow"
        :spinner-small="true"
        rounded="sm"
      >
        <CTable :id="checkedCTEs?.length" :fields="checkedFields" :items="checkedCTEs">
          <template #cell(can_issue)="data">
            <b-badge v-if="data.item.can_issue" else href="javascript:void(0)" variant="success text-white">
              PERMITIDO
            </b-badge>
            <b-badge v-else href="javascript:void(0)" variant="danger text-white">
              NÃO PERMITIDO
            </b-badge>
          </template>
          <template #cell(transport_number)="data">
            <b-badge else href="javascript:void(0)" variant="success text-white">
              {{ data.item?.entity?.transport_number ?? 'NI' }}
            </b-badge>
          </template>
        </CTable>
      </b-overlay>

      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->

        <b-button size="md" variant="success" @click="() => doTransportServicesEmission(ok)">
          Emitir MDFEs dos CTEs
        </b-button>
        <b-button size="md" variant="secondary" @click="() => cancel(ok)">
          Fechar
        </b-button>
      </template>
    </b-modal>

    <b-modal size="lg" ref="annotationEmissionModal" modal-class="modal-top">
      <div slot="modal-title">
        Resumo de documentos <span class="font-weight-light">emitidos</span><br />
        <small class="text-muted">Abaixo segue a lista de resultados da emissão no sefaz dos documentos. </small>
        <br />
      </div>
      <b-overlay
        id="overlay-background"
        :show="mdfeIsBusy"
        variant="white"
        opacity="0.85"
        blur="2px"
        spinner-variant="primary"
        spinner-type="grow"
        :spinner-small="true"
        rounded="sm"
      >
        <CTable :id="emittedMDFEs?.length" :fields="emittedFields" :items="emittedMDFEs">
          <template #cell(error)="data">
            <b-badge v-if="data.error" href="javascript:void(0)" variant="danger text-white">
              NÃO EMITIDO
            </b-badge>
            <b-badge v-else else href="javascript:void(0)" variant="success text-white">
              EMITIDO
            </b-badge>
          </template>
        </CTable>
      </b-overlay>

      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="md" variant="secondary" @click="() => cancel(ok)">
          Fechar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import 'moment'
import annotationsMx from '@/features/mdfes/store/mixin'
import { isEmpty, isNil } from 'lodash'

const checkedFields = [
  {
    key: 'entity.id',
    sortable: false,
    label: 'ID'
  },
  {
    key: 'transport_number',
    sortable: false,
    label: 'N° de Transporte'
  },
  {
    key: 'can_issue',
    sortable: true,
    label: 'Permite Emitir'
  },
  {
    key: 'message',
    sortable: false,
    label: 'Mensagem',
    formatter: value => {
      return isEmpty(value) || isNil(value) ? 'VÁLIDO' : value.toUpperCase()
    }
  }
]
const emittedFields = [
  {
    key: 'entity.id',
    sortable: false,
    label: 'ID'
  },
  {
    key: 'transport_number',
    sortable: false,
    label: 'N° de Transporte'
  },
  {
    key: 'error',
    sortable: true,
    label: 'Sucesso'
  },
  {
    key: 'message',
    sortable: false,
    label: 'Resultado',
    formatter: value => {
      return isEmpty(value) || isNil(value) ? 'Sucesso' : value
    }
  }
]
export default {
  name: 'emit-mdfe-check-modal',
  mixins: [annotationsMx],
  data() {
    return {
      checkedCTEs: [],
      emittedMDFEs: [],
      checkedFields,
      emittedFields
    }
  },
  props: {
    value: Object,
    entityName: String
  },
  methods: {
    async checkTransportServices(documents, entityName) {
      this.checkedCTEs = []
      this.toAnnotationDocuments = documents
      this.checkedCTEs = await this.checkCTEToMDFE({
        documents
      })

      this.$refs.annotationCheckModal.show()
    },
    async doTransportServicesEmission(ok) {
      this.emittedMDFEs = []
      this.emittedMDFEs = await this.emitMDFEFromCTE({
        documents: this.checkedCTEs.map(val => val.entity)
      })
      this.$refs.annotationCheckModal.hide()
      this.$refs.annotationEmissionModal.show()
    }
  }
}
</script>
