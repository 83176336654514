<template>
  <b-overlay
    :show="documentTypeIsBusy"
    variant="white"
    opacity="0.85"
    blur="2px"
    spinner-variant="primary"
    spinner-type="grow"
    :spinner-small="true"
    rounded="sm"
  >
    <Multiselector
      :id="id"
      track-by="id"
      label="name"
      :custom-label="val => `${val.name} - ${val.sefaz_code}`"
      :options="documentTypeList ?? []"
      @select="onSelect"
      :multiple="false"
      :allow-empty="false"
      :taggable="false"
      v-bind="{ ...$props, ...$attrs }"
      v-on="$listeners"
    >
      <template slot="option" slot-scope="{ props }">
        <div class="option__desc">
          <b-col>
            <b-row>
              <span class="option__small">
                {{ props?.name ?? 'NI' }}
              </span>
            </b-row>
            <b-row>
              <span class="option__small"
                >Código SEFAZ:
                {{ props?.sefaz_code ?? 'NI' }}
              </span>
            </b-row>
          </b-col>
        </div>
      </template>
    </Multiselector>
  </b-overlay>
</template>

<script>
import documentTypeMixin from '@/features/document-types/store/mixin'

export default {
  name: 'document-type-selector',
  mixins: [documentTypeMixin],
  props: {
    id: {
      default: null
    },
    rows: Array,
    header: Array,
    value: { type: Object, default: null },
    emitOnMount: {
      default: false
    }
  },
  async mounted() {
    await this.listDocumentTypes()
    if (this.emitOnMount) {
      this.emitMount()
    }
  },
  methods: {
    async emitMount() {
      this.onSelect(this.value)
    },
    onSelect(emitValue) {
      // eslint-disable-next-line vue/no-mutating-props
      this.value = emitValue
      this.$emit('input', this.value)
      this.$emit('change', this.value)
    }
  }
}
</script>
