var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Multiselector', _vm._g(_vm._b({
    attrs: {
      "track-by": "sefaz_code",
      "label": "name",
      "custom-label": function (val) {
        return ("#" + (val.sefaz_code) + " " + (val.acronym) + " - " + (val.name));
      },
      "options": _vm.sefazConveyorTypeList
    }
  }, 'Multiselector', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners));
}
var staticRenderFns = []

export { render, staticRenderFns }