'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import ciotService from '@/features/ciots/ciots-api'

const busyMutateName = 'setCiotsBusy'

export default {
  namespaced: true,
  state: {
    ciotList: [],
    ciotFilter: '',
    ciotIsBusy: false,
    ciotTotalRows: 0,
    ciotCurrentPage: 1,
    ciotPerPage: 10,
    ciotLastPage: 0
  },
  actions: {
    async listCiots({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await ciotService().list(page, perPage, text)
          setState(commit, 'ciotTotalRows', payload.total)
          setState(commit, 'ciotCurrentPage', payload.page)
          setState(commit, 'ciotPerPage', payload.perPage)
          setState(commit, 'ciotLastPage', payload.lastPage)

          commit('setCiotsList', payload.data)
        }
      )
    },
    async showCiot({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return ciotService().show(id)
        }
      )
    },
    async createCiot({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await ciotService().create(payload)
          commit('unshiftCiot', entry)
        }
      )
    },
    async updateCiot({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await ciotService().update(payload)
          commit('updateCiotInList', entry)
          return entry
        }
      )
    },
    async deleteCiot({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await ciotService().delete(id)
          if (result) await commit('removeCiotInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredCiots(state) {
      return filterElements(state.ciotList, state.filter)
    }
  },
  mutations: {
    mutate,
    setCiotsList(state, payload) {
      state.ciotList = payload
    },
    setCiotsFilter(state, payload) {
      state.ciotList = payload
    },
    setCiotsBusy(state, value = false) {
      state.ciotIsBusy = value
    },
    unshiftCiot(state, payload) {
      state.ciotList.unshift(payload)
    },
    removeCiotInList(state, payload) {
      state.ciotList = state.ciotList.filter(
        ciot => ciot.id !== payload
      )
    },
    updateCiotInList(state, payload) {
      if (state.ciotList) {
        state.ciotList = state.ciotList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
