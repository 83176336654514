var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Multiselector', _vm._g(_vm._b({
    attrs: {
      "track-by": "id",
      "label": "name",
      "custom-label": function (val) {
        return ("#" + (val.id) + " " + (val.name) + " - " + (val.cnpj));
      },
      "options": _vm.insurance_companyList
    },
    on: {
      "open": _vm.onList,
      "search-change": _vm.onSearchModel,
      "select": _vm.onSelectModel,
      "remove": _vm.onRemoveModel
    }
  }, 'Multiselector', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners));
}
var staticRenderFns = []

export { render, staticRenderFns }