'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  setState
} from '@/helpers/store-helpers'

import driverService from '@/features/drivers/drivers-api'

const busyMutateName = 'setDriversBusy'

export default {
  namespaced: true,
  state: {
    driverList: [],
    driverFilter: '',
    driverIsBusy: false,
    driverTotalRows: 0,
    driverCurrentPage: 1,
    driverPerPage: 10,
    driverLastPage: 0
  },
  actions: {
    async listDrivers({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await driverService().list(page, perPage, text)
          setState(commit, 'driverTotalRows', payload.total)
          setState(commit, 'driverCurrentPage', payload.page)
          setState(commit, 'driverPerPage', payload.perPage)
          setState(commit, 'driverLastPage', payload.lastPage)

          commit('setDriversList', payload.data)
        }
      )
    },
    async showDriver({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return driverService().show(id)
        }
      )
    },
    async createDriver({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = driverService().create(payload)
          commit('unshiftDriver', entry)
        }
      )
    },
    async updateDriver({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = driverService().update(payload)
          commit('updateDriverInList', entry)
          return entry
        }
      )
    },
    async deleteDriver({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await driverService().delete(id)
          if (result) await commit('removeDriverInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredDrivers(state) {
      return filterElements(state.driverList, state.filter)
    }
  },
  mutations: {
    mutate,
    setDriversList(state, payload) {
      state.driverList = payload
    },
    setDriversFilter(state, payload) {
      state.driverList = payload
    },
    setDriversBusy(state, value = false) {
      state.driverIsBusy = value
    },
    unshiftDriver(state, payload) {
      state.driverList.unshift(payload)
    },
    removeDriverInList(state, payload) {
      state.driverList = state.driverList.filter(
        driver => driver.id !== payload
      )
    },
    updateDriverInList(state, payload) {
      if (state.driverList) {
        state.driverList = state.driverList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
