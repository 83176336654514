var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CTable', _vm._g(_vm._b({
    ref: "selectableTable",
    attrs: {
      "bordered": "",
      "striped": "",
      "items": _vm.items,
      "fields": _vm.customFields,
      "selectable": true
    },
    on: {
      "row-selected": _vm.onSelectEntry
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, name) {
      return {
        key: name,
        fn: function (slotData) {
          return [_vm._t(name, null, null, slotData)];
        }
      };
    }), {
      key: "head(selected)",
      fn: function (_ref) {
        var label = _ref.label;
        return [_c('div', {
          staticClass: "text-center",
          attrs: {
            "title": label
          }
        }, [_c('b-checkbox', {
          on: {
            "change": _vm.selectAll
          },
          model: {
            value: _vm.isAllSelected,
            callback: function ($$v) {
              _vm.isAllSelected = $$v;
            },
            expression: "isAllSelected"
          }
        })], 1)];
      }
    }, {
      key: "cell(selected)",
      fn: function (_ref2) {
        var rowSelected = _ref2.rowSelected;
        return [rowSelected ? _c('div', {
          staticClass: "text-center"
        }, [_c('b-checkbox', {
          attrs: {
            "disabled": "",
            "checked": true
          }
        }), _vm._v(" "), _c('span', {
          staticClass: "sr-only"
        }, [_vm._v("Selecionado")])], 1) : _c('div', {
          staticClass: "text-center"
        }, [_c('b-checkbox', {
          attrs: {
            "disabled": "",
            "checked": false
          }
        }), _vm._v(" "), _c('span', {
          staticClass: "sr-only"
        }, [_vm._v("Não Selecionado")])], 1)];
      }
    }], null, true)
  }, 'CTable', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners), [_vm._l(_vm.$slots, function (_, name) {
    return _vm._t(name, null, {
      "slot": name
    });
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }