import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'

export default {
  mounted() {
    this.cleanErrors()
  },
  methods: {
    ...mapMutations('authentication', [
      'setLoginUser',
      'setLoginPass',
      'setLoginBranch',
      'cleanErrors',
      'setCredentials',
      'setUserInfo'
    ]),
    ...mapActions('authentication', ['logoutAccount', 'fetchOperations', 'fetchUserInfo'])
  },
  computed: {
    ...mapState('authentication', [
      'username',
      'password',
      'branches',
      'branchActive',
      'roles',
      'userInfo',
      'errMessage',
      'userName',
      'userId'
    ]),
    ...mapGetters('authentication', ['isAuthenticated', 'errorMessage', 'userRoles', 'isAnTransporter'])
  }
}
