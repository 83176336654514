import axios from 'axios'

import { getBaseApiUrl } from '@/helpers/store-helpers'

import Vue from 'vue'
import router from '@/router'

import nProgress from 'nprogress'

function showServerErrorDialog(response) {
  Vue.prototype.$swal({
    title: 'Falha',
    html:
      'Erro interno do sistema: </br> Por favor contate o administrador do seu sistema',
    icon: 'error'
  })
}

function logoutUser() {
  localStorage.setItem('token', null)
  router.push('/login')
}

export default function() {
  const host = getBaseApiUrl

  const axiosInstance = axios.create({
    baseURL: host, // baseApi Url host.com/api
    timeout: 60000
  })

  axiosInstance.interceptors.request.use(config => {
    // We are importing store before it is populated.
    // We intercept the request and use the current apiKey
    // eslint-disable-next-line dot-notation
    const token = localStorage.getItem('token')

    if (token) {
      config.headers.Authorization = token
    }

    if (host) config.headers['Access-Control-Allow-Origin'] = host

    return config
  })

  axiosInstance.interceptors.response.use(
    response => {
      return response
    },
    error => {
      const response = error.response

      if (response && response.status === 401) {
        logoutUser()
      }

      if (response && response.status === 500) {
        showServerErrorDialog(response)
      }

      throw error
    }
  )
  // before a request is made start the nprogress
  axiosInstance.interceptors.request.use(config => {
    nProgress.start()
    return config
  })

  // before a response is returned stop nprogress
  axiosInstance.interceptors.response.use(response => {
    nProgress.done()
    return response
  })

  return axiosInstance
}
