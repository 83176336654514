import { saveAs } from 'file-saver'

import ValidationException from '@/services/exceptions/ValidationException.js'

import HttpClient from './http-client'

class StoredFilesApi extends HttpClient {
  async fetchFile(b2File) {
    if (b2File) {
      return this.rest.get(`/v1/stored-files/${b2File.id}`, {
        timeout: b2File.contentLength * 1000
      })
    } else throw new ValidationException('Backblaze informado invalido')
  }

  async downloadFile(b2File) {
    const response = await this.fetchFile(b2File)
    const byteCharacters = window.atob(response)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], {
      type: b2File.contentType
    })
    await saveAs(blob, b2File.client_file_name)
  }

  async store({ filename = null, base64 = null, fileData = null }) {
    // eslint-disable-next-line no-unreachable
    if (fileData) {
      const fd = new FormData()
      fd.append('file', fileData.file, fileData.file.name)
      return (await this.http.post('/v1/stored-files', fd)).data
    } else {
      return (
        await this.http.post(
          '/v1/stored-files',
          { filename, base64 },
          {
            timeout: 3600000
          }
        )
      ).data
    }
  }
}

export default function () {
  return new StoredFilesApi()
}
