'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  setState
} from '@/helpers/store-helpers'

import closureService from '@/features/closures/closures-api'

const busyMutateName = 'setClosuresBusy'

export default {
  namespaced: true,
  state: {
    closureList: [],
    closureFilter: '',
    closureIsBusy: false,
    closureTotalRows: 0,
    closureCurrentPage: 1,
    closurePerPage: 10,
    closureLastPage: 0
  },
  actions: {
    async listClosures({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await closureService().list(page, perPage, text)
          setState(commit, 'closureTotalRows', payload.total)
          setState(commit, 'closureCurrentPage', payload.page)
          setState(commit, 'closurePerPage', payload.perPage)
          setState(commit, 'closureLastPage', payload.lastPage)

          commit('setClosuresList', payload.data)
        }
      )
    },
    async showClosure({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return closureService().show(id)
        }
      )
    },
    async createClosure({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = closureService().create(payload)
          commit('unshiftClosure', entry)
        }
      )
    },
    async updateClosure({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = closureService().update(payload)
          commit('updateClosureInList', entry)
          return entry
        }
      )
    },
    async deleteClosure({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await closureService().delete(id)
          if (result) await commit('removeClosureInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredClosures(state) {
      return filterElements(state.closureList, state.filter)
    }
  },
  mutations: {
    mutate,
    setClosuresList(state, payload) {
      state.closureList = payload
    },
    setClosuresFilter(state, payload) {
      state.closureList = payload
    },
    setClosuresBusy(state, value = false) {
      state.closureIsBusy = value
    },
    unshiftClosure(state, payload) {
      state.closureList.unshift(payload)
    },
    removeClosureInList(state, payload) {
      state.closureList = state.closureList.filter(
        closure => closure.id !== payload
      )
    },
    updateClosureInList(state, payload) {
      if (state.closureList) {
        state.closureList = state.closureList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
