'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  setState
} from '@/helpers/store-helpers'

import freightCostRangeService from '@/features/freight-cost-ranges/freight-cost-ranges-api'

const busyMutateName = 'setFreightCostRangesBusy'

export default {
  namespaced: true,
  state: {
    freightCostRangeList: [],
    freightCostRangeFilter: '',
    freightCostRangeIsBusy: false,
    freightCostRangeTotalRows: 0,
    freightCostRangeCurrentPage: 1,
    freightCostRangePerPage: 10,
    freightCostRangeLastPage: 0
  },
  actions: {
    async listFreightCostRanges({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await freightCostRangeService().list(page, perPage, text)
          setState(commit, 'freightCostRangeTotalRows', payload.total)
          setState(commit, 'freightCostRangeCurrentPage', payload.page)
          setState(commit, 'freightCostRangePerPage', payload.perPage)
          setState(commit, 'freightCostRangeLastPage', payload.lastPage)

          commit('setFreightCostRangesList', payload.data)
        }
      )
    },
    async showFreightCostRange({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return freightCostRangeService().show(id)
        }
      )
    },
    async createFreightCostRange({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = freightCostRangeService().create(payload)
          commit('unshiftFreightCostRange', entry)
        }
      )
    },
    async updateFreightCostRange({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = freightCostRangeService().update(payload)
          commit('updateFreightCostRangeInList', entry)
          return entry
        }
      )
    },
    async deleteFreightCostRange({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await freightCostRangeService().delete(id)
          if (result) await commit('removeFreightCostRangeInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredFreightCostRanges(state) {
      return filterElements(state.freightCostRangeList, state.filter)
    }
  },
  mutations: {
    mutate,
    setFreightCostRangesList(state, payload) {
      state.freightCostRangeList = payload
    },
    setFreightCostRangesFilter(state, payload) {
      state.freightCostRangeList = payload
    },
    setFreightCostRangesBusy(state, value = false) {
      state.freightCostRangeIsBusy = value
    },
    unshiftFreightCostRange(state, payload) {
      state.freightCostRangeList.unshift(payload)
    },
    removeFreightCostRangeInList(state, payload) {
      state.freightCostRangeList = state.freightCostRangeList.filter(
        freightCostRange => freightCostRange.id !== payload
      )
    },
    updateFreightCostRangeInList(state, payload) {
      if (state.freightCostRangeList) {
        state.freightCostRangeList = state.freightCostRangeList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
