import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  methods: {
    ...mapActions('companies', [
      'listCompanies',
      'showCompany',
      'deleteCompany',
      'createCompany',
      'updateCompany'
    ]),
    ...mapMutations('companies', [
      'cleanCompaniesErrors',
      'setCompaniesList',
      'setCompaniesFilter',
      'setCompaniesBusy'
    ])
  },
  computed: {
    ...mapState('companies', [
      'companyIsBusy',
      'companyList',
      'companyFilter',
      'companyPerPage',
      'companyCurrentPage',
      'companyTotalRows',
      'companyLastPage'
    ]),
    ...mapGetters('companies', [
      'filteredCompanies'
    ])
  }
}
