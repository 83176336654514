import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  methods: {
    ...mapActions('employeeRegistries', [
      'listEmployeeRegistries',
      'showEmployeeRegistry',
      'deleteEmployeeRegistry',
      'createEmployeeRegistry',
      'updateEmployeeRegistry'
    ]),
    ...mapMutations('employeeRegistries', [
      'cleanEmployeeRegistriesErrors',
      'setEmployeeRegistriesList',
      'setEmployeeRegistriesFilter',
      'setEmployeeRegistriesBusy'
    ])
  },
  computed: {
    ...mapState('employeeRegistries', [
      'employeeRegistryIsBusy',
      'employeeRegistryList',
      'employeeRegistryFilter',
      'employeeRegistryPerPage',
      'employeeRegistryCurrentPage',
      'employeeRegistryTotalRows',
      'employeeRegistryLastPage'
    ]),
    ...mapGetters('employeeRegistries', [
      'filteredEmployeeRegistries'
    ])
  }
}
