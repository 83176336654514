'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  setState
} from '@/helpers/store-helpers'

import freightRevenueRangeService from '@/features/freight-revenue-ranges/freight-revenue-ranges-api'

const busyMutateName = 'setFreightRevenueRangesBusy'

export default {
  namespaced: true,
  state: {
    freightRevenueRangeList: [],
    freightRevenueRangeFilter: '',
    freightRevenueRangeIsBusy: false,
    freightRevenueRangeTotalRows: 0,
    freightRevenueRangeCurrentPage: 1,
    freightRevenueRangePerPage: 10,
    freightRevenueRangeLastPage: 0
  },
  actions: {
    async listFreightRevenueRanges({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await freightRevenueRangeService().list(page, perPage, text)
          setState(commit, 'freightRevenueRangeTotalRows', payload.total)
          setState(commit, 'freightRevenueRangeCurrentPage', payload.page)
          setState(commit, 'freightRevenueRangePerPage', payload.perPage)
          setState(commit, 'freightRevenueRangeLastPage', payload.lastPage)

          commit('setFreightRevenueRangesList', payload.data)
        }
      )
    },
    async showFreightRevenueRange({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return freightRevenueRangeService().show(id)
        }
      )
    },
    async createFreightRevenueRange({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = freightRevenueRangeService().create(payload)
          commit('unshiftFreightRevenueRange', entry)
        }
      )
    },
    async updateFreightRevenueRange({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = freightRevenueRangeService().update(payload)
          commit('updateFreightRevenueRangeInList', entry)
          return entry
        }
      )
    },
    async deleteFreightRevenueRange({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await freightRevenueRangeService().delete(id)
          if (result) await commit('removeFreightRevenueRangeInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredFreightRevenueRanges(state) {
      return filterElements(state.freightRevenueRangeList, state.filter)
    }
  },
  mutations: {
    mutate,
    setFreightRevenueRangesList(state, payload) {
      state.freightRevenueRangeList = payload
    },
    setFreightRevenueRangesFilter(state, payload) {
      state.freightRevenueRangeList = payload
    },
    setFreightRevenueRangesBusy(state, value = false) {
      state.freightRevenueRangeIsBusy = value
    },
    unshiftFreightRevenueRange(state, payload) {
      state.freightRevenueRangeList.unshift(payload)
    },
    removeFreightRevenueRangeInList(state, payload) {
      state.freightRevenueRangeList = state.freightRevenueRangeList.filter(
        freightRevenueRange => freightRevenueRange.id !== payload
      )
    },
    updateFreightRevenueRangeInList(state, payload) {
      if (state.freightRevenueRangeList) {
        state.freightRevenueRangeList = state.freightRevenueRangeList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
