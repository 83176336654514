import { validateBr } from 'js-brasil'
import { validate_number } from 'js-brasil/dist/src/validate'

export function muchOldYear(minimunAllowedYear = 1950) {
  return val => {
    if (parseInt(val || '00') >= minimunAllowedYear) {
      return true
    }
    return false
  }
}

export function plateValid(mercosulIncluded = false) {
  return value => {
    return validateBr.placa(value || '', mercosulIncluded)
  }
}

export function renavamValid() {
  return value => {
    return validateBr.renavam(value || '')
  }
}

export function chassiValid() {
  return value => {
    return validateBr.chassi(value || '')
  }
}

export function muchNewYear(
  maximunAllowedYear = new Date(Date.now()).getFullYear() + 2
) {
  return val => {
    const currentYearPlusTwo = maximunAllowedYear

    if (parseInt(val || '0') <= currentYearPlusTwo) {
      return true
    }
    return false
  }
}

export function isValidNumber() {
  return val => {
    if (val && val.toString().length > 0) {
      return validate_number(val)
    }
    return false
  }
}
