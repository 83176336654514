<template>
  <div>
    <b-modal size="lg" ref="annotationCheckModal" modal-class="modal-top">
      <div slot="modal-title">
        Resumo de CTEs a serem <span class="font-weight-light">Cancelados</span><br />
        <small class="text-muted">Abaixo segue a lista de CTEs que podem ser canceladas. </small>
        <br />
      </div>
      <b-overlay
        id="overlay-background"
        variant="white"
        opacity="0.85"
        blur="2px"
        spinner-variant="primary"
        spinner-type="grow"
        :spinner-small="true"
        rounded="sm"
      >
        <CTable :id="ctesCancelChecks?.length" :fields="checkedFields" :items="ctesCancelChecks">
          <template #cell(can_cancel)="data">
            <b-badge v-if="data.item.can_cancel" else href="javascript:void(0)" variant="success text-white">
              PERMITIDO
            </b-badge>
            <b-badge v-else href="javascript:void(0)" variant="danger text-white">
              NÃO PERMITIDO
            </b-badge>
          </template>
          <template #cell(document_number)="data">
            <b-badge else href="javascript:void(0)" variant="success text-white">
              {{ data.item?.entity?.nfs_number ?? data.item?.entity?.collect_number ?? data.item?.entity?.cte_number }}
            </b-badge>
          </template>
          <template v-slot:cell(reason)="data">
            <textarea
              :value="data.reason"
              type="text"
              class="mr-sm-2 col-12"
              @input="
                value => {
                  updateReason(data.index, value.target.value)
                }
              "
            />
          </template>
        </CTable>
      </b-overlay>

      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->

        <b-button size="md" variant="success" @click="() => doCancelCTE(ok)">
          Cancelar CTE (incluí averbação)
        </b-button>
        <b-button size="md" variant="secondary" @click="() => cancel(ok)">
          Fechar
        </b-button>
      </template>
    </b-modal>

    <b-modal size="lg" ref="annotationEmissionModal" modal-class="modal-top">
      <div slot="modal-title">
        Resumo de documentos <span class="font-weight-light">averbados</span><br />
        <small class="text-muted">Abaixo a lista dos CTEs cancelados e se houve sucesso.</small>
        <br />
      </div>
      <b-overlay
        id="overlay-background"
        variant="white"
        opacity="0.85"
        blur="2px"
        spinner-variant="primary"
        spinner-type="grow"
        :spinner-small="true"
        rounded="sm"
      >
        <CTable :fields="emittedFields" :items="ctesCancelResults">
          <template #cell(error)="data">
            <b-badge v-if="data.item.error" href="javascript:void(0)" variant="danger text-white">
              CANCELAMENTO BLOQUEDO
            </b-badge>
            <b-badge v-else else href="javascript:void(0)" variant="success text-white">
              CANCELADO COM SUCESSO
            </b-badge>
          </template>
        </CTable>
      </b-overlay>

      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button v-if="allSuccess" size="md" variant="secondary" @click="() => cancel(ok)">
          Fechar
        </b-button>
        <b-button size="md" variant="secondary" @click="() => cancel(ok)">
          Voltar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import 'moment'
import annotationsMx from '@/features/ctes/store/mixin'
import { isEmpty } from 'lodash'

const checkedFields = [
  {
    key: 'entity.id',
    sortable: false,
    label: 'ID'
  },
  {
    key: 'entity.cte_number',
    sortable: false,
    label: 'N° do CTE'
  },
  {
    key: 'can_cancel',
    sortable: true,
    label: 'Pode Cancelar'
  },
  {
    key: 'entity.message',
    sortable: false,
    label: 'Motivo (não poder cancelar)',
    formatter: value => {
      return isEmpty(value) ? 'VÁLIDO' : value
    }
  },
  {
    key: 'reason',
    sortable: true,
    label: 'Motivo do Cancelamento'
  }
]
const emittedFields = [
  {
    key: 'entity.id',
    sortable: false,
    label: 'ID'
  },
  {
    key: 'entity.cte_number',
    sortable: false,
    label: 'N° do CTE'
  },
  {
    key: 'error',
    sortable: true,
    label: 'Sucesso'
  },
  {
    key: 'message',
    sortable: false,
    label: 'Resultado',
    formatter: value => {
      return isEmpty(value) ? 'Sucesso' : value.toUpperCase()
    }
  }
]
export default {
  name: 'CancelCTECheckModal',
  mixins: [annotationsMx],
  data() {
    return {
      ctesCancelChecks: [],
      ctesCancelResults: [],
      checkedFields,
      emittedFields
    }
  },
  props: {
    value: Object,
    entityName: String
  },
  computed: {
    allSuccess() {
      return !this.ctesCancelResults.find(value => value.error)
    }
  },
  methods: {
    async updateReason(index, value) {
      this.ctesCancelChecks[index].reason = value
    },
    async doCancelCheck(cteToValidate) {
      this.ctesCancelChecks = []
      this.ctesCancelChecks = cteToValidate.map(cte => {
        const payload = { entity: cte, can_cancel: true }
        if (cte?.cte_document_status?.toString() !== '100') {
          payload.can_cancel = false
          payload.message = `Status de CTE não pode ser cancelado (status:${cte?.cte_document_status?.toString()})`
        }
        return payload
      })

      this.$refs.annotationCheckModal.show()
    },
    async doCancelCTE() {
      this.ctesCancelResults = []
      this.ctesCancelResults = await this.cancelCTEs({
        ctes: this.ctesCancelChecks.filter(cte => cte.can_cancel).map(cte => cte)
      })
      this.askReason
      this.$refs.annotationEmissionModal.show()
    }
  }
}
</script>
