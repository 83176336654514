import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  methods: {
    ...mapActions('vehicles', [
      'listVehicles',
      'showVehicle',
      'deleteVehicle',
      'createVehicle',
      'updateVehicle'
    ]),
    ...mapMutations('vehicles', [
      'cleanVehiclesErrors',
      'setVehiclesList',
      'setVehiclesFilter',
      'setVehiclesBusy'
    ])
  },
  computed: {
    ...mapState('vehicles', [
      'vehicleIsBusy',
      'vehicleList',
      'vehicleFilter',
      'vehiclePerPage',
      'vehicleCurrentPage',
      'vehicleTotalRows',
      'vehicleLastPage'
    ]),
    ...mapGetters('vehicles', [
      'filteredVehicles'
    ])
  }
}
