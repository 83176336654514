import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  methods: {
    ...mapActions('annotations', [
      'listAnnotations',
      'showAnnotation',
      'emitAnnotations',
      'checkEmitAnnotation',
      'deleteAnnotation',
      'createAnnotation',
      'updateAnnotation'
    ]),
    ...mapMutations('annotations', [
      'cleanAnnotationsErrors',
      'setAnnotationsList',
      'setAnnotationsFilter',
      'setAnnotationsBusy'
    ])
  },
  computed: {
    ...mapState('annotations', [
      'annotationIsBusy',
      'annotationList',
      'annotationFilter',
      'annotationPerPage',
      'annotationCurrentPage',
      'annotationTotalRows',
      'annotationLastPage'
    ]),
    ...mapGetters('annotations', ['filteredAnnotations'])
  }
}
