var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.deliverPointIsBusy,
      "variant": "white",
      "opacity": "0.85",
      "blur": "2px",
      "spinner-variant": "primary",
      "spinner-type": "grow",
      "spinner-small": true,
      "rounded": "sm"
    }
  }, [_c('multiselector', _vm._g(_vm._b({
    attrs: {
      "track-by": "id",
      "label": "address.address",
      "custom-label": function (val) {
        var _val$id, _val$cnpj, _val$observations;
        return ("#" + ((_val$id = val === null || val === void 0 ? void 0 : val.id) !== null && _val$id !== void 0 ? _val$id : 'NI') + " - CNPJ:" + ((_val$cnpj = val === null || val === void 0 ? void 0 : val.cnpj) !== null && _val$cnpj !== void 0 ? _val$cnpj : 'NI') + " - Observação:" + ((_val$observations = val === null || val === void 0 ? void 0 : val.observations) !== null && _val$observations !== void 0 ? _val$observations : 'NI'));
      },
      "options": _vm.deliverPointList,
      "multiple": false,
      "allow-empty": false,
      "taggable": false
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (_ref) {
        var _props$id, _props$cnpj, _props$observations, _props$address$street, _props$address, _props$address$city$u, _props$address2, _props$address2$city, _props$address2$city$, _props$address$name_m, _props$address3;
        var props = _ref.props;
        return [_c('div', {
          staticClass: "option__desc"
        }, [_c('b-col', [_c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("#:" + _vm._s((_props$id = props === null || props === void 0 ? void 0 : props.id) !== null && _props$id !== void 0 ? _props$id : 'NI') + " ")])]), _vm._v(" "), _c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("CNPJ: " + _vm._s((_props$cnpj = props === null || props === void 0 ? void 0 : props.cnpj) !== null && _props$cnpj !== void 0 ? _props$cnpj : 'NI') + " ")])]), _vm._v(" "), _c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("Observação: " + _vm._s((_props$observations = props === null || props === void 0 ? void 0 : props.observations) !== null && _props$observations !== void 0 ? _props$observations : 'N/I') + " ")])]), _vm._v(" "), _c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("Endereço: " + _vm._s((_props$address$street = props === null || props === void 0 ? void 0 : (_props$address = props.address) === null || _props$address === void 0 ? void 0 : _props$address.street) !== null && _props$address$street !== void 0 ? _props$address$street : 'NI') + " ")])]), _vm._v(" "), _c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("Estado: " + _vm._s((_props$address$city$u = props === null || props === void 0 ? void 0 : (_props$address2 = props.address) === null || _props$address2 === void 0 ? void 0 : (_props$address2$city = _props$address2.city) === null || _props$address2$city === void 0 ? void 0 : (_props$address2$city$ = _props$address2$city.uf) === null || _props$address2$city$ === void 0 ? void 0 : _props$address2$city$.uf) !== null && _props$address$city$u !== void 0 ? _props$address$city$u : 'NI') + " ")]), _vm._v(" -\n\n            "), _c('span', {
          staticClass: "option__small"
        }, [_vm._v(" - Municipio: " + _vm._s((_props$address$name_m = props === null || props === void 0 ? void 0 : (_props$address3 = props.address) === null || _props$address3 === void 0 ? void 0 : _props$address3.name_micro_region) !== null && _props$address$name_m !== void 0 ? _props$address$name_m : 'NI') + " ")])])], 1)], 1)];
      }
    }])
  }, 'multiselector', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }