export default {
  methods: {
    showSuccessModal: function ({
      title = 'Feito, com sucesso !',
      text = '',
      type = 'success'
    }) {
      return this.$swal({ title: title, text: text, icon: type })
    }
  }
}
