'use strict'
// Thi store depends from some plugins like CASL in Vue instance
import Vue from 'vue'

import { AbilityBuilder } from '@casl/ability'
import userApi from '@/services/user-api'
import permissionsApi from '@/services/permissions-api'

import { filterElements, mutate, setState } from '@/helpers/store-helpers'

// import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    permissions: [],
    _filter: '',
    allPermissions: [],
    errMessage: undefined
  },
  actions: {
    async fetchPermissions({ state, commit }) {
      let permissionsSlugs = await userApi().permissions()
      if (permissionsSlugs && permissionsSlugs.length > 0) {
        permissionsSlugs = permissionsSlugs.map(p => p.slug)
      }
      const { can, rules } = new AbilityBuilder()

      await setState(commit, 'permissions', permissionsSlugs)
      can(permissionsSlugs)

      Vue.prototype.$ability.update(rules)
    },
    async listPermissions({ state, commit }) {
      const permissions = await permissionsApi().list()
      await setState(commit, 'allPermissions', permissions)
    }
  },
  getters: {
    errorMessage(state) {
      return state.errMessage
    },
    permissions(state) {
      return state.permissions || []
    },
    filteredPermissions(state) {
      return filterElements(state.allPermissions, state._filter)
    }
  },
  mutations: {
    mutate,
    cleanErrors(state) {
      state.errMessage = undefined
    },
    filterPermissions(state, filter) {
      if (filter) {
        state._filter = filter.toLowerCase().trim()
      }
    },
    markFoundError(state, message) {
      state.errMessage = message
    }
  }
}
