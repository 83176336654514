var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('multiselector', _vm._g(_vm._b({
    attrs: {
      "track-by": "id",
      "label": "name",
      "custom-label": function (val) {
        var _val$id, _val$originCity, _val$destinationCity;
        return ("#" + ((_val$id = val === null || val === void 0 ? void 0 : val.id) !== null && _val$id !== void 0 ? _val$id : 'NI') + " " + (val === null || val === void 0 ? void 0 : val.description) + " - de " + (val === null || val === void 0 ? void 0 : (_val$originCity = val.originCity) === null || _val$originCity === void 0 ? void 0 : _val$originCity.name) + " para -> " + (val === null || val === void 0 ? void 0 : (_val$destinationCity = val.destinationCity) === null || _val$destinationCity === void 0 ? void 0 : _val$destinationCity.name));
      },
      "options": _vm.routeList,
      "multiple": false,
      "allow-empty": false,
      "taggable": false
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (_ref) {
        var _props$id, _props$description, _props$originCity$nam, _props$originCity, _props$destinationCit, _props$destinationCit2;
        var props = _ref.props;
        return [_c('div', {
          staticClass: "option__desc"
        }, [_c('b-col', [_c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("#" + _vm._s((_props$id = props === null || props === void 0 ? void 0 : props.id) !== null && _props$id !== void 0 ? _props$id : 'NI') + " Descrição:\n            " + _vm._s((_props$description = props === null || props === void 0 ? void 0 : props.description) !== null && _props$description !== void 0 ? _props$description : 'NI') + "\n          ")])]), _vm._v(" "), _c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("Origem:\n            " + _vm._s((_props$originCity$nam = props === null || props === void 0 ? void 0 : (_props$originCity = props.originCity) === null || _props$originCity === void 0 ? void 0 : _props$originCity.name) !== null && _props$originCity$nam !== void 0 ? _props$originCity$nam : 'NI') + "\n          ")])]), _vm._v(" "), _c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("Destino:\n            " + _vm._s((_props$destinationCit = props === null || props === void 0 ? void 0 : (_props$destinationCit2 = props.destinationCity) === null || _props$destinationCit2 === void 0 ? void 0 : _props$destinationCit2.name) !== null && _props$destinationCit !== void 0 ? _props$destinationCit : 'NI') + "\n          ")])])], 1)], 1)];
      }
    }])
  }, 'multiselector', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners));
}
var staticRenderFns = []

export { render, staticRenderFns }