var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-input', _vm._b({
    directives: [{
      name: "currency",
      rawName: "v-currency",
      value: _vm.options,
      expression: "options"
    }],
    ref: "input",
    staticClass: "form-control",
    staticStyle: {
      "text-align": "right !important"
    },
    attrs: {
      "value": _vm.formattedValue
    },
    on: {
      "change": _vm.onChange,
      "input": _vm.onInput
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, name) {
      return {
        key: name,
        fn: function (slotData) {
          return [_vm._t(name, null, null, slotData)];
        }
      };
    })], null, true)
  }, 'c-input', Object.assign({}, _vm.$props, _vm.$attrs), false), [_vm._l(_vm.$slots, function (_, name) {
    return _vm._t(name, null, {
      "slot": name
    });
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }