<template>
  <span class="px-1">
    <template v-if="can">
      <Can :I="can">
        <b-btn
          size="xs"
          variant="warning"
          @click="click"
          :disabled="disabled"
          title="Editar Entrada"
          v-b-tooltip.hover
          v-bind="{ ...$props, ...$attrs }"
          v-on="$listeners"
          >Editar</b-btn
        >
      </Can>
      <Can not :I="can">
        <b-btn
          size="xs"
          variant="warning"
          @click="() => {}"
          disabled
          v-bind="{ ...$props, ...$attrs }"
          v-on="$listeners"
          title="Sem permissão para editar"
          v-b-tooltip.hover
          >Editar</b-btn
        >
      </Can>
    </template>
    <template v-else>
      <b-btn
        size="xs"
        variant="warning"
        @click="click"
        :disabled="disabled"
        title="Editar Entrada"
        v-b-tooltip.hover
        v-bind="{ ...$props, ...$attrs }"
        v-on="$listeners"
        >Editar</b-btn
      >
    </template>
  </span>
</template>

<script>
export default {
  name: 'EditBtn',
  props: {
    can: {
      default: null
    },
    click: {
      default: null
    },
    disabled: {
      default: false
    }
  }
}
</script>

<style></style>
