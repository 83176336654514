var render = function () {
  var _vm$manifestToEmit, _vm$manifestToEmit$co, _vm$manifestToEmit2, _vm$manifestToEmit2$c, _vm$manifestToEmit2$c2, _vm$manifestToEmit3, _vm$manifestToEmit3$d, _vm$manifestToEmit4, _vm$manifestToEmit4$t, _vm$manifestToEmit5, _vm$manifestToEmit5$t, _vm$manifestToEmit6, _vm$manifestToEmit6$t, _vm$manifestToEmit6$t2, _vm$manifestToEmit7, _vm$manifestToEmit7$t, _vm$manifestToEmit7$t2, _vm$manifestToEmit$ve, _vm$manifestToEmit8, _vm$manifestToEmit8$d, _vm$manifestToEmit9, _vm$manifestToEmit9$d, _vm$manifestToEmit10, _vm$manifestToEmit10$, _vm$manifestToEmit11, _vm$manifestToEmit11$, _vm$manifestToEmit12, _vm$manifestToEmit12$, _vm$manifestToEmit$to, _vm$manifestToEmit$to2, _vm$irrfTax$totalRate, _vm$irrfTax, _vm$irrfTax$tax$toFix, _vm$irrfTax2, _vm$irrfTax2$tax, _vm$inssTax$totalRate, _vm$inssTax, _vm$inssTax$tax$toFix, _vm$inssTax2, _vm$inssTax2$tax, _vm$sestSenatTax$tota, _vm$sestSenatTax, _vm$sestSenatTax$tax$, _vm$sestSenatTax2, _vm$sestSenatTax2$tax, _vm$taxRatesTotal$toF, _vm$taxRatesTotal, _vm$toReceiveWithoutA, _vm$toReceiveWithoutA2, _vm$manifestToEmit$ad, _vm$manifestToEmit$ad2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    ref: "manifestToEmit",
    attrs: {
      "size": "lg",
      "modal-class": "modal-top"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function (_ref) {
        var ok = _ref.ok,
          cancel = _ref.cancel;
        return [_c('b-button', {
          attrs: {
            "size": "md",
            "disabled": !_vm.validContractValue,
            "variant": "success"
          },
          on: {
            "click": function () {
              return _vm.genFreightContract(ok);
            }
          }
        }, [_vm._v("\n        Gerar contrato de frete\n      ")]), _vm._v(" "), _c('b-button', {
          attrs: {
            "size": "md",
            "variant": "secondary"
          },
          on: {
            "click": function () {
              return cancel(ok);
            }
          }
        }, [_vm._v("\n        Fechar\n      ")])];
      }
    }])
  }, [_c('div', {
    attrs: {
      "slot": "modal-title"
    },
    slot: "modal-title"
  }, [_vm._v("\n      Dados do Manifesto a gerar "), _c('span', {
    staticClass: "font-weight-light"
  }, [_vm._v("Contrato de Frete")]), _c('br'), _vm._v(" "), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Informações relevantes do contrato de frete. ")]), _vm._v(" "), _c('br')]), _vm._v(" "), _c('BusyOverlay', {
    attrs: {
      "show": _vm.manifestIsBusy || _vm.taxIsBusy
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "md-6"
  }, [_c('b-form-group', {
    staticClass: "col-12",
    attrs: {
      "label": "Contratante:"
    }
  }, [_c('span', [_c('b', [_vm._v(" Operação: ")])]), _vm._v(_vm._s((_vm$manifestToEmit = _vm.manifestToEmit) === null || _vm$manifestToEmit === void 0 ? void 0 : (_vm$manifestToEmit$co = _vm$manifestToEmit.contractor) === null || _vm$manifestToEmit$co === void 0 ? void 0 : _vm$manifestToEmit$co.operation_name) + "\n            "), _c('br'), _vm._v(" "), _c('span', [_c('b', [_vm._v(" Endereço: ")])]), _vm._v(_vm._s((_vm$manifestToEmit2 = _vm.manifestToEmit) === null || _vm$manifestToEmit2 === void 0 ? void 0 : (_vm$manifestToEmit2$c = _vm$manifestToEmit2.contractor) === null || _vm$manifestToEmit2$c === void 0 ? void 0 : (_vm$manifestToEmit2$c2 = _vm$manifestToEmit2$c.address) === null || _vm$manifestToEmit2$c2 === void 0 ? void 0 : _vm$manifestToEmit2$c2.street) + "\n            "), _c('br'), _vm._v(" "), _c('span', [_c('b', [_vm._v(" Dependentes: ")])]), _vm._v(_vm._s((_vm$manifestToEmit3 = _vm.manifestToEmit) === null || _vm$manifestToEmit3 === void 0 ? void 0 : (_vm$manifestToEmit3$d = _vm$manifestToEmit3.driver) === null || _vm$manifestToEmit3$d === void 0 ? void 0 : _vm$manifestToEmit3$d.dependents_quantity) + "\n          ")]), _vm._v(" "), _c('b-form-group', {
    staticClass: "col-12",
    attrs: {
      "label": "Dados do Transportador:"
    }
  }, [_c('span', [_c('b', [_vm._v(" ID: ")])]), _vm._v(" #" + _vm._s((_vm$manifestToEmit4 = _vm.manifestToEmit) === null || _vm$manifestToEmit4 === void 0 ? void 0 : (_vm$manifestToEmit4$t = _vm$manifestToEmit4.transporter) === null || _vm$manifestToEmit4$t === void 0 ? void 0 : _vm$manifestToEmit4$t.id) + "\n            "), _c('br'), _vm._v(" "), _c('span', [_c('b', [_vm._v(" Nome: ")])]), _vm._v(_vm._s((_vm$manifestToEmit5 = _vm.manifestToEmit) === null || _vm$manifestToEmit5 === void 0 ? void 0 : (_vm$manifestToEmit5$t = _vm$manifestToEmit5.transporter) === null || _vm$manifestToEmit5$t === void 0 ? void 0 : _vm$manifestToEmit5$t.name) + "\n            "), _c('br'), _vm._v(" "), _c('span', [_c('b', [_vm._v(" Endereço: ")])]), _vm._v(_vm._s((_vm$manifestToEmit6 = _vm.manifestToEmit) === null || _vm$manifestToEmit6 === void 0 ? void 0 : (_vm$manifestToEmit6$t = _vm$manifestToEmit6.transporter) === null || _vm$manifestToEmit6$t === void 0 ? void 0 : (_vm$manifestToEmit6$t2 = _vm$manifestToEmit6$t.address) === null || _vm$manifestToEmit6$t2 === void 0 ? void 0 : _vm$manifestToEmit6$t2.street) + "\n            "), _c('br'), _vm._v(" "), _c('span', [_c('b', [_vm._v(" CEP: ")])]), _vm._v(_vm._s((_vm$manifestToEmit7 = _vm.manifestToEmit) === null || _vm$manifestToEmit7 === void 0 ? void 0 : (_vm$manifestToEmit7$t = _vm$manifestToEmit7.transporter) === null || _vm$manifestToEmit7$t === void 0 ? void 0 : (_vm$manifestToEmit7$t2 = _vm$manifestToEmit7$t.address) === null || _vm$manifestToEmit7$t2 === void 0 ? void 0 : _vm$manifestToEmit7$t2.zipcode) + "\n          ")])], 1), _vm._v(" "), _c('b-form-group', {
    staticClass: "col-6",
    attrs: {
      "label": "Veículo / Motorista:"
    }
  }, [_c('span', [_c('b', [_vm._v(" Placa: ")])]), _vm._v(_vm._s((_vm$manifestToEmit$ve = _vm.manifestToEmit.vehicle) === null || _vm$manifestToEmit$ve === void 0 ? void 0 : _vm$manifestToEmit$ve.plate) + "\n          "), _c('br'), _vm._v(" "), _c('span', [_c('b', [_vm._v(" Motorista: ")])]), _vm._v(_vm._s((_vm$manifestToEmit8 = _vm.manifestToEmit) === null || _vm$manifestToEmit8 === void 0 ? void 0 : (_vm$manifestToEmit8$d = _vm$manifestToEmit8.driver) === null || _vm$manifestToEmit8$d === void 0 ? void 0 : _vm$manifestToEmit8$d.fullname) + "\n          "), _c('br'), _vm._v(" "), _c('span', [_c('b', [_vm._v(" CPF: ")])]), _vm._v(_vm._s((_vm$manifestToEmit9 = _vm.manifestToEmit) === null || _vm$manifestToEmit9 === void 0 ? void 0 : (_vm$manifestToEmit9$d = _vm$manifestToEmit9.driver) === null || _vm$manifestToEmit9$d === void 0 ? void 0 : _vm$manifestToEmit9$d.cpf) + "\n          "), _c('br'), _vm._v(" "), _c('span', [_c('b', [_vm._v(" CNH: ")])]), _vm._v(_vm._s((_vm$manifestToEmit10 = _vm.manifestToEmit) === null || _vm$manifestToEmit10 === void 0 ? void 0 : (_vm$manifestToEmit10$ = _vm$manifestToEmit10.driver) === null || _vm$manifestToEmit10$ === void 0 ? void 0 : _vm$manifestToEmit10$.cnh_number) + "\n          "), _c('br'), _vm._v(" "), _c('span', [_c('b', [_vm._v(" RENAVAM: ")])]), _vm._v(_vm._s((_vm$manifestToEmit11 = _vm.manifestToEmit) === null || _vm$manifestToEmit11 === void 0 ? void 0 : (_vm$manifestToEmit11$ = _vm$manifestToEmit11.vehicle) === null || _vm$manifestToEmit11$ === void 0 ? void 0 : _vm$manifestToEmit11$.renavam) + "\n          "), _c('br'), _vm._v(" "), _c('span', [_c('b', [_vm._v(" Dependentes: ")])]), _vm._v(_vm._s((_vm$manifestToEmit12 = _vm.manifestToEmit) === null || _vm$manifestToEmit12 === void 0 ? void 0 : (_vm$manifestToEmit12$ = _vm$manifestToEmit12.driver) === null || _vm$manifestToEmit12$ === void 0 ? void 0 : _vm$manifestToEmit12$.dependents_quantity) + "\n        ")])], 1), _vm._v(" "), _c('b-row', [_c('b-form-group', {
    staticClass: "col-4",
    attrs: {
      "label": "Valor Contratado:"
    }
  }, [_c('MoneyInput', {
    attrs: {
      "placeholder": "Digite o Valor",
      "state": _vm.validContractValue
    },
    model: {
      value: _vm.currentContractedValue,
      callback: function ($$v) {
        _vm.currentContractedValue = $$v;
      },
      expression: "currentContractedValue"
    }
  }), _vm._v(" "), !_vm.needReview ? [_vm._l(_vm.reviewReasons, function (item, index) {
    return [_c('div', {
      key: index,
      staticClass: "invalid-tooltip"
    }, [_vm._v("\n                " + _vm._s(item) + "\n              ")])];
  })] : _vm._e()], 2), _vm._v(" "), _c('b-form-group', {
    staticClass: "col-4",
    attrs: {
      "label": "Valor de Pedágio (+):"
    }
  }, [_c('span', [_vm._v("R$ " + _vm._s((_vm$manifestToEmit$to = (_vm$manifestToEmit$to2 = _vm.manifestToEmit.toll_value) === null || _vm$manifestToEmit$to2 === void 0 ? void 0 : _vm$manifestToEmit$to2.toFixed(2)) !== null && _vm$manifestToEmit$to !== void 0 ? _vm$manifestToEmit$to : 0))])])], 1), _vm._v(" "), _c('b-form-group', {
    attrs: {
      "label": "Alíquotas Aplicáveis:"
    }
  }, [_c('b-row', {
    staticClass: "col-12"
  }, [_c('b-form-group', {
    staticClass: "col-4",
    attrs: {
      "label": 'IRRF (-' + ((_vm$irrfTax$totalRate = (_vm$irrfTax = _vm.irrfTax) === null || _vm$irrfTax === void 0 ? void 0 : _vm$irrfTax.totalRates) !== null && _vm$irrfTax$totalRate !== void 0 ? _vm$irrfTax$totalRate : 0) + '%):'
    }
  }, [_c('span', [_vm._v("R$ " + _vm._s((_vm$irrfTax$tax$toFix = (_vm$irrfTax2 = _vm.irrfTax) === null || _vm$irrfTax2 === void 0 ? void 0 : (_vm$irrfTax2$tax = _vm$irrfTax2.tax) === null || _vm$irrfTax2$tax === void 0 ? void 0 : _vm$irrfTax2$tax.toFixed(2)) !== null && _vm$irrfTax$tax$toFix !== void 0 ? _vm$irrfTax$tax$toFix : 0))])]), _vm._v(" "), _c('b-form-group', {
    staticClass: "col-4",
    attrs: {
      "label": 'INSS (-' + ((_vm$inssTax$totalRate = (_vm$inssTax = _vm.inssTax) === null || _vm$inssTax === void 0 ? void 0 : _vm$inssTax.totalRates) !== null && _vm$inssTax$totalRate !== void 0 ? _vm$inssTax$totalRate : 0) + '%):'
    }
  }, [_c('span', [_vm._v("R$ " + _vm._s((_vm$inssTax$tax$toFix = (_vm$inssTax2 = _vm.inssTax) === null || _vm$inssTax2 === void 0 ? void 0 : (_vm$inssTax2$tax = _vm$inssTax2.tax) === null || _vm$inssTax2$tax === void 0 ? void 0 : _vm$inssTax2$tax.toFixed(2)) !== null && _vm$inssTax$tax$toFix !== void 0 ? _vm$inssTax$tax$toFix : 0))])]), _vm._v(" "), _c('b-form-group', {
    staticClass: "col-4",
    attrs: {
      "label": 'Sest/Senat (-' + ((_vm$sestSenatTax$tota = (_vm$sestSenatTax = _vm.sestSenatTax) === null || _vm$sestSenatTax === void 0 ? void 0 : _vm$sestSenatTax.totalRates) !== null && _vm$sestSenatTax$tota !== void 0 ? _vm$sestSenatTax$tota : 0) + '%):'
    }
  }, [_c('span', [_vm._v("R$ " + _vm._s((_vm$sestSenatTax$tax$ = (_vm$sestSenatTax2 = _vm.sestSenatTax) === null || _vm$sestSenatTax2 === void 0 ? void 0 : (_vm$sestSenatTax2$tax = _vm$sestSenatTax2.tax) === null || _vm$sestSenatTax2$tax === void 0 ? void 0 : _vm$sestSenatTax2$tax.toFixed(2)) !== null && _vm$sestSenatTax$tax$ !== void 0 ? _vm$sestSenatTax$tax$ : 0))])]), _vm._v(" "), _c('b-form-group', {
    staticClass: "col-4",
    attrs: {
      "label": "Total de Descontos (-):"
    }
  }, [_c('span', [_vm._v("R$ " + _vm._s((_vm$taxRatesTotal$toF = (_vm$taxRatesTotal = _vm.taxRatesTotal) === null || _vm$taxRatesTotal === void 0 ? void 0 : _vm$taxRatesTotal.toFixed(2)) !== null && _vm$taxRatesTotal$toF !== void 0 ? _vm$taxRatesTotal$toF : 0))])])], 1)], 1), _vm._v(" "), _c('hr'), _vm._v(" "), _c('b-form-group', {
    attrs: {
      "label": "Resumo:"
    }
  }, [_c('b-row', {
    staticClass: "col-12"
  }, [_c('b-form-group', {
    staticClass: "col-4",
    attrs: {
      "label": "Liquido a Receber Sem Adiantamento:"
    }
  }, [_c('span', [_vm._v("R$ " + _vm._s((_vm$toReceiveWithoutA = (_vm$toReceiveWithoutA2 = _vm.toReceiveWithoutAdvance) === null || _vm$toReceiveWithoutA2 === void 0 ? void 0 : _vm$toReceiveWithoutA2.toFixed(2)) !== null && _vm$toReceiveWithoutA !== void 0 ? _vm$toReceiveWithoutA : 0))])]), _vm._v(" "), _c('b-form-group', {
    staticClass: "col-4",
    attrs: {
      "label": "Adiantamento Concedido (-):"
    }
  }, [_c('span', [_vm._v("R$ " + _vm._s((_vm$manifestToEmit$ad = (_vm$manifestToEmit$ad2 = _vm.manifestToEmit.advance_value) === null || _vm$manifestToEmit$ad2 === void 0 ? void 0 : _vm$manifestToEmit$ad2.toFixed(2)) !== null && _vm$manifestToEmit$ad !== void 0 ? _vm$manifestToEmit$ad : 0))])]), _vm._v(" "), _c('b-form-group', {
    staticClass: "row-4",
    attrs: {
      "label": "Saldo a Receber:"
    }
  }, [_c('FloatInput', {
    attrs: {
      "disabled": "",
      "value": _vm.toReceive.toFixed(2)
    }
  })], 1)], 1)], 1), _vm._v(" "), _c('b-form-group', {
    attrs: {
      "label": "Observações:"
    }
  }, [_c('b-textarea', {
    model: {
      value: _vm.observations,
      callback: function ($$v) {
        _vm.observations = $$v;
      },
      expression: "observations"
    }
  })], 1), _vm._v(" "), _c('b-button', {
    attrs: {
      "size": "md",
      "variant": "primary"
    },
    on: {
      "click": _vm.calculateTax
    }
  }, [_vm._v("\n        Processar Valores\n      ")])], 1)], 1), _vm._v(" "), _c('b-modal', {
    ref: "manifestEmissionModal",
    attrs: {
      "size": "lg",
      "modal-class": "modal-top"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function (_ref2) {
        var ok = _ref2.ok,
          cancel = _ref2.cancel;
        return [_c('b-button', {
          attrs: {
            "size": "md",
            "variant": "secondary"
          },
          on: {
            "click": function () {
              return cancel(ok);
            }
          }
        }, [_vm._v("\n        Fechar\n      ")])];
      }
    }])
  }, [_c('div', {
    attrs: {
      "slot": "modal-title"
    },
    slot: "modal-title"
  }, [_c('span', {
    staticClass: "font-weight-light"
  }, [_vm._v(" Manifestos que podem gerar ")]), _vm._v("contrato de frete"), _c('br'), _vm._v(" "), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Abaixo segue a lista de manifestos validos para contrato de frete.")]), _vm._v(" "), _c('br')]), _vm._v(" "), _c('BusyOverlay', {
    attrs: {
      "show": _vm.manifestIsBusy
    }
  }, [_c('CTable', {
    attrs: {
      "fields": _vm.checkedFields,
      "items": _vm.manifestResults
    },
    scopedSlots: _vm._u([{
      key: "cell(actions)",
      fn: function (data) {
        return [_c('b-btn', {
          attrs: {
            "size": "sm",
            "variant": "primary text-white",
            "title": data.id
          },
          on: {
            "click": function ($event) {
              return _vm.generateContract(data.item.manifest);
            }
          }
        }, [_vm._v("\n            Gerar Contrato de Frete\n          ")])];
      }
    }])
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }