<template>
  <b-table
    ref="bTable"
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
    :items="items"
    :fields="customFields"
    type="number"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name"></slot>
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </b-table>
</template>

<script>
/* eslint-disable */
import { dateFn } from '@/helpers/table-formatter'
import { isNil } from 'lodash'
export default {
  name: 'CTable',
  props: {
    fields: {
      type: Array,
      default: () => null
    },
    items: {
      type: Array,
      default: () => null
    },
    // default values
    bordered: {
      type: Boolean,
      default: true
    },
    selectable: {
      type: Boolean,
      default: true
    },
    striped: {
      type: Boolean,
      default: true
    },
    isStickyHeader: {
      type: Boolean,
      default: false
    },
    isSortable: {
      type: Boolean,
      default: false
    },
    isSortIconLeft: {
      type: Boolean,
      default: false
    },
    isStacked: {
      type: Boolean,
      default: false
    },
    isStriped: {
      type: Boolean,
      default: false
    },
    isHoverable: {
      type: Boolean,
      default: true
    },
    isBordered: {
      type: Boolean,
      default: false
    },
    isBorderless: {
      type: Boolean,
      default: false
    },
    isOutlined: {
      type: Boolean,
      default: false
    },
    isSmall: {
      type: Boolean,
      default: false
    },
    isFixed: {
      type: Boolean,
      default: false
    },
    isSelectable: {
      type: Boolean,
      default: true
    },
    isDark: {
      type: Boolean,
      default: false
    },
    isFootClone: {
      type: Boolean,
      default: false
    },
    headerStyle: {
      default: null
    },
    // Table options
    headerStyleOptions: [
      { text: 'Default', value: null },
      { text: 'Light', value: 'light' },
      { text: 'Dark', value: 'dark' }
    ]
  },
  computed: {
    customFields() {
      return this.fields.map(value => {
        const mapped = value
        let formatter = value => value
        if (['start_at', 'end_at', 'created_at', 'updated_at', 'deleted_at', 'changed_at'].includes(mapped.key)) {
          formatter = dateFn()
        }
        if (isNil(mapped.formatter)) {
          mapped.formatter = value => {
            return !isNil(value) ? formatter(value) : 'NI'
          }
        }

        return mapped
      })
    }
  },
  methods: {
    selectAllRows() {
      return this.$refs.bTable.selectAllRows()
    },
    clearSelected() {
      return this.$refs.bTable.clearSelected()
    }
  }
}
</script>
