'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import serviceFiscalNoteService from '@/features/service-fiscal-notes/service-fiscal-notes-api'

const busyMutateName = 'setServiceFiscalNotesBusy'

export default {
  namespaced: true,
  state: {
    serviceFiscalNoteList: [],
    serviceFiscalNoteFilter: '',
    serviceFiscalNoteIsBusy: false,
    serviceFiscalNoteTotalRows: 0,
    serviceFiscalNoteCurrentPage: 1,
    serviceFiscalNotePerPage: 10,
    serviceFiscalNoteLastPage: 0
  },
  actions: {
    async listServiceFiscalNotes({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await serviceFiscalNoteService().list(page, perPage, text)
          setState(commit, 'serviceFiscalNoteTotalRows', payload.total)
          setState(commit, 'serviceFiscalNoteCurrentPage', payload.page)
          setState(commit, 'serviceFiscalNotePerPage', payload.perPage)
          setState(commit, 'serviceFiscalNoteLastPage', payload.lastPage)

          commit('setServiceFiscalNotesList', payload.data)
        }
      )
    },
    async showServiceFiscalNote({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return serviceFiscalNoteService().show(id)
        }
      )
    },
    async createServiceFiscalNote({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await serviceFiscalNoteService().create(payload)
          commit('unshiftServiceFiscalNote', entry)
        }
      )
    },
    async updateServiceFiscalNote({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await serviceFiscalNoteService().update(payload)
          commit('updateServiceFiscalNoteInList', entry)
          return entry
        }
      )
    },
    async deleteServiceFiscalNote({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await serviceFiscalNoteService().delete(id)
          if (result) await commit('removeServiceFiscalNoteInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredServiceFiscalNotes(state) {
      return filterElements(state.serviceFiscalNoteList, state.filter)
    }
  },
  mutations: {
    mutate,
    setServiceFiscalNotesList(state, payload) {
      state.serviceFiscalNoteList = payload
    },
    setServiceFiscalNotesFilter(state, payload) {
      state.serviceFiscalNoteList = payload
    },
    setServiceFiscalNotesBusy(state, value = false) {
      state.serviceFiscalNoteIsBusy = value
    },
    unshiftServiceFiscalNote(state, payload) {
      state.serviceFiscalNoteList.unshift(payload)
    },
    removeServiceFiscalNoteInList(state, payload) {
      state.serviceFiscalNoteList = state.serviceFiscalNoteList.filter(
        serviceFiscalNote => serviceFiscalNote.id !== payload
      )
    },
    updateServiceFiscalNoteInList(state, payload) {
      if (state.serviceFiscalNoteList) {
        state.serviceFiscalNoteList = state.serviceFiscalNoteList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
