<template>
  <c-input
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
    style="text-align: right !important;"
    class="form-control"
    :size="size"
    v-currency="{
      currency: {
        suffix: '',
        prefix: ''
      },
      precision: {
        min: 0,
        max: 0
      },
      valueAsInteger: false,
      distractionFree: false,
      autoDecimalMode: false,
      allowNegative: false
    }"
    ><slot></slot>
  </c-input>
</template>

<script>
export default {
  name: 'integer-input',
  props: {
    rows: Array,
    header: Array,
    size: {
      default: 'md'
    }
  },
  methods: {}
}
</script>
