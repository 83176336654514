import HttpClient from '@/services/http-client'

class FreightContractTaxService extends HttpClient {
  async show(id) {
    return this.rest.get('/v1/freight-contract-taxes/' + id)
  }

  async list(page = 1, perPage = 1000, text = null) {
    return this.rest.get('/v1/freight-contract-taxes', {
      params: {
        page,
        perPage,
        q: text
      }
    })
  }

  async create(payload) {
    return this.rest.post('/v1/freight-contract-taxes', payload)
  }

  async update(payload) {
    return this.rest.patch('/v1/freight-contract-taxes/' + payload.id, payload)
  }

  async delete(id) {
    return this.rest.delete('/v1/freight-contract-taxes/' + id)
  }
}

export default () => new FreightContractTaxService()
