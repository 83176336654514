'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  setState
} from '@/helpers/store-helpers'

import requestRecordService from '@/features/request-records/request-records-api'

const busyMutateName = 'setRequestRecordsBusy'

export default {
  namespaced: true,
  state: {
    requestRecordList: [],
    requestRecordFilter: '',
    requestRecordIsBusy: false,
    requestRecordTotalRows: 0,
    requestRecordCurrentPage: 1,
    requestRecordPerPage: 10,
    requestRecordLastPage: 0
  },
  actions: {
    async listRequestRecords({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await requestRecordService().list(page, perPage, text)
          setState(commit, 'requestRecordTotalRows', payload.total)
          setState(commit, 'requestRecordCurrentPage', payload.page)
          setState(commit, 'requestRecordPerPage', payload.perPage)
          setState(commit, 'requestRecordLastPage', payload.lastPage)

          commit('setRequestRecordsList', payload.data)
        }
      )
    },
    async showRequestRecord({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return requestRecordService().show(id)
        }
      )
    },
    async createRequestRecord({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await requestRecordService().create(payload)
          commit('unshiftRequestRecord', entry)
        }
      )
    },
    async updateRequestRecord({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await requestRecordService().update(payload)
          commit('updateRequestRecordInList', entry)
          return entry
        }
      )
    },
    async deleteRequestRecord({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await requestRecordService().delete(id)
          if (result) await commit('removeRequestRecordInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredRequestRecords(state) {
      return filterElements(state.requestRecordList, state.filter)
    }
  },
  mutations: {
    mutate,
    setRequestRecordsList(state, payload) {
      state.requestRecordList = payload
    },
    setRequestRecordsFilter(state, payload) {
      state.requestRecordList = payload
    },
    setRequestRecordsBusy(state, value = false) {
      state.requestRecordIsBusy = value
    },
    unshiftRequestRecord(state, payload) {
      state.requestRecordList.unshift(payload)
    },
    removeRequestRecordInList(state, payload) {
      state.requestRecordList = state.requestRecordList.filter(
        requestRecord => requestRecord.id !== payload
      )
    },
    updateRequestRecordInList(state, payload) {
      if (state.requestRecordList) {
        state.requestRecordList = state.requestRecordList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
