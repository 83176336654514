<template>
  <Multiselector
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
    track-by="id"
    label="name"
    :custom-label="
      val =>
        `#${val.id ?? 'NI'} - Placa: ${val.plate ?? 'NI'} - ${val?.plateUf?.name ?? ''}; Renavam: ${val?.renavam ??
          'NI'}; CRLV: ${val?.crlv ?? 'NI'}`
    "
    @open="onOpenPicker"
    :options="vehicleList"
    :multiple="false"
    :allow-empty="false"
    :taggable="false"
  >
    <template slot="option" slot-scope="{ props }">
      <div class="option__desc">
        <b-col>
          <b-row>
            <span class="option__small">#{{ props?.id ?? 'NI' }} </span>
            <span class="option__small">
              - Placa:
              {{ props?.plate ?? 'NI' }}
            </span>
          </b-row>
          <b-row>
            <span class="option__small"
              >Renavam:
              {{ props?.renavam ?? 'N/I' }}
            </span>
          </b-row>
        </b-col>
      </div>
    </template>
  </Multiselector>
</template>

<script>
import vehiclesMixin from '@/features/vehicles/store/mixin'

export default {
  name: 'vehicle-selector',
  props: {
    rows: Array,
    header: Array
  },
  mixins: [vehiclesMixin],
  methods: {
    async onOpenPicker() {
      await this.listVehicles({ page: 1, perPage: 1000 })
    }
  }
}
</script>
