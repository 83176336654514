var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "id": "overlay-background",
      "show": _vm.transportCostIsBusy,
      "variant": "white",
      "opacity": "0.85",
      "blur": "2px",
      "spinner-variant": "primary",
      "spinner-type": "grow",
      "spinner-small": true,
      "rounded": "sm"
    }
  }, [_c('multiselector', _vm._g(_vm._b({
    attrs: {
      "track-by": "id",
      "label": "name",
      "custom-label": function (value) {
        var _value$id, _value$supplier$name, _value$supplier, _value$company$name, _value$company;
        return ("#" + ((_value$id = value === null || value === void 0 ? void 0 : value.id) !== null && _value$id !== void 0 ? _value$id : 'NI') + " |Fornec.: " + ((_value$supplier$name = value === null || value === void 0 ? void 0 : (_value$supplier = value.supplier) === null || _value$supplier === void 0 ? void 0 : _value$supplier.name) !== null && _value$supplier$name !== void 0 ? _value$supplier$name : 'NI') + " |Empr.:" + ((_value$company$name = value === null || value === void 0 ? void 0 : (_value$company = value.company) === null || _value$company === void 0 ? void 0 : _value$company.name) !== null && _value$company$name !== void 0 ? _value$company$name : 'NI'));
      },
      "options": _vm.transportCostList,
      "multiple": false,
      "disabled": _vm.disabled,
      "allow-empty": false,
      "taggable": false
    },
    scopedSlots: _vm._u([{
      key: "singleLabel",
      fn: function (_ref) {
        var _props$supplier$name, _props$supplier, _props$company$name, _props$company;
        var props = _ref.props;
        return [_c('span', {
          staticClass: "option__name"
        }, [_vm._v("\n        ID: " + _vm._s(props === null || props === void 0 ? void 0 : props.id) + "\n\n        Fornecedor: " + _vm._s((_props$supplier$name = props === null || props === void 0 ? void 0 : (_props$supplier = props.supplier) === null || _props$supplier === void 0 ? void 0 : _props$supplier.name) !== null && _props$supplier$name !== void 0 ? _props$supplier$name : 'NI') + "\n\n        Empresa: " + _vm._s((_props$company$name = props === null || props === void 0 ? void 0 : (_props$company = props.company) === null || _props$company === void 0 ? void 0 : _props$company.name) !== null && _props$company$name !== void 0 ? _props$company$name : 'NI') + "\n      ")]), _vm._v(" "), _c('span', {
          staticClass: "option__desc"
        })];
      }
    }, {
      key: "option",
      fn: function (_ref2) {
        var _props$id, _props$company$name2, _props$company2, _props$supplier$name2, _props$supplier2, _props$vehicleType$na, _props$vehicleType, _props$route$descript, _props$route;
        var props = _ref2.props;
        return [_c('div', {
          staticClass: "option__desc"
        }, [_c('b-col', [_c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("ID: " + _vm._s((_props$id = props === null || props === void 0 ? void 0 : props.id) !== null && _props$id !== void 0 ? _props$id : 'NI') + " ")])]), _vm._v(" "), _c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("Empresa:\n              " + _vm._s((_props$company$name2 = props === null || props === void 0 ? void 0 : (_props$company2 = props.company) === null || _props$company2 === void 0 ? void 0 : _props$company2.name) !== null && _props$company$name2 !== void 0 ? _props$company$name2 : 'NI') + "\n            ")])]), _vm._v(" "), _c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("Fornecedor:\n              " + _vm._s((_props$supplier$name2 = props === null || props === void 0 ? void 0 : (_props$supplier2 = props.supplier) === null || _props$supplier2 === void 0 ? void 0 : _props$supplier2.name) !== null && _props$supplier$name2 !== void 0 ? _props$supplier$name2 : 'NI') + "\n            ")])]), _vm._v(" "), _c('b-row', [_c('span', {
          staticClass: "option__small"
        }, [_vm._v("Tipo de veículo:\n              " + _vm._s((_props$vehicleType$na = props === null || props === void 0 ? void 0 : (_props$vehicleType = props.vehicleType) === null || _props$vehicleType === void 0 ? void 0 : _props$vehicleType.name) !== null && _props$vehicleType$na !== void 0 ? _props$vehicleType$na : 'NI') + "\n            ")])]), _vm._v(" "), _c('b-row', [_c('span', {
          staticClass: "option__title"
        }, [_vm._v("\n              Descrição de Rota:\n              " + _vm._s((_props$route$descript = props === null || props === void 0 ? void 0 : (_props$route = props.route) === null || _props$route === void 0 ? void 0 : _props$route.description) !== null && _props$route$descript !== void 0 ? _props$route$descript : 'NI') + "\n            ")])])], 1)], 1)];
      }
    }])
  }, 'multiselector', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }