var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Multiselector', _vm._g(_vm._b({
    attrs: {
      "id": _vm.id,
      "disabled": _vm.disabled,
      "track-by": _vm.trackBy,
      "label": _vm.label,
      "loading": _vm.isBusy,
      "custom-label": _vm.customLabel,
      "options": _vm.entityRows,
      "multiple": false,
      "allow-empty": false,
      "taggable": false
    },
    on: {
      "search-change": _vm.asyncSearchEntity
    }
  }, 'Multiselector', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners));
}
var staticRenderFns = []

export { render, staticRenderFns }