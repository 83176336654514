var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.value ? _c('div', [_c('b-link', {
    attrs: {
      "target": "_blank"
    },
    on: {
      "click": _vm.downloadB2File
    }
  }, [_vm._v("\n      " + _vm._s(_vm.value.client_file_name || 'N/D') + "\n    ")])], 1) : _c('div', [_vm._v("Sem Arquivo")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }