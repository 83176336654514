'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  setState
} from '@/helpers/store-helpers'

import measurementTypeService from '@/features/measurement-types/measurement-types-api'

const busyMutateName = 'setMeasurementTypesBusy'

export default {
  namespaced: true,
  state: {
    measurementTypeList: [],
    measurementTypeFilter: '',
    measurementTypeIsBusy: false,
    measurementTypeTotalRows: 0,
    measurementTypeCurrentPage: 1,
    measurementTypePerPage: 10,
    measurementTypeLastPage: 0
  },
  actions: {
    async listMeasurementTypes({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await measurementTypeService().list(page, perPage, text)
          setState(commit, 'measurementTypeTotalRows', payload.total)
          setState(commit, 'measurementTypeCurrentPage', payload.page)
          setState(commit, 'measurementTypePerPage', payload.perPage)
          setState(commit, 'measurementTypeLastPage', payload.lastPage)

          commit('setMeasurementTypesList', payload.data)
        }
      )
    },
    async showMeasurementType({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return measurementTypeService().show(id)
        }
      )
    },
    async createMeasurementType({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await measurementTypeService().create(payload)
          commit('unshiftMeasurementType', entry)
        }
      )
    },
    async updateMeasurementType({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await measurementTypeService().update(payload)
          commit('updateMeasurementTypeInList', entry)
          return entry
        }
      )
    },
    async deleteMeasurementType({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await measurementTypeService().delete(id)
          if (result) await commit('removeMeasurementTypeInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredMeasurementTypes(state) {
      return filterElements(state.measurementTypeList, state.filter)
    }
  },
  mutations: {
    mutate,
    setMeasurementTypesList(state, payload) {
      state.measurementTypeList = payload
    },
    setMeasurementTypesFilter(state, payload) {
      state.measurementTypeList = payload
    },
    setMeasurementTypesBusy(state, value = false) {
      state.measurementTypeIsBusy = value
    },
    unshiftMeasurementType(state, payload) {
      state.measurementTypeList.unshift(payload)
    },
    removeMeasurementTypeInList(state, payload) {
      state.measurementTypeList = state.measurementTypeList.filter(
        measurementType => measurementType.id !== payload
      )
    },
    updateMeasurementTypeInList(state, payload) {
      if (state.measurementTypeList) {
        state.measurementTypeList = state.measurementTypeList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
