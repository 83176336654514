import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  methods: {
    ...mapActions('paths', [
      'listPaths',
      'showPath',
      'deletePath',
      'createPath',
      'updatePath'
    ]),
    ...mapMutations('paths', [
      'cleanPathsErrors',
      'setPathsList',
      'setPathsFilter',
      'setPathsBusy'
    ])
  },
  computed: {
    ...mapState('paths', [
      'pathIsBusy',
      'pathList',
      'pathFilter',
      'pathPerPage',
      'pathCurrentPage',
      'pathTotalRows',
      'pathLastPage'
    ]),
    ...mapGetters('paths', [
      'filteredPaths'
    ])
  }
}
