'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  setState
} from '@/helpers/store-helpers'

import sefazConveyorTypeService from '@/features/sefaz-conveyor-types/sefaz-conveyor-types-api'

const busyMutateName = 'setSefazConveyorTypesBusy'

export default {
  namespaced: true,
  state: {
    sefazConveyorTypeList: [],
    sefazConveyorTypeFilter: '',
    sefazConveyorTypeIsBusy: false,
    sefazConveyorTypeTotalRows: 0,
    sefazConveyorTypeCurrentPage: 1,
    sefazConveyorTypePerPage: 10,
    sefazConveyorTypeLastPage: 0
  },
  actions: {
    async listSefazConveyorTypes({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await sefazConveyorTypeService().list(page, perPage, text)
          setState(commit, 'sefazConveyorTypeTotalRows', payload.total)
          setState(commit, 'sefazConveyorTypeCurrentPage', payload.page)
          setState(commit, 'sefazConveyorTypePerPage', payload.perPage)
          setState(commit, 'sefazConveyorTypeLastPage', payload.lastPage)

          commit('setSefazConveyorTypeList', payload.data)
        }
      )
    },
    async showSefazConveyorType({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return sefazConveyorTypeService().show(id)
        }
      )
    },
    async createSefazConveyorType({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = sefazConveyorTypeService().create(payload)
          commit('unshiftSefazConveyorType', entry)
        }
      )
    },
    async updateSefazConveyorType({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = sefazConveyorTypeService().update(payload)
          commit('updateSefazConveyorTypeInList', entry)
          return entry
        }
      )
    },
    async deleteSefazConveyorType({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await sefazConveyorTypeService().delete(id)
          if (result) await commit('removeSefazConveyorTypeInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredSefazConveyorTypes(state) {
      return filterElements(state.sefazConveyorTypeList, state.filter)
    }
  },
  mutations: {
    mutate,
    setSefazConveyorTypeList(state, payload) {
      state.sefazConveyorTypeList = payload ?? []
    },
    setSefazConveyorTypesFilter(state, payload) {
      state.sefazConveyorTypeList = payload
    },
    setSefazConveyorTypesBusy(state, value = false) {
      state.sefazConveyorTypeIsBusy = value
    },
    unshiftSefazConveyorType(state, payload) {
      state.sefazConveyorTypeList.unshift(payload)
    },
    removeSefazConveyorTypeInList(state, payload) {
      state.sefazConveyorTypeList = state.sefazConveyorTypeList.filter(
        sefazConveyorType => sefazConveyorType.id !== payload
      )
    },
    updateSefazConveyorTypeInList(state, payload) {
      if (state.sefazConveyorTypeList) {
        state.sefazConveyorTypeList = state.sefazConveyorTypeList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
