var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-table', _vm._g(_vm._b({
    ref: "bTable",
    attrs: {
      "items": _vm.items,
      "fields": _vm.customFields,
      "type": "number"
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, name) {
      return {
        key: name,
        fn: function (slotData) {
          return [_vm._t(name, null, null, slotData)];
        }
      };
    })], null, true)
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners), [_vm._l(_vm.$slots, function (_, name) {
    return _vm._t(name, null, {
      "slot": name
    });
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }