<template>
  <c-input
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
    v-mask="'#####-###'"
    placeholder="99999-999"
    @input="onInput"
  >
    <slot></slot>
  </c-input>
</template>

<script>
import { debounce } from 'lodash'
import zipcodeApi from '@/features/zipcodes/zipcodes-api'

export default {
  mixins: [],
  data() {
    return {
      zipcode: null
    }
  },
  name: 'ZipInput',
  props: {
    // eslint-disable-next-line vue/no-unused-properties
    rows: Array,
    //  eslint-disable-next-line vue/no-unused-properties
    header: Array
  },
  methods: {
    onInput(value) {
      this.zipcode = value
      if (this.zipcode && this.zipcode.length >= 5) {
        this.debounceSearch()
      }
    },
    debounceSearch: debounce(function() {
      const that = this
      this.isBusy = true
      zipcodeApi()
        .show(that.zipcode)
        .then(payload => {
          that.isBusy = false
          that.$emit('onFoundAddress', payload)
        })
        .catch(err => {
          that.isBusy = false
          console.log(err)
        })
    }, 700)
  }
}
</script>
