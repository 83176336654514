'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  setState
} from '@/helpers/store-helpers'

import typeCostRangesService from '@/features/type-cost-ranges/type-cost-ranges-api'

const busyMutateName = 'setTypeCostRangesBusy'

export default {
  namespaced: true,
  state: {
    typeCostRangesList: [],
    typeCostRangesFilter: '',
    typeCostRangesIsBusy: false,
    typeCostRangesTotalRows: 0,
    typeCostRangesCurrentPage: 1,
    typeCostRangesPerPage: 10,
    typeCostRangesLastPage: 0
  },
  actions: {
    async listTypeCostRanges({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await typeCostRangesService().list(page, perPage, text)
          setState(commit, 'typeCostRangesTotalRows', payload.total)
          setState(commit, 'typeCostRangesCurrentPage', payload.page)
          setState(commit, 'typeCostRangesPerPage', payload.perPage)
          setState(commit, 'typeCostRangesLastPage', payload.lastPage)

          commit('setTypeCostRangesList', payload.data)
        }
      )
    },
    async showTypeCostRanges({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return typeCostRangesService().show(id)
        }
      )
    },
    async createTypeCostRanges({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = typeCostRangesService().create(payload)
          commit('unshiftTypeCostRanges', entry)
        }
      )
    },
    async updateTypeCostRanges({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = typeCostRangesService().update(payload)
          commit('updateTypeCostRangesInList', entry)
          return entry
        }
      )
    },
    async deleteTypeCostRanges({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await typeCostRangesService().delete(id)
          if (result) await commit('removeTypeCostRangesInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredTypeCostRanges(state) {
      return filterElements(state.typeCostRangesList, state.filter)
    }
  },
  mutations: {
    mutate,
    setTypeCostRangesList(state, payload) {
      state.typeCostRangesList = payload
    },
    setTypeCostRangesFilter(state, payload) {
      state.typeCostRangesList = payload
    },
    setTypeCostRangesBusy(state, value = false) {
      state.typeCostRangesIsBusy = value
    },
    unshiftTypeCostRanges(state, payload) {
      state.typeCostRangesList.unshift(payload)
    },
    removeTypeCostRangesInList(state, payload) {
      state.typeCostRangesList = state.typeCostRangesList.filter(
        typeCostRanges => typeCostRanges.id !== payload
      )
    },
    updateTypeCostRangesInList(state, payload) {
      if (state.typeCostRangesList) {
        state.typeCostRangesList = state.typeCostRangesList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
