'use strict'

import {
  mutate,
  doAsyncBusyTask,
  filterElements,
  // eslint-disable-next-line no-unused-vars
  setState
} from '@/helpers/store-helpers'

import conciergeRecordService from '@/features/concierge-records/concierge-records-api'

const busyMutateName = 'setConciergeRecordsBusy'

export default {
  namespaced: true,
  state: {
    conciergeRecordList: [],
    conciergeRecordFilter: '',
    conciergeRecordIsBusy: false,
    conciergeRecordTotalRows: 0,
    conciergeRecordCurrentPage: 1,
    conciergeRecordPerPage: 10,
    conciergeRecordLastPage: 0
  },
  actions: {
    async listConciergeRecords({ state, commit }, payload = {}) {
      const { page = 1, perPage = 100, text = '' } = payload
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const payload = await conciergeRecordService().list(page, perPage, text)
          setState(commit, 'conciergeRecordTotalRows', payload.total)
          setState(commit, 'conciergeRecordCurrentPage', payload.page)
          setState(commit, 'conciergeRecordPerPage', payload.perPage)
          setState(commit, 'conciergeRecordLastPage', payload.lastPage)

          commit('setConciergeRecordsList', payload.data)
        }
      )
    },
    async showConciergeRecord({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          return conciergeRecordService().show(id)
        }
      )
    },
    async createConciergeRecord({ state, dispatch, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await conciergeRecordService().create(payload)
          commit('unshiftConciergeRecord', entry)
        }
      )
    },
    async updateConciergeRecord({ state, commit }, payload) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const entry = await conciergeRecordService().update(payload)
          commit('updateConciergeRecordInList', entry)
          return entry
        }
      )
    },
    async deleteConciergeRecord({ state, commit }, id) {
      return doAsyncBusyTask(
        { commit, state, busyMutateName },
        async ({ state, commit }) => {
          const result = await conciergeRecordService().delete(id)
          if (result) await commit('removeConciergeRecordInList', id)
          return result
        }
      )
    }
  },
  getters: {
    filteredConciergeRecords(state) {
      return filterElements(state.conciergeRecordList, state.filter)
    }
  },
  mutations: {
    mutate,
    setConciergeRecordsList(state, payload) {
      state.conciergeRecordList = payload
    },
    setConciergeRecordsFilter(state, payload) {
      state.conciergeRecordList = payload
    },
    setConciergeRecordsBusy(state, value = false) {
      state.conciergeRecordIsBusy = value
    },
    unshiftConciergeRecord(state, payload) {
      state.conciergeRecordList.unshift(payload)
    },
    removeConciergeRecordInList(state, payload) {
      state.conciergeRecordList = state.conciergeRecordList.filter(
        conciergeRecord => conciergeRecord.id !== payload
      )
    },
    updateConciergeRecordInList(state, payload) {
      if (state.conciergeRecordList) {
        state.conciergeRecordList = state.conciergeRecordList.map(val => {
          if (val.id === payload.id) {
            return payload
          }
          return val
        })
      }
    }
  }
}
