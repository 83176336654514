import HttpClient from './http-client'

class PermissionAPI extends HttpClient {
  async update() {
    return this.rest.patch('/v1/permissions')
  }

  async list(opts) {
    const { page = 1, perPage = 10, text = null } = opts || {}
    return this.rest.get('/v1/permissions', {
      params: {
        page,
        perPage,
        text
      }
    })
  }

  async get(id) {
    return this.rest.get('/v1/permissions/' + id)
  }

  async delete(id) {
    return this.rest.delete('/v1/permissions/' + id)
  }
}
export default () => new PermissionAPI()
