var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('multiselector', _vm._g(_vm._b({
    attrs: {
      "track-by": "name",
      "label": "name",
      "options": _vm.documentTypes,
      "multiple": false,
      "allow-empty": false,
      "taggable": false
    }
  }, 'multiselector', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners));
}
var staticRenderFns = []

export { render, staticRenderFns }